export const redirect_tabs = [
  {
    containerName: 'col-12 p-0 d-flex flex-wrap',
    buttons: [
      {
        label: 'pending publish',
        viewLabel: 'PENDING_PUBLISH',
        to: `/posts`,
      },
      {
        label: 'published',
        viewLabel: 'TITLE_PUBLISHED',
        to: '/published',
      },
      {
        label: 'rented',
        viewLabel: 'RENTED',
        to: '/rented',
      },
      {
        label: 'registered',
        viewLabel: 'PROPERTY_REGISTERED',
        to: '/registered',
      },
      {
        label: 'pending republish',
        viewLabel: 'PENDING_REPUBLISH',
        to: '/posts/to-republish',
      },
      {
        label: 'requisition',
        viewLabel: 'REQUISITION',
        to: '/requisitions',
        state: process.env.REACT_APP_REQUISITION_STATE
      },
    ],
  },
]

export const PUBLISHED_POST_TABS = [
  {
    label: 'missing',
    viewLabel: 'NO_DOCUMENTS',
  },
  {
    label: 'pending',
    viewLabel: 'DOC_PENDING',
  },
  {
    label: 'approved',
    viewLabel: 'DOC_APPROVED',
  },
]

export const REGISTERED_POST_TABS = [
  {
    label: 'pending',
    viewLabel: 'PENDING_UNIT',
  },
  {
    label: 'approved',
    viewLabel: 'APPROVED_UNIT',
  },
  {
    label: 'rented',
    viewLabel: 'RENTED',
  }
]
