export const expireRenterStart = () => {
    return {
        type: 'EXPIRE_RENTER_STARTED',
    };
};

export const expireRenterSuccess = () => {
    return {
        type: 'EXPIRE_RENTER_SUCCESS',
    };
};
export const expireRenterError = (error: any) => {
    return {
        type: 'EXPIRE_RENTER_ERROR',
        payload: {
            error,
        },
    };
};
