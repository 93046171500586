// TODO Cambiar a dir 'actionCreators'!

export const rejectRenterAmountStart = () => {
  return {
    type: 'REJECT_RENTER_AMOUNT_STARTED',
  }
}

export const rejectRenterAmountSuccess = (renter_validation: any) => {
  return {
    type: 'REJECT_RENTER_AMOUNT_SUCCESS',
    payload: { renter_validation },
  }
}
export const rejectRenterAmountError = (error: any) => {
  return {
    type: 'REJECT_RENTER_AMOUNT_ERROR',
    payload: {
      error,
    },
  }
}
