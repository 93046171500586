import {  unpublishPostStart,  unpublishPostSuccess,  unpublishPostError } from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import  getPostAdmin  from '../../../tango-react-base/reduxActions/getPostAdmin'
const unpublishPost = (postId: number, docState: string) => {
  return (dispatch: any) => {
    dispatch(unpublishPostStart())
    putData(`posts/${postId}/cancel`)
      .then((result: any) => {
        dispatch(unpublishPostSuccess(result.data))
        toast(intl.get('SUCCESSFULLY_UNPUBLISHED'))
        dispatch(getPostAdmin(docState, 'available', 'brokerage,new'))
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(unpublishPostError(e))
      })
  }
}
export default unpublishPost
