import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import intl from 'react-intl-universal'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import {
  getPendingVisits,
  assignVisitors,
  getPendingVisitsIds,
} from '../../redux/actions'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import SortTable from '../../components/sortTable'

/**
 * Define the props of the component
 *  @typedef  Props
 *  @type {(function | Array | string)}
 *  @property {function} onSetTemplateChild - is a function.
 *  @property {function} onGetPendingVisits - is a function.
 *  @property {Array} visitsPhotographer - is an Array.
 *  @property {Array} visitsAgent - is an Array.
 *  @property {string} fetchingStatus - is an string.
 */
interface Props {
  onSetTemplateChild: (child: JSX.Element) => void
  onGetPendingVisits: (role: string) => void
  onAssignVisitors: (
    visitorsToAssign: any,
    setLoading: Function,
    role: string,
    visitorsSelected: Function,
    visitorsIds: Array<any>,
  ) => void
  onGetPendingVisitsIds: (role: string, ids: Array<any>) => void
  visitsPhotographer: Array<any>
  visitsAgent: Array<any>
  fetchingStatus: string
  photographsAvailable: Array<any>
  agentsAvailabe: Array<any>
  fetchingStatusPhotographerIds: string
  fetchingStatusAgentIds: string
}

/**
 * Visits is a functional component with props.
 * The main function of this component is to render the tables (tanguero/ photographer).
 *  @function
 *  @param {Props} onSetTemplateChild - function onSetTemplateChild
 *  @param {Props} onGetPendingVisits - function onGetPendingVisits
 *  @param {Props} visitsPhotographer - return an array of visits for photographer
 *  @param {Props} visitsAgent - return an array of visits for agent
 *  @param {Props} fetchingStatus - return a string with the status of the fetching
 *  @returns {(JSX.Element)} Returns a view with two tables
 */
const Visits: FC<Props> = ({
  onSetTemplateChild,
  onGetPendingVisits,
  onAssignVisitors,
  visitsPhotographer,
  visitsAgent,
  fetchingStatus,
  onGetPendingVisitsIds,
  fetchingStatusPhotographerIds,
  fetchingStatusAgentIds,
}) => {
  const history = useHistory()
  const [togglePhotographer, setTogglePhotographer] = useState(true)
  const [toggleTanguero, setToggleTanguero] = useState(true)
  const [photographersSelected, setPhotographersSelected] = useState<
    Array<any>
  >([])
  const [agentsSelected, setAgentsSelected] = useState<Array<any>>([])
  const [visitsAgentData, setVisitsAgentData] = useState(visitsAgent)
  const [visitsPhotographerData, setVisitsPhotographerData] = useState(
    visitsPhotographer,
  )
  const [loadingAgentTable, setLoadingAgentTable] = useState(false)
  const [loadingPhotographerTable, setLoadingPhotographerTable] = useState(
    false,
  )
  const [visitsAgentIds, setVisistAgentIds] = useState([])
  const [visitsPhotographerIds, setVisitsPhotographerIds] = useState([])

  useEffect(() => {
    const ROLES = ['verify', 'tour']
    ROLES.forEach((role) => onGetPendingVisits(role))
  }, [onGetPendingVisits])

  useEffect(() => {
    setVisitsPhotographerData(visitsPhotographer)
    let ids: any = []
    if (visitsPhotographer.length) {
      visitsPhotographer.forEach((visit) => {
        ids = ids.concat(visit.id)
      })
      setVisitsPhotographerIds(ids)
    }
    setTogglePhotographer(true)
  }, [visitsPhotographer])

  useEffect(() => {
    setVisitsAgentData(visitsAgent)
    if (visitsAgent.length) {
      let ids: any = []
      visitsAgent.forEach((visit) => {
        ids = ids.concat(visit.id)
      })
      setVisistAgentIds(ids)
    }
    setToggleTanguero(true)
  }, [visitsAgent])

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_VISITS')}</h1>
        </>,
      )
    }
  }, [history, onSetTemplateChild])

  const handleEditAction = (role: string) => {
    if (role === 'VERIFIER' && !togglePhotographer) {
      if (photographersSelected.length) {
        onAssignVisitors(
          photographersSelected,
          setLoadingPhotographerTable,
          'verify',
          setPhotographersSelected,
          visitsPhotographerIds,
        )
        setLoadingPhotographerTable(true)
      } else {
        setTogglePhotographer(!togglePhotographer)
      }
    }
    if (role === 'AGENT' && !toggleTanguero) {
      if (agentsSelected.length) {
        onAssignVisitors(
          agentsSelected,
          setLoadingAgentTable,
          'tour',
          setAgentsSelected,
          visitsAgentIds,
        )
        setLoadingAgentTable(true)
      } else {
        setToggleTanguero(!toggleTanguero)
      }
    }
    if (role === 'VERIFIER') {
      if (togglePhotographer) {
        onGetPendingVisitsIds('verify', visitsPhotographerIds)
        setTogglePhotographer(!togglePhotographer)
      }
    }
    if (role === 'AGENT') {
      if (toggleTanguero) {
        onGetPendingVisitsIds('tour', visitsAgentIds)
        setToggleTanguero(!toggleTanguero)
      }
    }
  }

  const handleCancelAction = (role: string) => {
    if (role === 'VERIFIER') {
      setPhotographersSelected([])
      setTogglePhotographer(!togglePhotographer)
    } else {
      setAgentsSelected([])
      setToggleTanguero(!toggleTanguero)
    }
  }

  const handleChange = (
    unitVisitId: number,
    visitType: string,
    value?: number,
  ) => {
    const newVisitor = value
      ? { visitor_id: value, unit_visit_id: unitVisitId }
      : null
    if (visitType === 'verify') {
      let updatePhotographerSelected = photographersSelected.filter(
        (photographerSelected) =>
          photographerSelected.unit_visit_id !== unitVisitId,
      )
      if (newVisitor) {
        updatePhotographerSelected = [...updatePhotographerSelected, newVisitor]
      }
      setPhotographersSelected(updatePhotographerSelected)
    } else {
      let updateAgentsSelected = agentsSelected.filter(
        (agentSelected) => agentSelected.unit_visit_id !== unitVisitId,
      )
      if (newVisitor) {
        updateAgentsSelected = [...updateAgentsSelected, newVisitor]
      }
      setAgentsSelected(updateAgentsSelected)
    }
  }

  const renderVisitsView = (role: string, key: number) => {
    return (
      <div key={key}>
        <h1 className='text text-color-black my-4'>{intl.get(role)}</h1>
        <div className='row'>
          <SortTable
            data={
              role === 'VERIFIER' ? visitsPhotographerData : visitsAgentData
            }
            handleEdit={validationEdit(role)}
            handleChange={handleChange}
            loading={
              role === 'VERIFIER' ? loadingPhotographerTable : loadingAgentTable
            }
          />
          <div
            className={`button-sort-table-section ${
              role === 'VERIFIER' && loadingPhotographerTable ? 'd-none' : ''
            } ${role === 'AGENT' && loadingAgentTable ? 'd-none' : ''}`}
          >
            <div className='button-sort-table-container'>
              <div
                className={
                  validationEdit(role) ? 'button-container-w100' : 'w-50 pr-1'
                }
              >
                <button
                  className='button-primary button-sort-table'
                  onClick={() => handleEditAction(role)}
                  disabled={
                    role === 'VERIFIER'
                      ? loadingPhotographerTable
                      : loadingAgentTable
                  }
                >
                  {intl.get(validationEdit(role) ? 'EDIT' : 'SAVE')}
                </button>
              </div>
              {!validationEdit(role) && (
                <div className='w-50 pl-1'>
                  <button
                    className='button-five button-sort-table'
                    onClick={() => handleCancelAction(role)}
                  >
                    {intl.get('CANCEL')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const validationEdit = (role: string) =>
    role === 'VERIFIER' ? togglePhotographer : toggleTanguero

  return (
    <div className='container-home mt-5 pd-1'>
      <h1 className='new-subtitle font-weight-bold'>
        {intl.get('TITLE_PENDING')}
      </h1>
      <div className='template-line mb-5' />
      <div className='container-sort-table'>
        {!!visitsPhotographerData ? (
          renderVisitsView('VERIFIER', 1)
        ) : (
          <CircularLoading />
        )}
        {!!visitsAgentData ? renderVisitsView('AGENT', 2) : <CircularLoading />}
      </div>
    </div>
  )
}

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: {
  pendingVisits: {
    visitsPhotographer: []
    visitsAgent: []
    fetchingStatus: string
    fetchingStatusPhotographerIds: string
    fetchingStatusAgentIds: string
  }
}) => {
  const {
    visitsPhotographer,
    visitsAgent,
    fetchingStatus,
    fetchingStatusPhotographerIds,
    fetchingStatusAgentIds,
  } = state.pendingVisits || []
  return {
    visitsPhotographer,
    visitsAgent,
    fetchingStatus,
    fetchingStatusPhotographerIds,
    fetchingStatusAgentIds,
  }
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPendingVisits: (role: string) => {
      return dispatch(getPendingVisits(role, 'pending'))
    },
    onAssignVisitors: (
      visitorsToAssign: any,
      setLoading: Function,
      role: string,
      visitorsSelected: Function,
      visitorsIds: Array<any>,
    ) => {
      return dispatch(
        assignVisitors(
          visitorsToAssign,
          setLoading,
          role,
          visitorsSelected,
          visitorsIds,
        ),
      )
    },
    onGetPendingVisitsIds: (role: string, ids: Array<any>) => {
      return dispatch(getPendingVisitsIds(role, ids))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Visits)
