import {
    markAsPaidChargesStart,
    markAsPaidChargesSuccess,
    markAsPaidChargesError,
    markAsPaidChargesAttemptStart,
} from './types';
import { postData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
const markAsPaidCharges = (
    chargeLinesIds: Array<number>,
    unitVersionId: number,
    rentID: number,
    provider_name: string,
    provider_method: string,
    total?: number,
    fullAdress?: string,
) => {
    const params = {
        charge_lines_ids: chargeLinesIds,
        unit_version_id: unitVersionId,
        provider_name: provider_name,
        rent_id: rentID,
        payment_method: provider_method,
    };
    return (dispatch: any) => {
        dispatch(markAsPaidChargesStart());
        postData(`charge_lines/pay`, params)
            .then((result) => {
                dispatch(markAsPaidChargesSuccess(result.data));
                dispatch(markAsPaidChargesAttemptStart());
                toast(intl.get('SUCCESS_MARK_AS_PAID'));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(markAsPaidChargesError(e));
            });
    };
};

export default markAsPaidCharges;
