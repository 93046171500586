import * as Yup from 'yup';
import intl from 'react-intl-universal';
import { setLocale } from 'yup';
const FormValidates = () => {
    setLocale({
        mixed: {
            required: intl.get('REQUIRED_FIELD'),
        },
    });

    return Yup.object().shape({
        document_name: Yup.string().when('document_type', {
            is: 'admin_contract_annex' || 'lease_contract_annex',
            then: Yup.string().required(),
        }),
    });
};
export default FormValidates;
