import {
  editAdvertGalleryStart,
  editAdvertGallerySuccess,
  editAdvertGalleryError,
} from './types'
import { putData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
import { createAdvertGallery } from '../../'
const editAdvertGallery = (
  data: Object,
  advertGalleryId: number,
  lastGalleryState: string,
  transitionDurationGallery: number,
) => {
  return (dispatch: any, getState: any) => {
    dispatch(editAdvertGalleryStart())
    putData(`advert_galleries/${advertGalleryId}`, data)
    .then((result) => {
        dispatch(editAdvertGallerySuccess(result))
        if (lastGalleryState === 'published') {
          dispatch(createAdvertGallery('carousel', transitionDurationGallery))
        }
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(editAdvertGalleryError(e))
      })
  }
}
export default editAdvertGallery
