import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import intl from 'react-intl-universal'
import { titlesHeader } from './createTable'
import { ButtonsHeader } from '../../tango-react-base/components'
import {
  getRequisitions,
  setTemplateChild,
} from '../../tango-react-base/reduxActions'
import { redirect_tabs } from '../postsIndex/buttonOptions'
import RequisitionIndexTable, {
  Requisitions,
  RequisitionsProps,
} from '../../components/RequisitionsIndexTable'
import { tabStatusOptions } from './options'

/**
 * TableTango is a functional component with props.
 * The main function of this component is to render the tables in general.
 *  @returns {(ReactComponent)} Returns a TableMui
 */
const RequisitionsView = ({
  requisitions,
  onSetTemplateChild,
  onGetRequisitions,
}: RequisitionsProps) => {
  const history = useHistory()
  const [tabSelection, setTabSelection] = useState('accepted')
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'requisition'}
          />
        </>,
      )
      onGetRequisitions('pending')
    }
  }, [history, onSetTemplateChild, onGetRequisitions])

  useEffect(() => {
    onGetRequisitions(tabSelection)
  }, [tabSelection])

  const getRequisitionByType = (
    type: string,
  ): {
    type: string
    requisitions: Array<Requisitions>
    fetchingDataRequisitions: string
  } => {
    const result = requisitions.find((requisition: any) => {
      return requisition.type === type
    })
    return result
      ? result
      : { type: '', requisitions: [], fetchingDataRequisitions: '' }
  }

  return (
    <div className='container-home my-5 px-2'>
      <h2 className='new-subtitle'>{intl.get('PENDING_REQUESTS')}</h2>
      <RequisitionIndexTable
        currentRequisition={getRequisitionByType('pending')}
        titles={titlesHeader}
      />
      <h2 className='new-subtitle pt-5'>{intl.get('REQUEST_HISTORY')}</h2>
      <div className='lateral-scroll-button-container'>
        {tabStatusOptions.map((option: any) => (
          <button
            className={tabSelection === option.value ? 'active' : ''}
            onClick={() => setTabSelection(option.value)}
          >
            <span>{intl.get(option.label)}</span>
          </button>
        ))}
      </div>
      <RequisitionIndexTable
        className='mt-4'
        currentRequisition={getRequisitionByType(tabSelection)}
        titles={titlesHeader}
      />
      <div className='mt-5 text-left'>
        <div className='color-secondary font-weight-bold'>
          {intl.get('GLOSSARY_OF_STATES')}
        </div>
        <div className='my-1'>{intl.getHTML('GLOSSARY_OF_REQUISITION_APPROVED')}</div>
        <div className='my-1'>{intl.getHTML('GLOSSARY_OF_REQUISITION_REJECTED')}</div>
        <div className='my-1'>{intl.getHTML('GLOSSARY_OF_REQUISITION_FINISHED')}</div>
      </div>
    </div>
  )
}
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => ({
  ...state.requisitions,
})

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetRequisitions: (type: string) => {
      dispatch(getRequisitions(type))
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
  }
}

const BuildingView = () => {
  return (
    <div className='container-home mt-5 px-2'>
      <h2>{intl.get('BUILDING_SITE')}</h2>
    </div>
  )
}
export { BuildingView }
export default connect(mapStateToProps, mapDispatchToProps)(RequisitionsView)
