export const rejectAnnexStart = () => {
    return {
        type: 'REJECT_ANNEX_STARTED',
    };
};

export const rejectAnnexSuccess = () => {
    return {
        type: 'REJECT_ANNEX_SUCCESS',
    };
};

export const rejectAnnexError = (error: any) => {
    return {
        type: 'REJECT_ANNEX_ERROR',
        payload: error,
    };
};
