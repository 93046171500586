import { toast } from 'react-toastify';
import { postData } from '../../../../tango-react-base/helpers/apiConnections';
import { handleError } from '../../../../tango-react-base/helpers/handleErrors';
import { createAdvertGalleryError, createAdvertGalleryStart, createAdvertGallerySuccess } from './types';

const createAdvertGallery = (kind: string = 'carousel', transitionDuration: number) => {
  let data = {
    advert_gallery: {
      kind: kind,
      transition_duration: transitionDuration,
    },
  };
  return (dispatch: any, getState: any) => {
    dispatch(createAdvertGalleryStart());
    postData('advert_galleries', data)
      .then((result) => {
        return dispatch(createAdvertGallerySuccess(result));
      })
      .catch((e) => {
        toast(handleError(e));
        return dispatch(createAdvertGalleryError(e));
      });
  };
};
export default createAdvertGallery;
