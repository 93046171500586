export const assignPromotionError = (error: any) => {
    return {
        type: 'ASSIGN_PROMOTION_ERROR',
        payload: {
            error,
        },
    };
};

export const assignPromotionStart = () => {
    return {
        type: 'ASSIGN_PROMOTION_STARTED',
    };
};

export const assignPromotionSucess = (payload: any) => {
    return {
        type: 'ASSIGN_PROMOTION_SUCCESS',
        payload: payload,
    };
};
