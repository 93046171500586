export const getPromotionsStart = () => {
  return {
    type: 'GET_PROMOTIONS_STARTED',
  }
}

export const getPromotionsSuccess = (result: any) => {
  return {
    type: 'GET_PROMOTIONS_SUCCESS',
    payload: { result },
  }
}
export const getPromotionsError = (error: any) => {
  return {
    type: 'GET_PROMOTIONS_ERROR',
    payload: {
      error,
    },
  }
}
