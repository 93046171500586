const initialState = {
  visitsPhotographer: [],
  visitsAgent: [],
  fetchingStatus: '',
  fetchingStatusPhotographerIds: '',
  fetchingStatusAgentIds: '',
}

export default (state = initialState, action: any) => {
  const createNewVisits = (
    availableVisitor: Array<any>,
    oldVisits: Array<any>,
  ) => {
    availableVisitor.forEach((availableVisitor: any) => {
      oldVisits.forEach(
        (pendingVisit: { id: number | string; attributes: Object }) => {
          if (availableVisitor.visit_id.toString() === pendingVisit.id) {
            Object.assign(pendingVisit.attributes, {
              available_visitors: availableVisitor.available_visitors,
            })
          }
        },
      )
    })
  }
  switch (action.type) {
    case 'GET_PENDING_VISITS_STARTED':
      return {
        ...state,
        fetchingStatus: 'LOADING',
      }
    case 'GET_PENDING_VISITS_VERIFY_SUCCESS':
      const visitsPhotographer = action.payload.resultVerify
      return {
        ...state,
        fetchingStatus: 'LOADED',
        visitsPhotographer: visitsPhotographer,
      }
    case 'GET_PENDING_VISITS_AGENT_SUCCESS':
      const visitsAgent = action.payload.resultAgent
      return {
        ...state,
        fetchingStatus: 'LOADED',
        visitsAgent: visitsAgent,
      }
    case 'GET_PENDING_VISITS_ERROR':
      return {
        ...state,
        fetchingStatus: 'ERROR',
      }
    case 'GET_PENDING_VISITS_VERIFY_IDS_STARTED':
      return {
        ...state,
        fetchingStatusPhotographerIds: 'LOADING',
      }
    case 'GET_PENDING_VISITS_AGENT_IDS_STARTED':
      return {
        ...state,
        fetchingStatusAgentIds: 'LOADING',
      }
    case 'GET_PENDING_VISITS_AGENT_IDS_SUCCESS':
      createNewVisits(action.payload.availableVisitors, state.visitsAgent)
      return {
        ...state,
        fetchingStatusAgentIds: 'LOADED',
      }
    case 'GET_PENDING_VISITS_VERIFY_IDS_SUCCESS':
      createNewVisits(
        action.payload.availableVisitors,
        state.visitsPhotographer,
      )
      return {
        ...state,
        fetchingStatusPhotographerIds: 'LOADED',
      }
    default:
      return state
  }
}
