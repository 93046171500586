import React from 'react'
import { DialogModal, IconButton } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { TreeItem, TreeView } from '@material-ui/lab'
import { formatDates } from '../../tango-react-base/helpers/date'

/** Here it is defined the type of the props for the interface "ContractHistoryModalProps"
 * @typedef ContractHistoryModalProps
 * @type {(function|number|boolean)}
 * @property {number} contract - is a number.
 * @property {boolean} open - is a boolean.
 * @property {function} handleClose - is a function.

 */
interface ContractHistoryModalProps {
  contract: any
  open: boolean
  handleClose?: () => void
}
/**
 * GoToContractModal is modal with the owner data contract
 *@function
 *@param {ContractHistoryModalTypes} open- boolean to open the modal
 *@param {ContractHistoryModalTypes} contract - variable with the contract id
 */
const ContractHistoryModal = ({
  contract,
  open,
  handleClose,
}: ContractHistoryModalProps) => {
  let contractData = contract?.attributes?.history

  return (
    <DialogModal title={intl.get('PROPERTY_HISTORY')} open={open}>
      <IconButton
        type='close'
        icon='fa-times'
        classColor='btn-close-dm'
        onClick={handleClose}
      />

      {contractData?.map((item: any) => {
        const itemExtra = item.extra && item.extra.split('. ')
        return (
          <>
            <TreeView
              className='treeView'
              aria-label='file system navigator'
              defaultCollapseIcon={
                item.extra && <i className='fa fa-keyboard-arrow-up' />
              }
              defaultExpandIcon={
                item.extra && <i className='fa fa-keyboard-arrow-down' />
              }
            >
              <TreeItem
                className={`${item.extra ? 'pointer' : ''} `}
                nodeId='1'
                label={
                  <>
                    <span
                      className={`left-item-list ${
                        item.extra ? 'pointer' : ''
                      } `}
                    >
                      {item.title}
                    </span>
                    <span
                      className={`right-item-list ${
                        item.extra ? 'pointer' : ''
                      } `}
                    >
                      {`${formatDates({
                        date: item.date,
                        formatDate: 'short',
                      })}   ${formatDates({
                        date: item.date,
                        formatTime: 'short',
                      })}`}
                    </span>
                  </>
                }
              >
                <div className='d-flex flex-column align-items-start'>
                  {itemExtra &&
                    itemExtra.map((item: any, index: any) => (
                      <span key={index} className='text-left'>{item}</span>
                    ))}
                </div>
              </TreeItem>
            </TreeView>
            <br />
          </>
        )
      })}
    </DialogModal>
  )
}

export default ContractHistoryModal
