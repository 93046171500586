import React, { FC, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import {
  publishAdvertGallery,
  editAdvertGallery,
  deleteAdvert,
  getAdvertGallery,
  resetData,
} from '../../redux/actions'
import RenderImages from './renderImages'
import { AddNewImage, infoIcon } from '../../tango-react-base/resources'

/**
 * Define the props of the component
 * @typedef  Props
 * @type {(function|function|Object|string)}
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onPublishAdvertGallery - is a function.
 * @property {function} onEditAdvertGallery - is a function.
 * @property {function} onDeleteAdvert - is a function.
 * @property {function} onGetAdvertGallery - is a function.
 * @property {function} onResetData - is a function.
 * @property {Object} advertGallery - is an Object.
 * @property {string} fetchingStatus - is a string.
 * @property {string} fetchingStatusPublish - is a string.
 * @property {string} fetchingStatusDeleteAdvert - is a string.
 * @property {string} fetchingStatusEdit - is a string.
 * @property {string} fetchingStatusCreate - is a string.
 */
interface Props {
  onSetTemplateChild: (child: JSX.Element) => void
  onPublishAdvertGallery: (advertGalleryId: number) => void
  onEditAdvertGallery: (
    data: Object,
    advertGalleryId: number,
    lastGalleryState: string,
    transitionDurationGallery: number,
  ) => void
  onDeleteAdvert: (
    advertId: number,
    advertGalleryId: number,
    lastGalleryState: string,
    transitionDurationGallery: number,
  ) => void
  onGetAdvertGallery: () => void
  onResetData: () => void
  advertGallery: {
    attributes: {
      adverts: Array<any>
      id: number
      last_gallery_date: string
      last_gallery_state: string
      transition_duration: number
      can_be_published: boolean
    }
    id: number
    type: string
  }
  fetchingStatus: string
  fetchingStatusPublish: string
  fetchingStatusDeleteAdvert: string
  fetchingStatusEdit: string
  fetchingStatusCreate: string
}

/**
 * Define the props of the button component
 * @typedef  PropsButton
 * @type {(string)}
 * @property {string} className - is an string
 * @property {number} advertGalleryId - is a number
 */
interface PropsButton {
  className?: string
}

/**
 * Campaigns is a functional component with props.
 * The main function of this component is to render elements in the view.
 * @function
 * @param {Props} onSetTemplateChild - function onSetTemplateChild
 * @returns {(JSX.Element)} Returns a view with two tables
 */
const Campaigns: FC<Props> = ({
  onSetTemplateChild,
  onPublishAdvertGallery,
  onEditAdvertGallery,
  onDeleteAdvert,
  onGetAdvertGallery,
  onResetData,
  advertGallery,
  fetchingStatus,
  fetchingStatusPublish,
  fetchingStatusDeleteAdvert,
  fetchingStatusEdit,
  fetchingStatusCreate,
}) => {
  const history = useHistory()
  const {
    adverts,
    id: advertGalleryId,
    last_gallery_date: lastGalleryDate,
    last_gallery_state: lastGalleryState,
    transition_duration: transitionDurationGallery,
    can_be_published: canBePublished,
  } = advertGallery?.attributes || ''

  const [advertsUpdate, setAdvertsUpdate] = useState([])
  /* doubleClickSave: It prevents the user from making successive clicks when
  pressing the save button. */
  const [doubleClickSave, setDoubleClickSave] = useState(false)
  const [transitionDuration, setTransitionDuration] = useState(5)
  const transitionDurationValidation =
    transitionDuration >= 3 && transitionDuration <= 5
  const totalAdverts = adverts?.length || 0
  const advertGalleryExists =
    fetchingStatus === 'LOADED' && !!Object.entries(advertGallery).length

  const isUpdated =
    adverts === advertsUpdate &&
    transitionDurationGallery === transitionDuration

  useEffect(() => {
    if (advertGalleryExists) setTransitionDuration(transitionDurationGallery)
  }, [advertGalleryExists])

  // PUBLISH
  useEffect(() => {
    if (fetchingStatusPublish === 'PUBLISHED') {
      onResetData()
      onGetAdvertGallery()
    }
  }, [fetchingStatusPublish])

  const canBePublishedCondition = canBePublished && isUpdated
  const canBeSaveCondition =
    !isUpdated && transitionDurationValidation && advertGalleryExists

  const handleClickPublish = () => {
    if (canBePublishedCondition) {
      onPublishAdvertGallery(advertGalleryId)
    }
  }

  const PublishButton = ({ className = 'w-15' }: PropsButton) => (
    <button
      className={`button-secondary font-weight-bold ${className} ${
        !canBePublishedCondition ? 'disabled-gray-btn' : ''
      }`}
      onClick={() => {
        handleClickPublish()
      }}
    >
      {intl.get('PUBLISH')}
    </button>
  )

  const ErrorUnsavedChanges = () => {
    return (
      <>
        {intl.get('ERROR_UNSAVED_CHANGES')}
        <img
          src={infoIcon}
          alt='info'
          className='info-icon pl-2 pb-1 align-center'
        />
      </>
    )
  }

  const LastUpdatedDate = () => {
    return (
      <>
        {lastGalleryDate ? (
          <>
            {intl.get('LAST_POST_PUBLISHED')}
            <span id='last-updated-date'>{lastGalleryDate}</span>
          </>
        ) : <>
        {intl.get('NO_PUBLICATIONS')}
        <img
          src={infoIcon}
          alt='info'
          className='info-icon pl-2 pb-1 align-center'
        />
        </>}
      </>
    )
  }

  // DELETE
  useEffect(() => {
    if (
      fetchingStatusDeleteAdvert === 'DELETED' &&
      lastGalleryState !== 'published'
    ) {
      onResetData()
      onGetAdvertGallery()
    }
  }, [fetchingStatusDeleteAdvert, lastGalleryState])

  useEffect(() => {
    if (
      fetchingStatusDeleteAdvert === 'DELETED' &&
      lastGalleryState === 'published' &&
      fetchingStatusCreate === 'CREATED'
    ) {
      onResetData()
      onGetAdvertGallery()
    }
  }, [fetchingStatusDeleteAdvert, fetchingStatusCreate, lastGalleryState])

  // EDIT
  useEffect(() => {
    if (fetchingStatusEdit === 'EDITED' && lastGalleryState !== 'published') {
      onResetData()
      onGetAdvertGallery()
      setDoubleClickSave(false)
    }
  }, [fetchingStatusEdit, lastGalleryState])

  useEffect(() => {
    if (
      fetchingStatusEdit === 'EDITED' &&
      lastGalleryState === 'published' &&
      fetchingStatusCreate === 'CREATED'
    ) {
      onResetData()
      onGetAdvertGallery()
      setDoubleClickSave(false)
    }
  }, [fetchingStatusEdit, fetchingStatusCreate, lastGalleryState])

  useEffect(() => {
    let data
    if (doubleClickSave) {
      data = {
        advert_gallery: {
          advert_gallery_id: advertGalleryId,
          transition_duration: transitionDuration,
          adverts_priority: advertsUpdate.map(
            (item: {
              name: string
              link: string
              image: string
              priority: number
              id: number
            }) => {
              return { advert_id: item.id, priority: item.priority }
            },
          ),
        },
      }
      return onEditAdvertGallery(
        data,
        advertGalleryId,
        lastGalleryState,
        transitionDuration,
      )
    }
  }, [doubleClickSave])

  const handleClickSave = () => {
    if (canBeSaveCondition) {
      setDoubleClickSave(true)
    }
  }

  const SaveButton = ({ className = ' w-15' }: PropsButton) => (
    <button
      className={`button-primary ${className} ${
        !canBeSaveCondition ? 'disabled-black-btn' : ''
      }`}
      onClick={() => {
        handleClickSave()
      }}
    >
      {intl.get('SAVE')}
    </button>
  )

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onGetAdvertGallery()
      onSetTemplateChild(
        <>
          <h1>{intl.get('CAMPAIGNS')}</h1>
        </>,
      )
    }
  }, [history, onSetTemplateChild])

  const { width } = useWindowSize()
  return (
    <div className={`container-home pd-1 ${width < 768 ? 'mt-2' : 'mt-5'}`}>
      <div className='button-carousel'>
        {
          <PublishButton
            className={`${
              width < 768
                ? 'w-100 mb-3'
                : width >= 768 && width < 1024
                ? 'w-25'
                : 'w-20'
            }`}
          />
        }
      </div>
      <div className='text-right pt-2' role='alert'>
        {advertGalleryExists ? (
          !isUpdated ? (
            <ErrorUnsavedChanges />
          ) : (
            <LastUpdatedDate />
          )
        ) : (
          ''
        )}
      </div>
      <div className='title-carousel'>
        <div>
          <h2 className='new-subtitle font-weight-bold'>
            {intl.get('GENERAL_CAROUSEL_SETTINGS')}
          </h2>
          <div className='template-line mb-5' />
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-12 col-lg-2 col-md-4 text-left p-0'>
          <span className='font-weight-bold'>
            {intl.getHTML('TRANSITION_TIME')}
          </span>
          <p className='pr-sm-2'>{intl.getHTML('TRANSITION_TIME_INFO')}</p>
        </div>
        <div
          className={`col-12 col-lg-2 col-md-4 justify-content-${
            width >= 768 ? 'center' : 'start'
          } p-0`}
        >
          <div className='col-12 d-flex m-auto p-0'>
            <input
              type='number'
              className={`form-control col-6 ${
                !transitionDurationValidation ? 'invalid-input-alert' : ''
              }`}
              max={5}
              min={3}
              onChange={(e) => setTransitionDuration(Number(e.target.value))}
              disabled={!advertGalleryExists}
              value={transitionDuration || ''}
            />
            <span className='text-capitalize col-6 m-auto text-left'>
              {intl.get('SECONDS')}
            </span>
          </div>
          {!transitionDurationValidation && (
            <p className='alert-input'>{intl.get('TRANSITION_TIME_ERROR')}</p>
          )}
        </div>
      </div>
      {advertGalleryExists && (
        <>
          <RenderImages
            data={adverts}
            element={
              <>
                <SaveButton
                  className={`w-100 mb-2 ${
                    !transitionDurationValidation ? 'disabled-black-btn' : ''
                  }`}
                />
              </>
            }
            onDeleteAdvert={onDeleteAdvert}
            transitionDuration={transitionDuration}
            fetchingStatusDeleteAdvert={fetchingStatusDeleteAdvert}
            advertGalleryId={advertGalleryId}
            onChangeAdvertPriority={(updated: any) => {
              setAdvertsUpdate(updated)
            }}
            onResetData={onResetData}
            lastGalleryState={lastGalleryState}
          />
        </>
      )}
      {totalAdverts < 5 && (
        <section>
          <div className='container-carousel-images'>
            <section className='row'>
              <div className='carousel-card-id-number'>
                <div className='carousel-card__number'>
                  {width < 768 && (
                    <p className='text-left'>{intl.get('POSITION')}</p>
                  )}
                  <div className='justify-content-center last-card-add'>
                    <span>{totalAdverts + 1}</span>
                  </div>
                </div>
              </div>
              <div className='carousel-card'>
                <div className='carousel-card__new-image'>
                  <div className={`w-100 ${width >= 768 && 'ml-3'}`}>
                    <img
                      src={AddNewImage}
                      alt='add new data'
                      className='w-100'
                    />
                  </div>
                </div>
                <div className='carousel-card__actions'>
                  <div className='row display-center-items h-100 pb-3'>
                    {width > 768 && (
                      <div className='col-lg-5 col-md-12 text-left' />
                    )}
                    <div className='col-lg-7 col-md-12 p-0'>
                      <div className='row'>
                        <button
                          className='col-12 button-secondary mr-3'
                          onClick={() => {
                            history.push(
                              `${
                                advertGalleryId
                                  ? `/new_advert/${advertGalleryId}`
                                  : '/creation_advert'
                              }`,
                            )
                            localStorage.setItem(
                              'transitionDuration',
                              String(transitionDuration),
                            )
                            localStorage.setItem(
                              'priority',
                              String(totalAdverts + 1),
                            )
                            localStorage.setItem(
                              'lastGalleryState',
                              `${advertGalleryId ? lastGalleryState : ''}`,
                            )
                            onResetData()
                          }}
                        >
                          {intl.get('ADD_IMAGES')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
      {width >= 768 && (
        <div className='w-100'>
          <SaveButton
            className={`${
              width >= 768 && width < 1024 ? 'w-25' : 'w-15'
            } float-right mt-4`}
          />
        </div>
      )}
    </div>
  )
}

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
  const { fetchingStatusDeleteAdvert } = state.advert
  return {
    ...state.advertGallery,
    fetchingStatusDeleteAdvert,
  }
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onPublishAdvertGallery: (advertGalleryId: number) => {
      dispatch(publishAdvertGallery(advertGalleryId))
    },
    onEditAdvertGallery: (
      data: Object,
      advertGalleryId: number,
      lastGalleryState: string,
      transitionDurationGallery: number,
    ) => {
      dispatch(
        editAdvertGallery(
          data,
          advertGalleryId,
          lastGalleryState,
          transitionDurationGallery,
        ),
      )
    },
    onDeleteAdvert: (
      advertId: number,
      advertGalleryId: number,
      lastGalleryState: string,
      transitionDurationGallery: number,
    ) => {
      dispatch(
        deleteAdvert(advertId, advertGalleryId, lastGalleryState, transitionDurationGallery),
      )
    },
    onGetAdvertGallery: () => {
      dispatch(getAdvertGallery())
    },
    onResetData: () => {
      dispatch(resetData())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns)
