export const publishAdvertGalleryStart = () => {
  return {
    type: 'PUBLISH_ADVERT_GALLERY_START',
  }
}

export const publishAdvertGallerySuccess = (result: any) => {
  return {
    type: 'PUBLISH_ADVERT_GALLERY_SUCCESS',
    payload: { result },
  }
}
export const publishAdvertGalleryError = (error: any) => {
  return {
    type: 'PUBLISH_ADVERT_GALLERY_ERROR',
    payload: {
      error,
    },
  }
}
