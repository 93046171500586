import React from 'react'
import { RadioButtonTangoLink } from '../../tango-react-base/components'
import { useHistory } from 'react-router'
/** Here it is defined the type of the props for the interface "OwnerLinkProps"
 * @typedef OwnerLinkProps
 * @type {(function|object|string)}
 * @property {number} ownerId - is a number.
 */
interface OwnerLinkProps {
  ownerId?: number
  ownerProfile: boolean
}
/**
 * ButtonsActions is a function that shows the property details
 *@function
 *@param {ButtonsActionsTypes} ownerId - boolean to check accept the declare
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */

/** this returns part of the form with the button to go to the owner details */
const OwnerLink = ({ ownerId, ownerProfile }: OwnerLinkProps) => {
  const history = useHistory()

  const option = [{ label: 'SHOW_DETAILS' }]
  return (
    <RadioButtonTangoLink
      disabled={false}
      id='owner'
      label='OWNER_DATA'
      options={option}
      className='d-flex justify-content-left pt-0'
      onClick={() => history.push(ownerProfile ? `/owners/${ownerId}` : `/users/details/${ownerId}`)}
    />
  )
}
export default OwnerLink
