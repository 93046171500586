import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import { getUsersSearch } from '../../redux/actions';
import InputUserSearch from '../inputSearch';

interface UserSearchProps {
  history: any;
  onGetUserSearch: (params: any, state: string) => void;
}

interface UserSearchForm {
  user_search: string;
}

const InnerForm: React.FunctionComponent<UserSearchProps & FormikProps<UserSearchForm>> = ({
  history,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  handleSubmit,
  onGetUserSearch,
}) => {
  const { state }: any = useLocation();
  const userSearchList = () => {
    history.push('/users');
  };
  useEffect(() => {
    if (state === 'users') {
      onGetUserSearch(null, state);
    }
  }, [onGetUserSearch, state]);

  return (
    <div className='search-container'>
      <div className='search-card-container center-search'>
        <Form className='row-search' autoComplete='off'>
          <div className='container-25 '>
            <p className='user-search-label'>{intl.get('USER_SEARCH_LABEL')}</p>
          </div>
          <div className='container-50 mb-2'>
            <div className='input-center-search no-line-bottom'>
              <InputUserSearch
                id='user_search'
                value={values.user_search}
                label={intl.get('USER_SEARCH')}
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className='container-25 button-color d-flex flex-column justify-content-center align-items-center'>
            <button className='btn-user-search' type='submit' onClick={() => userSearchList()}>
              {intl.get('SEARCH')}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};
const UserSearch = withFormik<UserSearchProps, UserSearchForm>({
  mapPropsToValues: (props: any) => {
    return {
      user_search: props.user_search,
    };
  },

  validationSchema: Yup.object().shape({}),

  handleSubmit(values: UserSearchForm, { props }) {
    props.onGetUserSearch(values, '');
  },
})(InnerForm);

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetUserSearch: (params: any, state: any) => {
      dispatch(getUsersSearch(params, state));
    },
  };
};
const mapStateToProps = (state: any) => {};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
