import { MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { CardPriceTable } from '../../components';
import { CircularLoading, PopupWithIcon } from '../../tango-react-base/components';
import NumberedTable from '../../tango-react-base/components/numberedTable';
import { getParameterByName } from '../../tango-react-base/helpers/getParamsUrl';
import {
  getChargeLines,
  getOwnerPayments,
  getPaymentsProviders,
  getPost,
  markAsPaidCharges,
  setTemplateChild,
  updateChargeLines,
} from '../../tango-react-base/reduxActions';

/**
 * @typedef  PublishPostPaymentsProps
 * @param {Function} onGetPost - Function to get post by id
 * @param {Function} onEditChargeLines - Function to edit charge lines
 * @param {Function} onGetChargeLines - Function to get charge lines by post id
 * @param {Object} post - Post object
 * @param {string} fetchingPost - Fetching post status
 * @param {string} error - Error message
 * @param {Array} chargeLines - Array of charge lines
 * @param {Array} ownerPayments - Array of ownerPayments
 * @param {number} clfValue - Clf fee value
 * @param {boolean} editable - Flag to edit charge lines
 * @param {string} fetchChargeLines - Fetching charge lines status
 * @param {string} fetchingUpdateChargeLines - Fetching update charge lines status
 */
interface PublishPostPaymentsProps {
  onGetPost: (postId: number) => void;
  onGetChargeLines: (postId: any) => void;
  onGetOwnerPayments: (postId: any) => void;
  onEditChargeLines: (chargeLines: any) => void;
  onSetTemplateChild: (child: Object) => void;
  onGetPaymentsProviders: () => void;
  onGetMarkAsPaid: (
    chargeLinesIds: Array<number>,
    unitVersionId: number,
    rentID: number,
    provider_name: string,
    provider_method: string,
    total: number,
    fullAdress: string,
  ) => void;
  post: any;
  fetchingPost: string;
  error: string;
  chargeLines: any;
  ownerPayments: any;
  clfValue: number;
  editable: boolean;
  paymentsProviders: any;
  providers: Array<any>;
  fetchChargeLines: string;
  fetchingUpdateChargeLines: string;
  fetchPaymentsProviders: string;
  fetchingMarkAsPaid: string;
  fetchingMarkAsPaidAttempt: string;
}

/**
 * PublishPostPayments Component
 * @param {PublishPostPaymentsProps} props - Props of the component
 * @returns {JSX.Element} - Return JSX element
 * @description Renderiza el componente de publicar pagos de una publicación.
 * Contiene una tabla con los pagos de la publicación y un botón para publicar los pagos.
 */
const PublishPostPayments = ({
  onGetPost,
  onEditChargeLines,
  onGetChargeLines,
  onGetPaymentsProviders,
  onGetMarkAsPaid,
  onGetOwnerPayments,
  onSetTemplateChild,
  post,
  fetchingPost,
  error,
  chargeLines,
  paymentsProviders,
  fetchPaymentsProviders,
  ownerPayments,
  clfValue,
  editable,
  fetchChargeLines,
  fetchingUpdateChargeLines,
  fetchingMarkAsPaid,
  fetchingMarkAsPaidAttempt,
}: PublishPostPaymentsProps) => {
  let location = useLocation();
  const { id }: any = useParams();
  const [isEditing, setIsEditing] = React.useState(false);
  const [openDialogSave, setOpenDialogSave] = React.useState(false);
  const [openDialogPayments, setOpenDialogSavePayments] = React.useState(false);
  const [providerPaymentList, setProviderPaymentList] = React.useState<any>('');
  const [providerPayment, setProviderPayment] = React.useState<any>('');
  const [paymentMethod, setPaymentMethod] = React.useState<any>('');
  const [validateSave, setValidateSave] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [updatedClfValue, setUpdatedClfValue] = React.useState(clfValue ? clfValue : '0');
  const [priceText, setPriceText] = React.useState<any>('');
  const rentId = getParameterByName('rentId', location?.search);
  const unitId = post?.attributes?.unit?.id;

  const validationValue = (id: string, value: number | string) => {
    if (id === 'guarantee') {
      if (value < 200000)
        return {
          error: true,
          helperText: intl.get('MIN_VALUE_HELPER_TEXT', { value: '200.000' }),
        };
    } else if (id === 'uf_value' || id === 'first_payment') {
      if (value === 0 || value === '0' || value === null)
        return {
          error: true,
          helperText: intl.get('MIN_VALUE_HELPER_TEXT', { value: '0' }),
        };
    }
    return { error: false, helperText: '' };
  };

  const formatChargeLines = (): Array<{ label: string; value: number }> => {
    return chargeLines
      ? chargeLines.map((chargeLine: any) => {
          return {
            label:
              chargeLine.attributes.kind.toUpperCase() === 'BROKERAGE'
                ? intl.get('COLLECTION_FEE')
                : intl.get(chargeLine.attributes.kind.toUpperCase()),
            value: chargeLine.attributes.fee_amount,
            labelClass: 'text-dark',
            labelId: chargeLine.attributes.kind,
            decimalScale: 0,
            validation: {},
            id: chargeLine.id,
          };
        })
      : [];
  };
  const formatOwnerPayments = (): Array<{ label: string; value: number }> => {
    return ownerPayments
      ? ownerPayments.map((ownerPayment: any) => {
          return {
            label: intl.get(ownerPayment.kind.toUpperCase()),
            value: ownerPayment.value,
            labelClass: 'text-dark',
            labelId: ownerPayment.kind,
            validation: {},
          };
        })
      : [];
  };
  const checkHaveErrorsChargeLines = (ChLns: Array<any>) => {
    const res =
      ChLns.filter((a) => a.validation.error).length > 0 ||
      (clfValue ? updatedClfValue === 0 || updatedClfValue === '0' : false);
    return res;
  };

  const [chargeLinesRows, setChargeLinesRows] = useState<Array<{ label: string; value: number }>>(formatChargeLines);

  const [ownerPaymentsRows, setOwnerPaymentsRows] = useState<Array<{ label: string; value: number }>>(
    formatOwnerPayments,
  );

  useEffect(() => {
    onGetPost(id);
    onGetChargeLines(rentId);
    onGetPaymentsProviders();
    onGetOwnerPayments(rentId);
    onSetTemplateChild(
      <>
        <h1>{intl.get('PAYMENTS')}</h1>
      </>,
    );
  }, []);

  useEffect(() => {
    if (fetchingMarkAsPaid === 'SUCCESS') {
      setOpenDialogSavePayments(false);
      onGetPost(id);
      onGetChargeLines(rentId);
      onGetPaymentsProviders();
      onGetOwnerPayments(rentId);
    }
  }, [fetchingMarkAsPaid]);

  useEffect(() => {
    setChargeLinesRows(formatChargeLines);
  }, [chargeLines]);

  useEffect(() => {
    setOwnerPaymentsRows(formatOwnerPayments);
  }, [ownerPayments]);

  useEffect(() => {
    setUpdatedClfValue(clfValue);
  }, [clfValue]);

  useEffect(() => {
    setValidateSave(checkHaveErrorsChargeLines(chargeLinesRows));
  }, [chargeLinesRows, updatedClfValue]);

  useEffect(() => {
    if (fetchingUpdateChargeLines === 'LOADED') {
      setOpenDialogSave(false);
      onGetPost(id);
      onGetChargeLines(rentId);
    }
  }, [fetchingUpdateChargeLines]);

  useEffect(() => {
    if (fetchPaymentsProviders === 'FETCHED') {
      setProviderPaymentList(
        paymentsProviders
          ? Object.entries(paymentsProviders).map((el: any) => {
              return {
                provider: el[0],
                methods: el[1].payment_methods,
              };
            })
          : [],
      );
    }
  }, [fetchPaymentsProviders]);

  useEffect(() => {
    const estimatePrice = post?.attributes?.estimate_price;
    const registeredCurrency = post?.attributes?.registered_currency;
    if (!estimatePrice || !registeredCurrency) return;

    setPriceText(
      `${estimatePrice[registeredCurrency?.toLowerCase()]} / ${
        estimatePrice[registeredCurrency?.toLowerCase() === 'clp' ? 'clf' : 'clp']
      }`,
    );
  }, [post]);

  const onInputChange = (index: number, value: number) => {
    const newRowValues = [...chargeLinesRows];
    newRowValues[index].value = value;
    setChargeLinesRows(newRowValues);
  };
  const onHandleCancel = () => {
    setIsEditing(false);
    setChargeLinesRows(formatChargeLines);
    setUpdatedClfValue(clfValue ? clfValue : '0');
  };
  const formatPayload = (): any => {
    const charges = chargeLinesRows.map((chargeLine: any) => {
      setIsEditing(false);
      return {
        id: parseInt(chargeLine.id),
        fee_amount: chargeLine.value,
        clf_value_initial: clfValue ? updatedClfValue : null,
      };
    });
    return { charge_lines: charges };
  };

  const chargelinesIds = chargeLines.map((chargeLine: any) => parseInt(chargeLine.id));

  const renderType = () => {
    return (
      <div className='container-home mt-3 pb-5'>
        <CardPriceTable
          title={intl.get('RENTER')}
          subtitle={
            <>
              {intl.get('LEASING_PRICE_VALUE_MONTH')} &nbsp; <p> {priceText}</p>
            </>
          }
          isEditing={isEditing}
          disabled={!editable}
          canEdit={ownerPaymentsRows.length === 0}
          disabledSave={validateSave}
          onChangeEditing={(value: boolean) => setIsEditing(value)}
          handleCancel={onHandleCancel}
          handleSave={() => setOpenDialogSave(true)}
        >
          <NumberedTable
            headers={[
              { name: 'TYPE', class: '' },
              { name: 'CHARGED_AMOUNT', class: 'text-right' },
            ]}
            rows={chargeLinesRows}
            className='mb-2'
            withoutBottomBorder={+updatedClfValue === 0}
            form={ownerPaymentsRows.length === 0}
            showTotal
            calculateTotal
            disabled={!isEditing}
            onChange={(index: number, number: number) => onInputChange(index, number)}
            onChageTotal={(value: number) => setTotal(value)}
            inputValidation={validationValue}
          />
          {Number(clfValue) !== 0 && (
            <NumberedTable
              rows={[
                {
                  label: intl.get('UF_VALUE_ON_THE_DAY_OF_SIGNING'),
                  value: updatedClfValue,
                  labelClass: 'text-dark',
                  valueClass: '',
                  labelId: 'uf_value',
                  validation: {},
                  decimalScale: 2,
                },
              ]}
              form={ownerPaymentsRows.length === 0}
              disabled={!isEditing}
              onChange={(index: number, number: number) => setUpdatedClfValue(number)}
              inputValidation={validationValue}
            />
          )}
        </CardPriceTable>
        <PopupWithIcon
          className='dialog-save-charge-lines'
          title={'EDIT_CHARGES'}
          open={openDialogSave}
          classNameTitle='font-weight-bold'
          lineUp
          contentDialogClass='d-md-block mx-md-4'
          handleClose={() => setOpenDialogSave(false)}
        >
          <div className='text-center font-weight-bold h6'>
            <p>{intl.get('CONFIRM_UPDATE_CHARGES')}</p>
          </div>

          <NumberedTable
            headers={[
              { name: 'TYPE', class: '' },
              { name: 'CHARGED_AMOUNT', class: 'text-right text-nowrap' },
            ]}
            rows={chargeLinesRows}
            className='mb-2'
            form={false}
            disabled={!isEditing}
            showTotal
            calculateTotal
            onChange={(index: number, number: number) => onInputChange(index, number)}
          />
          {Number(clfValue) !== 0 && (
            <NumberedTable
              rows={[
                {
                  label: intl.get('UF_VALUE_ON_THE_DAY_OF_SIGNING'),
                  value: updatedClfValue,
                  labelClass: 'text-dark',
                  valueClass: '',
                  labelId: 'uf_value',
                },
              ]}
              form={false}
              disabled={!isEditing}
              onChange={(index: number, number: number) => setUpdatedClfValue(number)}
            />
          )}
          <div className='buttons-container'>
            <button className='button-white text-dark col-12 col-md-4 mx-md-2' onClick={() => setOpenDialogSave(false)}>
              {intl.get('CANCEL')}
            </button>
            <button className='button-primary col-12 col-md-6' onClick={() => onEditChargeLines(formatPayload())}>
              {intl.get('SAVE_CHARGES')}
            </button>
          </div>
        </PopupWithIcon>

        <PopupWithIcon
          className='dialog-save-charge-lines'
          title={'ADD_PAYMENT'}
          open={openDialogPayments}
          classNameTitle='font-weight-bold'
          lineUp
          contentDialogClass='d-md-block mx-md-5 px-max-7'
          handleClose={() => setOpenDialogSavePayments(false)}
        >
          <NumberedTable
            headers={[
              { name: 'TYPE', class: '' },
              { name: 'CHARGED_AMOUNT', class: 'text-right text-nowrap' },
            ]}
            rows={chargeLinesRows}
            className='mb-2'
            form={false}
            disabled={!isEditing}
            showTotal
            calculateTotal
            onChange={(index: number, number: number) => onInputChange(index, number)}
          />
          {Number(clfValue) !== 0 && (
            <NumberedTable
              rows={[
                {
                  label: intl.get('UF_VALUE_ON_THE_DAY_OF_SIGNING'),
                  value: updatedClfValue,
                  labelClass: 'text-dark',
                  valueClass: '',
                  labelId: 'uf_value',
                },
              ]}
              form={false}
              disabled={!isEditing}
              onChange={(index: number, number: number) => setUpdatedClfValue(number)}
            />
          )}
          <div className='payments-containers'>
            <p>{intl.get('PROVIDER')}</p>
            <Select
              id='provider'
              variant='outlined'
              className='mb-2'
              placeholder='Select provider'
              value={providerPayment}
              IconComponent={ExpandMoreIcon}
              onChange={(event: any) => setProviderPayment(event.target.value)}
            >
              {!!providerPaymentList &&
                providerPaymentList.map((provider: any, i: number) => (
                  <MenuItem key={i} value={provider}>
                    {provider.provider}
                  </MenuItem>
                ))}
            </Select>

            <p>{intl.get('PAYMENT_KIND')}</p>
            <Select
              id='payment_method'
              variant='outlined'
              value={paymentMethod}
              disabled={providerPayment === ''}
              IconComponent={ExpandMoreIcon}
              className='mb-2'
              onChange={(event: any) => setPaymentMethod(event.target.value)}
            >
              {providerPayment !== '' &&
                providerPayment.methods.map((provider: any, i: number) => (
                  <MenuItem key={i} value={provider}>
                    {provider.text}
                  </MenuItem>
                ))}
            </Select>
            <p>{intl.get('THIS_ACTION_CANNOT_BE_UNDONE')}</p>
          </div>

          <div className='buttons-container'>
            <button
              className='button-white text-dark col-12 col-md-4 mr-md-2'
              onClick={() => setOpenDialogSavePayments(false)}
            >
              {intl.get('CANCEL')}
            </button>
            <button
              className='button-secondary col-12 col-md-8'
              onClick={() =>
                onGetMarkAsPaid(
                  chargelinesIds,
                  unitId,
                  parseInt(rentId ? rentId : '0'),
                  providerPayment.provider,
                  paymentMethod.value,
                  total,
                  post?.attributes?.unit?.full_address,
                )
              }
              disabled={fetchingMarkAsPaid === 'FETCHING'}
            >
              {fetchingMarkAsPaid === 'FETCHING' ? <span>{<CircularLoading />}</span> : intl.get('MARK_AS_PAID')}
            </button>
          </div>
        </PopupWithIcon>

        {ownerPaymentsRows.length === 0 && (
          <div className='container-button-paid'>
            <button
              className='button-secondary'
              disabled={validateSave}
              onClick={() => setOpenDialogSavePayments(true)}
            >
              {intl.get('MARK_AS_PAID')}
            </button>
          </div>
        )}

        {ownerPaymentsRows.length > 0 && (
          <div className='pt-5'>
            <CardPriceTable
              title={intl.get('OWNER')}
              subtitle={<>{}</>}
              isEditing={isEditing}
              disabled={true}
              canEdit={ownerPaymentsRows.length === 0}
              disabledSave={validateSave}
              onChangeEditing={(value: boolean) => setIsEditing(value)}
              handleCancel={onHandleCancel}
            >
              <NumberedTable
                headers={[
                  { name: 'TYPE', class: '' },
                  { name: 'CHARGED_AMOUNT', class: 'text-right' },
                ]}
                rows={ownerPaymentsRows}
                className='mb-2'
                withoutBottomBorder={true}
                form={false}
                disabled={true}
                showTotal={true}
                calculateTotal={true}
                onChange={(index: number, number: number) => onInputChange(index, number)}
              />
            </CardPriceTable>
          </div>
        )}
      </div>
    );
  };

  const renderLoading = () => (
    <div>
      {(fetchingPost === 'LOADING' || fetchChargeLines === 'FETCHING') && <span>{<CircularLoading />}</span>}
      {(fetchingPost === 'ERROR' || fetchChargeLines === 'ERROR') && (
        <div className='container-home mt-3 pb-5'>
          <CardPriceTable
            title={intl.get('RENTER')}
            subtitle={
              <>
                {intl.get('LEASING_PRICE_VALUE_MONTH')} &nbsp; <p> {priceText}</p>
              </>
            }
          >
            <div className='py-5'>
              <span>{intl.get('NO_CHARGE_LINES_FOUND')}</span>
            </div>
          </CardPriceTable>
        </div>
      )}
    </div>
  );

  return <>{fetchingPost !== 'LOADED' || fetchChargeLines !== 'FETCHED' ? renderLoading() : renderType()}</>;
};
const mapStateToProps = (state: any) => {
  const { chargeLines, clfValue, editable, fetchChargeLines } = state.chargeLines;
  const { ownerPayments } = state.ownerPayments;
  const { post, fetchingPost, error } = state.publish;
  const { fetchingUpdateChargeLines } = state.updateChargeLines;
  const { paymentsProviders, fetchPaymentsProviders } = state.paymentsProviders;
  const { fetchingMarkAsPaid, fetchingMarkAsPaidAttempt } = state.markAsPaid;
  return {
    post,
    fetchingPost,
    error,
    chargeLines,
    ownerPayments,
    clfValue,
    editable,
    fetchChargeLines,
    paymentsProviders,
    fetchingUpdateChargeLines,
    fetchPaymentsProviders,
    fetchingMarkAsPaid,
    fetchingMarkAsPaidAttempt,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPost: (postId: number) => {
      dispatch(getPost(postId));
    },
    onGetChargeLines: (postId: any) => {
      dispatch(getChargeLines(postId));
    },
    onGetOwnerPayments: (postId: any) => {
      dispatch(getOwnerPayments(postId));
    },
    onEditChargeLines: (chargeLines: any) => {
      dispatch(updateChargeLines(chargeLines));
    },
    onGetPaymentsProviders: () => {
      dispatch(getPaymentsProviders());
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onGetMarkAsPaid: (
      chargeLinesIds: Array<number>,
      unitVersionId: number,
      rentID: number,
      provider_name: string,
      provider_method: string,
      total: number,
      fullAdress: string,
    ) => {
      dispatch(
        markAsPaidCharges(chargeLinesIds, unitVersionId, rentID, provider_name, provider_method, total, fullAdress),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishPostPayments);
