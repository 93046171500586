import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
var moment = require('moment')

type VisitCalendarState = {
  startWeek: any
  endWeek: any
  week: any
  blocksTaken: Array<any>
  blocksDisabled: Array<any>
  submitting: Array<any>
  fetchingBlocks: string
  visits: Array<any>
  visitsFetching: string
  error: string
  numberBlock: number
  fetchingBlocksSubmitting: string
  submittingError: string
  isLoadingBlocks: string
}
const getWeeks = (start: any, end: any) => {
  let weekAux: any[] = []
  let day = start
  while (day.isBefore(end)) {
    weekAux.push(day)
    day = day.clone().add(1, 'days')
  }
  return weekAux
}
const startWeek = moment().startOf('week').add(1, 'days')
const endWeek = moment().endOf('week').add(1, 'days')

const initialState: VisitCalendarState = {
  startWeek,
  endWeek,
  week: getWeeks(startWeek, endWeek),
  blocksTaken: [],
  blocksDisabled: [],
  submitting: [],
  fetchingBlocks: 'NO_BLOCKS',
  visits: [],
  visitsFetching: 'NO_VISITS',
  error: 'NOT_ERROR',
  numberBlock: 0,
  fetchingBlocksSubmitting: 'NOT_FETCH',
  submittingError: 'NOT_ERROR',
  isLoadingBlocks: '',
}

export default (state = initialState, action: any) => {
  let e = 'NOT_ERROR'
  let fetchingBlocksSubmittingAux = 'NOT_FETCH'
  const isSelected = (date: any) => {
    const filteredblocksTaken = state.blocksTaken.filter((dateIter: any) => {
      return moment(dateIter.date).isSame(moment(date))
    })
    return filteredblocksTaken.length > 0
  }
  const removeDate = (dateToRemove: any) => {
    return state.blocksTaken.filter((block: any) => {
      return !moment(dateToRemove).isSame(moment(block.date))
    })
  }
  switch (action.type) {
    case 'ADD_DATE':
      let blocksTakenAux = state.blocksTaken
      if (isSelected(action.payload.date)) {
        blocksTakenAux = removeDate(action.payload.date)
      } else {
        blocksTakenAux = blocksTakenAux.concat(action.payload)
      }
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        blocksTaken: blocksTakenAux,
        submittingError: [],
        error: 'NOT_ERROR',
      }
    case 'GET_BLOCKS_STARTED':
      return {
        ...state,
        fetchingBlocks: 'FETCHING_BLOCKS',
      }
    case 'GET_BLOCKS_SUCCESS':
      return {
        ...state,
        fetchingBlocks: 'FETCHED_BLOCKS',
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        submittingError: [],
        blocksDisabledWithId: action.payload.result.data.map(
          (dateFilter: any) => {
            const id = dateFilter.id
            const start_time = dateFilter.attributes.start_time
            const aasm_state = dateFilter.attributes.aasm_state
            const type = dateFilter.type
            return { id, start_time, aasm_state, type }
          },
        ),
        blocksDisabled: action.payload.result.data
          .filter((date: any) => {
            const actualDate = moment(date.attributes.start_time)
            const morning_date = moment(date.attributes.start_time)
              .hour(8)
              .minutes(0)
              .seconds(0)
            const afternoon_date = moment(date.attributes.start_time)
              .hour(12)
              .minutes(0)
              .seconds(0)
            const night_date = moment(date.attributes.start_time)
              .hour(17)
              .minutes(0)
              .seconds(0)
            return (
              actualDate.isSame(morning_date) ||
              actualDate.isSame(afternoon_date) ||
              actualDate.isSame(night_date)
            )
          })
          .map((dateFilter: any) => {
            return dateFilter.attributes.start_time
          }),
      }
    case 'GET_BLOCKS_ERROR':
      return {
        ...state,
        fetchingBlocks: 'ERROR_FETCHING_BLOCKS',
      }
    case 'NEXT_WEEK':
      const nextStartWeek = state.startWeek.add(7, 'days')
      const nextEndWeek = state.endWeek.add(7, 'days')
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
        week: getWeeks(nextStartWeek, nextEndWeek),
      }
    case 'LAST_WEEK':
      const beforeStartWeek = state.startWeek.subtract(7, 'days')
      const beforeEndWeek = state.endWeek.subtract(7, 'days')
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        startWeek: beforeStartWeek,
        endWeek: beforeEndWeek,
        week: getWeeks(beforeStartWeek, beforeEndWeek),
      }

    case 'SUBMIT_CALENDAR_STARTED':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        isLoadingBlocks: 'STARTED',
        error: 'NOT_ERROR',
        numberBlock: state.numberBlock + 1,
        submittingError: [],
        submitting: state.submitting.concat(
          action.payload.date.block_group.start_time,
        ),
      }
    case 'SUBMIT_CALENDAR_SUCCESS':
      let result = action.payload.result
      const morning_date = moment(result.attributes.start_time)
        .hour(8)
        .minutes(0)
        .seconds(0)
      const afternoon_date = moment(result.attributes.start_time)
        .hour(12)
        .minutes(0)
        .seconds(0)
      const night_date = moment(result.attributes.start_time)
        .hour(17)
        .minutes(0)
        .seconds(0)
      let actualDate = moment(result.attributes.start_time)
      let blocksDisabledAux: Array<any> = state.blocksDisabled
      if (
        actualDate.isSame(morning_date) ||
        actualDate.isSame(afternoon_date) ||
        actualDate.isSame(night_date)
      ) {
        const block = actualDate
        blocksDisabledAux.push(block)
      }
      e = 'NOT_ERROR'
      if (state.numberBlock - 1 === 0 && state.submittingError.length > 0) {
        e = 'disabled blocks'
      } else if (
        state.numberBlock - 1 === 0 &&
        state.submittingError.length === 0
      ) {
        toast(intl.get('BLOCKS_TAKEN_UPDATE'))
        fetchingBlocksSubmittingAux = 'SUBMIT_CALENDAR_SUCCESS'
      }
      return {
        ...state,
        error: e,
        fetchingBlocksSubmitting: fetchingBlocksSubmittingAux,
        blocksDisabled: blocksDisabledAux,
        isLoadingBlocks: 'SUCCESS',
        blocksTaken: [],
        numberBlock: state.numberBlock - 1,
        submitting: state.submitting.filter((date: any) => {
          return actualDate.isSame(date)
        }),
      }
    case 'SUBMIT_CALENDAR_ERROR':
      let data = action.payload.error.config.data
      const d = data.split('"', 6)
      data = d[5]
      data = data.split(' ', 6)
      data = data[0] + ' ' + data[1]
      data = moment(data).format('HH:mm DD-MM-YYYY')
      e = 'NOT_ERROR'
      return {
        ...state,
        fetchingBlocksSubmitting: 'SUBMIT_CALENDAR_ERROR',
        blocksTaken: [],
        error: e,
        numberBlock: state.numberBlock - 1,
        submittingError: state.submittingError.concat(data),
      }

    case 'GET_VISITS_STARTED':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        visitsFetching: 'FETCHING_VISITS',
      }
    case 'GET_VISITS_SUCCESS':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'NOT_ERROR',
        visits: action.payload.result.data,
        visitsFetching: 'FETCHED_VISITS',
      }
    case 'GET_VISITS_ERROR':
      return {
        ...state,
        fetchingBlocksSubmitting: 'NOT_FETCH',
        error: 'ERROR_FETCHING_VISITS',
      }

    default:
      return state
  }
}
