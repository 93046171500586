export const RejectEarlyRequisitionStart = () => {
    return {
        type: 'REJECT_EARLY_REQUISITION_STARTED',
    };
};

export const RejectEarlyRequisitionSuccess = (paramOk: any) => {
    return {
        type: 'REJECT_EARLY_REQUISITION_SUCCESS',
        payload: paramOk,
    };
};

export const RejectEarlyRequisitionError = (error: any) => {
    return {
        type: 'REJECT_EARLY_REQUISITION_ERROR',
        payload: error,
    };
};
