import { getOwnerStart, getOwnerSuccess, getOwnerError } from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getOwnerProfile = (id: number) => {
  return (dispatch: any) => {
    dispatch(getOwnerStart())
    getData(`owner_profiles/${id}`)
      .then((result) => {
        return dispatch(getOwnerSuccess(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getOwnerError(e))
      })
  }
}

export default getOwnerProfile