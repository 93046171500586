export const createAdvertImagesStart = () => {
  return {
    type: 'CREATE_ADVERT_IMAGES_START',
  }
}

export const createAdvertImageStart = () => {
  return {
    type: 'CREATE_ADVERT_IMAGE_START',
  }
}

export const createAdvertImageSuccess = (result: any) => {
  return {
    type: 'CREATE_ADVERT_IMAGE_SUCCESS',
    payload: { result },
  }
}

export const createAdvertImageError = (error: any) => {
  return {
    type: 'CREATE_ADVERT_IMAGE_ERROR',
    payload: {
      error,
    },
  }
}

export const createAdvertImagesSuccess = () => {
  return {
    type: 'CREATE_ADVERT_IMAGES_SUCCESS',
  }
}
