import { getData, multipartPost } from '../../helpers/apiConnections';
import { uploadFileStart, uploadFileSuccess, uploadFileError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
const uploadDocument = (id: number, values: any) => {
    return (dispatch: any, getState: any) => {
        let formData = new FormData();

        formData.append('rent_id', id.toString());
        formData.append('doc_type', values.document_type);
        formData.append('attachment', values.file);
        formData.append('meta[signed_contract_at]', values.signed_contract_at);
        formData.append('meta[lease_start_at]', values.lease_start_at);
        formData.append('meta[due_at]', values.due_at);
        formData.append('meta[correlative_id]', values.folio);
        formData.append('meta[name]', values.file.name);

        dispatch(uploadFileStart());
        multipartPost('lease_documents/upload_document', formData)
            .then((result) => {
                dispatch(uploadFileSuccess(result));
                toast(intl.get('DATA_SAVED'));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(uploadFileError(e));
            });
    };
};
export default uploadDocument;
