const initialState: any = {
  contract: {},
  fetchContract: 'NO_FETCH',
  fetchContractDetail: 'NO_FETCH',
  fetchContractHistory: 'NO_FETCH',
  contractDetail: {},
  contractHistory: {},
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_CONTRACT_STARTED':
      return {
        ...state,
        fetchContract: 'FETCHING',
      }
    case 'GET_CONTRACT_SUCCESS':
      return {
        ...state,
        contract: action.payload.result.data.data,
        fetchContract: 'FETCHED',
      }
    case 'GET_CONTRACT_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchContract: 'ERROR',
      }

    case 'GET_CONTRACT_HISTORY_STARTED':
      return {
        ...state,
        fetchContractHistory: 'FETCHING_CONTRACT_HISTORY',
        contractDetail: {},
        contractHistory: {},
        fetchContractDetail: 'NO_FETCH',
      }
    case 'GET_CONTRACT_HISTORY_SUCCESS':
      return {
        ...state,
        contractHistory: action.payload.result.data.data,
        fetchContractHistory: 'FETCHED_CONTRACT_HISTORY',
        contractDetail: {},
      }
    case 'GET_CONTRACT_HISTORY_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchContractHistory: 'ERROR_CONTRACT_HISTORY',
      }

    case 'GET_CONTRACT_DETAIL_STARTED':
      return {
        ...state,
        fetchContractDetail: 'FETCHING_CONTRACT_DETAIL',
        contractDetail: {},
        contractHistory: {},
        fetchContractHistory: 'NO_FETCH',
      }
    case 'GET_CONTRACT_DETAIL_SUCCESS':
      return {
        ...state,
        contractDetail: action.payload.result.data.data,
        fetchContractDetail: 'FETCHED_CONTRACT_DETAIL',
        contractHistory: {},
      }
    case 'GET_CONTRACT_DETAIL_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchContractDetail: 'ERROR_CONTRACT_DETAIL',
      }

    default:
      return state
  }
}
