const initialState: any = {
  fetchingMarkAsPaid: 'NO_FETCH',
  fetchingMarkAsPaidAttempt: 'NO_FETCH',
  fethingMarkAsPaidOwnerFine: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'MARK_AS_PAID_CHARGES_STARTED':
      return {
        ...state,
        fetchingMarkAsPaid: 'FETCHING',
      }
    case 'MARK_AS_PAID_CHARGES_SUCCESS':
      return {
        ...state,
        fetchingMarkAsPaid: 'SUCCESS',
      }
    case 'MARK_AS_PAID_CHARGES_ERROR':
      return {
        ...state,
        fetchingMarkAsPaid: 'ERROR',
      }
    case 'MARK_AS_PAID_CHARGES_ATTEMPT_STARTED':
      return {
        ...state,
        fetchingMarkAsPaidAttempt: 'FETCHING',
      }
    case 'MARK_AS_PAID_CHARGES_ATTEMPT_SUCCESS':
      return {
        ...state,
        fetchingMarkAsPaidAttempt: 'SUCCESS',
      }
    case 'MARK_AS_PAID_CHARGES_ATTEMPT_ERROR':
      return {
        ...state,
        fetchingMarkAsPaidAttempt: 'ERROR',
      }
    case 'MARK_AS_PAID_OWNER_FINE_CHARGES_STARTED':
      return {
        ...state,
        fethingMarkAsPaidOwnerFine: 'FETCHING',
      }
    case 'MARK_AS_PAID_OWNER_FINE_CHARGES_SUCCESS':
      return {
        ...state,
        fethingMarkAsPaidOwnerFine: 'SUCCESS',
      }
    case 'MARK_AS_PAID_OWNER_FINE_CHARGES_ERROR':
      return {
        ...state,
        fethingMarkAsPaidOwnerFine: 'ERROR',
      }

    default:
      return state
  }
}
