export const updateProfileStart = () => {
  return {
    type: 'UPDATE_PROFILE_STARTED',
  }
}

export const updateProfileSuc = (profile: any) => {
  return {
    type: 'UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  }
}

export const updateProfileError = (error: any) => {
  return {
    type: 'UPDATE_PROFILE_ERROR',
    payload: {
      error,
    },
  }
}
