const initialState: any = {
  ownerProfile: {},
  fetchingOwner: 'NO_OWNER_PROFILE',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_OWNER_PROFILE_STARTED':
      return {
        ...state,
        fetchingOwner: 'FETCHING_OWNER',
      }

    case 'GET_OWNER_PROFILE_SUCCESS':
      return {
        ...state,
        ownerProfile: action.payload,
        fetchingOwner: 'FETCHED_OWNER',
      }
    case 'GET_OWNER_PROFILE_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingOwner: 'ERROR_FETCHING_OWNER',
      }
    default:
      return state
  }
}
