export const  updateDocumentStart = (index: string) => {
  return {
    type: 'UPDATE_DOCUMENT_STARTED',
    payload: { index },
  }
}
  
export const  updateDocumentSuccess = (document: any, id: number, index: string, renterProfileIndex: number) => {
  return {
    type: 'UPDATE_DOCUMENT_SUCCESS',
    payload: {
      document,
      id,
      index,
      renterProfileIndex
    },
  }
}
export const  updateDocumentError = (error: any, index: number) => {
  return {
    type: 'UPDATE_DOCUMENT_ERROR',
    payload: {
      error,
      index
    },
  }
}