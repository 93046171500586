import React, { useEffect, useState } from 'react'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import intl from 'react-intl-universal'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

interface PublishedPostProps {
  onSetTemplateChild: (child: Object) => void
}
const PublishedPost = ({
  onSetTemplateChild
}: PublishedPostProps) => {
  const history = useHistory()
  const location: any = useLocation()
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      if (location.state === undefined) {
        history.goBack()
      } else {
        setUrl(location.state.redirect)
      }
      onSetTemplateChild(
        <>
          <h1>{intl.get('PROPERTY_PUBLISHED')}</h1>
        </>
      )
    }
  }, [onSetTemplateChild])

  const renderContent = () => {
    return (
      <div className='text-center'>
        <button
          className='col-6 mt-5 button-primary'
          onClick={() => history.push(url)}
        >
          {intl.get('CONTINUE')}
        </button>
      </div>
    )
  }

  return (
    <div className='container-home mt-0'>{renderContent()}</div>
  )
}
 
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    }
  }
}
const mapStateToProps = (state: any) => {}

export default connect(mapStateToProps, mapDispatchToProps)(PublishedPost)
