import { getData } from '../../helpers/apiConnections';
import { getOwnerPaymentsStart, getOwnerPaymentsError, getOwnerPaymentsSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getOwnerPayments = (id: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getOwnerPaymentsStart());
        getData('owner_payments/first_payments?rent_id=' + id)
            .then((result) => {
                dispatch(getOwnerPaymentsSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                dispatch(getOwnerPaymentsError(e));
            });
    };
};
export default getOwnerPayments;
