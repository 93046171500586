export const updateOwnerProfileStart = () => {
    return {
      type: 'UPDATE_OWNER_PROFILE_STARTED',
    }
  }
  export const updateOwnerProfileSuccess = (profile: any) => {
    return {
      type: 'UPDATE_OWNER_PROFILE_SUCCESS',
      payload: { profile },
    }
  }
  export const updateOwnerProfileError = (error: any) => {
    return {
      type: 'UPDATE_OWNER_PROFILE_ERROR',
      payload: {
        error,
      },
    }
  }