import React from 'react'
import intl from 'react-intl-universal'
import { PopupWithIcon } from '../../tango-react-base/components'
import { sendIcon } from '../../tango-react-base/resources'

/** Here the types of the props for the interface 'DataSentProps' are defined
 * @typedef DataSentModalTypes
 * @type {(function|boolean|string)}
 * @property {boolean} openSent - is a boolean.
 * @property {function} handleCloseSent - is a function.
 * @property {string} optionSeleted - is a string.
 */

interface DataSentProps {
  openSent: boolean
  handleCloseSent: () => void
  optionSeleted: string
}
/**
 * DataSentModal is a functional component that renders a confirmation data sent modal
 *@function
 *@param {DataSentModalTypes} optionSeleted - to know what is the text to be showed, depending on de option before seleted
 *@param {DataSentModalTypes} openSent - state that validates the rendering of the modal
 *@param {DataSentModalTypes} handleCloseSent - function that closes the modal
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */

const DataSentModal = ({
  openSent,
  handleCloseSent,
  optionSeleted,
}: DataSentProps) => {
  return (
    <PopupWithIcon
      title={
        optionSeleted === 'reject'
          ? 'RENEWAL_REJECTED_SENT'
          : 'RENEWAL_PROPOSAL_SENT'
      }
      open={openSent}
      handleClose={handleCloseSent}
      icon={sendIcon}
      lineUp
    >
      <div className='confirmation-text-container'>
        <p>{intl.get('DATA_SENT_TO_RENTER_OWNER')}</p>
        {optionSeleted === 'newPrice' && (
          <p>{intl.get('CONTINUE_PROCCESS')}</p>
        )}
      </div>
      <div className='mt-4'>
        <button
          className='button-primary col-md-5 mr-3 mb-2'
          onClick={handleCloseSent}
        >
          {intl.get('ACCEPT')}
        </button>
      </div>
    </PopupWithIcon>
  )
}

export default DataSentModal
