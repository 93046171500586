export const getAdvertStart = () => {
  return {
    type: 'GET_ADVERT_START',
  }
}

export const getAdvertSuccess = (result: any) => {
  return {
    type: 'GET_ADVERT_SUCCESS',
    payload: { result },
  }
}
export const getAdvertError = (error: any) => {
  return {
    type: 'GET_ADVERT_ERROR',
    payload: {
      error,
    },
  }
}
