import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { ButtonsHeader } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { Post } from '../../tango'
import { redirect_tabs, PUBLISHED_POST_TABS } from './buttonOptions'
import getPostAdmin from '../../tango-react-base/reduxActions/getPostAdmin'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import { PostCardAdmin } from '../../components'
import PostsButtons from './postsButtons'

/**
 * Here the type of each property is defined, this prop is similar to 'PostPublishedProps' but 'PostPublishedTypes' is for the documentation
 * @typedef PostPublishedTypes
 * @type {(function|string|array)}
 * @property {array} posts - is an Array.
 * @property {string} fetchingPosts - is a String.
 * @property {function} onGetPostAdmin - is a Function.
 * @property {function} onSetTemplateChild - is a Function.
 */

interface PostPublishedProps {
  posts: Array<Post>
  fetchingPosts: string
  onGetPostAdmin: (
    unitTypeDocument: string,
    unitState: string,
    registerType: string,
  ) => void
  onSetTemplateChild: (child: Object) => void
}

/**
 * PostPublished is a functional component which has many props.
 * The main function of this component is to render the inventory uploaded by a Tanguero.
 * This component connects itself with validation endpoints.
 *@function
 *@param {PostPublishedTypes}  posts - Returns an array of posts
 *@param {PostPublishedTypes}  fetchingPosts - Returns a string representing the fetching state of the posts
 *@param {PostPublishedTypes}  onGetPostAdmin - Gets the  posts by an Redux action
 *@param {PostPublishedTypes}  onSetTemplateChild  - Gets the template for the header
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

const PostPublished = ({
  posts,
  fetchingPosts,
  onGetPostAdmin,
  onSetTemplateChild,
}: PostPublishedProps) => {
  const history = useHistory()
  const [buttonSelected, setButtonSelected] = useState('missing')
  const [state, setState] = useState('pending')
  const showBadge = state.includes('pending')

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'published'}
          />
        </>,
      )
      // Based on the tab/button selected, a different endpoint call for the posts is triggered
      if (buttonSelected === 'pending') {
        setState('pending')
        onGetPostAdmin('pending', 'available', 'brokerage,new')
      } else if (buttonSelected === 'approved') {
        setState('approved')
        onGetPostAdmin('approved', 'available', 'brokerage,new')
      } else if (buttonSelected === 'missing') {
        setState('missing')
        onGetPostAdmin('missing', 'available', 'brokerage,new')
      }
    }
  }, [buttonSelected, history, onGetPostAdmin, onSetTemplateChild])

  /** detailsButton: This function renders a button element that redirects to the post details
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const detailsButton = (id: number, nameButton = 'DETAILS') => {
    return (
      <button
        className='button-fourth col-12'
        onClick={() => {
          history.replace(`posts/${id}`)
        }}
      >
        {intl.get(nameButton)}
        {showBadge && <span className='badge'>!</span>}
      </button>
    )
  }

  /** PublishedPosts : This function renders an iteration of PostCardAdmin component
   *  If there are no posts it renders a paragraph message
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const PublishedPosts = () => {
    return posts.length > 0 ? (
      <>
        {posts.map((post: any, index: number) => (
          <Fragment key={`post-${index}`}>
            <PostCardAdmin postId={post.id} {...post.attributes} docState={state}>
              {detailsButton(post.id)}
            </PostCardAdmin>
          </Fragment>
        ))}
      </>
    ) : (
      <p>{intl.get('NO_PROPERTY_SECTION')}</p>
    )
  }
  return (
    <div className='container-home pd-1'>
      <PostsButtons
        buttonSelected={buttonSelected}
        setButtonSelected={setButtonSelected}
        buttonsArray={PUBLISHED_POST_TABS}
      />
      <div className='template-line-title' />
      <h2 className='text-left my-2'>{intl.get('TITLE_PUBLISHED')}</h2>
      <div className='row'>
        {fetchingPosts === 'LOADING' && (
          <div className='col-md-6'>
            <CircularLoading />
          </div>
        )}
        {fetchingPosts === 'ERROR' && <p>{intl.get('ERROR')}</p>}
        {fetchingPosts === 'LOADED' && <PublishedPosts />}
      </div>
    </div>
  )
}
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatches an a action that usually changes a reducer state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPostAdmin: (
      unitTypeDocument: string,
      unitState: string,
      registerType: string,
    ) => {
      dispatch(getPostAdmin(unitTypeDocument, unitState, registerType))
    },
  }
}

/**
 *@function
 *@param {MapToProps}  state - By state the redux data can be accesssed.
 */
const mapStateToProps = (state: any) => {
  const { posts, fetchingPosts } = state.postsAdmin
  return {
    posts,
    fetchingPosts,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostPublished)
