import React, { useEffect } from 'react'
import { UserDetails } from '../../components'
import { connect } from 'react-redux'
import {
  getUserData,
  setTemplateChild,
} from '../../tango-react-base/reduxActions'
import { User } from '../../tango'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { CircularLoading } from '../../tango-react-base/components'

interface RenterShowProps {
  user: User
  onGetUser: (id: string) => void
  fetchingUser: string
  match: any
  onSetTemplateChild: (child: Object) => void
}
const RenterShow = ({
  user,
  onGetUser,
  fetchingUser,
  match,
  onSetTemplateChild,
}: RenterShowProps) => {
  const history = useHistory()
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('RENTER_DETAILS')}</h1>
        </>,
      )
      onGetUser(match.params.id)
    }
  }, [onSetTemplateChild, onGetUser, match.params.id])

  if (fetchingUser === 'FETCHED_USER') {
    return <UserDetails user={user} />
  } else if (fetchingUser === 'FETCHING_USER') {
    return <p>{<CircularLoading />}</p>
  } else if (fetchingUser === 'FETCHING_USER_ERROR') {
    return <p>{intl.get('ERROR')}</p>
  } else {
    return <p>{intl.get('NO_RENTERS')}</p>
  }
}

const mapStateToProps = (state: any) => ({
  ...state.userValidation,
})

const mapDispatchToProps = (dispatch: any, getState: any) => {
  return {
    onGetUser: (id: string) => {
      return dispatch(getUserData(id))
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenterShow)
