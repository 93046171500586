import {
  updateDocumentStart,
  updateDocumentSuccess,
  updateDocumentError,
} from './types'
import {
  renterDocumentsCountStart,
  renterDocumentsCountModified
} from '../renterDocumentsCount/types'
import { multipartPost } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'

const updateDocument = (id: number, index: string, data: any, renterProfileIndex?: any, documentType?: any) => {
  return (dispatch: any) => {
    dispatch(updateDocumentStart(index))
    multipartPost('renter_documents', data)
    .then((result) => {
      toast(intl.get('DOCUMENT_SUCCESSFULLY_UPLOADED'))
      dispatch(updateDocumentSuccess(result.data, id, index, renterProfileIndex))
      if (typeof renterProfileIndex === 'number' && documentType) {
        dispatch(renterDocumentsCountStart())
        dispatch(renterDocumentsCountModified(renterProfileIndex, documentType, 2))
      }
    })
    .catch((e) => {
      toast(handleError(e))
      return dispatch(updateDocumentError(e, id))
    })
  }
}

export default updateDocument