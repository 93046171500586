import React, { FC, useEffect, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

/**
 * Define the props of the component
 *  @typedef  Props
 *  @type {(Array | boolean | number | Function)}
 *  @property {Array} visitors - is an Array.
 *  @property {boolean} handleEdit - is a boolean.
 *  @property {string} visitor - is a string.
 *  @property {number} unitVisitId - is a number.
 *  @property {Function} handleChange - is a Function.
 *  @property {string} visitType - is a string.
 */
interface Props {
  visitors: Array<any>
  handleEdit: boolean
  visitor: string
  unitVisitId: number
  handleChange: Function
  visitType: string
}

/**
 * RenderUserVisits is a functional component with props.
 *  Render a custom select with the data.
 *  @function
 *  @param {Props} visitors - array with the users to render
 *  @param {Props} handleEdit - boolean to handle the edit button
 *  @param {Props} visitor - current visitor
 *  @param {Props} unitVisitId - dropdown identifier
 *  @param {Props} handleChange - function to handle the change
 *  @param {Props} visitType - current visit type
 *  @returns {(JSX.Element)} Returns a view with two tables
 */
const RenderUserVisits: FC<Props> = ({
  visitors,
  handleEdit,
  visitor,
  unitVisitId,
  handleChange,
  visitType,
}) => {
  const existVisitors = handleEdit || !visitors?.length
  const [currentVisitor, setCurrentVisitor] = useState('')

  const handleChangeSelect = (e: any) => {
    if (e.target.value === visitor) {
      handleChange(unitVisitId, visitType)
      setCurrentVisitor(visitor)
    } else {
      handleChange(unitVisitId, visitType, e.target.value[0])
      setCurrentVisitor(e.target.value[1])
    }
  }

  useEffect(() => {
    if (handleEdit) {
      setCurrentVisitor(visitor)
    }
  }, [handleEdit, visitor])

  const equalVisitor = currentVisitor === visitor
  return (
    <>
      <Select
        className={`select-visit-table-admin
          ${
            handleEdit
              ? 'select-disabled-visitors'
              : 'select-available-visitors'
          }`}
        disabled={existVisitors}
        IconComponent={ExpandMoreIcon}
        onChange={(e: any) => handleChangeSelect(e)}
        value={`${currentVisitor}`}
        name={`visitors-${unitVisitId}`}
        id={`visitors-${unitVisitId}`}
      >
        <MenuItem value={visitor} disabled={equalVisitor}>
          {equalVisitor ? currentVisitor : visitor}
        </MenuItem>
        {visitors &&
          visitors.map((visitorOption) =>
            currentVisitor === visitorOption.name ? (
              <MenuItem value={currentVisitor} disabled>
                {currentVisitor}
              </MenuItem>
            ) : (
              <MenuItem
                value={[parseInt(visitorOption.id), visitorOption.name]}
                key={`${visitorOption.id}-${unitVisitId}`}
              >
                {visitorOption.name}
              </MenuItem>
            ),
          )}
      </Select>
    </>
  )
}

export default RenderUserVisits
