import React from 'react'
import { NavbarMenuOptions, NavBar } from '../../tango-react-base/components'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ownerA854X400,
  ownerA1926X600,
  admin1926X600,
  admin854X400,
  white,
  tangoWhiteGreyLogo,
} from '../../tango-react-base/resources/index'

import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize/index'
import UserSearch from './UserSearch'
import { useLastLocation } from 'react-router-last-location'
import intl from 'react-intl-universal'
interface NavBarProps {
  childTemplate: any
}

const Navbar = ({ childTemplate }: NavBarProps) => {
  let { pathname } = useLocation()
  const lastLocation = useLastLocation()
  const history = useHistory()
  const size = useWindowSize()
  const navMenu = <NavbarMenuOptions />
  const session = sessionStorage.getItem('session')
  const mediationUrlSearch = window.location.href.indexOf('/renewal-period/')
  let templateAdminMobile = size.width <= 768 ? admin854X400 : admin1926X600
  let homeTemplateMobile = size.width <= 768 ? ownerA854X400 : ownerA1926X600

  const templateAndLogo = () => {
    let templateAux = !session ? homeTemplateMobile : templateAdminMobile
    return [templateAux]
  }

  const template = templateAndLogo()
  const logo = tangoWhiteGreyLogo
  const colorLine = white

  const sizeTemplate =
    size.width <= 768 ? '35rem' : session === null ? '50%' : '100%'

  let lastPathname = lastLocation?.pathname

  let allowedRoutes =
    lastPathname?.includes('users/details/') ||
    lastPathname?.includes('owners/')
      ? ['users', 'owners', 'renters/', 'posts/']
      : ['users', 'owners']

  const pathWithSearch = allowedRoutes.some((path) => pathname.includes(path))
  const excludePaths = ['/', '/login', '/visits', '/campaigns']

  return (
    <>
      <div className={pathWithSearch ? 'navbar-renter-container' : ''}>
        <div className='template-navbar-container'>
          <div
            className='template-navbar h-phone-75'
            style={{
              backgroundImage: `url(${template}`,
              backgroundSize: `${sizeTemplate}`,
            }}
          >
            <div className='container-navbar'>
              <NavBar childrenMenuItem={navMenu} logo={logo}></NavBar>
              {Object.keys(childTemplate).length > 0 && (
                <div className='second-navbar'>
                  <div
                    className='template-line'
                    style={{ backgroundColor: `${colorLine}` }}
                  />
                  {childTemplate}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='search-navbar-container'>
          {pathWithSearch && <UserSearch history={history} />}
        </div>
        {!excludePaths.includes(pathname) && mediationUrlSearch === -1 && (
          <div
            className={`text-left ${
              pathname === '/contracts'
                ? 'contracts-container'
                : size.width > 994
                ? 'container-home'
                : ''
            }`}
          >
            <button
              className='button-back p-0 d-none d-sm-none d-md-block d-lg-block'
              onClick={() => history.goBack()}
              data-cy='button'
            >
              {intl.get('BACK_BTN')}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    childTemplate: state.templateChild.child,
  }
}
const mapDispatchToProps = (dispatch: any) => {}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
