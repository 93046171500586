const initialState: any = {
  rejectLeaseRenewalStatus: 'REJECT_LEASE_RENEWAL_NOT_SENT',
  error: '',
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'REJECT_LEASE_RENEWAL_STARTED':
      return {
        ...state,
        rejectLeaseRenewalStatus: 'SENDING_REJECT_LEASE_RENEWAL',
      }
    case 'REJECT_LEASE_RENEWAL_SUCCESS':
      return {
        ...state,
        rejectLeaseRenewalStatus: 'SENT_REJECT_LEASE_RENEWAL',
      }
    case 'REJECT_LEASE_RENEWAL_ERROR':
      return {
        ...state,
        error: action.payload.error,
        rejectLeaseRenewalStatus: 'ERROR_REJECT_LEASE_RENEWAL',
      }

    default:
      return state
  }
}
