export const getAdvertGalleryStart = () => {
  return {
    type: 'GET_ADVERT_GALLERY_START',
  }
}

export const getAdvertGallerySuccess = (result: any) => {
  return {
    type: 'GET_ADVERT_GALLERY_SUCCESS',
    payload: { result },
  }
}
export const getAdvertGalleryError = (error: any) => {
  return {
    type: 'GET_ADVERT_GALLERY_ERROR',
    payload: {
      error,
    },
  }
}
