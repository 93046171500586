import { updateOwnerProfileStart, updateOwnerProfileSuccess, updateOwnerProfileError } from './types'
import putData from '../../../tango-react-base/helpers/apiConnections/putData'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import intl from 'react-intl-universal'
const updateOwnerProfile = (id: string, values: any) => {
  return (dispatch: any) => {
    dispatch(updateOwnerProfileStart())
    let owner = {
      owner_profile: {
        marital_status: values.marital_status,
        full_address: values.full_address,
        occupation: values.occupation,
        street_address_extra: values.street_address_extra,
        country_of_residence: values.country_of_residence,
      },
      user: {
        names: values.names,
        surnames: values.surnames,
        first_surname: values.first_surname,
        second_surname: values.second_surname,
        birth_date: values.birth_date,
      }
    }
    putData(`owner_profiles/${id}`, owner)
      .then((result) => {
        dispatch(updateOwnerProfileSuccess(result.data))
        toast(intl.get('INFORMATION_UPDATED'))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(updateOwnerProfileError(e))
      })
  }
}
export default updateOwnerProfile