import intl from 'react-intl-universal';
import { PRICE_OPTIONS } from '../selectOptions/options';

//TODO FOR ALL THESE FUNCTIONS, WHEN TANGO ADD OTHERS COUNTRIES WILL SHOULD CHANGE TO COMPARE process.env.REACT_APP_DEFAULT_COUNTRY

/**
 * This function returns a string with a price validation message when the user enters a price lower than expected
 * @function
 * @returns {(string)} price validation message
 */
export const priceMessageValidation = (price: number) => {
    return price &&
        price < PRICE_OPTIONS.find((element) => element.country === process.env.REACT_APP_DEFAULT_COUNTRY)!.price
        ? intl.get('MINIMUM_PRICE')
        : '';
};

/**
 * This function returns a boolean value based on the entered value
 * @function
 * @returns {(boolean)} price validation
 */
export const priceNumberValidation = (price: number) => {
    if (price) {
        return (
            price < PRICE_OPTIONS.find((element) => element.country === process.env.REACT_APP_DEFAULT_COUNTRY)!.price
        );
    } else {
        return true;
    }
};

/**
 * This function returns a the minimum value allowed by country
 * @function
 * @returns {(number)} price
 */
export const priceAllowedByCountry = () => {
    return PRICE_OPTIONS.find((element) => element.country === process.env.REACT_APP_DEFAULT_COUNTRY)?.price;
};
