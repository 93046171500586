export const getPendingVisitsStart = () => {
  return {
    type: 'GET_PENDING_VISITS_STARTED',
  }
}

export const getPendingVisitsAgentSuccess = (result: Array<any>) => {
  return {
    type: 'GET_PENDING_VISITS_AGENT_SUCCESS',
    payload: { resultAgent: result },
  }
}

export const getPendingVisitsVerifySuccess = (result: Array<any>) => {
  return {
    type: 'GET_PENDING_VISITS_VERIFY_SUCCESS',
    payload: { resultVerify: result },
  }
}

export const getPendingVisitsError = (error: any) => {
  return {
    type: 'GET_PENDING_VISITS_ERROR',
    payload: {
      error,
    },
  }
}
