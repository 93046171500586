export const getAdminContractsStart = () => {
    return {
        type: 'GET_ADMIN_CONTRACTS_STARTED',
    };
};

export const getAdminContractsSuccess = (result: any) => {
    return {
        type: 'GET_ADMIN_CONTRACTS_SUCCESS',
        payload: { result },
    };
};
export const getAdminContractsError = (error: any) => {
    return {
        type: 'GET_ADMIN_CONTRACTS_ERROR',
        payload: {
            error,
        },
    };
};
