import { getClosureLeaseDataStart, getClosureLeaseDataSuccess, getClosureLeaseDataError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const getClosureLeaseData = (leasePeriodId: number) => {
    return (dispatch: any) => {
        dispatch(getClosureLeaseDataStart());
        getData(`lease_periods/${leasePeriodId}/closure_data`)
            .then((result) => {
                return dispatch(getClosureLeaseDataSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getClosureLeaseDataError(e));
            });
    };
};
export default getClosureLeaseData;
