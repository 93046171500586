import {
  approveDocumentStart,
  approveDocumentSuc,
  approveDocumentError,
} from './types'
import {
  renterDocumentsCountStart,
  renterDocumentsCountModified
} from '../renterDocumentsCount/types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'

const getUnit = (id: number, index: string, alternative_url?: string, renterProfileIndex?: any, documentType?: any) => {
  return (dispatch: any) => {
    const uri =
      alternative_url === undefined
        ? `unit_documents/${id}/approve`
        : `${alternative_url}/${id}/approve`
    dispatch(approveDocumentStart(index))
    putData(uri)
      .then((result) => {
        toast(intl.get('DOCUMENT_APPROVED'))
        dispatch(approveDocumentSuc(result.data, id, index, renterProfileIndex))
        if (typeof renterProfileIndex === 'number' && documentType) {
          dispatch(renterDocumentsCountStart())
          dispatch(renterDocumentsCountModified(renterProfileIndex, documentType, 3))
        }
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(approveDocumentError(e, index))
      })
  }
}

export default getUnit
