import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import { putData } from '../../../../tango-react-base/helpers/apiConnections';
import { handleError } from '../../../../tango-react-base/helpers/handleErrors';
import { expireRenterError, expireRenterStart, expireRenterSuccess } from './types';
const expireRenter = (renter_validation_id: number) => {
    return (dispatch: any) => {
        dispatch(expireRenterStart());
        putData(`renter_validations/${renter_validation_id}/expire`)
            .then(() => {
                toast(intl.get('RENTER_EXPIRED'));
                dispatch(expireRenterSuccess());
            })
            .catch((e: any) => {
                toast(handleError(e));
                dispatch(expireRenterError(e));
            });
    };
};
export default expireRenter;
