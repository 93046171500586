import {
  getAdvertGalleryStart,
  getAdvertGallerySuccess,
  getAdvertGalleryError,
} from './types'
import { getData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const getAdvertGallery = () => {
  return (dispatch: any, getState: any) => {
    dispatch(getAdvertGalleryStart())
    getData(`advert_galleries/last`, { kind: 'carousel' })
      .then((result) => {
        return dispatch(getAdvertGallerySuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getAdvertGalleryError(e))
      })
  }
}
export default getAdvertGallery
