const initialState: any = {
  users: [],
  user: [],
  fetch: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_USERS_RESET':
      return {
        ...state,
        users: [],
        user: [],
      }
    case 'GET_USERS_DETAIL_STARTED':
    case 'GET_USERS_STARTED':
      return {
        ...state,
        user: [],
        fetch: 'LOADING',
      }
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        users: action.payload,
        fetch: 'LOADED',
      }
    case 'GET_USERS_DETAIL_SUCCESS':
      return {
        ...state,
        user: action.payload,
        fetch: 'LOADED',
      }
    case 'GET_USERS_DETAIL_ERROR':
    case 'GET_USERS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'ERROR',
      }
    default:
      return state
  }
}
