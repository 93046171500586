const initialState: any = {
    chargeLines: [],
    editable: null,
    clfValue: null,
    fetchChargeLines: 'NO_INIT',
  }
  
  export default (state = initialState, action: any) => {
    
    switch (action.type) {
      case 'GET_CHARGE_LINES_STARTED':
        return {
          ...state,
          fetchChargeLines: 'FETCHING',
        }
      case 'GET_CHARGE_LINES_SUCCESS':
        return {
          ...state,
          chargeLines: action.payload.data.attributes.charge_lines.data,
          editable: action.payload.data.attributes.editable,
          clfValue: action.payload.data.attributes.clf_value_initial,
          fetchChargeLines: 'FETCHED',
        }
      case 'GET_CHARGE_LINES_ERROR':
        return {
          ...state,
          error: action.payload.error,
          fetchChargeLines: 'ERROR',
        }
    
      default:
        return state
    }
  }
  