const initialState = {
  unitVersion: {},
  fetchingUnitVersion: 'LOADING',
  savingUnitVersion: 'NO_SAVING',
  publishingUnitVersion: 'NO_PUBLISH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_UNIT_VERSION_STARTED':
      return {
        ...state,
        fetchingUnitVersion: 'LOADING',
      }
    case 'GET_UNIT_VERSION_SUCCESS':
      return {
        ...state,
        unitVersion: action.payload.data,
        fetchingUnitVersion: 'LOADED',
      }
    case 'GET_UNIT_VERSION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingUnitVersion: 'ERROR',
      }

    case 'UPDATE_UNIT_VERSION_STARTED':
      return {
        ...state,
        savingUnitVersion: 'SAVING',
      }
    case 'UPDATE_UNIT_VERSION_SUCCESS':
      return {
        ...state,
        savingUnitVersion: 'SAVED',
      }
    case 'UPDATE_UNIT_VERSION_ERROR':
      return {
        ...state,
        savingUnitVersion: 'ERROR',
        error: action.payload.error,
      }

    case 'PUBLISH_UNIT_VERSION_STARTED':
      return {
        ...state,
        publishingUnitVersion: 'PUBLISHING',
      }
    case 'PUBLISH_UNIT_VERSION_SUCCESS':
      return {
        ...state,
        publishingUnitVersion: 'PUBLISHED',
      }
    case 'PUBLISH_UNIT_VERSION_ERROR':
      return {
        ...state,
        publishingUnitVersion: 'ERROR',
        error: action.payload.error,
      }

    default:
      return state
  }
}
