import { ThemeProvider } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { Navbar } from './components';
import './customBootstrap.scss';
import store from './redux/store';
import routes from './routes';
import routesMount from './routesMount';
import { Footer } from './tango-react-base/components';
import { usePageTracking } from './tango-react-base/helpers/hookAnalytics';
import { theme } from './tango-react-base/resources/theme';

require('intl/locale-data/jsonp/en.js');
declare global {
    interface Window {
        Cypress: any;
        intl: any;
    }
}

// only for tests
if (window.Cypress) {
    window.intl = intl;
}

const locales: any = {
    'en-US': require('./tango-react-base/locales/en-US'),
    'es-ES': require('./tango-react-base/locales/es-ES'),
    'es-CL': require('./tango-react-base/locales/es-ES'),
    'es-MX': require('./tango-react-base/locales/es-ES'),
};

function App() {
    const [loadingLanguajes, setLoadingLanguajes] = useState(true);
    useEffect(() => {
        loadLocales();
        if (!window.location.href.includes('login')) {
            sessionStorage.setItem('lastLocation', window.location.pathname + window.location.search);
        }
    }, []);
    const loadLocales = () => {
        const currentLocale = 'es-ES';
        let locales_parsed: any = {};
        Object.keys(locales).forEach((key) => (locales_parsed[`${key}`] = locales[key].default));
        intl.init({
            currentLocale,
            locales: locales_parsed,
        }).then(() => {
            // After loading CLDR locale data, start to render
            setLoadingLanguajes(false);
        });
    };
    const RenderWithSubRoutes = (route: any) => {
        const { pathname } = useLocation();
        useLayoutEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
        usePageTracking();
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => <route.component {...props} routes={route.routes} />}
            />
        );
    };
    return (
        <Router>
            <LastLocationProvider>
                <ToastContainer hideProgressBar={true} autoClose={10000} />
                <div className='App'>
                    <Provider store={store}>
                        <ThemeProvider theme={theme}>
                            {loadingLanguajes ? (
                                <div style={{ height: '100vh' }}> Loading ... </div>
                            ) : (
                                <>
                                    <div className='container-mount-view'>
                                        <div className='container-uniq-mount'>
                                            {routesMount.map((route, index) => (
                                                <RenderWithSubRoutes {...route} key={`route-cmp-${index}`} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='principal-container'>
                                        <Navbar />
                                        <Switch>
                                            {routes.map((route, index) => (
                                                <RenderWithSubRoutes {...route} key={`route-cmp-${index}`} />
                                            ))}
                                        </Switch>
                                        <Footer />
                                        <div>{process.env.REACT_APP_VERSION}</div>
                                    </div>
                                </>
                            )}
                        </ThemeProvider>
                    </Provider>
                </div>
            </LastLocationProvider>
        </Router>
    );
}
export default App;
