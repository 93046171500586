import {
  rejectPropertyDocumentStart,
  rejectPropertyDocumentSuccess,
  rejectPropertyDocumentError,
} from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { getPropertyDocuments } from '../../../tango-react-base/reduxActions'

const rejectPropertyDocument = (id: number, unitId: number) => {
  return (dispatch: any) => {
    dispatch(rejectPropertyDocumentStart())
    putData(`unit_documents/${id}/reject`)
      .then((result) => {
        dispatch(getPropertyDocuments(unitId))
        dispatch(rejectPropertyDocumentSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(rejectPropertyDocumentError(e))
      })
  }
}
export default rejectPropertyDocument
