import {
  enableDocumentStart,
  enableDocumentSuccess,
  enableDocumentError,
} from './types'
import intl from 'react-intl-universal'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { getLeaseDocuments } from '../../../tango-react-base/reduxActions'

const enableDocument = (id: number, rentId: number | null, doc_type: any) => {
  return (dispatch: any) => {
    dispatch(enableDocumentStart())
    putData(
      `lease_documents/enable_document?document_id=${id}&document_type=${doc_type}`,
    )
      .then((result: any) => {
        if (rentId) {
          dispatch(getLeaseDocuments(rentId))
          dispatch(enableDocumentSuccess())
        } else {
          toast(intl.get('VOUCHER_ENABLED'))
          dispatch(enableDocumentSuccess(result))
        }
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(enableDocumentError(e))
      })
  }
}
export default enableDocument
