import React from 'react'
import intl from 'react-intl-universal'

/**
 * Here the type of each property is defined, this prop is similar to 'PostsButtonsProps' but 'PostsButtonsTypes' is for the documentation
 * @typedef PostsButtonsTypes
 * @type {(function|string|array)}
 * @property {array} buttonsArray - is an Array.
 * @property {string} buttonSelected - is a string.
 * @property {function} setButtonSelected - is a function.
 */
type PostsButtonsProps = {
  buttonsArray: Array<any>
  buttonSelected: string
  setButtonSelected: (buttonSelected: string) => void 
}
/**
 * PostsButtons is a functional component which has many props.
 * The main function of this component is to render a set of buttons.
 *@function
 *@param {PostsButtonsTypes}  buttonsArray - Returns an array with options or the buttons to be displayed
 *@param {PostsButtonsTypes}  buttonSelected - Returns a string which serves to validate which button is clicked
 *@param {PostsButtonsTypes}  setButtonSelected - Mutates a the buttonSelected state
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */
const PostsButtons = ({
  buttonsArray,
  buttonSelected,
  setButtonSelected,
}: PostsButtonsProps) => {
  const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return (
    <div className='row my-5'>
          {buttonsArray.map((button, key) => (
              <button
                key={key}
                className={`${
                  buttonSelected === button.label
                    ? 'button-admin-tab'
                    : 'button-admin-tab-disabled'
                } m-2 big-button`}
                onClick={() => {
                    setButtonSelected(button.label)
                }}
              >
                {capitalize(intl.get(button.viewLabel))}
              </button>
            ),
          )}
    </div>
  )
}
export default PostsButtons
