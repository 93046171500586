import { Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularLoading, DialogModal } from '../../tango-react-base/components';
import CalendarColorMeaning from '../../tango-react-base/components/calendarColorMeaning/index';
import VisitsList from '../../tango-react-base/components/visitsList/index';
import { getMonth } from '../../tango-react-base/helpers/getMonth';
import { getParameterByName } from '../../tango-react-base/helpers/getParamsUrl/index';
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize/index';
import {
  automaticTakeSchedule,
  getBlocks,
  getVisitsCanceled,
  getVisitsFinished,
  getVisitsPending,
} from '../../tango-react-base/reduxActions';
import { addDate } from '../../tango-react-base/reduxActions/calendarActions';
import submitCalendar from '../../tango-react-base/reduxActions/calendarActions/submitCalendar/index';
import { lastWeek, nextWeek } from '../../tango-react-base/reduxActions/requesterCalendarActions';
import { backArrow, CalendarDate, CalendarOff, forwardArrow } from '../../tango-react-base/resources';
import { CalendarMobile } from './calendarMobile';
var moment = require('moment');

interface CalendarProps {
  onSubmit: any;
  automaticSchedule: string;
  startWeek: any;
  endWeek: any;
  onLastWeek: any;
  onNextWeek: any;
  week: Array<any>;
  blocksTaken: Array<any>;
  blocksDisabled: Array<any>;
  onGetBlocks: (id?: any, visitorType?: string) => void;
  onGetVisitsPending: (id: any, rol: string) => void;
  onGetVisitsFinished: (id: any, rol: string) => void;
  onGetVisitsCanceled: (id: any, rol: string) => void;
  onSetTemplateChild: (child: Object) => void;
  updateSchedule: (arg: any) => void;
  visitsPending: Array<any>;
  visitsFinished: Array<any>;
  visitsCanceled: Array<any>;
  fetchingVisitsPending: string;
  fetchingVisitsFinished: string;
  fetchingVisitsCanceled: string;
}

const CalendarLite = ({
  onSubmit,
  automaticSchedule,
  startWeek,
  endWeek,
  onLastWeek,
  onNextWeek,
  week,
  blocksTaken,
  blocksDisabled,
  onGetBlocks,
  updateSchedule,
  onGetVisitsPending,
  onGetVisitsFinished,
  onGetVisitsCanceled,
  // VISITS INFO
  visitsPending,
  visitsFinished,
  visitsCanceled,
  fetchingVisitsPending,
  fetchingVisitsFinished,
  fetchingVisitsCanceled,
}: CalendarProps) => {
  const inputSwitch = useRef<any>();
  const { search } = useHistory().location;
  const { id }: any = useParams();
  const [checkbox, setCheckbox] = useState({
    openModal: false,
    isChecked: automaticSchedule === 'yes',
  });

  const VisitsTable = () => {
    return (
      <>
        <div className='template-line' />
        <h2 className='new-subtitle '>{intl.get('MY_VISITS')}</h2>
        <h2 className='text text-color-secondary'>{intl.get('TITLE_PENDING')}</h2>
        <VisitsList visits={visitsPending} fetch={fetchingVisitsPending} />
        <h2 className='text text-color-secondary'>{intl.get('TITLE_FINISHED')}</h2>
        <VisitsList visits={visitsFinished} fetch={fetchingVisitsFinished} />
        <h2 className='text text-color-secondary'>{intl.get('TITLE_CANCELED')}</h2>
        <VisitsList visits={visitsCanceled} fetch={fetchingVisitsCanceled} />
      </>
    );
  };

  const roleType = getParameterByName('role', search) || '';

  const morningDate = (date: any) => {
    return moment(date).hours(8).minutes(0).seconds(0);
  };
  const afternoonDate = (date: any) => {
    return moment(date).hours(12).minutes(0).seconds(0);
  };
  const nightDate = (date: any) => {
    return moment(date).hours(17).minutes(0).seconds(0);
  };
  const confirmSwitch = (isEnable: boolean) => {
    updateSchedule(isEnable);
    setCheckbox({ ...checkbox, openModal: false });
  };
  const handleCancelOrClose = (enable: boolean) => {
    if (inputSwitch.current) {
      inputSwitch.current.checked = !enable;
    }
    setCheckbox({ ...checkbox, openModal: false });
  };

  useEffect(() => {
    const visitType = roleType === 'verifier' ? 'verify' : 'show';
    onGetBlocks(id, roleType);
    onGetVisitsPending(id, visitType);
    onGetVisitsFinished(id, visitType);
    onGetVisitsCanceled(id, visitType);
  }, []);

  const isDisabled = (date: any) => {
    if (blocksDisabled.length === 0) return false;
    const filteredblocksTaken = blocksDisabled.filter((dateIter: any) => {
      return moment(dateIter).isSame(date);
    });
    return filteredblocksTaken.length > 0;
  };

  const size = useWindowSize();
  const { fetchingBlocks } = useSelector((state: any) => state.visitCalendar);

  const blockType = [
    {
      name: 'Morning',
      block: '8:00 - 11:59',
      action: morningDate,
    },
    {
      name: 'Afternoon',
      block: '12:30 - 16:59',
      action: afternoonDate,
    },
    {
      name: 'Night',
      block: '17:00 - 20:30',
      action: nightDate,
    },
  ];

  const currentYear = new Date().getFullYear();
  return fetchingBlocks === 'FETCHED_BLOCKS' ? (
    <div className='container-home'>
      <div className='columns wrap calendar-container pd-1 mt-5'>
        <DialogModal title='' handleClose={() => handleCancelOrClose(checkbox.isChecked)} open={checkbox.openModal}>
          <h2 className='subtitle-modal'>{intl.get('BLOCK_AVAILABILITY')}</h2>
          <img src={checkbox.isChecked ? CalendarDate : CalendarOff} alt='Logo' className='my-3' />
          <Typography gutterBottom>
            {checkbox.isChecked ? intl.get('ACTIVATE_BLOCK_TWO_WEEKS') : intl.get('DEACTIVATE_BLOCK_TWO_WEEKS')}
          </Typography>
          <div className='d-flex justify-content-between mt-4 row'>
            <button
              className='button-disabled-bank col-md-5 mb-3'
              onClick={() => handleCancelOrClose(checkbox.isChecked)}
            >
              {intl.get('CANCEL')}
            </button>
            <button className='button-primary col-md-5' onClick={() => confirmSwitch(checkbox.isChecked)}>
              {intl.get('CONFIRM_BTN')}
            </button>
          </div>
        </DialogModal>
        {size.width >= 768 ? (
          <>
            <div className='row calendar-month'>
              <button
                onClick={() => onLastWeek()}
                disabled={blocksTaken.length > 0}
                className='col-2 arrow-logo-container'
              >
                <img src={backArrow} alt='arrow' className={size.width >= 768 ? 'arrow-logo' : 'h-sm-50'} />
              </button>
              <h2 className='col-8 align-self-center '>
                {getMonth(startWeek, endWeek)} - {currentYear}
              </h2>
              <button
                onClick={() => onNextWeek()}
                disabled={blocksTaken.length > 0}
                className='col-2 arrow-logo-container'
              >
                <img src={forwardArrow} alt='arrow' className={size.width >= 768 ? 'arrow-logo' : 'h-sm-50'} />
              </button>
            </div>
            <section className='row no-wrap week-section align-items-start'>
              {week.map((day: any, index: number) => {
                const startOrEndBorder =
                  (index === 0 && 'border-radius-left') || (index === 6 && 'border-radius-rigth') || '';
                const now = new moment().subtract(1, 'days');

                const threeMonths = new moment().add(3, 'month');
                const classBtnSchedule = (action: any) => {
                  return isDisabled(action) && disabledBtn ? 'only-view-btn-schedule' : 'only-view-available';
                };

                const disabledBtn = day.day() !== 0 && threeMonths > day && day > now;

                return (
                  <div className='day-column col-mb-4 mt-sm-2' key={index}>
                    <div
                      className={`${startOrEndBorder} d-flex justify-content-center align-items-center divSection mb-2`}
                    >
                      <span className='word-nowrap font-sm-1r'>
                        {`${intl.get(day.format('dddd').toUpperCase())} ${day.format('DD')}`}
                      </span>
                    </div>
                    {blockType.map(({ name, block, action }) => {
                      return (
                        <div className={`${classBtnSchedule(action(day))}`}>
                          <ToggleButton
                            value='check'
                            disabled={!disabledBtn}
                            className={`toggle-button-calendar ${!disabledBtn && 'btn-disabled'} ${classBtnSchedule(
                              action(day),
                            )}`}
                          >
                            <p className='columns font-sm-07'>
                              <span className='text-capitalize'>{intl.get(name.toUpperCase())}</span>
                              <br />
                              <span>{block}</span>
                            </p>
                          </ToggleButton>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </section>
          </>
        ) : (
          <CalendarMobile
            isDisabled={isDisabled}
            blockType={blockType}
            fetchingBlocks={fetchingBlocks}
            blocksTaken={blocksTaken}
            onSubmit={onSubmit}
          />
        )}
        <CalendarColorMeaning />
        <VisitsTable />
      </div>
    </div>
  ) : (
    <div className='my-3'>
      {fetchingBlocks === 'ERROR_FETCHING_BLOCKS' ? <div>{intl.get('REQUESTED_NOT_FOUND')}</div> : <CircularLoading />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state.visitCalendar,
  ...state.visit,
  automaticSchedule: state.session.user?.attributes?.automatic_schedule,
});

const mapDispatchToProps = (dispatch: any, getState: any) => {
  return {
    onNextWeek: () => {
      return dispatch(nextWeek());
    },
    onLastWeek: () => {
      return dispatch(lastWeek());
    },
    onChangeDate: (date: any, aasm_state?: string, id?: number) => {
      return dispatch(addDate(date, aasm_state, id));
    },
    submitblocksTaken: () => {
      return dispatch(submitCalendar());
    },
    onGetBlocks: (id?: any, visitorType?: string) => {
      return dispatch(getBlocks(null, id, null, false, visitorType));
    },
    updateSchedule: (arg: any) => {
      return dispatch(automaticTakeSchedule(arg));
    },
    onGetVisitsPending: (id: any, role_type?: string) => {
      dispatch(getVisitsPending(id, role_type));
    },
    onGetVisitsFinished: (id: any, role_type?: string) => {
      dispatch(getVisitsFinished(id, role_type));
    },
    onGetVisitsCanceled: (id: any, role_type?: string) => {
      dispatch(getVisitsCanceled(id, role_type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLite);
