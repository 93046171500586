import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { setTemplateChild, getPosts } from '../../tango-react-base/reduxActions'
import {
  ButtonsHeader,
  CircularLoading,
} from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { Post } from '../../tango'
import { redirect_tabs } from './buttonOptions'
import { PostCardAdmin } from '../../components'

interface PostsIndexProps {
  posts: Array<Post>
  fetchingPosts: string

  onSetTemplateChild: (child: Object) => void
  onGetPosts: (filter: any, states: any) => void
}
const PostsIndex = ({
  posts,
  fetchingPosts,

  onGetPosts,
  onSetTemplateChild,
}: PostsIndexProps) => {
  const history = useHistory()
  const homeView =
    history.location.pathname === '/' &&
    !sessionStorage.getItem('authorization')

  useEffect(() => {
    if (homeView) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'pending publish'}
          />
        </>,
      )
      onGetPosts(null, {
        unit_state: 'pending',
        register_type: 'brokerage,new',
      })
    }
  }, [history, onGetPosts, onSetTemplateChild, homeView])

  const renderButton = (id: number, nameButton = 'DETAILS') => {
    return (
      <button
        className='button-fourth col-12'
        onClick={() => {
          history.push(`posts/${id}`)
        }}
      >
        {intl.get(nameButton)}
      </button>
    )
  }

  const VerifiedPosts = () => {
    return (
      <>
        {posts.map(
          (post: any, index: number) =>
            post.attributes.aasm_state === 'verified' && (
              <Fragment key={`post-${index}`}>
                <PostCardAdmin {...post.attributes}>
                  {renderButton(post.id, 'PUBLISH')}
                </PostCardAdmin>
              </Fragment>
            ),
        )}
      </>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className='template-line-title mt-5' />
        <h2 className='text my-2'>{intl.get('PENDING_PUBLISH')}</h2>
        {posts.length > 0 ? (
          <div className='row'>
            <VerifiedPosts />
          </div>
        ) : (
          <p>{intl.get('NO_PROPERTY_SECTION')}</p>
        )}
      </>
    )
  }
  return (
    <>
      {fetchingPosts === 'LOADING' && <p>{<CircularLoading />}</p>}
      {fetchingPosts === 'ERROR' && <p>{intl.get('ERROR')}</p>}
      {fetchingPosts === 'LOADED' && (
        <div className='container-home pd-1'>
          {!homeView && renderContent()}
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPosts: (filter: any, states: any) => {
      dispatch(getPosts(filter, states))
    },
  }
}
const mapStateToProps = (state: any) => {
  const { posts, fetchingPosts } = state.posts
  return {
    posts,
    fetchingPosts,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsIndex)
