import { putData } from '../../helpers/apiConnections';
import { RejectEarlyRequisitionStart, RejectEarlyRequisitionError, RejectEarlyRequisitionSuccess } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';

const RejectEarlyRequisition = (requisitionID: number, comment: string) => {
    return (dispatch: any) => {
        dispatch(RejectEarlyRequisitionStart());

        putData(`requisitions/${requisitionID}/reject`, { closing_comment: comment })
            .then((result: any) => {
                toast(intl.get('REJECTION_EARLY_REQUISITION'));
                dispatch(RejectEarlyRequisitionSuccess(result.data));
            })
            .catch((error) => {
                toast(handleError(error));
                return dispatch(RejectEarlyRequisitionError(error));
            });
    };
};

export default RejectEarlyRequisition;
