const initialState = {
  data: [],
  dataRestorationVoucher: [],
  dataDeliveryVoucher: [],
  fetchingData: 'NO_DATA',
  voucher_validated: false,
  validation_status: '',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PENDING_VOUCHERS_STARTED':
      return {
        ...state,
        fetchingData: 'LOADING',
      }
    case 'GET_PENDING_VOUCHERS_SUCCESS':
      return {
        ...state,
        data: action.payload.data,
        fetchingData: 'LOADED',
      }
    case 'GET_RESTORATION_PENDING_VOUCHERS_SUCCESS':
      return {
        ...state,
        dataRestorationVoucher: action.payload.data,
        fetchingData: 'LOADED',
      }
    case 'GET_DELIVERY_PENDING_VOUCHERS_SUCCESS':
      return {
        ...state,
        dataDeliveryVoucher: action.payload.data,
        fetchingData: 'LOADED',
      }
    case 'GET_PENDING_VOUCHERS_ERROR':
      return {
        ...state,
        fetchingData: 'ERROR',
      }
    case 'ENABLE_DOCUMENT_SUCCESS':
      return {
        ...state,
        voucher_validated: action.payload.data,
        validation_status: 'SUCCESS',
      }
    case 'ENABLE_DOCUMENT_STARTED':
      return {
        ...state,
        validation_status: 'LOADING',
      }
    default:
      return state
  }
}
