import React, { useEffect } from 'react'
import { SectionProps } from './SectionInterfaces'
import { SwitchString, RadioButtonTango, Input, CheckBoxTango } from '../..'
import {
  verifierOrAdminPermissions,
  propertyStatusPermissions,
} from './SectionHelpers'
import {
  ORIENTATION_OPTIONS,
  LIVING_ROOM_OPTIONS,
  BOOLEAN_OPTIONS,
  FLOOR_APARTMENT_COUNT_OPTIONS,
  KITCHEN_OPTIONS,
  TYPE_FLOOR_APARTMENT,
} from '../../../../tango-react-base/helpers/selectOptions/options'
import intl from 'react-intl-universal'
import { isRolePhotographer } from '../../../helpers/roleComparison/index'
const UnitDetails = ({
  values,
  errors,
  handleChange,
  handleBlur,
  disabled,
  isVerifier,
  setFullFieldsUnitDetail,
  toRepublish = false,
}: SectionProps) => {
  const fields: any =
    isRolePhotographer || toRepublish
      ? [
          values.floors_count || undefined,
          values.unit_type === 'apartment' ? values.story || undefined : '',
          values.orientation?.length > 0
            ? values.orientation || undefined
            : undefined,
          values.kitchen || undefined,
          values.living_room || undefined,
          values.floor_to_ceiling_height || undefined,
        ]
      : []

  useEffect(() => {
    setFullFieldsUnitDetail &&
      setFullFieldsUnitDetail(!fields.includes(undefined))
  }, [fields])

  return (
    <>
      {(verifierOrAdminPermissions() ||
        propertyStatusPermissions(values.aasm_state)) && (
        <>
          {!toRepublish && (
            <>
              <div className='template-line mt-4' />
              <h2 className='new-subtitle'>{intl.get('UNIT_DETAIL')}</h2>
            </>
          )}
          <RadioButtonTango
            error={errors.floors_count}
            options={TYPE_FLOOR_APARTMENT}
            id='floors_count'
            label='TYPE_FLOOR_APARTMENT'
            valueChecked={Number(values.floors_count)}
            onChange={handleChange}
            disabled={disabled}
            isVerifier={isVerifier}
            isRequiredVerifier={!values.floors_count}
          />
          {values.unit_type === 'apartment' && (
            <>
              <RadioButtonTango
                options={FLOOR_APARTMENT_COUNT_OPTIONS}
                id='story'
                label='FLOOR_COUNT_APARTMENT'
                valueChecked={Number(values.story)}
                onChange={handleChange}
                disabled={disabled}
                isVerifier={isVerifier}
                error={errors.story}
                isRequiredVerifier={!values.story}
              />
              <SwitchString
                label='PENTHOUSE'
                name='penthouse'
                options={BOOLEAN_OPTIONS}
                value={values.penthouse}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
              />

              <SwitchString
                label='TERRACE'
                name='terrace'
                options={BOOLEAN_OPTIONS}
                value={values.terrace}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
              />
              <SwitchString
                label='BALCONY'
                name='balcony'
                options={BOOLEAN_OPTIONS}
                value={values.balcony}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
              />
            </>
          )}

          <CheckBoxTango
            disabled={disabled}
            label='ORIENTATION'
            name='orientation'
            options={ORIENTATION_OPTIONS}
            error={errors.orientation}
            isVerifier={isVerifier}
            isRequiredVerifier={values.orientation.length === 0}
          />
          <SwitchString
            label='PRIVATE_JACUZZI'
            name='private_jacuzzi'
            options={BOOLEAN_OPTIONS}
            value={values.private_jacuzzi}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <RadioButtonTango
            error={errors.kitchen}
            label='KITCHEN_OPTION'
            id='kitchen'
            options={KITCHEN_OPTIONS}
            valueChecked={values.kitchen}
            disabled={disabled}
            onChange={handleChange}
            isVerifier={isVerifier}
            isRequiredVerifier={!values.kitchen}
          />
          <SwitchString
            label='DINING_ROOM'
            name='dining_room'
            options={BOOLEAN_OPTIONS}
            value={values.dining_room}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <SwitchString
            label='LAUNDRY'
            name='laundry_room'
            options={BOOLEAN_OPTIONS}
            value={values.laundry_room}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <RadioButtonTango
            error={errors.living_room}
            label='LIVING_ROOM'
            id='living_room'
            options={LIVING_ROOM_OPTIONS}
            valueChecked={values.living_room}
            disabled={disabled}
            onChange={handleChange}
            isVerifier={isVerifier}
            isRequiredVerifier={!values.living_room}
          />
          <SwitchString
            label='PRIVATE_COURTYARD'
            name='private_courtyard'
            options={BOOLEAN_OPTIONS}
            value={values.private_courtyard}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <SwitchString
            label='PRIVATE_POOL'
            name='private_pool'
            options={BOOLEAN_OPTIONS}
            value={values.private_pool}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <SwitchString
            label='WALK_IN_CLOSET'
            name='walk_in_closet'
            options={BOOLEAN_OPTIONS}
            value={values.walk_in_closet}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
          />
          <Input
            id='floor_to_ceiling_height'
            label='HEIGHT_FLOOR_CEILING'
            type='number'
            value={values.floor_to_ceiling_height}
            onChange={handleChange}
            onBlur={handleBlur}
            min='0'
            max='5000000'
            requiredMessage='Estimated price is required'
            disabled={disabled}
            unit='cms'
            isVerifier={isVerifier}
            isRequiredVerifier={!values.floor_to_ceiling_height}
          />
        </>
      )}
    </>
  )
}
export default UnitDetails
