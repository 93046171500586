const initialState = {
  paymentsProviders: [],
  fetchPaymentsProviders: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PAYMENTS_PROVIDERS_STARTED':
      return {
        ...state,
        fetchPaymentsProviders: 'FETCHING',
      }
    case 'GET_PAYMENTS_PROVIDERS_SUCCESS':
      return {
        ...state,
        fetchPaymentsProviders: 'FETCHED',
        paymentsProviders: action.payload.data.data,
      }
    case 'GET_PAYMENTS_PROVIDERS_ERROR':
      return {
        ...state,
        fetchPaymentsProviders: 'ERROR',
      }
    default:
      return state
  }
}


