import { UserValidationState } from '../../../tango'

const initialState: UserValidationState = {
  user: {},
  fetchingUser: 'NO_USER',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_USER_VALIDATION_DATA_STARTED':
      return {
        ...state,
        fetchingUser: 'FETCHING_USER',
      }
    case 'GET_USER_VALIDATION_DATA_SUCCESS':
      return {
        ...state,
        user: action.payload.user.data,
        fetchingUser: 'FETCHED_USER',
      }
    case 'GET_USER_VALIDATION_DATA_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingUser: 'FETCHING_USER_ERROR',
      }
    default:
      return state
  }
}
