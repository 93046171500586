import React, { useState, useEffect, useRef } from 'react';
import { FormikProps, withFormik, Form } from 'formik';
import Input from '../input';
import intl from 'react-intl-universal';
import { LEASE_DOCUMENTS_OPTIONS } from '../../../tango-react-base/helpers/selectOptions/options';
import { ButtonLoading, Select } from '..';
import FormValidates from './validation';
import { toast } from 'react-toastify';
import { DatePicker } from '../../../tango-react-base/components';
import { useWindowSize } from '../../helpers/hookWindowSize';

/**
 * Here it is defined the type of the property, 'uploadDocumentsProps' is for the documentation
 * @typedef uploadDocumentsProps
 * @type {(function|object|boolean|string)}
 * @property {function} onSubmit - is a function.
 * @property {string} fetchDocument - is an string.
 * @property {array} datasContracts - is an object.
 */

interface uploadDocumentsProps {
    onSubmit: any;
    fetchDocument: string;
    datasContracts: {
        data_admin_contract: {};
        data_lease_contract: {};
        rent_active_or_finished: boolean;
        full_tango: boolean;
    };
}

/**
 * Here it is defined the type of the DocumentPropsTypes Component
 * @typedef VisitsAllTablePropsTypes
 * @type {(string)}
 * @property {string} file_name - is a string.
 * @property {string} document_type - is a string.
 * @property {string} document_name - is a string.
 * @property {string} folio - is a string.
 * @property {string} renter_name - is a string.
 * @property {date} signed_contract_at - is a date.
 * @property {date} lease_start_at - is a date.
 * @property {date} due_at - is a date.
 */

interface DocumentData {
    document_type: string;
    document_name: string;
    file_name: string;
    folio: string;
    renter_name: string;
    signed_contract_at: Date;
    lease_start_at: Date;
    due_at: Date;
}

const InnerForm: React.FunctionComponent<uploadDocumentsProps & FormikProps<DocumentData>> = ({
    values,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    fetchDocument,
    datasContracts,
}) => {
    const size = useWindowSize();
    const [fileToUpload, setFileToUpload] = useState('');
    let mobile = size.width <= 768;
    const [documentNameRequired, setDocumentNameRequired] = useState(false);
    const [contractData, setContractData] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const [dataContract, setDataContract] = useState<any>(datasContracts['data_lease_contract']);
    const [selectedFolio, setSelectedFolio] = useState(dataContract.folio);
    
    useEffect(() => {
        setDocumentNameRequired(false);
        setContractData(false);
        if (['admin_contract', 'lease_contract'].includes(values.document_type)) {
            const valueKey = `data_${values.document_type}` as keyof typeof datasContracts;
            setDataContract(datasContracts[valueKey]);
        }
        if (['admin_contract_annex', 'lease_contract_annex'].includes(values.document_type))
            return setDocumentNameRequired(true);
        if (['admin_contract', 'lease_contract'].includes(values.document_type)) return setContractData(true);
    }, [values.document_type]);
    
    useEffect(() => {
        if (['admin_contract', 'lease_contract'].includes(values.document_type)) {
            let folio = dataContract.unit_version_folios.includes(parseInt(values.folio)) ? values.folio : dataContract.folio
            setSelectedFolio(folio)
        }
    });

    useEffect(() => {
        fetchDocument === 'FETCHED' && ClearForm();
    }, [fetchDocument]);

    /**
     * This is the fuction to get the document uploaded
     */
    const handleUploadFile = (e: any) => {
        setFileToUpload('');
        setFileToUpload((File = e.target.files[0].name));
        let file: File = e.target.files[0];
        setFieldValue('file', file);
    };

    /**
     * This is the fuction to clear the form
     */
    const ClearForm = () => {
        resetForm();
        setFileToUpload('');
        setFieldValue('file', '');
    };

    /**
     * This is the fuction check if has a upload files
     */
    const cannot_upload_data = () => {
        if (!datasContracts.full_tango && ['admin_contract', 'lease_contract'].includes(values.document_type))
            return true;

        if (values.document_type === 'admin_contract' && dataContract.is_active)
            return true;

        return (
            datasContracts.full_tango &&
            datasContracts.rent_active_or_finished &&
            ['admin_contract', 'lease_contract'].includes(values.document_type)
        );
    };

    /**
     * This is the fuction to open the dialog box to find and get the file to be uploaded
     */
    const openFileDialog = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };
    /**
     * This is the fuction to disabled and show the circular in the button to avoid click
     */
    const isFetchingDocument = fetchDocument === 'FETCHING';
    
    const orderFolios = () => {
        if (!dataContract.unit_version_folios) return dataContract.unit_version_folios

        let options = dataContract.unit_version_folios
        
        options.splice(options.indexOf(dataContract.folio), 1);
        options.unshift(dataContract.folio);
        options.map((folio: number) => ({
            value: folio,
            label: folio 
        }));

        return(options)
    };

    let folioOptions;
    if (dataContract.unit_version_folios){
        orderFolios()
        folioOptions = dataContract.unit_version_folios.map((folio: number) => ({
            value: folio,
            label: folio 
        }));
    }



    return (
        <Form className='container-home'>
            <h2 className='new-subtitle'> {intl.get('DOCUMENTS')}</h2>
            <div className='row p-0 d-flex'>
                <div className='col-lg-8 p-0'>
                    <Select
                        options={LEASE_DOCUMENTS_OPTIONS}
                        id='document_type'
                        onChange={handleChange}
                        label={'TYPE'.toUpperCase()}
                        value={values.document_type}
                        disabled={false}
                        classNameInput='col-md-7 p-0'
                        classNameSelect={'select-style'}
                        className='col-12 p-0'
                        classNameLabel='col-md-4 p-0'
                        error={errors.document_type}
                    />
                    {contractData && (
                        <>
                            {cannot_upload_data() && (
                                <div className='row'>
                                    <div className='col-md-4 p-0' />
                                    <div className='col-md-7 p-0 m-0'>
                                        {!datasContracts.full_tango && (
                                            <p className='text-left text-color-error m-0'>
                                                {intl.get('ERROR_NOT_FULL_TANGO')}
                                            </p>
                                        )}
                                        {datasContracts.rent_active_or_finished && (
                                            <p className='text-left text-color-error m-0'>
                                                {intl.get('RENT_ACTIVE_OR_FINISHED')}
                                            </p>
                                        )}
                                        {values.document_type === 'admin_contract' && dataContract.is_active && (
                                            <p className='text-left text-color-error m-0'>
                                                {intl.get('ACTIVE_ADMIN_CONTRACT_EXISTS')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            <Input
                                id='renter_name'
                                label='RENTER'
                                type='text'
                                value={(values.renter_name = dataContract.renter_name)}
                                error={errors.renter_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                classNameInput='col-md-7 p-0'
                                className='col-12 my-4 p-0'
                                classNameLabel='col-md-4 p-0'
                                disabled={true}
                            />
                            <Select
                                options={folioOptions} //dataContract.unit_version_folios
                                id='folio'
                                onChange={(e: any) => setSelectedFolio(e.target.value)}
                                label='FOLIO'
                                disabled={false}
                                value={values.folio = selectedFolio}
                                classNameInput='col-md-7 p-0'
                                classNameSelect={'select-style'}
                                className='col-12 p-0'
                                classNameLabel='col-md-4 p-0'
                                isCustome={true}
                                error={errors.folio}
                            />
                            <DatePicker
                                name='signed_contract_at'
                                disableToolbar={false}
                                value={values.signed_contract_at}
                                label='SIGNED_CONTRACT_AT'
                                disabled={datasContracts.rent_active_or_finished || !datasContracts.full_tango}
                                classNameInput='col-md-7 p-0'
                                className='col-12 my-4 p-0'
                                classNameLabel='col-md-4 p-0'
                            />
                            <DatePicker
                                name='lease_start_at'
                                disableToolbar={false}
                                value={values.lease_start_at}
                                minDate={values.signed_contract_at}
                                label='LEASE_START_AT'
                                disabled={datasContracts.rent_active_or_finished || !datasContracts.full_tango}
                                classNameInput='col-md-7 p-0'
                                className='col-12 my-4 p-0'
                                classNameLabel='col-md-4 p-0'
                            />
                            <DatePicker
                                name='due_at'
                                disableToolbar={false}
                                value={values.due_at}
                                minDate={values.signed_contract_at}
                                label='DUE_AT'
                                disabled={datasContracts.rent_active_or_finished || !datasContracts.full_tango}
                                classNameInput='col-md-7 p-0'
                                className='col-12 my-4 p-0'
                                classNameLabel='col-md-4 p-0'
                            />
                        </>
                    )}
                    <Input
                        id='file_name'
                        label='FILE'
                        type='upload_button_file'
                        value={(values.file_name = fileToUpload)}
                        error={errors.file_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        classNameInput='col-md-7 p-0'
                        className='col-12 my-4 p-0'
                        classNameLabel='col-md-4 p-0'
                        info='INFO_UPLOAD_DOCUMENTS'
                        disabled={cannot_upload_data()}
                        iconPosition={mobile ? 'end' : 'start'}
                        onClick={!cannot_upload_data() ? openFileDialog : undefined}
                    />
                    <input
                        type='file'
                        accept='application/pdf'
                        style={{ display: 'none' }}
                        id='button-file'
                        onChange={handleUploadFile}
                        ref={fileInputRef}
                    />
                    {documentNameRequired && (
                        <Input
                            id='document_name'
                            label='NAME'
                            type='text'
                            value={values.document_name}
                            error={errors.document_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNameInput='col-md-7 p-0'
                            className='col-12 my-4 p-0'
                            classNameLabel='col-md-4 p-0'
                            info='NAME_DOCUMENT_INFO'
                            disabled={false}
                        />
                    )}
                    <div className='row'>
                        <div className='col-md-3 p-0' />
                        <div className='col-md-8 p-0 d-flex justify-content-end'>
                            <button type='button' onClick={() => ClearForm()} className='cancel-lease-document-button'>
                                {intl.get('CANCEL')}
                            </button>
                            <button
                                type='submit'
                                className='save-lease-document-button'
                                disabled={cannot_upload_data()}
                            >
                                {isFetchingDocument ? <ButtonLoading /> : intl.get('SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

const UploadDocumentsForm = withFormik<uploadDocumentsProps, DocumentData>({
    mapPropsToValues: (props: any) => {
        return { ...props };
    },
    validationSchema: FormValidates,

    handleSubmit(values: DocumentData, { props }) {
        if (values.document_type === 'none') {
            toast(intl.get('SELECT_AN_OPTION'));
        }
        if (values.file_name === '') {
            toast(intl.get('SELECT_FILE_TO_UPLOAD'));
        } else {
            props.onSubmit(values);
        }
    },
})(InnerForm);

export default UploadDocumentsForm;
