const initialState = {
  requisitions: [],
}

export default (state = initialState, action: any) => {
  const currentType: string = action?.payload?.type

  const hasType = state.requisitions.find(
    (requisition: any) => requisition.type === currentType,
  )
  switch (action.type) {
    case 'GET_REQUISITIONS_STARTED':
      if (hasType) {
        return {
          ...state,
          requisitions: state.requisitions.map((requisition: any) => {
            if (requisition.type === currentType) {
              return {
                ...requisition,
                requisitions: [],
                fetchingDataRequisitions: 'LOADING',
              }
            }
            return requisition
          }),
        }
      } else {
        return {
          ...state,
          requisitions: [
            ...state.requisitions,
            {
              type: currentType,
              requisitions: [],
              fetchingDataRequisitions: 'LOADING',
            },
          ],
        }
      }

    case 'GET_REQUISITIONS_SUCCESS':
      if (hasType) {
        return {
          ...state,
          requisitions: state.requisitions.map((requisition: any) => {
            if (requisition.type === currentType) {
              return {
                ...requisition,
                requisitions: action.payload.result.data.data,
                fetchingDataRequisitions: 'LOADED',
              }
            }
            return requisition
          }),
        }
      } else {
        return {
          ...state,
          requisitions: [
            ...state.requisitions,
            {
              type: currentType,
              requisitions: action.payload.result.data.data,
              fetchingDataRequisitions: 'LOADED',
            },
          ],
        }
      }
    case 'GET_REQUISITIONS_ERROR':
      if (hasType) {
        return {
          ...state,
          requisitions: state.requisitions.map((requisition: any) => {
            if (requisition.type === currentType) {
              return {
                ...requisition,
                requisitions: [],
                fetchingDataRequisitions: 'ERROR',
              }
            }
            return requisition
          }),
        }
      } else {
        return {
          ...state,
          requisitions: [
            ...state.requisitions,
            {
              type: currentType,
              requisitions: [],
              fetchingDataRequisitions: 'ERROR',
            },
          ],
        }
      }
    default:
      return state
  }
}
