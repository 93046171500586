import { getAdminContractHistoryStart, getAdminContractHistorySuccess, getAdminContractHistoryError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
const getAdminContractHistory = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getAdminContractHistoryStart());
        getData(`admin_contracts/${id}/history`)
            .then((result) => {
                return dispatch(getAdminContractHistorySuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getAdminContractHistoryError(e));
            });
    };
};
export default getAdminContractHistory;
