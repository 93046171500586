import React from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { Input, PopupWithIcon } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import {sendRenterEvaluation } from '../../tango-react-base/reduxActions'

interface MensageViewFormProps {
  disabled: boolean
  onSubmit?: any
  userId: string
  children?: any
  comment?: string
  showPopUp?: boolean
  statePreApproved?: string
  handleClosePopUp: () => void
  onSendPreApprovedStatus: (
    id: string,
    status: string,
    comments: string,
    callback: () => void,
  ) => void
}

interface MensageViewForm {
  comment: string
  error: string
}

const InnerForm: React.FunctionComponent<
  MensageViewFormProps & FormikProps<MensageViewForm>
> = ({
  values,
  errors,
  showPopUp = false,
  statePreApproved = '',
  userId,
  handleClosePopUp,
  handleChange,
  handleBlur,
  onSendPreApprovedStatus,
  disabled,
  resetForm
}) => {
  const closeModal = ()=> {
    handleClosePopUp()
    resetForm()
  }

  return (
    <PopupWithIcon
      title='RENTER_CONFIRM_PRE_APPROVED'
      open={showPopUp}
      handleClose={closeModal}
      lineUp
      contentDialogClass=''
      centeredContent={false}
    >
      <p className='pb-4 pb-md-5'>
        {intl.getHTML('RENTER_CONFIRM_PRE_APPROVED_TEXT', {
          state: intl.get(
            statePreApproved === 'accept' ? 'APPROVED' : 'REJECTED',
          ),
        })}
      </p>
      {statePreApproved === 'reject' && (
        <Form>
          <p>{intl.get('OPTIONAL_COMMENTS')}</p>
          <Input
            id='comment'
            type='text'
            placeholder={intl.get('WRITE_COMMENTS_REJECT_APPROVED')}
            value={values.comment}
            error={errors.error}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            multiline
            className='input-pre-approved'
            classNameInput='col-lg-12 px-0'
            variant
            rows={4}
          />
        </Form>
      )}

      <div className='d-flex justify-content-center'>
        <button
          className='button-five buttons-pre-approved'
          onClick={closeModal}
        >
          {intl.get('CANCEL')}
        </button>
        <button
          className='button-primary buttons-pre-approved'
          onClick={() =>{
            onSendPreApprovedStatus(
              userId,
              statePreApproved,
              values.comment,
              handleClosePopUp,
            )
            resetForm()
          }}
        >
          {intl.get('ACCEPT')}
        </button>
      </div>
    </PopupWithIcon>
  )
}

const PopUpInputReject = withFormik<MensageViewFormProps, MensageViewForm>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      comment: props.comment || '',
      error: '',
    }
    return { ...initialValues, ...props }
  },
  validationSchema: Yup.object().shape({}),

  handleSubmit(values: MensageViewForm, { props, resetForm }) {
    props.onSubmit(values)
  },
})(InnerForm)

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSendPreApprovedStatus: (
      id: string,
      status: string,
      comments: string,
      callback: () => void,
    ) => {
      dispatch(sendRenterEvaluation(id, status, comments))
      callback()
    },
  }
}

export default connect(null, mapDispatchToProps)(PopUpInputReject)
