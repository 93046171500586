import { getRequisitionsStart, getRequisitionsSuccess, getRequisitionsError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRequisitions = (type: string) => {
    return (dispatch: any) => {
        dispatch(getRequisitionsStart(type));
        getData(`requisitions`, { state: type })
            .then((data) => {
                return dispatch(getRequisitionsSuccess(data, type));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRequisitionsError(e, type));
            });
    };
};
export default getRequisitions;
