export const renterDocumentsStart = () => {
  return {
    type: 'RENTER_DOCUMENTS_START'
  }
}
  
export const renterDocumentsSuc = (data: any) => {
  return {
    type: 'RENTER_DOCUMENTS_SUC',
    payload: data
  }
}