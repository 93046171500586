import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import intl from 'react-intl-universal'
import {
  setTemplateChild,
  getContracts
} from '../../tango-react-base/reduxActions'
import { getContractHistory, getAdminContractHistory, getContractDetail, getAdminContractDetail, getAdminContracts } from '../../redux/actions'
import ContractHistoryModal from './ContractHistoryModal'
import ContractDetailModal from './ContractDetailModal'
import {
  CircularLoading,
  TableTango,
} from '../../tango-react-base/components'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { contractHeader } from './tableHeaders'
import {
  leaseRenewalIcon,
  thickHistory,
} from '../../tango-react-base/resources'
import { getParameterByName } from '../../tango-react-base/helpers/getParamsUrl/'
import { Tooltip } from '@material-ui/core'
import ContractStatesExplanation from './contractStatesExplanation'

/**
 * Here the type of each Table is defined, this prop is similar to 'ContractsProps' but 'ContractsTypes' is for the documentation
 *  @typedef  ContractsTypes
 *  @type {(function|string|array)}
 *  @property {function} onSetTemplateChild - is a function.
 *  @property {function} onGetContractHistory - is a function.
 *  @property {funciton} onGetAdminContractHistory - is a function
 *  @property {function} onGetContractDetail - is a function.
 *  @property {string} fetchContractDetail - is a string.
 *  @property {string} fetchContractHistory - is a string.
 *  @property {string} fetchContracts - is a string.
 *  @property {string} fetchAdminContracts - is a string 
 *  @property {string} fetchAdminContractHistory - is a string
 *  @property {array} contracts - is an array.
 *  @property {array} adminContracts - is an array
 *  @property {any} contractHistory - is an object.
 *  @property {any} contractDetail - is a function.
 *  @property {function} onGetContracts - is an action that fetches all contracts.
 *  @property {function} onGetAdminContracts - is an action that fetches all admin_contracts
 */
type ContractsProps = {
  onSetTemplateChild: (child: Object) => void
  onGetContractHistory: (id: number) => void
  onGetContractDetail: (id: number) => void
  onGetAdminContractHistory: (id: number) => void
  onGetAdminContractDetail: (id: number) => void
  fetchContractDetail: string
  fetchContractHistory: string
  onGetContracts: () => void
  onGetAdminContracts: () => void
  fetchContracts: string
  contracts: Array<any>
  fetchAdminContracts: string
  adminContracts: Array<any>
  contractHistory: any
  contractDetail: any
  adminContractDetail: any
  adminContractHistory: any
  fetchAdminContractHistory: any
  fetchAdminContractDetail: any
}

/**
 * Contracts is a functional component with props.
 * The main function of this component is to render a table with all the contracts.
 *  @param {ContractsTypes} onSetTemplateChild - function onSetTemplateChild
 *  @param {ContractsTypes} onGetContracts - function onGetContracts gets all contracts for admin to see
 *  @param {ContractsTypes} onGetAdminContracts - function onGetAdminContracts gets all admin contracts for admin to see
 *  @param {ContractsTypes} onGetContractHistory - function onGetContractsHistory gets the history of contract
 *  @param {ContractsTypes} onGetAdminContractHistory - function onGetAdminContractHistory gets the history of admin contract
 *  @param {ContractsTypes} fetchContractDetail - fetchContractDetail shows the fetching state of the contractDetail
 *  @param {ContractsTypes} fetchContractHistory - fetchContractHistory shows the fetching state of the contractHistory
 *  @param {ContractsTypes} fetchAdminContractHistory - fetchAdminContractHistory shows the fetching state of the adminContractHistory
 *  @param {ContractsTypes} contracts - contracts to be shown in the table
 *  @param {ContractsTypes} adminContracts - adminContracts to be shown in the table
 *  @param {ContractsTypes} contractHistory - the history of each contract
 *  @param {ContractsTypes} contractDetail - the detail of each contract
 *  @param {ContractsTypes} adminContractDetail - the detail of each admin contract
 *  @param {ContractsTypes} adminContractHistory - the history of each admin contract
 *  @returns {(ReactComponent)} Returns a TableMui
 */
const Contracts = ({
  onSetTemplateChild,
  onGetContractHistory,
  onGetContractDetail,
  onGetAdminContractHistory,
  fetchContractDetail,
  fetchContractHistory,
  onGetContracts,
  onGetAdminContracts,
  fetchContracts,
  contracts,
  fetchAdminContracts, 
  adminContracts, 
  contractHistory,
  contractDetail,
  adminContractDetail,
  adminContractHistory,
  fetchAdminContractHistory, 
  onGetAdminContractDetail,
  fetchAdminContractDetail
}: ContractsProps) => {
  const history = useHistory()
  const [openContractHistory, setOpenContractHistory] = useState(false)
  const [openContractDetail, setOpenContractDetail] = useState(false)
  const [contractDataHistory, setContractDataHistory] = useState({})
  const [contractDataDetail, setContractDataDetail] = useState([])
  const contractIdParam: any =
    sessionStorage.getItem('contract_id') ||
    getParameterByName('contract_id', history.location.search)
  useEffect(() => {

    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('CONTRACTS')}</h1>
        </>,
      )
      if(contractIdParam){
        history.push(`/contracts?contract_id=${contractIdParam}`)
      }
      onGetContracts()
      onGetAdminContracts()
    }
  }, [onSetTemplateChild])

  useEffect(() => {
    if (contractIdParam) {
      openContractHistoryModal(contractIdParam)
    }
  }, [])

  useEffect(() => {
    if (fetchContractDetail === 'FETCHED_CONTRACT_DETAIL') {
      setContractDataDetail(contractDetail)
    }
  }, [fetchContractDetail])

  useEffect(() => {
    if (fetchAdminContractDetail === 'FETCHED_ADMIN_CONTRACT_DETAIL') {
      setContractDataDetail(adminContractDetail)
    }
  }, [fetchAdminContractDetail])

  useEffect(() => {
    if (fetchContractHistory === 'FETCHED_CONTRACT_HISTORY') {
      setContractDataHistory(contractHistory)
    }
  }, [fetchContractHistory])

  useEffect(() => {
    if (fetchAdminContractHistory === 'FETCHED_ADMIN_CONTRACT_HISTORY') {
      setContractDataHistory(adminContractHistory)
    }
  }, [fetchAdminContractHistory])

  const openContractHistoryModal = (id: number, contract_type?: string) => {
    if (contract_type === 'Administración'){
      onGetAdminContractHistory(id)
      setOpenContractHistory(true)
    }else{
      onGetContractHistory(id)
      setOpenContractHistory(true)
    }
  }

  const openContractDetailModal = (id: number, contract_type?: string) => {
    if (contract_type === 'Administración'){
      onGetAdminContractDetail(id)
      setOpenContractDetail(true)
    }else {
      onGetContractDetail(id)
      setOpenContractDetail(true)
    }
  }
  const handleCloseContractDetailModal = () => {
    setContractDataDetail([])
    setOpenContractDetail(false)
  }
  const handleCloseHistoryModal = () => {
    setOpenContractHistory(false)
    setContractDataHistory({})
    sessionStorage.removeItem('contract_id')
  }
  const customColumnStyle = {
    minWidth: '25rem',
  }
  const TableBody = () => {
    contracts = [...contracts, ...adminContracts]
    return (
      <>
        {!contracts.length ? (
          <TableRow>
            <TableCell colSpan={6} className='table-content text-center'>
              {intl.get('NO_DATA')}
            </TableCell>
          </TableRow>
        ) : (
          contracts.map((data: any) => {
            const { attributes, id } = data
            const {
              full_address,
              renter_name,
              owner_name,
              contract_type,
              start_lease,
              end_lease,
              state,
              data_ids,
            } = attributes
            const { owner_profile, post, renter_user } = data_ids
            return (
              <TableRow key={`${full_address} - ${id}`}>
                <TableCell style={customColumnStyle} className='table-content'>
                  <Link to={`posts/${post}`}>{full_address}</Link>
                </TableCell>
                <TableCell className='table-content'>
                  <Link to={`owners/${owner_profile}`}>{owner_name}</Link>
                </TableCell>
                <TableCell className='table-content'>
                  <Link to={`renters/${renter_user}`}>{renter_name}</Link>
                </TableCell>
                <TableCell className='table-content'>{contract_type}</TableCell>
                <TableCell className='table-content'>{start_lease}</TableCell>
                <TableCell className='table-content'>{end_lease}</TableCell>
                <TableCell className='table-content'>{state}</TableCell>
                <TableCell
                  component='th'
                  scope='row'
                  className='table-content d-flex justify-content-center'
                >
                  <Tooltip title={intl.get('PROPERTY_CONTRACTS_AND_ACTIONS')}>
                    <a>
                      <img
                        src={leaseRenewalIcon}
                        alt='contract-detail'
                        width='30'
                        onClick={() => openContractDetailModal(id, contract_type)}
                      />
                    </a>
                  </Tooltip>
                  <Tooltip title={intl.get('PROPERTY_HISTORY')}>
                    <a>
                      <img
                        className='ml-3'
                        src={thickHistory}
                        alt='contract-detail'
                        width='45'
                        onClick={() => openContractHistoryModal(id, contract_type)}
                      />
                    </a>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })
        )}
      </>
    )
  }
  const showHistory = fetchContractHistory === 'FETCHED_CONTRACT_HISTORY' || fetchAdminContractHistory === 'FETCHED_ADMIN_CONTRACT_HISTORY' 
  const showDetail = fetchContractDetail === 'FETCHED_CONTRACT_DETAIL' || fetchAdminContractDetail === 'FETCHED_ADMIN_CONTRACT_DETAIL'
  return (
    <div className='contracts-container my-4'>
      {fetchContracts === 'FETCHED' ? (
        <>
          <TableTango listTitle={contractHeader.titles}>
            <TableBody />
          </TableTango>
          <ContractStatesExplanation/>
        </>
      ) : (
        <CircularLoading />
      )}
      {showDetail && (
        <ContractDetailModal
          open={openContractDetail}
          contract={contractDataDetail}
          handleClose={handleCloseContractDetailModal}
        />
      )}

      {showHistory && (
        <ContractHistoryModal
          open={openContractHistory}
          contract={contractDataHistory}
          handleClose={handleCloseHistoryModal}
        />
      )}
    </div>
  )
}
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is an Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */

const mapStateToProps = (state: any) => {
  const {
    contractDetail,
    fetchContractDetail,
    contractHistory,
    fetchContractHistory,
  } = state.contract
  const {
    adminContractDetail,
    fetchAdminContractDetail,
    adminContractHistory,
    fetchAdminContractHistory,
  } = state.adminContract
  const { contracts, fetchContracts } = state.contracts
  const { adminContracts, fetchAdminContracts } = state.adminContracts
  return {
    contractDetail,
    fetchContractDetail,
    contractHistory,
    fetchContractHistory,
    contracts,
    fetchContracts,
    adminContracts,
    fetchAdminContracts,
    adminContractDetail,
    adminContractHistory,
    fetchAdminContractHistory,
    fetchAdminContractDetail
  }
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetAdminContractHistory: (id: number) => {
      return dispatch(getAdminContractHistory(id))
    },
    onGetContractHistory: (id: number) => {
      return dispatch(getContractHistory(id))
    },
    onGetContractDetail: (id: number) => {
      return dispatch(getContractDetail(id))
    },
    onGetAdminContractDetail: (id: number) => {
      return dispatch(getAdminContractDetail(id))
    },
    onGetContracts: () => {
      return dispatch(getContracts())
    },
    onGetAdminContracts: () => {
      return dispatch(getAdminContracts())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)
