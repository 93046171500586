import React from 'react';
import { RadioButtonTangoLink } from '../../tango-react-base/components';

/** Here it is defined the type of the props for the interface "RequisitonLinkProps"
 * @typedef RequisitionsLinkProps
 * @type {(function|object|string)}
 * @property {number} requisitionId - id for requisition.
 * @property {number} leasePeriodId - id for lease period.
 */
interface RequisitionsLinkProps {
  requisitionId?: number;
  leasePeriodId?: number;
}
/**
 * ButtonsActions is a function that shows the property details
 * @function
 * @param ClosureLease
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */

/** this returns part of the form with the button to go to the requisition lists */
const RequisitionsLink = ({ requisitionId, leasePeriodId }: RequisitionsLinkProps) => {
  const option = [{ label: !!requisitionId ? 'SHOW_DETAILS' : 'GENERATE' }];
  return (
    <>
      {!!requisitionId ? (
        <RadioButtonTangoLink
          disabled={false}
          id={'requisition'}
          label='ANTICIPATED_CONTRACT_TERM'
          options={option}
          className='d-flex justify-content-left pt-0'
          onClick={() => {
            window.location.href = window.location.origin + `/requisitions/${requisitionId}`;
          }}
        />
      ) : (
        <RadioButtonTangoLink
          disabled={false}
          id={'lease-period'}
          label='ANTICIPATED_CONTRACT_TERM'
          options={option}
          className='d-flex justify-content-left pt-0'
          onClick={() => {
            window.location.href = window.location.origin + `/lease_period/${leasePeriodId}/closure_lease`;
          }}
        />
      )}
    </>
  );
};
export default RequisitionsLink;
