import { getRenterPreApprovedStart, getRenterPreApprovedSuccess, getRenterPreApprovedError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRenterPreApproved = () => {
    return (dispatch: any) => {
        dispatch(getRenterPreApprovedStart());
        getData(`credit_evaluation`, { aasm_state: 'failed' })
            .then((result: any) => {
                dispatch(getRenterPreApprovedSuccess(result.data));
            })
            .catch((e) => {
                dispatch(getRenterPreApprovedError());
                toast(handleError(e));
            });
    };
};
export default getRenterPreApproved;
