import React from 'react'
import { DialogModal, IconButton } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { connect } from 'react-redux';
import { getDocumentFile } from '../../redux/actions'
import {
  inventoryIcon,
  mediationIcon,
  contractIcon,
} from '../../tango-react-base/resources'

/** Here it is defined the type of the props for the interface "ContractDetailModalProps"
 * @typedef ContractDetailModalProps
 * @type {(function|number|boolean)}
 * @property {number} contract - is a number.
 * @property {boolean} open - is a boolean.
 * @property {function} handleClose - is a function.

 */
interface ContractDetailModalProps {
  contract: any;
  open: boolean;
  handleClose?: () => void;
  onGetDocumentFile: (docId: number, route: string) => void;
}
/**
 * GoToContractModal is modal with the owner data contract
 *@function
 *@param {ContractDetailModalTypes} open- boolean to open the modal
 *@param {ContractDetailModalTypes} contract - variable with the contract id
 */
const ContractDetailModal = ({
  contract,
  open,
  handleClose,
  onGetDocumentFile
}: ContractDetailModalProps) => {
  const apiAddress = process.env.REACT_APP_API_URL_BASE;
  const contractData = contract?.map((item: any) => {
    Object.values(item).forEach((data: any) => {
      let imgIcon
      let type = data.type

      switch (type) {
        case 'contract':
          imgIcon = contractIcon
          break
        case 'mediation':
          imgIcon = mediationIcon
          break
        case 'inventory':
          imgIcon = inventoryIcon
          break
        case 'restitution':
          imgIcon = inventoryIcon
          break
        default:
          imgIcon = contractIcon
          break
      }
      data.img = imgIcon
    })
    return Object.values(item)[0]
  })

  const getDocument = (item: any) => {
    if(item.url.includes('lease_contracts')) {
      onGetDocumentFile(item.id, `${process.env.REACT_APP_API_URL_BASE}/document/lease_contracts/${item.id}`)
    } else if (item.url.includes('admin_contracts')) {
      onGetDocumentFile(item.id, `${process.env.REACT_APP_API_URL_BASE}/document/admin_contracts/${item.id}`)
    }  else {
        window.open(item.url, '_blank');
    }
  }

  const preparedData = contractData?.map(
    (item: any) => 
      <>
        <span className='contract-details-modal-link' onClick={() => {getDocument(item)}} >
          <img src={item.img} alt='dowloadLogo' />
          <span>{item.title} {!item.url && `- ${intl.get('PENDING_UPLOAD')}`}</span>
        </span>
        <br/>
      </>
  )

  return (
    <DialogModal title={intl.get('PROPERTY_CONTRACTS_AND_ACTIONS')} open={open}>
      <IconButton
        type='close'
        icon='fa-times'
        classColor='btn-close-dm'
        onClick={handleClose}
      />
      <div className='p-0 p-md-4 container-item-list text link-general-app'>
        {preparedData}
      </div>
    </DialogModal>
  )
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetDocumentFile: (docId: number, route: string) => {
      dispatch(getDocumentFile(docId, route));
    }
  };
};

export default connect(null, mapDispatchToProps)(ContractDetailModal);