export const disableDocumentStart = () => {
  return {
    type: 'DISABLE_DOCUMENT_STARTED',
  }
}

export const disableDocumentSuccess = () => {
  return {
    type: 'DISABLE_DOCUMENT_SUCCESS',
  }
}

export const disableDocumentError = (error: any) => {
  return {
    type: 'DISABLE_DOCUMENT_ERROR',
    payload: error,
  }
}