export const markAsPaidChargesStart = () => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_STARTED',
    };
};

export const markAsPaidChargesSuccess = (result: any) => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_SUCCESS',
        payload: { result },
    };
};
export const markAsPaidChargesError = (error: any) => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_ERROR',
        payload: {
            error,
        },
    };
};

export const markAsPaidChargesAttemptStart = () => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_STARTED',
    };
};

export const markAsPaidChargesAttemptSuccess = (result: any) => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_SUCCESS',
        payload: { result },
    };
};
export const markAsPaidChargesAttemptError = (error: any) => {
    return {
        type: 'MARK_AS_PAID_OWNER_FINE_CHARGES_ERROR',
        payload: {
            error,
        },
    };
};
