import {
  createAdvertImagesStart,
  createAdvertImageStart,
  createAdvertImageSuccess,
  createAdvertImageError,
  createAdvertImagesSuccess,
} from './types'
import intl from 'react-intl-universal'
import { postData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'

interface AdvertImages {
  files: Array<any>
  filesNames: Array<string>
  kinds: Array<string>
  advertId: number
  advertGalleryId: number
}

const createAdvertImage = (data: AdvertImages) => {
  return (dispatch: any, getState: any) => {
    dispatch(createAdvertImagesStart())
    const { files, filesNames, kinds, advertId, advertGalleryId } = data
    const length = kinds.length
    kinds.forEach((item, index) => {
      if (files[index] === 'LOADED') {
        if (index === length - 1) {
          dispatch(createAdvertImagesSuccess())
        }
      } else {
        dispatch(createAdvertImageStart())
        let formData: any = new FormData()
        let fileToUpload: File = files[index]
        formData.append('advert_image[name]', filesNames[index])
        formData.append('advert_image[kind]', item)
        formData.append('advert_image[advert_id]', advertId)
        formData.append('advert_image[advert_gallery_id]', advertGalleryId)
        formData.append('advert_image[image]', fileToUpload)
        postData('advert_images', formData)
          .then((result) => {
            toast(intl.get('CREATED_ADVERT_IMAGE'))
            if (index === length - 1) {
              dispatch(createAdvertImagesSuccess())
            }
            return dispatch(createAdvertImageSuccess(result))
          })
          .catch((e) => {
            toast(handleError(e))
            return dispatch(createAdvertImageError(e))
          })
      }
    })
  }
}
export default createAdvertImage
