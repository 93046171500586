import axios from 'axios';

export default async (url: string, params?: any, fullRoute?: boolean) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}`;
    const role = `${process.env.REACT_APP_API_ROLE_APP}`;
    const roleLowerCase = role.toLowerCase();
    const urlsWithoutApp = ['auth', 'user'];
    const baseUrl = url.split('/')[0];
    const useUrl = fullRoute ? `${roleLowerCase}/${url}` : url;

    const uri = urlsWithoutApp.includes(baseUrl) ? apiUrl.replace(roleLowerCase, useUrl) : `${apiUrl}/${url}`;

    let headers: any = {
        authorization: sessionStorage.getItem('authorization'),
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
    };

    return axios.get(url, { params: params, headers });
};
