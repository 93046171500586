import { requestUserListStart, requestUserListSuccess, requestUserListError } from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import intl from 'react-intl-universal'
const requestUserList = () => {
  return (dispatch: any) => {
    dispatch(requestUserListStart())
    getData('users/excel')
      .then((result) => {
        toast(intl.get('USERLIST_REQUEST_SENT'))
        return dispatch(requestUserListSuccess(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(requestUserListError(e))
      })
  }
}

export default requestUserList