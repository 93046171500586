const initialState: any = {
  datasContracts: [],
  fetchDatasContracts: 'NO_FETCH',
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_DATAS_CONTRACTS_STARTED':
      return {
        ...state,
        fetchDatasContracts: 'FETCHING',
      }
    case 'GET_DATAS_CONTRACTS_SUCCESS':
      return {
        ...state,
        datasContracts: action.payload.result.data.data.attributes,
        fetchDatasContracts: 'FETCHED',
      }
    case 'GET_DATAS_CONTRACTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchDatasContracts: 'ERROR',
      }
    default:
      return state
  }
}
