import { combineReducers } from 'redux'
import { AdminReduxStates } from '../../tango'
import advertisementGallery from './advertisementGalleryReducer'
import advertisementImageReducer from './advertisementImageReducer'
import advertisementReducer from './advertisementReducer'
import annexReducer from './annexReducer'
import assingPromotionReducer from './assingPromotionReducer'
import bankResourceReducer from './bankResourceReducer'
import calendarReducer from './calendarReducer'
import citiesReducer from './citiesReducer'
import CLFResourceReducer from './CLFResourceReducer'
import closureLeaseConfirmationReducer from './closureLeaseConfirmationReducer'
import closureLeaseDataReducer from './closureLeaseDataReducer'
import communesReducer from './communesReducer'
import confirmEarlyRequisitionReducer from './confirmEarlyRequisitionReducer'
import consigneeReducer from './consigneeReducer'
import contractsReducer from './contractsReducer'
import countriesCodesReducer from './countriesCodesReducer'
import countriesReducer from './countriesReducer'
import currentRenewalPeriodReducer from './currentRenewalPeriodReducer'
import datasContractsReducer from './datasContractsReducer'
import generateNewTokenReducer from './generateNewTokenReducer'
import getAdminContractReducer from './getAdminContractReducer'
import getChargeLinesReducer from './getChargeLinesReducer'
import getContractReducer from './getContractReducer'
import getOwnerPaymentsReducer from './getOwnerPaymentsReducer'
import getPaidOrDueReducer from './getPaidOrDueReducer'
import getPaymentsProvidersReducer from './getPaymentsProvidersReducer'
import getPendingVisitsReducer from './getPendingVisitsReducer'
import propertyDocumentsReducer from './getPropertyDocumentsReducer'
import getRenterPreAprrovedReducer from './getRenterPreAprrovedReducer'
import getRequisitionReducer from './getRequisitionReducer'
import GetRequisitionsReducer from './GetRequisitionsReducer'
import ipLocationReducer from './ipLocationReducer'
import leaseDocumentsReducer from './leaseDocumentsReducer'
import markAsPaidChargesReducer from './markAsPaidChargesReducer'
import navBarReducer from './navBarReducer'
import notificationReducer from './notificationReducer'
import ownerProfileReducer from './ownerProfileReducer'
import ownerPropertyReducer from './ownerPropertyReducer'
import pendingVouchersReducer from './pendingVouchersReducer'
import postsAdminReducer from './postAdminReducer'
import postsReducer from './postsReducer'
import promotionsReducer from './promotionsReducer'
import proposeReducer from './proposePriceReducer'
import publishReducer from './publishReducer'
import rejectEarlyRequisitionReducer from './rejectEarlyRequisitionReducer'
import rejectLeaseRenewalReducer from './rejectLeaseRenewalReducer'
import rentersReducer from './rentersReducer'
import salesNotesReducer from './salesNotesReducer'
import sendRenterEvaluationReducer from './sendPreApprovedReducer'
import sessionReducer from './sessionReducer'
import templateChildReducer from './templateChildReducer'
import unitVersionReducer from './unitVersionReducer'
import updateChargeLinesReducer from './updateChargeLinesReducer'
import uploadDocumentsReducer from './uploadDocumentsReducer'
import userReducer from './userReducer'
import usersReducers from './usersReducer'
import userValidationReducer from './userValidarionReducer'
import visitReducer from './visitReducer'
import adminContractsReducer from './adminContractsReducer'

export default combineReducers<AdminReduxStates>({
  posts: postsReducer,
  promotions: promotionsReducer,
  postsAdmin: postsAdminReducer,
  publish: publishReducer,
  userValidation: userValidationReducer,
  consignee: consigneeReducer,
  renters: rentersReducer,
  session: sessionReducer,
  user: userReducer,
  navBar: navBarReducer,
  ipLocation: ipLocationReducer,
  notification: notificationReducer,
  templateChild: templateChildReducer,
  contries: countriesReducer,
  contract: getContractReducer,
  adminContract: getAdminContractReducer,
  annexReducer: annexReducer,
  salesNotes: salesNotesReducer,
  paidOrDueReducer: getPaidOrDueReducer,
  userProperty: ownerPropertyReducer,
  countriesCodes: countriesCodesReducer,
  generateNewToken: generateNewTokenReducer,
  usersSearch: usersReducers,
  communes: communesReducer,
  ownerProfileReducer: ownerProfileReducer,
  bankResource: bankResourceReducer,
  visit: visitReducer,
  visitCalendar: calendarReducer,
  cities: citiesReducer,
  contracts: contractsReducer,
  proposePrice: proposeReducer,
  rejectRenewal: rejectLeaseRenewalReducer,
  currentRenewalPeriod: currentRenewalPeriodReducer,
  unitVersion: unitVersionReducer,
  uploadLeaseDocument: uploadDocumentsReducer,
  leaseDocuments: leaseDocumentsReducer,
  propertyDocuments: propertyDocumentsReducer,
  pendingVouchers: pendingVouchersReducer,
  CLFResource: CLFResourceReducer,
  renterPreApproved: getRenterPreAprrovedReducer,
  sendRenterEvaluation: sendRenterEvaluationReducer,
  pendingVisits: getPendingVisitsReducer,
  assignPromotion: assingPromotionReducer,
  advertGallery: advertisementGallery,
  advert: advertisementReducer,
  advertImage: advertisementImageReducer,
  requisitions: GetRequisitionsReducer,
  chargeLines: getChargeLinesReducer,
  ownerPayments: getOwnerPaymentsReducer,
  updateChargeLines: updateChargeLinesReducer,
  datasContracts: datasContractsReducer,
  paymentsProviders: getPaymentsProvidersReducer,
  markAsPaid: markAsPaidChargesReducer,
  requisition: getRequisitionReducer,
  rejectEarlyRequisition: rejectEarlyRequisitionReducer,
  confirmEarlyRequisition: confirmEarlyRequisitionReducer,
  closureLeaseData: closureLeaseDataReducer,
  closureLeaseConfirmation: closureLeaseConfirmationReducer,
  adminContracts: adminContractsReducer
})
