import { assignPromotionError, assignPromotionStart, assignPromotionSucess } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';
const assignPromotion = (unit_version_id: number, promotion_id: number) => {
    return (dispatch: any) => {
        dispatch(assignPromotionStart());
        putData(`unit_versions/${unit_version_id}/set_promotion`, { promotion: promotion_id })
            .then((result) => {
                toast(intl.get('DATA_SAVED'));
                dispatch(assignPromotionSucess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(assignPromotionError(e));
            });
    };
};
export default assignPromotion;
