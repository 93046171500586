import {
  getPendingVisitsStart,
  getPendingVisitsAgentSuccess,
  getPendingVisitsVerifySuccess,
  getPendingVisitsError,
} from './types'
import { getDataGeneral } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'

const getPendingVisits = (role: string, status: string) => {
  return (dispatch: any) => {
    dispatch(getPendingVisitsStart())
    getDataGeneral(`admin/unit_visits/filter_index?state=${status}&visit_type=${role}`)
      .then((result) => {
        if (role === 'verify') {
          dispatch(getPendingVisitsVerifySuccess(result.data.data))
        } else {
          dispatch(getPendingVisitsAgentSuccess(result.data.data))
        }
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPendingVisitsError(e))
      })
  }
}
export default getPendingVisits
