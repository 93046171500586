export const getPostsToRepublishStart = () => {
    return {
        type: 'GET_POSTS_TO_REPUBLISH_STARTED',
    };
};

export const getPostsToRepublishSuccess = (posts: any) => {
    return {
        type: 'GET_POSTS_TO_REPUBLISH_SUCCESS',
        payload: {
            posts,
        },
    };
};
export const getPostsToRepublishError = (error: any) => {
    return {
        type: 'GET_POSTS_TO_REPUBLISH_ERROR',
        payload: {
            error,
        },
    };
};
