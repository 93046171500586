const initialState: any = {
  propertyDocuments: [],
  fetchPropertyDocuments: 'NO_FETCH',
  rejectedDocuments: [],
  fetchRejectedDocuments: 'NO_FETCH',
  approvedDocuments: [],
  fetchApprovedDocuments: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PROPERTY_DOCUMENTS_STARTED':
      return {
        ...state,
        fetchPropertyDocuments: 'FETCHING',
      }
    case 'GET_PROPERTY_DOCUMENTS_SUCCESS':
      const sortObject = action.payload.result.data.data.sort(
        (a: any, b: any) => a.id - b.id,
      )
      return {
        ...state,
        propertyDocuments: sortObject,
        fetchPropertyDocuments: 'FETCHED',
      }
    case 'GET_PROPERTY_DOCUMENTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchPropertyDocuments: 'ERROR',
      }

    case 'REJECT_PROPERTY_DOCUMENT_STARTED':
      return {
        ...state,
        fetchRejectedDocuments: 'FETCHING',
      }
    case 'REJECT_PROPERTY_DOCUMENT_SUCCESS':
      return {
        ...state,
        rejectedDocuments: action.payload.result.data.data,
        fetchRejectedDocuments: 'FETCHED',
      }
    case 'REJECT_PROPERTY_DOCUMENT_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchRejectedDocuments: 'ERROR',
      }
    case 'APPROVE_PROPERTY_DOCUMENT_STARTED':
      return {
        ...state,
        fetchApprovedDocuments: 'FETCHING',
      }
    case 'APPROVE_PROPERTY_DOCUMENT_SUCCESS':
      return {
        ...state,
        approvedDocuments: action.payload.document.data,
        fetchApprovedDocuments: 'FETCHED',
      }
    case 'APPROVE_PROPERTY_DOCUMENT_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchApprovedDocuments: 'ERROR',
      }

    default:
      return state
  }
}
