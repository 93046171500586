import {
  publishAdvertGalleryStart,
  publishAdvertGallerySuccess,
  publishAdvertGalleryError,
} from './types'
import intl from 'react-intl-universal'
import { postData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const publishAdvertGallery = (advertGalleryId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(publishAdvertGalleryStart())
    postData(`advert_galleries/${advertGalleryId}/publish`)
      .then((result) => {
        toast(intl.get('PUBLISHED_ADVERT_GALLERY'))
        return dispatch(publishAdvertGallerySuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(publishAdvertGalleryError(e))
      })
  }
}
export default publishAdvertGallery
