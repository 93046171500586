const initialState: any = {
    contracts: [],
    fetchContracts: 'NO_FETCH',
    contractsError: ''
  }
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case 'GET_CONTRACTS_STARTED':
        return {
          ...state,
          fetchContracts: 'FETCHING'
        }
      case 'GET_CONTRACTS_SUCCESS':
        return {
          ...state,
          contracts: action.payload.result.data.data,
          fetchContracts: 'FETCHED'
        }
      case 'GET_CONTRACTS_ERROR':
        return {
          ...state,
          contractsError: action.payload.error,
          fetchContracts: 'ERROR'
        }
      default:
        return state
    }
  }
  