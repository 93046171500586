import React, { Fragment, useEffect } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PostCardAdmin } from '../../components';
import { Post } from '../../tango';
import { ButtonsHeader, CircularLoading } from '../../tango-react-base/components';
import { getPosts, setTemplateChild } from '../../tango-react-base/reduxActions';
import { redirect_tabs } from './buttonOptions';

interface PostRentedProps {
  posts: Array<Post>;
  fetchingPosts: string;
  onSetTemplateChild: (child: Object) => void;
  onGetPostRented: (filter: any, states: any) => void;
}

const PostRented = ({ posts, fetchingPosts, onSetTemplateChild, onGetPostRented }: PostRentedProps) => {
  const history = useHistory();
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader imAdmin={true} buttonShowsArray={redirect_tabs} buttonSelected={'rented'} />
        </>,
      );
      onGetPostRented(null, {
        unit_state: 'rented',
        register_type: 'new,brokerage',
      });
    }
  }, [onGetPostRented, onSetTemplateChild]);

  const renderButton = (id: number, nameButton = 'DETAILS') => {
    return (
      <button
        className='button-fourth'
        onClick={() => {
          history.push(`posts/${id}`);
        }}
      >
        {intl.get(nameButton)}
      </button>
    );
  };

  const renderPostsRented = () => {
    return posts.map(
      (post: any, index: number) =>
        post.attributes.aasm_state === 'unpublished' && (
          <Fragment key={`post-${index}`}>
            <PostCardAdmin {...post.attributes}>{renderButton(post.id)}</PostCardAdmin>
          </Fragment>
        ),
    );
  };

  const renderContent = () => {
    if (fetchingPosts === 'LOADED') {
      return (
        <>
          <div className='template-line-title' />
          <h2 className='text my-2'>{intl.get('RENTED')}</h2>
          {posts.length > 0 ? <div className='row'>{renderPostsRented()}</div> : <p>{intl.get('NO_POSTS')}</p>}
        </>
      );
    } else {
      return (
        <>
          {fetchingPosts === 'LOADING' && <p>{<CircularLoading />}</p>}
          {fetchingPosts === 'ERROR' && <p>{intl.get('ERROR')}</p>}
        </>
      );
    }
  };
  return <div className='container-home'>{renderContent()}</div>;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onGetPostRented: (filter: any, states: any) => {
      dispatch(getPosts(filter, states));
    },
  };
};
const mapStateToProps = (state: any) => {
  const { posts, fetchingPosts } = state.posts;
  return {
    posts,
    fetchingPosts,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostRented);
