const initialState: any = {
  notification_action: {},
  notification_action_list: [],
  notifications: [],
  fetch: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_STARTED':
      return {
        ...state,
        fetch: 'FETCHING',
      }
    case 'GET_NOTIFICATION_SUCCESS':
      return {
        ...state,
        notifications: action.payload.data,
        fetch: 'FETCHED',
      }
    case 'NOTIFICATION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetch: 'FETCHED_ERROR',
      }
    case 'SET_NOTIFICATION_STARTED':
      return {
        ...state,
        fetch: 'FETCHING_DETAIL',
      }
    case 'SET_NOTIFICATION_SUCCESS':
      return {
        ...state,
        notification_action_list: state.notification_action_list.concat(
          action.payload.data,
        ),
        notification_action: action.payload.data,
        fetch: 'FETCHED_DETAIL',
      }
    default:
      return state
  }
}
