import { PostsState } from '../../../tango'

const initialState: PostsState = {
  posts: [],
  fetchingPosts: 'NO_POSTS',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_POSTS_STARTED':
      return {
        ...state,
        fetchingPosts: 'LOADING',
      }
    case 'GET_POSTS_SUCCESS':
      return {
        ...state,
        posts: action.payload.posts.data,
        fetchingPosts: 'LOADED',
      }
    case 'GET_POSTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingPosts: 'ERROR',
      }
    default:
      return state
  }
}
