import { Typography } from '@material-ui/core';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { unpublishPost } from '../../redux/actions/index';
import { IconButton } from '../../tango-react-base/components';
import DialogModal from '../../tango-react-base/components/dialogModal';
import { danger } from '../../tango-react-base/resources/index';

/** Here the types of the props for the interface 'UnpublishPostModalProps' are defined
 * @typedef UnpublishPostModalTypes
 * @type {(function|boolean|string|number)}
 * @property {number} postId - is a number.
 * @property {string} address - is a string.
 * @property {string} docState - is a string.
 * @property {boolean} open - is a boolean.
 * @property {function} handleClose - is a function.
 * @property {function} setShowModal - is a function.
 * @property {function} onUnpublishPost - is a function.
 */

interface UnpublishPostModalProps {
  postId: number;
  address: string;
  open: boolean;
  handleClose?: () => void;
  setShowModal: (state: boolean) => void;
  onUnpublishPost: (postId: number, searchPath: string) => void;
  docState: string;
}
/**
 * UnpublishPostModal is a functional component that renders a confirmation modal
 *@function
 *@param {UnpublishPostModalTypes} postId - id of the post, used for endpoint connection
 *@param {UnpublishPostModalTypes} address - address of the post
 *@param {UnpublishPostModalTypes} docState - state of the unit documents
 *@param {UnpublishPostModalTypes} open - state that validates the rendering of the modal
 *@param {UnpublishPostModalTypes} handleClose - function that closes the modal
 *@param {UnpublishPostModalTypes} setShowModal - function that updates the showModal state
 *@param {UnpublishPostModalTypes} onUnpublishPost - function that connects to an endpoint and change the published state of a post
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const UnpublishPostModal = ({
  postId,
  address,
  open,
  handleClose,
  setShowModal,
  onUnpublishPost,
  docState,
}: UnpublishPostModalProps) => {
  return (
    <DialogModal title={intl.get('UNPUBLISH')} open={open} handleClose={handleClose}>
      <IconButton
        type='close'
        icon='fa-times'
        classColor='btn-close-dm'
        onClick={() => {
          setShowModal(false);
        }}
      />
      <img src={danger} alt='danger-icon' />
      <Typography gutterBottom>
        {intl.getHTML('ABOUT_TO_UNPUBLISH', { address: address })}
        <div className='row'>
          <div className='col-sm-6'>
            <button
              type='button'
              className='button-tertiary mt-4 px-5'
              onClick={() => {
                setShowModal(false);
              }}
            >
              {intl.get('CANCEL')}
            </button>
          </div>
          <div className='col-sm-6'>
            <button
              type='button'
              className='button-primary mt-4 px-5'
              onClick={() => {
                setShowModal(false);
                onUnpublishPost(postId, docState);
              }}
            >
              {intl.get('CONFIRM')}
            </button>
          </div>
        </div>
      </Typography>
    </DialogModal>
  );
};
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatches an a action to change the state of a post.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onUnpublishPost: (postId: number, docState: string) => {
      return dispatch(unpublishPost(postId, docState));
    },
  };
};

export default connect(null, mapDispatchToProps)(UnpublishPostModal);
