const initialState = {
    rentersPreApproved: [],
    fetchingDataPreApproved: 'NO_RENTER',
  }
  
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case 'GET_RENTER_PRE_APPROVED_START':
        return {
          ...state,
          fetchingDataPreApproved: 'LOADING',
        }
      case 'GET_RENTER_PRE_APPROVED_SUCCESS':
        return {
          ...state,
          fetchingDataPreApproved: 'LOADED',
          rentersPreApproved: action.payload.data,
        }
      case 'GET_RENTER_PRE_APPROVED_ERROR':
        return {
          ...state,
          fetchingDataPreApproved: 'ERROR_LOADED',
        }
      default:
        return state
    }
  }
