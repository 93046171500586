export const approveAnnexStart = () => {
    return {
        type: 'APPROVE_ANNEX_STARTED',
    };
};

export const approveAnnexSuccess = () => {
    return {
        type: 'APPROVE_ANNEX_SUCCESS',
    };
};

export const approveAnnexError = (error: any) => {
    return {
        type: 'APPROVE_ANNEX_ERROR',
        payload: error,
    };
};
