import React, { useEffect } from 'react'
import { SectionProps } from './SectionInterfaces'
import {
  RadioButtonTango,
  SwitchString,
  CheckBoxTango,
  Input,
  Select,
} from '../..'
import intl from 'react-intl-universal'
import {
  isRoleAdmin,
  isRolePhotographer,
} from '../../../helpers/roleComparison/index'
import {
  COMMON_SPACES_OPTIONS,
  BOOLEAN_OPTIONS,
  JANITOR_OPTIONS,
} from '../../../../tango-react-base/helpers/selectOptions/options'
import {
  verifierOrAdminPermissions,
  propertyStatusPermissions,
} from './SectionHelpers'

const CommonSpaces = ({
  values,
  errors,
  handleChange,
  handleBlur,
  disabled,
  isVerifier,
  setFullFieldsCommonSpaces,
  toRepublish = false,
  countriesCodes,
  touched,
  fetchingCodes,
}: SectionProps) => {
  const currentYear = new Date().getFullYear()
  const fields: any =
    isRolePhotographer || toRepublish
      ? [
          values.janitor,
          values.construction_year || undefined,
          values.janitor !== 'none'
            ? values.janitor_phone_number || undefined
            : '',
        ]
      : []

  useEffect(() => {
    setFullFieldsCommonSpaces &&
      setFullFieldsCommonSpaces(!fields.includes(undefined))
  }, [fields])

  return (
    <>
      {(verifierOrAdminPermissions() ||
        propertyStatusPermissions(values.aasm_state)) && (
        <>
          {!toRepublish && (
            <>
              <div className='template-line mt-4' />
              <h2 className='new-subtitle'>{intl.get('OTHERS')}</h2>
            </>
          )}
          <CheckBoxTango
            disabled={disabled}
            label='COMMON_SPACES'
            name='common_spaces'
            options={COMMON_SPACES_OPTIONS}
          />
          <RadioButtonTango
            disabled={disabled}
            id='janitor'
            label='JANITOR'
            onChange={handleChange}
            options={JANITOR_OPTIONS}
            valueChecked={values.janitor}
            isVerifier={isVerifier}
            error={errors.janitor}
            isRequiredVerifier={!values.janitor}
          />
          {(isVerifier || isRoleAdmin) &&
            values.janitor !== 'none' &&
            values.janitor !== undefined && (
              <div className={'col-12 plm-0'}>
                <div className='row col-12 p-0'>
                  <div className='col-lg-4 col-md-12 p-0 text-left'>
                    <label className='text-color-secondary'>
                      {intl.get('JANITOR_PHONE_NUMBER').toUpperCase()}
                    </label>
                  </div>

                  <div className='col-lg-1 col-md-4 p-0 pr-2'>
                    {fetchingCodes === 'FETCHED_CODES' ? (
                      <Select
                        options={countriesCodes}
                        id='country_code'
                        onChange={handleChange}
                        value={values.country_code}
                        disabled={disabled}
                        classNameInput='col-md-12 col-12 plm-0 pr-0'
                        classNameSelect={'col-12 select-no-border'}
                        resource={true}
                        className='m-0 pr-0'
                      />
                    ) : (
                      <select className='col-12 select-no-border'>
                        <option label='---'></option>
                      </select>
                    )}
                    <div className='template-line-input' />
                  </div>
                  <div className='col-lg-2 col-md-8 p-0'>
                    <Input
                      isVerifier={isVerifier}
                      isRequiredVerifier={!values.janitor_phone_number}
                      required={values.janitor !== 'none'}
                      disabled={disabled}
                      error={errors.janitor_phone_number}
                      id='janitor_phone_number'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      requiredMessage={intl.get('REQUIRED_FIELD')}
                      touched={touched.janitor_phone_number}
                      type='phone_number'
                      value={
                        values.aasm_state !== 'created' &&
                        values.janitor_phone_number?.length > 3
                          ? values.janitor_phone_number.substr(
                              3,
                              values.janitor_phone_number.length,
                            )
                          : values.janitor_phone_number
                      }
                      className='mt-1 '
                      classNameInput='col-12 pl-0'
                    />
                  </div>
                </div>
              </div>
            )}
          <SwitchString
            disabled={disabled}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            label='CLOSED_CIRCUIT'
            name='close_circuit'
            options={BOOLEAN_OPTIONS}
            value={values.close_circuit}
          />
          {values.unit_type === 'apartment' && (
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='ELEVATOR'
              name='elevator'
              options={BOOLEAN_OPTIONS}
              value={values.elevator}
            />
          )}
          <Input
            disabled={disabled}
            error={errors.construction_year}
            id='construction_year'
            label='CONSTRUCTION_YEAR'
            onBlur={handleBlur}
            onChange={handleChange}
            requiredMessage={intl.get('REQUIRED_FIELD')}
            type='number'
            min='1900'
            max={currentYear.toString()}
            value={values.construction_year}
            isVerifier={isVerifier}
            isRequiredVerifier={!values.construction_year}
          />
          {values.unit_type === 'apartment' && (
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='UNIVERSAL_ACCESS'
              name='universal_access'
              options={BOOLEAN_OPTIONS}
              value={values.universal_access}
            />
          )}
        </>
      )}
    </>
  )
}

export default CommonSpaces
