import { markAsPaidChargesStart, markAsPaidChargesSuccess, markAsPaidChargesError } from './types';
import { putData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
import intl from 'react-intl-universal';
const markAsPaidCharges = (id: number, payment_provider: string, payment_method: string) => {
    return (dispatch: any) => {
        dispatch(markAsPaidChargesStart());
        putData(`fines/${id}/pay_owner_amount`, {
            owner_provider: payment_provider,
            owner_payment_method: payment_method,
        })
            .then((result) => {
                dispatch(markAsPaidChargesSuccess(result.data));
                toast(intl.get('SUCCESS_MARK_AS_PAID'));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(markAsPaidChargesError(e));
            });
    };
};

export default markAsPaidCharges;
