import { UserProperty } from '../../../tango'

const initialState: UserProperty = {
  name: '',
  rut: '',
  user: [],
  fetchingUser: 'NO_USER',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_USER_STARTED':
      return {
        ...state,
        fetchingUser: 'LOADING',
      }
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        name: action.payload.result.attributes.full_name,
        rut: action.payload.result.attributes.rut,
        user: action.payload.result.attributes,
        fetchingUser: 'LOADED',
      }
    case 'GET_USER_ERROR':
      return {
        ...state,
        fetchingUser: 'ERROR',
      }
    default:
      return state
  }
}
