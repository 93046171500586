import { getPostsAdminStart, getPostsAdminSuccess, getPostsAdminError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getPostAdmin = (unitDocument: string, unitState: string, registerType: string) => {
    const docsMissing = unitDocument === 'missing';
    const docState = docsMissing ? 'index_by_missing_unit_documents' : 'index_by_unit_document';
    let state = docsMissing
        ? { unit_state: unitState, register_type: registerType }
        : { unit_document_state: unitDocument, unit_state: unitState, register_type: registerType };
    return (dispatch: any) => {
        dispatch(getPostsAdminStart());
        getData(`posts/${docState}`, state)
            .then((result) => {
                return dispatch(getPostsAdminSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPostsAdminError(e));
            });
    };
};
export default getPostAdmin;
