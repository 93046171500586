const initialState: any = {
    renewalPeriod: {},
    fetchingRenewalPeriod: 'NO_RENEWAL_PERIOD',
  }
  
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case 'GET_CURRENT_RENEWAL_PERIOD_STARTED':
        return {
          ...state,
          fetchingRenewalPeriod: 'FETCHING',
        }
      case 'GET_CURRENT_RENEWAL_PERIOD_SUCCESS':
        return {
          ...state,
        renewalPeriod: action.payload.result.data.data,
          fetchingRenewalPeriod: 'FETCHED',
        }
      case 'GET_CURRENT_RENEWAL_PERIOD_ERROR':
        return {
          ...state,
          error: action.payload.error,
          fetchingRenewalPeriod: 'ERROR_FETCHING',
        }
      default:
        return state
    }
  }