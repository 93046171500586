export const enableDocumentStart = () => {
  return {
    type: 'ENABLE_DOCUMENT_STARTED',
  }
}

export const enableDocumentSuccess = (result?: any) => {
  return {
    payload: result,
    type: 'ENABLE_DOCUMENT_SUCCESS',
  }
}

export const enableDocumentError = (error: any) => {
  return {
    type: 'ENABLE_DOCUMENT_ERROR',
    payload: error,
  }
}
