import {
  getPendingVisitsIdsStart,
  getPendingVisitsAgentIdsSuccess,
  getPendingVisitsVerifyIdsSuccess,
  getPendingVisitsIdsError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'

const getPendingVisitsIds = (
  role: string,
  ids: Array<any>,
  setLoading?: Function,
) => {
  return (dispatch: any) => {
    dispatch(getPendingVisitsIdsStart(role))
    getData(`unit_visits/availables_visitor?unit_visit_ids=${ids}`)
      .then((result) => {
        if (role === 'verify') {
          dispatch(getPendingVisitsVerifyIdsSuccess(result.data.data))
          setLoading && setLoading(false)
        } else {
          dispatch(getPendingVisitsAgentIdsSuccess(result.data.data))
          setLoading && setLoading(false)
        }
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPendingVisitsIdsError(e))
      })
  }
}

export default getPendingVisitsIds
