export const editAdvertStart = () => {
  return {
    type: 'EDIT_ADVERT_START',
  }
}

export const editAdvertSuccess = (result: any) => {
  return {
    type: 'EDIT_ADVERT_SUCCESS',
    payload: { result },
  }
}
export const editAdvertError = (error: any) => {
  return {
    type: 'EDIT_ADVERT_ERROR',
    payload: {
      error,
    },
  }
}
