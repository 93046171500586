export const getContractHistoryStart = () => {
  return {
    type: 'GET_CONTRACT_HISTORY_STARTED',
  }
}

export const getContractHistorySuccess = (result: any) => {
  return {
    type: 'GET_CONTRACT_HISTORY_SUCCESS',
    payload: { result },
  }
}
export const getContractHistoryError = (error: any) => {
  return {
    type: 'GET_CONTRACT_HISTORY_ERROR',
    payload: {
      error,
    },
  }
}
