import React, { useEffect } from 'react'
import {
  SwitchString,
  SwitchStringInput,
  RadioButtonTango,
  RadioButtonInputTango,
  CheckBoxTango,
  Input,
} from '../..'
import intl from 'react-intl-universal'
import {
  PARKING_LOT_OPTIONS,
  HOME_APPLIANCES_OPTIONS,
  THERMALPANELS_OPTIONS,
  HEATING_OPTIONS,
  BOOLEAN_OPTIONS,
  WATER_HEATING_OPTIONS,
  BEDROOM_FLOOR_MATERIALS_OPTIONS,
  LIVING_FLOOR_MATERIALS_OPTIONS,
  BATHROOM_FLOOR_MATERIALS_OPTIONS,
  KITCHEN_FLOOR_MATERIALS_OPTIONS,
} from '../../../../tango-react-base/helpers/selectOptions/options'
import { SectionProps } from './SectionInterfaces'
import {
  verifierOrAdminPermissions,
  propertyStatusPermissions,
} from './SectionHelpers'
import { useHistory } from 'react-router-dom'
import { isRolePhotographer } from '../../../helpers/roleComparison/index'

const Equipment = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  disabled,
  isRegistered = false,
  isVerifier,
  setFullFieldsEquipment,
  toRepublish = false,
}: SectionProps) => {
  const history = useHistory()
  const currentLocation = history.location.pathname.split('/')[1]
  const newRentedView = currentLocation === 'new-rented-unit'

  const fields: any =
    isRolePhotographer || toRepublish
      ? [
          values.unit_type === 'apartment'
            ? values.parking_spaces < 0 || values.parking_spaces === null
              ? undefined
              : values.parking_spaces
            : '',
          values.unit_type === 'apartment' && values.parking_spaces > 0
            ? values.parking_space_number || undefined
            : '',
          values.heating_system || undefined,
          values.water_heating || undefined,
          values.insulated_glazing || undefined,
          values.bedroom_floor_materials || undefined,
          values.living_room_floor_materials || undefined,
          values.bathroom_floor_materials || undefined,
          values.kitchen_floor_materials || undefined,
        ]
      : []

  useEffect(() => {
    setFullFieldsEquipment &&
      setFullFieldsEquipment(!fields.includes(undefined))
  }, [fields])

  const equipmentValidations = () => {
    return (
      verifierOrAdminPermissions() ||
      propertyStatusPermissions(values.aasm_state) ||
      isRegistered ||
      newRentedView
    )
  }
  if (equipmentValidations()) {
    return (
      <div>
        {!toRepublish && (
          <>
            <div className='template-line mt-4' />
            <h2 className='new-subtitle'>{intl.get('EQUIPMENT')}</h2>
          </>
        )}
        {values.unit_type === 'apartment' && (
          <>
            <RadioButtonInputTango
              isVerifier={isVerifier}
              required={false}
              disabled={disabled}
              error={errors.parking_spaces}
              id='parking_spaces'
              label='PARKING_LOT'
              onChange={handleChange}
              options={PARKING_LOT_OPTIONS}
              valueChecked={values.parking_spaces}
              idInput='parking_space_number'
              labelInput='PARKING_LOT_NUMBER'
              onBlur={handleBlur}
              onChangeInput={handleChange}
              touched={touched.parking_space_number}
              type='text'
              valueInput={values.parking_space_number}
              requiredInput={Boolean(values.parking_spaces)}
              isRequiredVerifier={
                values.parking_spaces < 0 ||
                values.parking_spaces === null ||
                values.parking_spaces === undefined
              }
            />
            <SwitchStringInput
              required={false}
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='STORAGE'
              name='storage_room'
              options={BOOLEAN_OPTIONS}
              value={values.storage_room}
              idInput='storage_room_number'
              labelInput='STORAGE_NUMBER'
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.storage_room_number}
              type='text'
              valueInput={values.storage_room_number}
            />
          </>
        )}
        {!isRegistered && !newRentedView && (
          <>
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='DOOR_BELL'
              name='door_bell'
              options={BOOLEAN_OPTIONS}
              value={values.door_bell}
            />
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='ALARM'
              name='alarm'
              options={BOOLEAN_OPTIONS}
              value={values.alarm}
            />
            <CheckBoxTango
              disabled={disabled}
              label='HOME_APPLIANCES'
              name='home_appliances'
              options={HOME_APPLIANCES_OPTIONS}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.heating_system}
              disabled={disabled}
              id='heating_system'
              label='HEATING'
              onChange={handleChange}
              options={HEATING_OPTIONS}
              valueChecked={values.heating_system}
              isRequiredVerifier={!values.heating_system}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.water_heating}
              disabled={disabled}
              id='water_heating'
              label='WATER_HEATING'
              onChange={handleChange}
              options={WATER_HEATING_OPTIONS}
              valueChecked={values.water_heating}
              isRequiredVerifier={!values.water_heating}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.insulated_glazing}
              disabled={disabled}
              id='insulated_glazing'
              label='THERMALPANEL'
              onChange={handleChange}
              options={THERMALPANELS_OPTIONS}
              valueChecked={values.insulated_glazing}
              isRequiredVerifier={!values.insulated_glazing}
            />
            <span className='new-unit-subtitle-two'>
              {intl.get('FLOOR_MATERIALS')}
            </span>
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.bedroom_floor_materials}
              disabled={disabled}
              id='bedroom_floor_materials'
              label='BEDROOM_FLOOR_MATERIALS'
              onChange={handleChange}
              options={BEDROOM_FLOOR_MATERIALS_OPTIONS}
              valueChecked={values.bedroom_floor_materials}
              classNameLabelInput='ml-5'
              isRequiredVerifier={!values.bedroom_floor_materials}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.living_room_floor_materials}
              disabled={disabled}
              id='living_room_floor_materials'
              label='LIVING_FLOOR_MATERIALS'
              onChange={handleChange}
              options={LIVING_FLOOR_MATERIALS_OPTIONS}
              valueChecked={values.living_room_floor_materials}
              classNameLabelInput='ml-5'
              isRequiredVerifier={!values.living_room_floor_materials}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.bathroom_floor_materials}
              disabled={disabled}
              id='bathroom_floor_materials'
              label='BATHROOM_FLOOR_MATERIALS'
              onChange={handleChange}
              options={BATHROOM_FLOOR_MATERIALS_OPTIONS}
              valueChecked={values.bathroom_floor_materials}
              classNameLabelInput='ml-5'
              isRequiredVerifier={!values.bathroom_floor_materials}
            />
            <RadioButtonTango
              isVerifier={isVerifier}
              error={errors.kitchen_floor_materials}
              disabled={disabled}
              id='kitchen_floor_materials'
              label='KITCHEN_FLOOR_MATERIALS'
              onChange={handleChange}
              options={KITCHEN_FLOOR_MATERIALS_OPTIONS}
              valueChecked={values.kitchen_floor_materials}
              classNameLabelInput='ml-5'
              isRequiredVerifier={!values.kitchen_floor_materials}
            />
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='CURTAINS'
              name='curtains'
              options={BOOLEAN_OPTIONS}
              value={values.curtains}
            />
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='RECENT_REMODEL'
              name='recent_remodel'
              options={BOOLEAN_OPTIONS}
              value={values.recent_remodel}
            />
            {values.recent_remodel === 'yes' && (
              <>
                <Input
                  disabled={disabled}
                  error={errors.recent_remodel_comment}
                  id='recent_remodel_comment'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={intl.get('SPECIFY_REMODELING')}
                  requiredMessage={intl.get('REQUIRED_FIELD')}
                  type='text'
                  value={values.recent_remodel_comment}
                  className='mt-0'
                  classNameInput='col-md-4 col-12 pl-0'
                  label='NO_LABEL'
                  info=''
                />
              </>
            )}
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='FURNISHED'
              name='furnished'
              options={BOOLEAN_OPTIONS}
              value={values.furnished}
            />
          </>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Equipment
