import { PromotionsState } from '../../../tango'

const initialState: PromotionsState = {
  promotions: [],
  fetchingPromotions: 'NO_PROMOTIONS',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PROMOTIONS_STARTED':
      return {
        ...state,
        fetchingPromotions: 'LOADING',
      }
    case 'GET_PROMOTIONS_SUCCESS':
      return {
        ...state,
        promotions: action.payload.result.data.data,
        fetchingPromotions: 'LOADED',
      }
    case 'GET_PROMOTIONS_ERROR':
      console.error(action.payload.error)
      return {
        ...state,
        error: action.payload.error,
        fetchingPromotions: 'ERROR',
      }
    default:
      return state
  }
}
