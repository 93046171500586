export const createAdvertStart = () => {
  return {
    type: 'CREATE_ADVERT_START',
  }
}

export const createAdvertSuccess = (result: any) => {
  return {
    type: 'CREATE_ADVERT_SUCCESS',
    payload: { result },
  }
}
export const createAdvertError = (error: any) => {
  return {
    type: 'CREATE_ADVERT_ERROR',
    payload: {
      error,
    },
  }
}
