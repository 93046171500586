import React, { FC, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createAdvert, createAdvertGallery, createAdvertImage, editAdvert, getAdvert } from '../../redux/actions';
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize';
import { setTemplateChild } from '../../tango-react-base/reduxActions';
import { AddImage } from '../../tango-react-base/resources/index';

/**
 * Define the props of the component
 * @typedef  Props
 * @type {(function|Object|string)}
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onCreateAdvertGallery - is a function.
 * @property {function} onCreateAdvert - is a function.
 * @property {function} onCreateAdvertImage - is a function.
 * @property {function} onGetAdvert - is a function.
 * @property {function} onEditAdvert - is a function.
 * @property {Object} advertGallery - is an Object.
 * @property {Object} advert - is an Object.
 * @property {string} fetchingStatusCreate - is a string.
 * @property {string} fetchingStatusAdvert - is a string.
 * @property {string} fetchingStatusCreateAdvert - is a string.
 * @property {string} fetchingStatusCreateAdvertImage - is a string.
 * @property {string} fetchingStatusCreateAdvertImages - is a string.
 * @property {string} fetchingStatusCreateAdvertImageError - is a string.
 * @property {string} fetchingStatusEditAdvert - is a string.
 */
interface Props {
  onSetTemplateChild: (child: JSX.Element) => void;
  onCreateAdvertGallery: (kind: string, transitionDuration: number) => void;
  onCreateAdvert: (data: Object) => void;
  onCreateAdvertImage: (data: any) => void;
  onGetAdvert: (advertId: number) => void;
  onEditAdvert: (data: any, advertId: number) => void;
  advertGallery: {
    id: number;
  };
  advert: {
    id: number;
    type: string;
    attributes: {
      link: string;
      name: string;
      description: string;
      images: {
        desktop: {
          name: string;
          url: string;
        };
        tablet: {
          name: string;
          url: string;
        };
        mobile: {
          name: string;
          url: string;
        };
      };
    };
  };
  fetchingStatusCreate: string;
  fetchingStatusAdvert: string;
  fetchingStatusCreateAdvert: string;
  fetchingStatusCreateAdvertImage: string;
  fetchingStatusCreateAdvertImages: string;
  fetchingStatusCreateAdvertImageError: boolean;
  fetchingStatusEditAdvert: string;
}

/**
 * Define the props of the button component
 *  @typedef  PropsButton
 *  @type {(string)}
 *  @property {string} className - is an string
 */
interface PropsButton {
  className?: string;
}

/**
 * Campaigns is a functional component with props.
 * The main function of this component is to render elements in the view.
 *  @function
 *  @param {Props} onSetTemplateChild - function onSetTemplateChild
 *  @returns {(JSX.Element)} Returns a view with two tables
 */

const Campaigns: FC<Props> = ({
  onSetTemplateChild,
  onCreateAdvertGallery,
  onCreateAdvert,
  onCreateAdvertImage,
  onGetAdvert,
  onEditAdvert,
  advertGallery,
  advert,
  fetchingStatusCreate,
  fetchingStatusAdvert,
  fetchingStatusCreateAdvert,
  fetchingStatusCreateAdvertImages,
  fetchingStatusCreateAdvertImageError,
  fetchingStatusEditAdvert,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!fetchingStatusCreateAdvertImageError && fetchingStatusCreateAdvertImages === 'SUCCESS') {
      localStorage.clear();
      return history.push('/campaigns');
    }
  }, [fetchingStatusCreateAdvertImages, fetchingStatusCreateAdvertImageError]);

  const history = useHistory();
  const pathname = history.location.pathname;
  const [editingAdvert, setEditingAdvert] = useState(false);
  const [creatingAdvert, setCreatingAdvert] = useState(false);
  const [creatingNewAdvert, setCreatingNewAdvert] = useState(false);
  const [advertGalleryId, setAdvertGalleryId] = useState(0);
  const [advertId, setAdvertId] = useState(0);

  useEffect(() => {
    let URLArray = pathname.split('/');
    if (pathname.includes('/creation_advert')) {
      setCreatingNewAdvert(true);
    }
    if (pathname.includes('/new_advert')) {
      setAdvertGalleryId(Number(URLArray[URLArray.length - 1]));
      setCreatingAdvert(true);
    }
    if (pathname.includes('/gallery')) {
      setAdvertGalleryId(Number(URLArray[URLArray.length - 3]));
      setAdvertId(Number(URLArray[URLArray.length - 1]));
      setEditingAdvert(true);
    }
  }, [pathname]);

  // FIELD FORM
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [alternativeText, setAlternativeText] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [oldLink, setOldLink] = useState('');
  const [oldAlternativeText, setOldAlternativeText] = useState('');

  // IMAGES
  const fileInputRefMobile = useRef<HTMLInputElement>(null);
  const fileInputRefTablet = useRef<HTMLInputElement>(null);
  const fileInputRefDesktop = useRef<HTMLInputElement>(null);
  const [fileMobile, setFileMobile] = useState('');
  const [fileTablet, setFileTablet] = useState('');
  const [fileDesktop, setFileDesktop] = useState('');
  const [URLMobile, setFileMobileURL] = useState('');
  const [URLTablet, setFileTabletURL] = useState('');
  const [URLDesktop, setFileDesktopURL] = useState('');
  const [nameFileMobile, setNameFileMobile] = useState('');
  const [nameFileTablet, setNameFileTablet] = useState('');
  const [nameFileDesktop, setNameFileDesktop] = useState('');
  const [dimensions, setDimensions] = useState({});
  const [currentFile, setCurrentFile] = useState('');
  const [currentType, setCurrentType] = useState('');
  const [currentDataURL, setCurrentDataURL] = useState('');
  const [transitionDuration, setTransitionDuration] = useState(Number(localStorage.getItem('transitionDuration')) || 5);
  const [priority, setPriority] = useState(Number(localStorage.getItem('priority')) || 1);
  const [lastGalleryState, setLastGalleryState] = useState(localStorage.getItem('lastGalleryState') || '');

  const galleryPublished = lastGalleryState === 'published';

  /* doubleClickSaveAdvert: It prevents the user from making successive clicks when
  pressing the save button. */
  const [doubleClickSaveAdvert, setDoubleClickSaveAdvert] = useState(false);

  useEffect(() => {
    if (
      fetchingStatusCreate === 'ERROR' ||
      fetchingStatusCreateAdvertImageError ||
      fetchingStatusCreateAdvert === 'ERROR'
    )
      return setDoubleClickSaveAdvert(false);
  }, [fetchingStatusCreate, fetchingStatusCreateAdvertImageError, fetchingStatusCreateAdvert]);

  const includesHttp = link.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );

  const enableButton = title && link && alternativeText && fileMobile && fileTablet && fileDesktop && includesHttp;

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('CAMPAIGNS')}</h1>
        </>,
      );
    }
  }, [history, onSetTemplateChild]);

  const { width, height } = useWindowSize();
  let containerHeight = document?.getElementById('container-home')?.offsetHeight || 0;

  // ***** Creation for the first time: Advert Gallery, Advert and Advert Images *****

  // SET ID OF ADVERT GALLERY
  useEffect(() => {
    if (fetchingStatusCreate === 'CREATED' && !editingAdvert) return setAdvertGalleryId(advertGallery.id);
  }, [fetchingStatusCreate]);

  // SET ID OF ADVERT
  useEffect(() => {
    if (fetchingStatusCreateAdvert === 'CREATED') return setAdvertId(advert.id);
  }, [fetchingStatusCreateAdvert]);

  // CREATION OF ADVERT
  useEffect(() => {
    let data;
    if (
      doubleClickSaveAdvert &&
      (creatingNewAdvert || galleryPublished) &&
      fetchingStatusCreate === 'CREATED' &&
      !editingAdvert
    ) {
      data = {
        advert: {
          name: title,
          description: alternativeText,
          link: link,
          priority: priority,
          advert_gallery_id: advertGallery.id,
        },
      };
      return onCreateAdvert(data);
    }
  }, [doubleClickSaveAdvert, creatingNewAdvert, fetchingStatusCreate]);

  // CREATION ADVERT IMAGES BEFORE CREATED ADVERT
  useEffect(() => {
    if (fetchingStatusCreateAdvert === 'CREATED') {
      return createAdvertImages();
    }
  }, [doubleClickSaveAdvert, fetchingStatusCreateAdvert]);

  const createAdvertImages = () => {
    let data = {
      files: [fileMobile, fileDesktop, fileTablet],
      filesNames: [nameFileMobile, nameFileDesktop, nameFileTablet],
      kinds: ['mobile', 'desktop', 'tablet'],
      advertId: advert.id,
      advertGalleryId: advertGallery.id || advertGalleryId,
    };
    onCreateAdvertImage(data);
  };

  // ***** Edit an advert *****
  useEffect(() => {
    if (editingAdvert) return onGetAdvert(advertId);
  }, [editingAdvert]);

  useEffect(() => {
    if (fetchingStatusAdvert === 'LOADED') {
      const { link, name, description, images } = advert?.attributes;
      const { desktop, tablet, mobile } = images;
      const { name: nameDesktop, url: urlDesktop } = desktop;
      const { name: nameTablet, url: urlTablet } = tablet;
      const { name: nameMobile, url: urlMobile } = mobile;
      setTitle(name);
      setLink(link);
      setAlternativeText(description);
      setOldTitle(name);
      setOldLink(link);
      setOldAlternativeText(description);
      setFileDesktopURL(urlDesktop);
      setFileTabletURL(urlTablet);
      setFileMobileURL(urlMobile);
      setNameFileDesktop(nameDesktop);
      setNameFileTablet(nameTablet);
      setNameFileMobile(nameMobile);
      setFileDesktop('LOADED');
      setFileTablet('LOADED');
      setFileMobile('LOADED');
    }
  }, [fetchingStatusAdvert]);

  const unmodifiedFiles = fileMobile === 'LOADED' && fileTablet === 'LOADED' && fileDesktop === 'LOADED';

  const unmodifiedField = title === oldTitle && link === oldLink && alternativeText === oldAlternativeText;

  const availableSaveButton = !unmodifiedFiles || !unmodifiedField;

  useEffect(() => {
    if (editingAdvert && fetchingStatusEditAdvert === 'EDITED' && unmodifiedFiles) {
      localStorage.clear();
      return history.push('/campaigns');
    }
  }, [fetchingStatusEditAdvert]);

  useEffect(() => {
    if (editingAdvert && fetchingStatusCreate === 'CREATED') {
      let data;
      data = {
        advert: {
          name: title,
          description: alternativeText,
          link: link,
        },
      };
      if (!unmodifiedField) onEditAdvert(data, advertId);
      if (!unmodifiedFiles) createAdvertImages();
    }
  }, [fetchingStatusCreate]);

  // CASES TO PERFORM AFTER PRESSING THE SAVE BUTTON
  const handleSaveAdvertButton = (e: any) => {
    e.preventDefault();
    let data;
    // Creation for the first time: Advert Gallery, Advert and Advert Images
    if (creatingNewAdvert) {
      onCreateAdvertGallery('carousel', transitionDuration);
    }
    // Creation for the second time: Advert Gallery CREATED, only create Advert and Advert Images
    if (creatingAdvert) {
      if (galleryPublished) {
        onCreateAdvertGallery('carousel', transitionDuration);
      } else {
        data = {
          advert: {
            name: title,
            description: alternativeText,
            link: link,
            priority: priority,
            advert_gallery_id: advertGalleryId,
          },
        };
        onCreateAdvert(data);
      }
    }
    // Editing an Advert
    if (editingAdvert) {
      if (galleryPublished) {
        onCreateAdvertGallery('carousel', transitionDuration);
      } else {
        data = {
          advert: {
            name: title,
            description: alternativeText,
            link: link,
          },
        };
        if (!unmodifiedField) onEditAdvert(data, advertId);
        if (!unmodifiedFiles) createAdvertImages();
      }
    }
    setDoubleClickSaveAdvert(true);
  };

  const SaveAdvertButton = ({ className }: PropsButton) => (
    <button
      className={`button-primary ${className}`}
      disabled={doubleClickSaveAdvert || !enableButton || !availableSaveButton}
      type='submit'
    >
      {intl.get('SAVE')}
    </button>
  );

  const openFileDialog = (inputRef: any) => {
    if (inputRef.current) inputRef.current.click();
  };

  const deleteImage = (type: string) => {
    switch (type) {
      case 'desktop':
        setFileDesktop('');
        setFileDesktopURL('');
        setNameFileDesktop('');
        break;
      case 'tablet':
        setFileTablet('');
        setFileTabletURL('');
        setNameFileTablet('');
        break;
      case 'mobile':
        setFileMobile('');
        setFileMobileURL('');
        setNameFileMobile('');
        break;
    }
  };

  useEffect(() => {
    if (Object.entries(dimensions).length !== 0) {
      if (validateImage(dimensions, currentType)) {
        uploadFile(currentFile, currentType, currentDataURL);
      }
    }
  }, [dimensions]);

  const validateImage = (dimensions: any, type: string) => {
    const { height, width } = dimensions;
    switch (type) {
      case 'desktop':
        if (height === 600 && width === 1440) {
          return true;
        } else {
          toast(intl.get('WRONG_IMAGE_TYPE_OR_DIMENSIONS'));
          setCurrentFile('');
          setCurrentType('');
          setCurrentDataURL('');
          setDimensions({});
          return false;
        }
      case 'tablet':
        if (height === 460 && width === 994) {
          return true;
        } else {
          toast(intl.get('WRONG_IMAGE_TYPE_OR_DIMENSIONS'));
          setCurrentFile('');
          setCurrentType('');
          setCurrentDataURL('');
          setDimensions({});
          return false;
        }
      case 'mobile':
        if (height === 300 && width === 360) {
          return true;
        } else {
          toast(intl.get('WRONG_IMAGE_TYPE_OR_DIMENSIONS'));
          setCurrentFile('');
          setCurrentType('');
          setCurrentDataURL('');
          setDimensions({});
          return false;
        }
    }
  };

  const uploadFile = (file: any, type: string, dataURL: any) => {
    switch (type) {
      case 'desktop':
        setFileDesktop(file);
        setFileDesktopURL(dataURL);
        setNameFileDesktop(file.name);
        break;
      case 'tablet':
        setFileTablet(file);
        setFileTabletURL(dataURL);
        setNameFileTablet(file.name);
        break;
      case 'mobile':
        setFileMobile(file);
        setFileMobileURL(dataURL);
        setNameFileMobile(file.name);
        break;
    }
  };

  const getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(
          setDimensions({
            height: img.height,
            width: img.width,
          }),
        );
      };
      img.src = dataURL;
    });

  const handleUploadFile = (e: any, type: string) => {
    let dataURL = window.URL.createObjectURL(e.target.files[0]);
    if (e.target.files[0].size <= 1024 * 1024 * 50) {
      getHeightAndWidthFromDataUrl(dataURL);
      setCurrentFile(e.target.files[0]);
      setCurrentType(type);
      setCurrentDataURL(dataURL);
    } else {
      toast(intl.get('WRONG_IMAGE_WEIGHT'));
    }
  };

  return (
    <form id='container-home' className='container-home mt-3 pd-1' onSubmit={handleSaveAdvertButton}>
      <div className='title-carousel'>
        <div>
          <h1 className='new-subtitle font-weight-bold'>{intl.get('UPLOAD_IMAGES_ON_DEVICES')}</h1>
          <div className='template-line mb-5' />
        </div>
      </div>
      <div className={`row mb-3 ${width >= 1024 ? 'pr-4' : ''}`}>
        <div
          className={`col-12 col-lg-4 col-md-6 d-flex flex-row justify-content-start
          p-0  align-items-center`}
        >
          <span className='ml-2'>{intl.getHTML('TITLE')}</span>
        </div>
        <div
          className={`col-12 col-lg-5 col-md-6 d-flex flex-row justify-content-${
            width >= 768 ? 'center' : 'start'
          } p-0  align-items-center`}
        >
          <input
            type='string'
            value={title}
            className='form-control input-adverts w-100'
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className={`row mb-3 ${width >= 1024 ? 'pr-4' : ''}`}>
        <div
          className={`col-12 col-lg-4 col-md-6 d-flex flex-row justify-content-start
          p-0  align-items-center`}
        >
          <span className='ml-2'>{intl.getHTML('LINK')}</span>
        </div>
        <div
          className={`col-12 col-lg-5 col-md-6 d-flex flex-row justify-content-${
            width >= 768 ? 'center' : 'start'
          } p-0  align-items-center`}
        >
          <input
            type='string'
            value={link}
            className='form-control input-adverts w-100'
            required
            onChange={(e) => setLink(e.target.value)}
            placeholder={'https://www.example.cl'}
          />
        </div>
      </div>
      <div className={`row mb-3 ${width >= 1024 ? 'pr-4' : ''}`}>
        <div
          className={`col-12 col-lg-4 col-md-6 d-flex flex-row justify-content-start
          p-0  align-items-center`}
        >
          <span className='ml-2'>{intl.getHTML('ALTERNATIVE_TEXT')}</span>
        </div>
        <div
          className={`col-12 col-lg-5 col-md-6 d-flex flex-column justify-content-${
            width >= 768 ? 'center' : 'start'
          } p-0  align-items-center`}
        >
          <input
            type='string'
            value={alternativeText}
            className='form-control input-adverts w-100'
            required
            onChange={(e) => setAlternativeText(e.target.value)}
            maxLength={60}
            id='alternative-text'
          />
        </div>
      </div>
      <div className='row mb-5 mt-5'>
        <div className='col-12 col-lg-5 col-md-8 text-left p-0'>
          <span className='font-weight-bold h5'>{'DESKTOP'}</span>
          <p className='mt-2 pr-sm-2 text-color-tertiary'>{intl.getHTML('IMAGE_DESKTOP_INFO')}</p>
        </div>
        <div
          className={`col-12 col-lg-4 col-md-4 d-flex flex-column justify-content-center
          p-0  align-items-center`}
        >
          {fileDesktop ? (
            <>
              <img src={URLDesktop} height='90%' width='90%' />
              <div className='row w-100'>
                <div className='col-6 text-left pl-4 text-truncate d-inline-block'>
                  <span>{nameFileDesktop}</span>
                </div>
                <div className='col-6 text-right pr-4'>
                  <a className='hyperlink' onClick={() => deleteImage('desktop')}>
                    {intl.getHTML('DELETE')}
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <input
                type='file'
                accept='image/jpg,image/png,image/jpeg'
                style={{ display: 'none' }}
                id='image-desktop'
                onChange={(e) => handleUploadFile(e, 'desktop')}
                ref={fileInputRefDesktop}
              />
              <img
                src={AddImage}
                alt='owner-icon'
                height='90%'
                width='90%'
                onClick={() => openFileDialog(fileInputRefDesktop)}
              />
            </>
          )}
        </div>
      </div>
      <hr className='separator-line'></hr>
      <div className='row mb-5 mt-3'>
        <div className='col-12 col-lg-5 col-md-8 text-left p-0'>
          <span className='font-weight-bold h5'>{'MOBILE'}</span>
          <p className='mt-2 pr-sm-2 text-color-tertiary'>{intl.getHTML('IMAGE_MOBILE_INFO')}</p>
        </div>
        <div
          className={`col-12 col-lg-4 col-md-4 d-flex flex-column justify-content-center
          p-0  align-items-center`}
        >
          {fileMobile ? (
            <>
              <img src={URLMobile} height='90%' width='90%' />
              <div className='row w-100'>
                <div className='col-6 text-left pl-4 text-truncate d-inline-block'>
                  <span>{nameFileMobile}</span>
                </div>
                <div className='col-6 text-right pr-4'>
                  <a onClick={() => deleteImage('mobile')}>{intl.getHTML('DELETE')}</a>
                </div>
              </div>
            </>
          ) : (
            <>
              <input
                type='file'
                accept='image/jpg,image/png,image/jpeg'
                style={{ display: 'none' }}
                id='image-mobile'
                onChange={(e) => handleUploadFile(e, 'mobile')}
                ref={fileInputRefMobile}
              />
              <img
                src={AddImage}
                alt='owner-icon'
                height='90%'
                width='90%'
                onClick={() => openFileDialog(fileInputRefMobile)}
              />
            </>
          )}
        </div>
      </div>
      <hr className='separator-line'></hr>
      <div className='row mb-5 mt-3'>
        <div className='col-12 col-lg-5 col-md-8 text-left p-0'>
          <span className='font-weight-bold h5'>{'TABLET'}</span>
          <p className='mt-2 pr-sm-2 text-color-tertiary'>{intl.getHTML('IMAGE_TABLET_INFO')}</p>
        </div>
        <div
          className={`col-12 col-lg-4 col-md-4 d-flex flex-column justify-content-center
          p-0  align-items-center`}
        >
          {fileTablet ? (
            <>
              <img src={URLTablet} height='90%' width='90%' />
              <div className='row w-100'>
                <div className='col-6 text-left pl-4 text-truncate d-inline-block'>
                  <span>{nameFileTablet}</span>
                </div>
                <div className='col-6 text-right pr-4'>
                  <a onClick={() => deleteImage('tablet')}>{intl.getHTML('DELETE')}</a>
                </div>
              </div>
            </>
          ) : (
            <>
              <input
                type='file'
                accept='image/jpg,image/png,image/jpeg'
                style={{ display: 'none' }}
                id='image-tablet'
                onChange={(e) => handleUploadFile(e, 'tablet')}
                ref={fileInputRefTablet}
              />
              <img
                src={AddImage}
                alt='owner-icon'
                height='90%'
                width='90%'
                onClick={() => openFileDialog(fileInputRefTablet)}
              />
            </>
          )}
        </div>
      </div>
      {width >= 768 && width < 1024 && (
        <div className='w-100'>
          <SaveAdvertButton className='w-25 float-right mt-4' />
        </div>
      )}
      {width >= 1024 && (
        <div className='w-100 col-9'>
          <SaveAdvertButton className='w-25 float-right mt-4' />
        </div>
      )}
      {width < 768 && (
        <div>
          <div className='w-100'>
            <SaveAdvertButton
              className={`w-100 mt-4 ${
                scrollPosition + height / 2 < containerHeight + 40 ? 'invisible-button disabled' : ''
              }`}
            />
          </div>
        </div>
      )}
      {width < 768 && (
        <div className=''>
          <div
            className={`w-100 ${
              scrollPosition + height / 2 < containerHeight + 40 ? 'button-floating-center' : 'hidden'
            }`}
          >
            <SaveAdvertButton className={`w-90 mt-4`} />
          </div>
        </div>
      )}
    </form>
  );
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
  const { advertGallery, fetchingStatusCreate } = state.advertGallery;
  const { advert, fetchingStatusCreateAdvert, fetchingStatusAdvert, fetchingStatusEditAdvert } = state.advert;
  return {
    advertGallery,
    fetchingStatusCreate,
    advert,
    fetchingStatusCreateAdvert,
    fetchingStatusEditAdvert,
    fetchingStatusAdvert,
    ...state.advertImage,
  };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onCreateAdvertGallery: (kind: string, transitionDuration: number) => {
      dispatch(createAdvertGallery(kind, transitionDuration));
    },
    onCreateAdvert: (data: Object) => {
      dispatch(createAdvert(data));
    },
    onCreateAdvertImage: (data: any) => {
      dispatch(createAdvertImage(data));
    },
    onGetAdvert: (advertId: number) => {
      dispatch(getAdvert(advertId));
    },
    onEditAdvert: (data: any, advertId: number) => {
      dispatch(editAdvert(data, advertId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
