const initialState: any = {
  banks: null,
  status: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_BANK_RESOURCE_STARTED':
      return {
        ...state,
        status: 'FETCHING',
      }
    case 'GET_BANK_RESOURCE_SUCCESS':
      return {
        ...state,
        banks: action.payload.data.data,
        status: 'FETCHED',
      }
    case 'GET_BANK_RESOURCE_ERROR':
      return {
        ...state,
        banks: null,
        status: 'ERROR',
      }
    default:
      return state
  }
}
