import {
    renterDocumentsSuc,
    renterDocumentsStart
  } from './types'
  const renterDocuments = (data: any) => {
    return (dispatch: any) => {
      dispatch(renterDocumentsStart())
      dispatch(renterDocumentsSuc(data))
    }
  }
  
  export default renterDocuments