export const getPendingVouchersStart = () => {
    return {
        type: 'GET_PENDING_VOUCHERS_STARTED',
    };
};
export const getPendingVouchersSuccess = (data: any, doc_type: any) => {
    if (doc_type === 'restoration_voucher') {
        return {
            type: 'GET_RESTORATION_PENDING_VOUCHERS_SUCCESS',
            payload: data,
        };
    } else if (doc_type === 'delivery_voucher') {
        return {
            type: 'GET_DELIVERY_PENDING_VOUCHERS_SUCCESS',
            payload: data,
        };
    } else {
        return {
            type: 'GET_PENDING_VOUCHERS_SUCCESS',
            payload: data,
        };
    }
};
export const getPendingVouchersError = (error: any) => {
    return {
        type: 'GET_PENDING_VOUCHERS_ERROR',
        payload: {
            error,
        },
    };
};
