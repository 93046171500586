import {
  getContractDetailStart,
  getContractDetailSuccess,
  getContractDetailError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getContractDetail = (id: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(getContractDetailStart())
    getData(`contracts/${id}/documents_summary`)
      .then((result) => {
        return dispatch(getContractDetailSuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getContractDetailError(e))
      })
  }
}
export default getContractDetail
