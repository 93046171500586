import { MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DocumentsLink from '../../components/documentsLink/DocumentsLink';
import OwnerLink from '../../components/ownerLink/OwnerLink';
import RequisitionsLink from '../../components/requisitionsLink/RequisitionsLink';
import { approvePostRegistered, getUser, publishPost, rejectPost } from '../../redux/actions';
import { CircularLoading, PostForm } from '../../tango-react-base/components';
import AllPhotos from '../../tango-react-base/components/allPhotos/index';
import VideosCarousel from '../../tango-react-base/components/carouselVideo';
import { isRoleAdmin } from '../../tango-react-base/helpers/roleComparison/index';
import PostInterface from '../../tango-react-base/model_interfaces/PostInterface';
import { getPost, setTemplateChild, updateUnit } from '../../tango-react-base/reduxActions';

interface PublishPostProps {
  post: PostInterface;
  onGetUser: (OwnerId: number) => void;
  name: string;
  rut: string;
  user: any;
  fetchingUser: string;
  onGetPost: (postId: any) => void;
  fetchingPost: string;
  onPublishPost: (postId: any, history: any) => void;
  onRejectPost: (postId: any, history: any) => void;
  changingPostState: string;
  documentsProcessed: any;
  onSetTemplateChild: (child: Object) => void;
  onApprovePostRegistered: (postId: number, history: any) => void;
  error: any;
  onUpdateUnit: (id: number, values: any) => void;
}

const PublishPost = ({
  post,
  onGetUser,
  name,
  rut,
  user,
  fetchingUser,
  onGetPost,
  fetchingPost,
  onPublishPost,
  onRejectPost,
  changingPostState,
  documentsProcessed,
  onSetTemplateChild,
  onApprovePostRegistered,
  onUpdateUnit,
  error,
}: PublishPostProps) => {
  const history = useHistory();
  const location: any = useLocation();
  const { id }: any = useParams();
  const [url, setUrl] = useState(false);
  const [annexContractSection, setAnnexContractSection] = useState(React.createRef<HTMLDivElement>());
  const [requisitionData, setRequisitionData] = useState(post?.attributes?.requisition_data);
  const [leasePeriodId, setLeasePeriodId] = useState(post?.attributes?.lease_period_id);

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('POST_DETAILS')}</h1>
        </>,
      );
      onGetPost(id);
      setUrl(location.hash === '#annex');
    }
  }, [id, onGetPost, onSetTemplateChild]);

  const [rentId, setRentId] = useState(0);

  useEffect(() => {
    if (fetchingPost === 'LOADED') {
      onGetUser(post.attributes.unit.owner_id);
      if (post.attributes.renters_with_rents.length == 1) setRentId(+post.attributes.renters_with_rents[0]?.rent_id);
      setRequisitionData(post.attributes.requisition_data);
      setLeasePeriodId(post.attributes.lease_period_id);
    }
  }, [fetchingPost, post, onGetUser]);

  const publishPost = () => {
    onPublishPost(id, history);
  };
  const approvedRegisterPost = (id: number, history: any) => {
    onApprovePostRegistered(id, history);
  };
  /** rejectPost: This function sends a PUT request so as to reject the publication of the property
   * @function
   */
  const rejectPost = () => {
    onRejectPost(id, history);
  };
  /** updateUnit: This function creates a new instance of FormData, iterates through the form values and sends these values in a PUT request.
   *  This request is to update a property's attributes
   *  TODO: refactor this function based on changes from back-end
   * @function
   */
  const updateUnit = (values: any) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      // Checks whether the values are booleans, if they are, front should send a yes or no, unless it's alert_to_renter
      if (typeof values[key] === 'boolean' && key !== 'alert_to_renter') {
        formData.append(`unit[${key}]`, values[key] ? 'yes' : 'no');
        // If the attributes are rut, email and full_name, then they belong to the renter of a registed unit
      } else if (key === 'rut' || key === 'email' || key === 'full_name') {
        formData.append(`registered_unit_renter[${key}]`, values[key]);
        // Description belongs to a post, not a unit
      } else if (key === 'description') {
        formData.append(`post[${key}]`, values[key]);
      } else if (key === 'estimate_price_amount') {
        formData.append(`post[price_amount]`, values[key]);
        formData.append(`unit[${key}]`, values[key]);
      } else if (key === 'estimate_price_currency') {
        formData.append(`post[price_currency]`, values[key]);
        formData.append(`unit[${key}]`, values[key]);
      } else {
        formData.append(`unit[${key}]`, values[key]);
      }
    });

    onUpdateUnit(post.id, formData);
  };

  const renderLoading = () => (
    <div>
      {(fetchingPost === 'LOADING' || fetchingUser === 'LOADING') && <span>{<CircularLoading />}</span>}
      {fetchingPost === 'ERROR' && <span>{intl.get('ERROR')}</span>}
    </div>
  );

  const scrollAutomatic = () => {
    let section = React.createRef<HTMLDivElement>();
    setAnnexContractSection(section);
    if (section && section.current) {
      section.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (url) {
      setTimeout(scrollAutomatic, 3000);
    }
  }, [url]);

  const renderPhotos = (photos: any) => {
    return (
      <>
        <div className='template-line mt-4' />
        <h2 className='new-subtitle'>{intl.get('PHOTOS')}</h2>
        <div className='wrap'>{photos.length ? <AllPhotos photos={photos} /> : intl.get('NO_PHOTOS')}</div>
      </>
    );
  };

  const renderVideos = () => {
    if (post.attributes.videos && post.attributes.videos.length !== 0) {
      const videosArray = post.attributes.videos;
      return (
        <>
          <div className='template-line mt-4' />
          <h2 className='new-subtitle'>{intl.get('VIDEOS')}</h2>
          <div className='wrap'>
            <VideosCarousel videos={videosArray} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className='template-line mt-4' />
        <h2 className='new-subtitle'>{intl.get('VIDEOS')}</h2>
        {intl.get('NO_VIDEOS')}
      </>
    );
  };

  const disabledButton = (aasmState: string, unitType: string) => {
    if (unitType !== 'registered') return true;

    const stateDisabled = ['created', 'published', 'rejected', 'unpublished'];
    return stateDisabled.includes(aasmState);
  };

  const disabledButtonVerify = (
    aasmState: string,
    unitType: string,
    verifyFinished: boolean,
    comments: string,
    canApproved: boolean,
  ) => {
    switch (unitType) {
      case 'new':
        return !(verifyFinished && comments !== '' && aasmState === 'verified');

      case 'registered':
        return !(canApproved && comments !== '');

      case 'brokerage':
        return !(verifyFinished && comments !== '' && aasmState === 'verified');
      default:
        return true;
    }
  };
  const renderMessage = (aasmState: string, finishVisit: boolean) => {
    if (!finishVisit) return intl.get('PENDING_VERIFY');
    if (aasmState === 'published') return intl.get('PUBLISHED');
    if (aasmState === 'rejected') return intl.get('REJECTED');
    if (aasmState === 'unpublished') return intl.get('RENTED');
  };

  const handleChange = (event: any) => {
    setRentId(event.target.value);
  };

  const renderForm = () => {
    const {
      photos,
      aasm_state,
      unit,
      can_be_approved,
      verify_visit_finished,
      can_update,
      owner_profile,
      full_address_present,
      identity_document_present,
      renters_with_rents,
    } = post?.attributes;

    const ownerProfilePresent = user?.owner_profile_present;
    const ownerProfileId = user?.owner_profile?.id;

    const postId = post?.id;
    const { owner_id, property_comments, id: unitId } = unit || '';

    return (
      <div className='container-home pd-1 mt-3'>
        {isRoleAdmin && (
          <>
            <div className='d-flex justify-content-start flex-column'>
              <div className='template-line mt-4' />
              <h2 className='new-subtitle'>{intl.get('OWNER')}</h2>
              <OwnerLink ownerProfile={ownerProfilePresent} ownerId={ownerProfilePresent ? ownerProfileId : owner_id} />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <div className='template-line mt-4' />
              <h2 className='new-subtitle'>{intl.get('RENTER')}</h2>
              <div className={'row input-container d-flex align-items-start '}>
                <div className={'col-md-4 col-12 pl-0 my-auto'}>
                  <span className='span-text'>{intl.get('SELECT_RENTER')}</span>
                </div>
                <div className='col-md-3 col-12 pl-0'>
                  <Select
                    className='show-renter-select-style'
                    labelId='select-label'
                    id=''
                    value={rentId}
                    IconComponent={() => <></>}
                    onChange={handleChange}
                  >
                    {renters_with_rents?.map((item: { renter_name: string; rent_id: number }) => (
                      <MenuItem value={item.rent_id}>{item.renter_name}</MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <div className='template-line mt-4' />
              <h2 className='new-subtitle'>{intl.get('PROPERTY_DOCUMENTATION')}</h2>
              <DocumentsLink unitId={unitId} rentId={rentId} postId={postId} />
            </div>
            {!!requisitionData?.section_visible && (
              <div className='d-flex justify-content-start flex-column requisition-section'>
                <div className='template-line mt-4' />
                <h2 className='new-subtitle'>{intl.get('REQUESTS')}</h2>
                <RequisitionsLink
                  requisitionId={requisitionData.early_closing_requisition_id}
                  leasePeriodId={leasePeriodId}
                />
              </div>
            )}
          </>
        )}
        <PostForm
          isRegistered={unit.register_type === 'registered'}
          post={post}
          disabled={!can_update}
          onSubmit={updateUnit}
        >
          {renderPhotos(photos)}
          {renderVideos()}
        </PostForm>
        <div className='row mt-5'>
          <div className='col-4'>
            <button className='button-primary col-12' onClick={() => rejectPost()} disabled={aasm_state !== 'verified'}>
              {intl.get('REJECT_PROPERTY_ADMIN')}
            </button>
          </div>

          <p className='col-4 align-self-center d-none d-md-block'>
            {renderMessage(aasm_state, verify_visit_finished)}
          </p>

          <div className='col-md-4 col-6'>
            <button
              className='button-primary col-12'
              onClick={
                unit.register_type !== 'registered' ? () => publishPost() : () => approvedRegisterPost(id, history)
              }
              disabled={disabledButtonVerify(
                aasm_state,
                unit.register_type,
                verify_visit_finished,
                property_comments,
                can_be_approved,
              )}
            >
              {intl.get('PUBLISH_PROPERTY_ADMIN')}
            </button>
            {unit.register_type === 'registered' && (
              <p className='text-color-secondary small'>
                {!full_address_present && (
                  <>
                    * {intl.get('OWNER_FULL_ADDRESS_NO_PRESENT')}
                    <br />
                  </>
                )}

                {!identity_document_present && <>* {intl.get('OWNER_IDENTITY_DOCUMENT_NO_PRESENT')}</>}
              </p>
            )}
            {!property_comments && (
              <p className='text-color-secondary small mt-2'>
                <>* {intl.get('CANNOT_PUBLISH')}</>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };
  return <>{fetchingPost !== 'LOADED' ? renderLoading() : renderForm()}</>;
};

const mapStateToProps = (state: any) => {
  const { post, fetchingPost, changingPostState, documentsProcessed, error } = state.publish;
  const { name, rut, fetchingUser, user } = state.userProperty;
  return {
    post,
    fetchingPost,
    changingPostState,
    documentsProcessed,
    error,
    name,
    rut,
    fetchingUser,
    user,
  };
};
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onApprovePostRegistered: (postId: number, history?: any) => {
      dispatch(approvePostRegistered(postId, history));
    },
    onGetPost: (postId: number) => {
      dispatch(getPost(postId));
    },
    onGetUser: (ownerID: number) => {
      dispatch(getUser(ownerID));
    },
    onPublishPost: (postId: number, history: any) => {
      dispatch(publishPost(postId, history));
    },
    onRejectPost: (postId: number, history: any) => {
      dispatch(rejectPost(postId, history));
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onUpdateUnit: (id: number, values: any) => {
      dispatch(updateUnit(id, values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishPost);
