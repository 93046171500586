import { postData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import getPendingVisits from '../getPendingVisits'
import getPendingVisitsIds from '../getPendingVisitsIds'

interface VisitorToAssign {
  visitor_id: number
  unit_visit_id: number
}

const assignVisitors = (
  visitorsToAssign: any,
  setLoading: Function,
  role: string,
  visitorsSelected: Function,
  visitorsIds: Array<any>,
) => {
  return (dispatch: any) => {
    visitorsToAssign.map((visitorToAssign: VisitorToAssign) => {
      postData(
        `unit_visits/assign_visitor?visitor_id=${visitorToAssign.visitor_id}&unit_visit_id=${visitorToAssign.unit_visit_id}`,
      )
        .then((result) => {
          if (
            visitorsToAssign[visitorsToAssign.length - 1] === visitorToAssign
          ) {
            dispatch(getPendingVisits(role, 'pending'))
            dispatch(getPendingVisitsIds(role, visitorsIds, setLoading))
            visitorsSelected([])
          }
        })
        .catch((e) => {
          if (
            visitorsToAssign[visitorsToAssign.length - 1] === visitorToAssign
          ) {
            dispatch(getPendingVisits(role, 'pending'))
            dispatch(getPendingVisitsIds(role, visitorsIds, setLoading))
            visitorsSelected([])
          }
          toast(handleError(e))
        })
    })
  }
}
export default assignVisitors
