import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { getUsersDetailStart, getUsersDetailError, getUsersDetailSuc } from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUsersDetail = (id: number) => {
  return (dispatch: any) => {
    dispatch(getUsersDetailStart())
    getData(`users/${id}`)
      .then((result) => {
        dispatch(getUsersDetailSuc(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getUsersDetailError(e))
      })
  }
}
export default getUsersDetail
