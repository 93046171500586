import {
  approveRenterStart,
  approveRenterSuccess,
  approveRenterError,
} from './types'
import { putData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const approveRenter = (user_id: number, value: any) => {
  return (dispatch: any) => {
    dispatch(approveRenterStart())
    let authorized_amount = {
      renter_validation: {
        authorized_amount: value.authorized_amount_cents,
        authorized_amount_currency: value.authorized_amount_currency,
      },
    }
    putData(`renter_validations/${user_id}/verify`, authorized_amount)
      .then((result: any) => {
        toast(intl.get('RENTER_AUTHORIZED'))
        dispatch(approveRenterSuccess(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(approveRenterError(e))
      })
  }
}

export default approveRenter
