import DocumentTermsAndConditions from './documents/es-TermsAndConditions'; // aqui iria el documento en ingles en-TermsAndConditions
import us_fakePaymentMethod from './fake_payment_method/en-US';
import us_contractStates from './us_contractStates';

export default {
  ...us_contractStates,
  ...us_fakePaymentMethod,
  undefined: '',
  A_STEP:
    "We are one step away from completing your profile, now you only need to upload your documents. You don't need to do it now, but you won't be able to make an offer until you have completed the process.",
  AB: 'AB',
  ABOUT_TO_EXPIRE: "Are you sure you want to expire the credit approval? The tenant will need to resubmit their documentation to be approved again.",
  ABOUT_TO_VETO:
    "You are about to <strong> veto </strong> this user from being a renter in Tango. This will automatically send them an email informing them that they will not be able to use the platform's services. Do you confirm this action?",
  ABOUT_US: 'About us',
  ABOUT_YOURSELF: 'Tell the owner about yourself',
  ACCEPT_ANIMALS_A: 'Allows pets',
  ACCEPT_ANIMALS: 'Pets allowed?',
  ACCEPT: 'Accept',
  ACCEPTED_FOR_PLURALS: 'Accepted',
  ACCEPTED_OFFERS: 'You accepted a lease offer!',
  ACCEPTED: 'Accepted',
  ACCOUNT_NUMBER: 'Account number',
  ACCOUNTING_ADMIN: 'Accounting admin',
  ACCREDITATION: 'Accreditation',
  ACEPT_FORM:
    'By clicking Submit, you agree that you are the person whose information was entered above or that you have the express consent of that person to provide us thier information. In addition, you accept and acknowledge that the information you entered above will be used to answer your query to obtain more information about Tango Rents job offers. The use of this website implies acceptance of the privacy policy of Tango Rent.',
  ACTIONS: 'Actions',
  ACTIVE_FOR_PLURALS: 'Active',
  ACTIVE: 'Active',
  ACTIVE_ADMIN_CONTRACT_EXISTS: 'An active management contract already exists',
  AD_WITH_PROFESSIONAL_PHOTOS_AND_VIDEOS:
    "We create your listing with <span class='text-color-secondary'>professional photos and videos</span>",
  LISTING_WITH_PROFESSIONAL_PHOTOS_AND_VIDEOS:
    'We take photos, videos and plans of your property and create your professional listing',
  ADDITIONAL_INFORMATION: 'Additional information',
  ADDRESS_INSTRUCTIONS:
    'Select an address from the list.</br>If the property numbering starts with 0, do not enter it',
  ADDRESS: 'Address',
  ADMIN_SEND_DATA: 'You need to send your documents',
  ADMIN_VALIDATING_DATA:
    'An administrator is currently validating your information',
  ADMIN: 'Administrator',
  ADMINISTRATION_AND_INSURANCE: 'Administration and Rent guarantee',
  ADMINISTRATION_CONTRACT: 'Administration Contract',
  ADMINISTRATION_FEE: 'Administration fee',
  ADMINISTRATION_FEE_NEW_DISCOUNT: 'Descuento fee de administración',
  ADMINISTRATION_FEE_REGISTERED_DISCOUNT: 'Descuento fee de administración',
  OWNER_BROKERAGE_FEE_NEW_DISCOUNT: 'Brokerage fee discount for owner',
  RENTER_BROKERAGE_FEE_NEW_DISCOUNT: 'Brokerage fee discount for renter',
  OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT: 'Brokerage fee discount for owner',
  RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT: 'Brokerage fee discount for renter',
  ADMINISTRATION_FEE_DISCOUNT: 'Administration fee discount',
  DISCOUNT_PORCENTAGE_OWNER: '% Owner fee',
  DISCOUNT_PORCENTAGE_RENTER: '% Renter fee',
  ADMINISTRATION_OF_DEPOSIT: 'Administration of the security deposit',
  ADMINISTRATION: 'Administration',
  ADVANCED_FILTER: 'Advanced filter',
  ADVANCED_SEARCH: 'Advanced Search',
  AFTERNOON: 'Afternoon',
  AGENT_DESCRIPTION:
    'These are the days and hours available for a Tanguero to visit your property, choose your availability so that a renter can make a tour of your property.',
  AGENT_ID: 'Tanguero id',
  AGENT: 'Tanguero',
  ALARM: 'Alarm',
  ALMOST_THERE: 'Almost there!',
  ALL_RENTERS: 'All renters',
  ALL_SERVICES_INCLUDED:
    'All our services are included in your monthly payment',
  ALONE: 'Alone',
  AMERICAN: 'American',
  AMMOUNT_REQUIRED:
    'Sorry, the ammount of the rent to high, you can update your register for beening revaluate for our Tango team.',
  AMOUNT: 'Amount',
  APPROVED_AMOUNT: 'Approved amount to lease:',
  AND: 'And',
  ANY_QUESTION_AGENT:
    'If you have any questions you can contact the Tanguero at {agent} ',
  ANY_QUESTION_VERIFIER:
    'If you have any questions you can contact the photographer at {verifier}',
  APARTMENT: 'Apartment',
  APPROVE_PROPOSAL: 'Approve offer',
  APPROVED: 'Approved',
  APPROVED_UNIT: 'Approved',
  APRIL: 'April',
  APPROVE: 'Approve',
  ARE_YOU_OWNER: 'Are you the sole owner of the property?',
  AT_LEAST_7: 'Remember that your password must have at least 8 characters.',
  AT: 'at',
  ATTENTION: 'Attention',
  AUGUST: 'August',
  AUTHORIZED_AMOUNT: 'Authorized amount',
  AVAILABLE_NOW: 'Availability now?',
  AVAILABLE_PROPERTIES: 'Available Properties',
  AVAILABLE_SCHEDULE: 'The unit has an schedule available to be visited.',
  AVAILABLE_VISIT: 'Available for tours',
  AVALIABLE_FROM: 'Available from',
  AVERAGE_INCOME_LAST_3: 'Average income last 3 months amount',
  AVERAGE_SCORE_LAST_12_MONTHS: 'Average score last 12 months',
  BACK_BTN: '< Back',
  BACK_HOME: 'Back to home',
  BALCONY: 'Balcony',
  BANK_ACCOUNT_TYPE: 'Account type',
  BANK_ACCOUNT: 'Do you have a bank account?',
  BANK_INFORMATION: 'Bank information',
  BANK_NAME: 'Bank',
  BARBECUE: 'Barbecue',
  BASIC_DATA_A: 'Basic data',
  BASIC_DATA: 'Basic information',
  BASIC_DETAILS: 'Basic details',
  BATHROOM_FLOOR_MATERIALS: 'Bathroom',
  BATHROOMS_NUMBER:
    '{num, plural, =0 {No bathrooms} =1 {1 bathroom} other {# bathrooms}}',
  BATHROOMS: 'Bathrooms',
  NUMBER_BATHROOMS: 'Number of bathrooms',
  BE_A_DANCE: 'be a dance',
  BE_REFERRER: 'I want to refer someone',
  BE_TANGUERO: 'Be a Tanguero',
  BE_VERIFIER: 'Be a Photographer',
  BEDROOM_FLOOR_MATERIALS: 'Bedroom',
  BEDROOMS_NUMBER_A:
    '{num, plural, =0 {Studio} =1 {1 bedroom} other {# bedrooms}}',
  BEDROOMS_NUMBER: 'Number of  bedrooms',
  BEDROOMS: 'Bedrooms',
  BEFORE_CREATING:
    'Before creating your property let us explain all services by',
  BEFORE_MAKING_OFFER:
    'Before making an offer, you need to go to your profile, fill in your details and upload the required documents.',
  BETTERUSER_EXPERIENCE:
    "You can read the contract in detail from your computer, but in order to sign it you must do it from a <span class='text-color-secondary'>mobile device</span> through the link that we sent you by email.",
  BICYCLE_ROOM: 'Bicycle parking',
  BIRTHDATE: 'Birthdate',
  ASSIGNED: 'Assigned',
  BLOCKS_ASSIGNED: 'The blocks have been assigned',
  BLOCKS_NO_AVAILABLE: 'You have no blocks available for that day',
  BLOCKS_TAKEN: 'Block(s) has/have been taken',
  BLOCKS_TAKEN_UPDATE: 'Block(s) has/have been updated',
  BOTTOM_LABEL_USABLE_AREA:
    '* A Photographer from Tango will check the exact surface',
  BROKERAGE_FEE: 'Brokerage fee',
  BROKERAGE: 'Brokerage',
  BROKERAGE_FEE_OWNER: 'Brokerage fee',
  BROKERAGE_FEE_DISCOUNT_OWNER: 'Brokerage fee discount',
  BROKERAGE_INFO_1:
    'Remember, the brokerage plan will only allow you to see the receipts associated with the first payment of the lease and guarantee.',
  BROKERAGE_INFO_2:
    'You will receive the payment of the proportional value of the lease and the guarantee, in 10 business days from the payment of the tenant.',
  BROKERAGE_INFO_3:
    'Do not forget that the amount to pay the brokerage fee will be deducted as appropriate',
  BURNERS: 'Burners',
  BUILDING_SITE: 'This resource is not available',
  BROKER: 'Tanguero',
  BY: 'by',
  CLP: '$',
  C1A: 'C1a',
  C1B: 'C1b',
  C2: 'C2',
  C3: 'C3',
  CALENDAR_VISIT_INFO:
    'Mark your available dates and times on the calendar so that the owners can coordinate a visit',
  CALENDAR_VISIT: 'Visits Calendar',
  CANCEL: 'Cancel',
  CANT_OFFER:
    'You should schedule and make a tour before sending a lease proposal',
  CAPTURE_BACK: 'Go!',
  CARPET: 'Carpet',
  CARRY_OUT_TOURS:
    "We carry out virtual or face-to-face tours. With our <span class='text-color-secondary'>Tangobox</span> it is not necessary for you to be there",
  CERAMIC: 'Ceramic',
  CHEAPEST_FEE: 'We have the cheapest fee in the market',
  CHECK_LOGO: 'Check logo',
  CHECK_YOUR_INBOX_THANK_YOU:
    'Check your<span class="font-weight-bold"> inbox </span> and <span class="font-weight-bold"> verify your email address </span>in the email we just sent you.',
  CHECK_PASS_ERROR: 'Passwords do not match',
  CHECK_PASS: 'Password error, check password',
  CHECKING_ACCOUNT: 'Checking',
  CHILDISH_GAMES: 'Children playground',
  CHOOSE_DATE: 'Choose a date to start the rental',
  CI_BOTH_SIDES: 'CI both sides',
  CINEMA: 'Cinema',
  CLEAR_FILTERS: 'Clear filters',
  CLOSE: 'Close',
  CLOSED_CIRCUIT: 'Closed circuit',
  CLOSED: 'Closed',
  CODE_VALIDATE: 'Verification code',
  COLLABORATORS: 'Collaborators',
  CREATE_NEW_VISIT: 'Create new visit',
  COMMENT_DATE_EMPTY: "The comment or date can't be empty.",
  COMMISSION_ADMINISTRATION_AND_INSURANCE: '7% of monthly rent',
  COMMISSION_BROKERAGE: '50% of rental fee',
  COMMISSION_EXTRA_INFO:
    'Both charges are deducted from the rent that we collect monthly.',
  COMMISSION_WHEN_TO_PAY: 'Pay only when your property has been rented!',
  COMMISSIONS: 'Commissions',
  COMMON_EXPENSES_FILTER: 'Common expenses',
  COMMON_EXPENSES: 'Common expenses',
  COMMON_SPACES: 'Common spaces',
  COMMUNES_FOR_THE_MOMENT:
    'For the moment Tango is only available in the following ',
  COMMUNES_TITLE: 'They should be in the following communes:',
  COMMUNES: 'communes',
  COMPANY_SUPPORTS_US:
    'The company with the most global experience supports us',
  COMPLETE_DATA_OFFER:
    'You should complete your data before making an offer for this property',
  COMPLETE_DATA_SUCCESS:
    'You have finished filling in your details, now you can accept or reject the rental offer',
  COMPLETE_DATA:
    'You should complete your data before to accept or reject a rental offer. ',
  COMPLETE_YOUR_PROFILE_WE_NEED:
    'To complete your profile we need you to enter some data.',
  COMUNITY_TYPE: 'Kind of community',
  CONDITIONS_DESCRIPTION:
    'The Tango Brokerage and Administration contract is 1 only (we call it Administration Contract) and it has one condition that is important for you to know:',
  CONDOMINIUM: 'Condominium',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CONFIRM_PASSWORD: 'Confirm your password',
  CONFIRMATION: 'Confirmation',
  CONGRATS_YOU_RENTED:
    'You just signed the lease contract. Now you only need to make the payment of the guarantee, commission and first month of rent.',
  CONGRATS: 'Congratulations',
  CONSENT_CREDIT_INFO: 'Consent to use credit information',
  CONSIGNEE_APPROVAL:
    '{renter}, has assigned you as Guarantor and Co-debtor for the lease of a property through Tango.',
  CONSIGNEE_APPROVAL_REGISTERED:
    '{renter}, indicated us that you signed as Guarantor and Co-debtor in the property lease contract in {street_address}',
  CONSIGNEE_AUTH:
    'I AUTHORIZE TANGO TO ANALYZE MY INCOME INFORMATION PROVIDED BY (RENTER NAME) AND TO CONSULT MY DICOM PLATINUM 360 AND TRUORA BACKGROUND REPORTS',
  CONSIGNEE_FORM: 'Guarantor and co-debtor information',
  CONSIGNEE_STEP_ONE:
    'To accept this request you must allow us to analyze your income information provided by {renter} and allow us to consult your Dicom Platinum 360 and Truora background reports. You must also send us an image of your identity document from both sides',
  CONSIGNEE_STEP_TWO:
    'Note: The following authorization does not generate an obligation or designate you as a Guarantor and Co-debtor. Once the documents are approved, we will send you the complete lease contract that you can review and sign.',
  CONSIGNEE_STEP_ONE_REGISTERED:
    'The owner of this property entered the lease in Tango for us to manage. You should not worry about anything, simply give us authorization to analyze your financial information provided by {renter} and allow us to consult your Dicom Platinum 360 and Truora report. You must also send us an image on both sides of your identity card.',
  CONSIGNEE_STEP_TWO_REGISTERED:
    'Note: The following authorization does not generate an additional obligation to the one you already have as Guarantor and Co-debtor of the rental contract.',
  CONSIGNEE: 'Guarantor and co-debtor',
  CONSTRUCTION_YEAR: 'Construction year',
  CONTINUE: 'Continue',
  CONTRACT: 'Contract',
  CONTRACTS: 'Contracts',
  COPY: 'Copy',
  COSIGNEES_NUMBER: 'Number of guarantors and co-debtor',
  COSIGNER_INFORMATION: "Guarantor and co-debtor's information",
  COSIGNER_NAME: 'Name of guarantor and co-debtor',
  COSIGNER_NOTIFICATION:
    'If you added a guarantor and co-debtor, tell them that we will send them an email, requesting authorization to review their credit information.',
  COSIGNER_RUT: 'ID of guarantor and co-debtor',
  COUNTRIES: 'Countries',
  CAN_COMPLETE_YOUR_INFORMATION:
    'Then you can complete your information and upload your identity card',
  COUSIN: 'Cousin',
  COWORK: 'Cowork',
  CREATE_ERROR: 'Create error:',
  CREATE_MY_PROPERTY: 'Create my property',
  CREATE_NEW_USER: 'Create profiles',
  CREATE_PROFILE_SCHEDULE_TOURS:
    "Create your profile and schedule virtual or face-to-face tours with our <span class='text-color-secondary'>Tangueros</span>",
  CREATED: 'Created',
  CRIMINAL_RECORD: 'Criminal records',
  CURRENT_AMOUNT: 'Current amount',
  CURRENT_PASSWORD: 'Current password',
  CURRENT_SCORE: 'Current score',
  CURRENTLY_WHO_LIVES:
    'We ask this so we can facilitate the tour process. On apartments that are empty we can install a Tangobox so you don’t need be there on every tour.',
  CURTAINS: 'Curtains',
  CV: 'Resume',
  D: 'D',
  DATA_CONSIGNEE_TITLE: 'Upload the data of your guarantor(s) and co-debtor(s)',
  DATA_CONSIGNEE:
    'Remember that if you are going to sign with a guarantor and co-debtor, you must complete their details to move forward.',
  DATA_HAS_BEEN_SAVED: 'The data have been saved',
  DATA_SAVED: 'Data saved successfully!',
  DATE_FUTURE: 'Please, choose a date in the future',
  DATE: 'Date',
  DEATHLINE_CONSIGNEE:
    'Now we only need you to send us a photo of your identity document from both sides',
  DEBT_UNPAID_DETAIL: 'Debt unpaid detail',
  DECEMBER: 'December',
  DEFAULT_COVERAGE: 'Default coverage and management of the eviction process',
  DEFINE_AVAILABILITY: 'Define availability for visits',
  DEFINITION_CONSIGNE:
    'If you are going to sign with a guarantor and co-debtor, go to the view a guarantor and co-debtor at the top',
  DEFINITION_PET:
    'The definition of pets according to Tango corresponds to dogs, cats or caged animals that do not emit annoying noises or smells and that do not pose a danger to the community.',
  DELETE_DOCUMENT: 'Delete document',
  DELETE_IMAGE: 'Delete image',
  DELETE_UPDATE: 'Update document',
  DELIVERED: 'Delivered',
  DELIVERY: 'Delivery',
  DELIVERY_AND_REPLACEMENT_HELP:
    "We help you with the <span class='text-color-secondary'>delivery and replacement</span> of the property",
  DENIED: 'Rejected',
  DEPENDENT: 'Dependent',
  DESCRIPTION_ADMIN_AND_INSURANCE:
    "<p>We collect the rental payment through our platform.</p> <p>We also give you a deposit in which <span class='text-color-secondary'>we cover the payment of the rent for up to 6 months </span> of rent in case the renter does not pay, and we take care of all the legal processes in case of eviction.</p>",
  DESCRIPTION_ADMINISTRATION:
    'After this process, leave the property in our hands!',
  DESCRIPTION_BROKERAGE:
    "We publish, show, lease and deliver your property! We review the ability to pay and profile of potential renters. In addition, <span class='text-color-secondary'>the contract signing process is 100% online</span>.",
  CONTRACT_TERM: 'The lease contracts have a renewable duration of',
  NUMBER_OF_MONTHS_CONTRACT: '12 months',
  DESCRIPTION_CREATE_PROPERTY:
    "Create the property in Tango in <span class='text-color-secondary'>5 minutes</span> by giving us basic details of your property and without the need to upload photos.",
  DESCRIPTION_PROPOSALS:
    "<p>You will receive the rental proposals through our platform and will be able to see the <span class='text-color-secondary'>general information of the renter</span>. We will also give you a Tango score so you can make an informed decision of whether to approve or not.</p><p>Remember that all Tango approved renters go through an in-depth credit review.</p>",
  DESCRIPTION_PUBLISH_PROPERTY:
    "Our photographer will upload all the property information in <span class='text-color-secondary'>less than 48 hours </span>and your property will be published.",
  DESCRIPTION_SIGNATURE:
    "Review and <span class='text-color-secondary'>sign the Lease contract</span> and the Administration contract with <span class='text-color-secondary'>Tango online</span>.",
  DESCRIPTION_VERIFY_INFO:
    "<p> Schedule through Tango a <span class='text-color-secondary'>visit from a photographer</span> to come to your property and gather all the necessary information, take photos / videos and create a complete and attractive listing.</p><p>The photographer will also install a <span class='font-weight-bold tangobox' data-tooltip='The Tangobox is a safe that contains the keys and is placed outside your property and enables us to coordinate tours without the need for you to go'>Tangobox</span> on your property as long as it is empty (no furniture or renters).</p>",
  DESCRIPTION_VISITS:
    "<p>Our tangueros will direct all tours to the property, regardless of whether the property has a Tangobox or not.</p><p>In the event that the property does not have a Tangobox, you must indicate your time availability through our platform so that the renters can <span class='text-color-secondary'>schedule tours online</span>. All tours will be notified to you!</p>",
  DESCRIPTION_PROPERTY: 'What makes this property unique?',
  DETAILS: 'Details',
  DINING_ROOM: 'Dining room',
  DISCOUNT:
    "Receive 5% of the first month's rent once the referred property is rented.",
  DISCOVER_EVERY_DETAIL: 'Discover every detail of your next home',
  DISCOVER_MORE: 'Discover more',
  DISH_WASHER: 'Dishwasher',
  DIVORCED: 'Divorced',
  DO_YOU_KNOW_A_RENTER:
    'Do you know someone who wants to lease their property?',
  DO_YOU_SOMEONE_WHO_HAS_A_PROPERTY:
    'Do you know someone who has a property and wants to rent it?',
  DOC_APPROVED: 'Approved documents',
  DOC_PENDING: 'Documents to approve',
  DOCUMENT_1_INFO:
    'You can obtain it by following these steps: www.sii.cl / My SII / My Real Estate / [Enter the Role of the home] / Go to the menu to pay contributions or make other queries / Check appraisals and certificates / Check background information on a root / [Here you have to enter ole] / Search / [Print in PDF]',
  DOCUMENT_1: 'Real Estate Certificate or SII tax collection receipt',
  DOCUMENT_2_INFO: 'You can upload a photo of the last payment',
  DOCUMENT_2: 'SII tax collection receipt',
  DOCUMENT_3_INFO:
    'You must download and print the file, then complete all the fields and upload a photo',
  DOCUMENT_3: 'Simple power',
  DOCUMENT_APPROVED_ADMIN: 'Document approved by Admin, this cannot be deleted',
  DOCUMENT_APPROVED: 'Document approved',
  DOCUMENT_CHILE: 'Do you have valid documentation to work in Chile?',
  DOCUMENT_DELETED: 'Document(s) deleted successfully',
  DOCUMENT_REJECTED: 'Document rejected',
  DOCUMENT_SENT: 'Your documents were successfully sent',
  DOCUMENT_SUCCESSFULLY_UPLOADED: 'Document uploaded succesfully',
  DOCUMENT_TERMS_AND_CONDITIONS: DocumentTermsAndConditions,
  DOCUMENTS: 'Documents',
  PROPERTY_DOCUMENTATION: 'Property documentation',
  DONT_CONSIGNEE: 'Do not have guarantor and co-debtor',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  DONT_HAVE_AVAILABLE: "You don't have available properties",
  DONT_HAVE_PENDING: "You don't have properties awaiting publication",
  DONT_HAVE_RENTED: "You don't have rented properties",
  DONT_HAVE_SECOND_SURNAME: "I don't have a second surname",
  DONT_HAVE_VISIT: 'You do not have tours',
  DONT_NEED_GUARANTOR: "You don't need a guarantor",
  DONT_PAY_UNTIL_2: "You don't pay until your property is rented",
  DONT_PAY_UNTIL: 'You do not pay until your property is rented!',
  DONT_SLEEP: 'do not sleep',
  DOOR_BELL: 'Doorbell',
  DOWNLOAD_CONTRACT: 'Download contract',
  DOWNLOAD_DOCUMENT: 'Download document',
  DOWNLOAD_RECEIPT: 'Download receipt',
  DOWNLOAD_FILE: 'Download File',
  DOWNLOADED_FILE: 'Downloaded File',
  DROP_FILE: 'Drop your file',
  DROP_IMAGES_HERE:
    'Drop your images here, or click on the box and select the files.',
  DROP_VIDEOS_HERE:
    'Drop your videos here, or click on the box and select the files.',
  DRY_MACHINE: 'Dry machine',
  DUE_DATE: 'Due date',
  DUPLEX: 'Duplex',
  E: 'E',
  EAST: 'East',
  EASY: 'That easy!',
  EDIT_PROFILE: 'Edit data',
  EDIT: 'Edit',
  ELECTRIC: 'Electric',
  ELECTRICITY: 'electricity',
  ELEVATOR: 'Elevator',
  EMAIL_ERROR_FORGETPASS: 'Email error, check your email',
  EMAIL_PATTERN_ERROR: 'Enter a valid email',
  EMAIL_SENT: 'The email has been sent',
  EMAIL_TANGO: 'ayuda@tango.rent',
  EMAIL: 'Email',
  EXACT_CURRENT_ADDRESS: 'Exact current address',
  ENTER_INFORMATION_EXTRA_ADDRESS: 'Optional',
  EMPLOYEE: 'Worker status',
  ENABLE_RENTER: 'Enable to rent',
  END: 'End',
  ENTER_COMMUNE_CITY: 'Enter Commune or City',
  ENTER_IT_HERE: 'Enter it here',
  ENTER_NEW_PASSWORD:
    'Enter your new password. <br/> Make sure the fields match.',
  EQUIPMENT: 'Equipment',
  ERROR_404: 'Error 404',
  ERROR_ACTION: 'Error, you can not do this action',
  ERROR_SENDING_EMAIL: 'Error sending email',
  ERROR: 'ERROR',
  EVALUATE_OFFERS: 'Evaluate your offers',
  EVERCLEAN_INDICATOR: 'Everclean indicator',
  EXAMPLE_EMAIL: 'Email@example.com',
  EXPIRE: 'Expire',
  EXPIRE_METHOD_INFO: "Use this option to <span class='font-weight-bold'>prematurely expire</span> an enabled profile.",
  EXCLUSIVITY_1:
    'At Tango we put a lot of effort into creating a high quality listing so that your property is rented as quickly as possible.',
  EXCLUSIVITY_2:
    "That is why we require to have exclusivity to lease your property for <span class='text-color-secondary'>3 months</span>.",
  EXCLUSIVITY_3:
    'If we are unable to lease your property in this period, we lose the right to that exclusivity.',
  EXCLUSIVITY: 'Exclusivity',
  EXPERIENCED_TEAM:
    'Our team with the most experience in the market will be supporting you to move from where you are at all times',
  EXTENDED_FAMILY: 'Extended family',
  FAMILY_GROUP: 'Family group',
  FAMILY_WITH_CHILDREN: 'Family with children',
  FAMILY_WITHOUT_CHILDREN: 'Family without children',
  FEATURED_PROPERTIES: 'Featured Properties in Tango',
  FEATURED: 'Featured Properties',
  FEBRUARY: 'February',
  FILE_DELETED: 'File deleted',
  FILE_UPLOADED_TO_CONTRACT:
    "File uploaded! You'll be automatically redirected to the contract",
  FILE_UPLOADED_TO_POST:
    "File uploaded! You'll be automatically redirected to the property",
  FILE_UPLOADED_TO_CALENDAR:
    "File uploaded! You'll be automatically redirected to the scheduled visit",
  FILE_UPLOADED_TO_PENDING:
    "File uploaded! You'll be automatically redirected to the pending properties to publish",
  FILE_UPLOADED: 'File was uploaded!',
  FILE_UPDATED: 'File was updated!',
  FILL_OUT_FORM_REFERRED: 'Fill out the form with the data of your referral',
  FILTER_BY: 'Filter by',
  FILTER: 'Filter',
  FIND_BY: 'Find by',
  FIND_YOUR_NEW_HOME: 'Find your new home',
  FINISH_HOUR: 'Finish hour',
  FINISH_VISIT: 'Finish visit',
  FIRST_SURNAME: 'First surname',
  SURNAMES: 'Surnames',
  FLOATING_FLOOR: 'Floating floor',
  FLOOR_A: 'Floor',
  FLOOR_COUNT_APARTMENT: 'What floor is it on?',
  FLOOR_LOCATED: 'It is located in the floor',
  FLOOR_MATERIALS: 'Floor Materials',
  FLOORS_A: 'Floors',
  FLOORS_COUNT: 'How many floors does it have?',
  FLOORS: 'Stories',
  FOLLOW_SIGNING_STEPS: 'You need to follow the following steps to sign',
  FORGET_ABOUT_COMPLICATED: 'Forget about the complicated rental process.',
  FORGET_PASSWORD: 'Forgot password',
  FORGOT_PASS: 'Forgot your password?',
  FORGOT_PASSWORD_INSTRUCTIONS_TOKEN: 'Check your inbox',
  FORGOT_PASSWORD_INSTRUCTIONS_TOKEN_INFO:
    'We sent you an email to continue the process.',
  FORGOT_PASSWORD_INSTRUCTIONS:
    'Enter your email and we will send you a code to reset your password.',
  FORM_INFO: 'Fill in the following form to record your data',
  FORM_READY: 'Form ready!',
  FORM: 'Form',
  FRIDAY: 'Friday',
  FRIDGE: 'Fridge',
  FRIEND: 'Friend',
  FROM: 'From',
  FULL_ADDRESS: 'Full address',
  FULLDAY: '24/7',
  FURNISHED: 'Furnished',
  GARDEN: 'Garden',
  GAS: 'gas',
  GLOBAL_MARKETS: 'Global markets',
  GO_TO_DOCUMENTS: "Let's go!",
  GO_TO_RENTER_PROFILE: 'Go to my profile',
  NEW_RENTER_PROFILE_1:
    'The next step for your rent to be paid through Tango is that you fill out your <span class="font-weight-bold">Renter Profile</span>',
  NEW_RENTER_PROFILE_2:
    'For this we will need you to give us information about yourself as <span class="color-secondary">renter</span> and the <span class="color-secondary">guarantors and co-debtors</span> that appear in the lease.',
  GO_RENTER_PROFILE: 'Go to the Renter Profile',
  GO_TO_PROPERTY: 'Go to the property',
  GRANDPARENTS: 'Grandparent',
  GUARANTEE_AND_CODEBTOR_PLURAL: 'Guarantors',
  GUARANTEE_AND_CODEBTOR_SINGULAR: 'Guarantor',
  GYM: 'Gym',
  HAS_PET: 'Do you have a pet?',
  HAVE_READ_CONTRACT_AND_INFO:
    'I declare that I have read the contract and checked that my personal information, property data and conditions are correct',
  HEATING: 'Heating',
  HEIGHT_FLOOR_CEILING: 'Floor to ceiling height',
  HELLO: 'Hello',
  HELP_CENTER: 'Help center',
  HELP_YOU_RENT_HOME_1: 'We will help you rent',
  HELP_YOU_RENT_HOME_2: 'your properties!',
  HELP: 'Help',
  HOME_APPLIANCES: 'Home appliances',
  INVALID_FIELDS: 'You need to fill in data and/or you have invalid data',
  HOUR_NOTIFICATION:
    'Within the next 48 hours we will notify you once you have been approved as a renter in Tango.',
  HOURS_CONTRACT_SIGNING_COSIGNERS:
    'You have 48 hours for you and your cosigners to sign the lease and pay',
  HOURS_CONTRACT_SIGNING: 'You have 48 hours to sign the administration lease.',
  HOURS_CONTRACT: 'You have 48 hours to sign the lease',
  HOURS_SCHEDULED: 'It has been saved',
  HOUSE: 'House',
  HOW_DOES_TANGO_WORK: 'How does Tango work?',
  HOW_TANGO_WORKS:
    "How does Tango work<span class='text-color-secondary'>?</span>",
  I_AGREE: 'I understand',
  I: 'Me',
  INFORM_WHEN_COSIGNERS_SIGN: 'We will inform you when everyone signs so you can make the payment.',
  ID_DOCUMENTS_COMPLETED: 'ID documents completed',
  ID_REQUIRED_INFO_MOBILE:
    'In order to tour a property, we first need you to upload your identity document.',
  ID_REQUIRED_INFO:
    "To be able to lease this property, you must go to <strong> 'My Account' </strong> from the navigation menu, section <strong> 'Identity document' </strong> and upload your ID document",
  ID_DATA_REQUIRED_INFO:
    "To continue with the evaluation process, you must first upload your identity document. In the <strong>'Identity document'</strong> section.",
  ID_REQUIRED:
    "<p class='text-color-secondary mt-2'>Remember to do this from a mobile device</p>",
  IDECLARE:
    'I declare that I do not have a close relationship, familial or otherwise, with the renter or with the guarantor and co-debtor(s).',
  IDENTITY_CARD_BACK: 'Identity card back',
  IDENTITY_CARD_FRONT: 'Identity card front',
  IDENTITY_DOCUMENT: 'Your identity document',
  IDENTITY_DOCUMENT_TITLE: 'Identity document',
  IDENTITY_VERIFIED: 'Your identity was verified',
  IDK_MEASUREMENTS:
    'If you do not know exact measurements, do not worry, the photographer will verify them during the visit',
  IF_OWNER: "If you're an owner",
  IF_RENTER: "If you're a renter",
  INCLUDED: 'Included',
  INCLUDES_BASIC_SERVICES: 'Does the price include electricity, water and gas?',
  INCLUDES_COMMON_EXPENSES: 'Common expenses?',
  INCORRECT_INFO: 'In case any information is not correct, write to us at ',
  INDEPENDENT: 'Independent',
  INDOOR_POOL: 'Indoor pool',
  INFO_BASIC_SERVICES: 'if it does not include any, leave blank',
  INFO_BATHROOM: 'Does not include service bathroom',
  INFO_BEDROOM: 'Does not include service bedroom',
  INFO_CONTACT: 'Contact information',
  INFO_COSINGER:
    'The guarantor and co-debtor endorses the obligation to pay the rent, so that if the main debtor does not pay, the creditor can collect the entire debt and not just a part of it from the joint guarantor and co-debtor',
  INFO_PROFILE:
    'Dependent workers: last 3 salary settlements or certificate of AFP contributions last 3 months Independent workers: Certificate of AFP contributions of the last 6 month or service invoices of last month + bank statements',
  INFO_PROLE_DEPENDENT:
    'Last 3 salary settlements or certificate of AFP contributions last 3 months ',
  INFO_PROLE_INDEPENDENT:
    'Certificate of AFP contributions of the last 6 months or service invoices of last 6 months ',
  INFO_PROLE_INDEPENDENT2: 'Bank statements',
  INFO_UPLOAD_FILES:
    'Attach the following documents to validate ownership of the property. You can upload a pdf with all the files together or each one separately. The format can be in PDF, JPG or PNG format.',
  INFORMAL: 'Informal',
  INFORMATION_UPDATED: 'Information updated',
  INSERT_CODE: 'Enter the code that we have sent to the email',
  INSERT_MORE_CHARACTERS: 'Insert more than 10 characters',
  INSERT_VALID_MAIL: 'Insert valid mail',
  INVALID_ADDRESS: 'Invalid Address. You must enter an address with a number',
  INVALID_ADDRESS_GENERIC: 'Invalid Address.',
  INVALID_MARITAL_STATUS: 'Invalid marital status.',
  INVALID_COMMUNE:
    'Invalid commune. Unfortunately Tango is not working in this particular commune',
  INVALID_FORMAT: 'Invalid format',
  INVALID_RUT: 'Invalid Rut',
  INVENTORY_APPROVED: 'Inventory approved',
  INVENTORY_NOT_UPLOADED: 'Inventory not uploaded',
  INVENTORY_UPLOADED: 'Inventory uploaded successfully',
  IS_EMPTY: 'Empty',
  IS_OWNER:
    'If you do not own the property, you are requested a simple power of attorney in the name of the owner',
  IS_REQUIRED: 'This field is required',
  IVA: 'IVA', 
  JANITOR_PHONE_NUMBER: 'Concierge phone number',
  JANITOR: 'Concierge',
  JANUARY: 'January',
  JOB_NEWSLETTER: 'Job newsletter',
  JOB_OPPORTUNITY: 'Are you looking for a job opportunity?',
  JULY: 'July',
  JUNE: 'June',
  KITCHEN_FLOOR_MATERIALS: 'Kitchen',
  KITCHEN_OPTION: 'Kitchen',
  LABEL_INNER_AREA: 'Inner surface',
  LABEL_TERRACE_AREA: 'Terrace surface',
  LABEL_TERRAIN_AREA: 'Ground surface',
  LABEL_TOTAL_AREA: 'Total surface',
  LABEL_AREA: 'Surface',
  LABEL_VALUE: 'Monthly Rent Amount',
  LAST_AMOUNT: 'Last amount',
  LAST_OFFER: `You're about to send your <span class="text-danger">last</span> lease proposal`,
  LAST_PAYMENT_AMOUNT: 'Last payment amount',
  LAST_PAYMENT_DEBT_TYPE: 'Last payment debt type',
  LAST_PAYMENT_DUE: 'Last payment due',
  LAUNDRY_ROOM: 'Laundry room',
  LAUNDRY: 'Laundry',
  LAWSUITS: 'Lawsuits',
  LEASE_CONTRACT: 'Lease contract',
  LEAVE_PROPERTY_TO_US:
    "Leave your property in our hands and pay once it's rented",
  LETS_START: "Let's get started<span class='text-color-secondary'>!</span>",
  LIST: 'List',
  LISTING: 'Listing',
  LIVE_IN_PARTNER: 'Live in partner',
  LIVE_IN_THE_PROPERTY: 'she/he is going to live in the property?',
  LIVING_FLOOR_MATERIALS: 'Living / Dining room',
  LIVING_ROOM: 'Living / Dining room',
  LOADING: 'Loading...',
  LOGIN_ERROR: 'Login error, check your email and password',
  TOKEN_EXPIRED: 'Your verification code has expired, request it again ',
  LOGIN: 'Login',
  LOGIN_1: 'Login',
  LOGOUT: 'Logout',
  MAIN_AREA: 'Main area',
  MAIN_FEATURES: 'Main Features',
  MAP: 'Map',
  MARCH: 'March',
  MARITAL_STATUS: 'Civil State',
  SELECT_MARITAL_STATUS: 'Select your marital status',
  MARRIED: 'Married',
  MAX_CAPACITY: 'Max capacity',
  MAX_SCORE_NUMBER: 'The number must be less than the one entered',
  MAX_TWO_HOURS:
    'You can only choose a time slot within the next <span class="text-color-secondary">two weeks</span>',
  MAY: 'May',
  MESSAGE_SENT_TO_OWNER: 'Message sent to the owner',
  MESSAGES: 'Messages',
  MICROWAVE: 'Microwave',
  MIN_CHARS_PASSWORD: 'Use at least 8 characters',
  MIN_SCORE_NUMBER: 'The number must be greater or equal to 0',
  MINIMUM_AUTHORIZED_AMOUNT: 'The minimum amount to authorized is $200.000',
  MINIMUM_PRICE:
    'The monthly rent minimum amount authorized in Tango is <strong> $200.000 {priceInCLF}</strong>',
  MINIMUM_RENT_TITLE: 'The monthly rent has a minimum value of:',
  MISSING_DOCUMENTS: 'You have pending documents to upload!',
  MONDAY: 'Monday',
  MONTH: '{num, plural,=1 {1 month} other {# months}}',
  MONTHLY_RENT_PAYMENT: 'Pago de renta mensual los días 10 de cada mes',
  MONTHLY_RENT_VALUE: 'Of the monthly rent value',
  MORE_BEDROOMS_IN_SUITE: "You can't have more bedrooms in suite than total",
  MORNING: 'Morning',
  MULTIUSE_ROOM: 'Multipurpose room',
  MUST_BE_OVER_18: 'You must be over 18 years old',
  MUST_SEE_DOCUMENT_BEFORE_VALIDATE: 'Mus see document before validate',
  MY_ACCOUNT: 'My account',
  MY_CURRENT_LEASE: 'My current lease',
  DELIVERS: 'Delivers',
  RESTITUTIONS: 'Restitutions',
  MY_DOCUMENTS: 'My documents',
  MY_BANK: 'Bank data',
  MY_PROFILE: 'Profile',
  MY_PROPERTIES: 'My properties',
  MY_VISITS: 'My visits',
  NEW_VISIT_DATA: 'New visit data',
  NAME: 'Name',
  USERNAME: 'User Name',
  USERS: 'Users',
  NAMES: 'Names',
  NATIONALITY: 'Nationality',
  NEW: 'Full',
  NEW_PASSWORD: 'New password',
  NEW_UNIT_INFO:
    'Enter your property data to be <br> verified by the Tango team.',
  NEW_UNIT_TITLE: 'New unit',
  NEW_UPLOAD_PHOTOS: 'New uploaded photos',
  NEW_UPLOAD_VIDEOS: 'New uploaded videos',
  NEW_USER: 'Create new user',
  NIECE_NEPHEW: 'Niece/Nephew',
  NIGHT: 'Night',
  NO_CHARGES: 'No charges',
  NO_CONCIERGE: 'No concierge',
  NO_CONTRACTS: 'No active contracts',
  NO_DOCUMENT: 'No document',
  NO_HEATING: 'No heating system',
  NO_PAYMENT: "You don't have payment history",
  NO_PAYMENTS: 'No payments',
  NO_PHOTOS: 'No photos',
  NO_POSTS: "You don't have posts",
  NO_PROPERTY_SECTION: 'There are no properties in this section',
  NO_PROPERTY: "You don't have property proposals",
  NO_RENTERS: 'No renters',
  NO_RESULTS_MATCH:
    'At the moment we do not have properties available in this commune. You can get better results by consulting other communes',
  NO: 'No',
  NON_PAYMENT_ORDERS: 'Non payment orders',
  NORTH: 'North',
  NOT_AVAILABLE_BLOCKS: 'Not available blocks',
  NOT_AVAILABLE_DAY: 'There are no schedules available for this day',
  NOT_AVAILABLE: 'Not available',
  NOT_COMMON_SPACES: 'The property does not have common spaces',
  NOT_EMPLOYED: 'Not employed',
  NOT_HOME_APPLIANCES: 'The property does not have home appliances',
  NOT_PAID: 'They are not paid',
  NOT_RENT: 'You have not yet rented a property in Tango.',
  NOT_VALIDATED: 'Not validated',
  NOVEMBER: 'November',
  NOW_CREATE_PROPERTY: 'Now you can create your property in',
  NOW_SCHEDULE_WITH_VERIFIER:
    'Now you must schedule an appointment with a photographer so he/she can add details and photos to your listing.',
  NOW_SHARE_CODE: 'The code has been copied. You can now share it!',
  NUMBER_ACCOUNT_INFO: 'Here we will deposit your rent',
  NUMBER_ACCOUNT_INFO_V2:
    'This account will be use for payments (referral services/lease)',
  OCTOBER: 'October',
  OCUPATION: 'Profession',
  OFFER_ACCEPTED: 'Offer accepted',
  OFFER_ACEPTED:
    'The offer has been accepted! The next step is for you to digitally sign the lease and administration contract. You have 48 hours to do this. You only need to have your identity card.',
  OFFER_CANCELED: 'Offer canceled',
  OFFER_DETAILS: 'Offer Details',
  OFFER_MADE: 'Offer made succesfully!',
  OFFER_ACCEPTED_MODAL: 'You have successfully passed the proposal. Now you can go to sign the administration and lease contract.',
  OFFER_PLACEHOLDER:
    'Tell him about who you are, who you are going to live with, where you work, why you are moving, or whatever you want!',
  OFFER_RECEIVED: 'Offer received',
  OFFER_REJECTED:
    'The offer was rejected. Your property will continue to be available in Tango.',
  OFFER_TITLE_INFO:
    'The property will remain available until both parties have signed the contract. All lease contracts signed through Tango have a 12 months term, renewable.',
  OFFER_TITLE:
    'You are about to submit a rental proposal! <br> <small class="text-danger">Remember that you can only make 3 proposals. <span class="font-weight-bold text-dark">Proposals left: {valueCount}</span></small>',
  ON_DAY: 'on',
  ONE_FLOOR: 'One floor',
  ONE_MONTH_RENT: '50% of rental fee',
  ONE_STEP_MISSING: 'One step missing!',
  ONLINE_CONTRACT: 'Online signing of the contract',
  ONLINE_CONTROL: 'Online control panel to review requests and payments',
  ONLNE_PAYMENT: 'Your payments are online, with a credit or debit card',
  ONLY_BEDROOMS: 'Bedrooms only',
  ONLY_DAY: 'Only day',
  ONLY_NIGHT: 'Only night',
  ONLY_TWO_IMAGES_ALLOWED: 'You should only send 2 images',
  OPEN_AMERICAN: 'Open / American',
  OPEN_MOBILE_ID: 'You must do this step from your mobile device.',
  OPEN_MOBILE: 'Open this page on a mobile device.',
  OPEN_MOBILE_ID_QR:
    'Scan this QR code to continue with the proccess from your mobile device.',
  OPEN_MOBILE_QR:
    "Open this page on a mobile device, <span class='font-weight-bold'>scan this QR code.</span>",
  OPERATIONS_ADMIN: 'Operations admin',
  OR: 'or',
  ORDER_BY_HIGHEST: 'Highest price',
  ORDER_BY_LOWEST: 'Lowest price',
  ORDER_BY_MOST_RECENT: 'Most recent',
  ORDER_BY_OLDEST: 'Oldest',
  ORDER_BY: 'Order by',
  ORIENTATION: 'Orientation',
  OTHER_CONDITIONS: 'Other conditions',
  OTHER_PROOF_OF_INCOME_1: 'Other proof of income 1',
  OTHER_PROOF_OF_INCOME_2: 'Other proof of income 2',
  OTHER: 'Other',
  OTHERS: 'Common spaces',
  OUR_PLATFORM_IS_DIGITAL:
    'Our platform is 100% digital so you can rent without moving from where you are',
  OUR_SERVICES_GUARANTEE:
    'Our services guarantee the security of owners and renters throughout the rental process',
  OUTDOOR_POOL: 'Outdoor pool',
  OVEN: 'Oven',
  OWNER_DOCUMENT: 'Simple power',
  OWNER: 'Owner',
  PAGE_NOT_FOUND: 'Page not found',
  PAID_SEPARATELY: 'They are paid separately',
  PARENT_IN_LAW: 'Parent-in-law',
  PARENT: 'Parent',
  PARKING_LOT_NUMBER: 'Number/Letter',
  PARKING_LOT: 'Parking spaces',
  PARKING_SPACES: 'Parking spaces',
  PARQUET: 'Parquet',
  PARTNER_RELATIONSHIPS: 'Partner relationships',
  PASSWORD: 'Password',
  PAY_RENT: 'Pay your rent',
  PAY: 'Pay',
  PAYMENT_BUTTON: 'See invoice',
  GROSS_PAY: 'Gross pay',
  SUCCESS_FEE: 'Success fee',
  SUCCESS_FEE_DISCOUNT: 'Success fee discount',
  COLLECTION_FEE: 'Brokerage fee',
  COLLECTION_FEE_DISCOUNT: 'Brokerage fee discount',
  PAYMENT_DATE: 'Payment date',
  PAYMENT_DETAIL: 'Payment detail',
  PAYMENT_HISTORY: 'Payment history',
  PAYMENT_TITLE_2: 'Payments made',
  PAYMENT_TITLE: 'Payment to be made',
  PAYMENTS: 'Payments',
  PENDING_DELIVERS: 'Pending to deliver',
  PENDING_DOCUMENT_INFO:
    'In case you do not have pending documents, wait for the approval of a Tango administrator.',
  PENDING_DOCUMENT:
    "Check your properties' details, you have pending documents to send!",
  PENDING_IDENTITY_DOCUMENTS:
    "Now it's time to schedule a visit with the photographer, we have sent an email with the instructions.",
  PENDING_IDENTITY_DOCUMENTS_MOBILE: 'You must upload your identity document.',
  PENDING_IDENTITY_DOCUMENTS_DESKTOP:
    "You must upload your identity document. <span class='text-color-secondary'>You must do this step from a mobile device<span/>.",
  PENDING_PUBLISH: 'Pending publication',
  PENDING_RENTERS_MESSAGE: "You don't have renters pending approval",
  PENDING_RENTERS: 'Pending approval',
  PENDING: 'Pending',
  PENDING_UNIT: 'Pending',
  PENTHOUSE: 'Penthouse',
  PERIOD_1:
    "The Tango Administration contract has a minimum term of <span class='text-color-secondary'>24 months</span>.",
  PERIOD_2:
    'This will be automatically renewed only if there is a current lease contract through Tango.',
  PERIOD_3:
    "If the <span class='text-color-secondary'>24-month</span> period has passed and the property lease contract expires, the Administration contract continues only if the owner requests it.",
  PERIOD: 'Period',
  PERSONAL_INFORMATION: 'Personal data',
  PERSONAL_PHOTO: 'Personal photo',
  PERSONS: 'Persons',
  PET_AREA: 'Pet area',
  PHONE_NUMBER: 'Mobile',
  PHONE: 'Phone',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  PHONE_NUMBER_REQUIRED: 'Enter a valid phone number',
  PHOTOS: 'Photos',
  POLICY_PRIVACY_TITLE: 'Privacy policies',
  POLICY_PRIVACY:
    'The use of this website implies acceptance of the privacy policies of Tango Rent',
  PORCELAIN: 'Porcelain',
  POST_DETAILS: 'Post details',
  POSTS: 'Posts',
  POTENTIAL_RENTER_MESSAGE: 'Potential renter message',
  PRESS_NEXT_TO_REPEAT:
    "Once scanned, press next to repeat the steps for the document's back side.",
  PRICE: 'Price',
  PRICES: 'Prices',
  PRIVACY_POLICIES: 'Privacy policies',
  PRIVATE_COURTYARD: 'Private courtyard',
  PRIVATE_JACUZZI: 'Private jacuzzi',
  PRIVATE_POOL: 'Private pool',
  PROFESSION: 'Profession',
  PROFESSIONAL_VIDEOS: 'Professional video and photo session',
  PROFILE_CREATED: 'Profile created successfully',
  PROFILE_IS_EXPIRED:
    'Unfortunately your profile is expired, please update your documents',
  PROFILE_REJECTED: 'Unfortunately, your profile has been rejected',
  PROFILE_SAVED: 'Profile saved',
  SAVE_BANK_DATA: 'Save data',
  PROOF_OF_INCOME_CONSIGNEE_1: 'Proof of income guarantor and co-debtor 1',
  PROOF_OF_INCOME_CONSIGNEE_2: 'Proof of income guarantor and co-debtor 2',
  PROOF_OF_INCOME_INDEPENDENT: 'Proof of income independent',
  PROOF_OF_INCOME: 'Proof of income',
  PROPERTIES_NUMBER: 'Properties number',
  PROPERTIES_THAT_BEST_SUIT:
    'Below are the properties that best suit your <br> search according to the filters applied',
  PROPERTIES_TO_BE_RESTORED: 'Properties to be restored',
  PROPERTY_AND_HOUSE_DESCRIPTION:
    'Condominium refers to houses or apartments within a complex.',
  PROPERTY_COMMENTS: 'Property comments',
  PROPERTY_DATA: 'Property data',
  PROPERTY_DETAIL: 'Property detail',
  PROPERTY_INVENTORY: 'Property inventory',
  PROPERTY_PROPOSALS: 'Property proposals',
  PROPERTY_ROLE: 'Property role',
  PROPERTY_TO_VISIT: 'Property to visit',
  PROPERTY_TYPE: 'Property type',
  PROPERTY_UPDATED: 'Property updated successfully',
  PROPOSAL_DATE: 'Proposal date',
  PROPOSALS_MADE: 'Proposals made',
  PROPOSALS: 'Proposals',
  PROPOSED: 'Proposed',
  PROTESTS_DELINQUENCIES: 'Protests, Late payments',
  QUESTION_PROTESTS_DELINQUENCIES: 'Protests and/or late payments?',
  PUBLICATION_PENDING: 'Publication pending',
  PUBLISH_ERROR: "<p style='font-weight:600; color:red' >Publish error</p>",
  PUBLISH_PROPERTY_ADMIN: 'Publish property',
  PUBLISH_PROPERTY: 'Publish your property!',
  PUBLISH_YOUR_PROPERTY: 'Publish your property',
  PUBLISH: 'Publish',
  PUBLISHED_AVAILABLE: 'Available',
  PUBLISHED_DESCRIPTION:
    'The unit is published once the information, photos and videos are reviewed and approved by the Tango team within a maximum period of 72 hours after verification.',
  PUBLISHED: 'Published',
  PUBLISHING_PROPERTY: 'Publishing the property',
  PUBLISHED_ADVERT_GALLERY: 'Gallery successfully published',
  CREATED_ADVERT: 'Advert succesfully created',
  EDITED_ADVERT: 'Advert succesfully edited',
  DELETED_ADVERT: 'Advert succesfully deleted',
  CREATED_ADVERT_IMAGE: 'Advert image succesfully created',
  PUBLISHING: "<p style='font-weight:600'>Publishing</p>",
  PUT_MY_PROPERTY: 'Put my property up for rent',
  PUBLISH_YOUR_PROPERTY_CHOOSE_REGISTER:
    'Publish your property in simple steps',
  QUALIFIED_RENTERS_MESSAGE: "You don't have renters qualified to rent",
  QUALIFIED_RENTERS: 'Qualified to rent',
  PROPERTY_PUBLISHED: 'Property published',
  RE_UPLOAD_ID_DOCUMENT:
    'Click here to re-upload your documents in case of not being able to verify your identity with a biometric scanner',
  READ_CONTRACT:
    'Please, read the contract. You have only 48 hours to sign the contract.',
  READY: 'Ready',
  REAL_ESTATE_DOCUMENT: 'Real Estate document',
  RECEIVE_INFORMATION_TITLE: 'We received your information!',
  RECEIVE_PAYMENT: 'Receive your payment when the property is rented',
  RECEIVED_OFFERS: 'You have received one or more rental offers!',
  RECENT_REMODEL_A: 'Recent remodel',
  RECENT_REMODEL: 'Recent remodel?',
  RECENTY_UPLOADED_DOCUMENT:
    'Recently uploaded document, it can no longer be deleted.',
  RECOVER_PASSWORD: 'Recover your password',
  REDIRECT_TO_PROPERTY_OFFERS: 'You will be redirect to property offers',
  REDIRECT_TO: 'You will be redirect to complete your data',
  REFER_AND_EARN_FOR_EACH:
    "Receive 5% of the first month's rent once the referred property is rented. It's that easy!",
  REFER_PROPERTY: 'Refer properties and earn money in simple steps',
  REFERENCE_CODE: 'Reference code',
  REFERENTIAL_ADDRESS: '*The location of the property is referential',
  REFERRED_CODE: 'With that code he/she can register',
  REFRIGERATOR: 'Refrigerator',
  REFUSE: 'Reject offer',
  REGISTER_TYPE: 'Register type',
  REGISTERED: 'Registered',
  REJECT_ERROR: "<p style='font-weight:600; color:red' >Rejected error</p>",
  REJECT_MINIMUM_AMOUNT: 'Reject by minimum amount',
  REJECT_ON_MINIMUM_AMOUNT:
    'You are about to reject this user for insufficient amount. This will automatically send them an email suggesting they update their details. Do you confirm this action?',
  REJECT_PROPERTY_ADMIN: 'Reject property',
  REJECT: 'Reject',
  REJECTED: 'Rejected',
  REJECT_RENTER: 'Renter Reject',
  REJECT_RENTER_REASON:
    'Below you should indicate the reason for rejecting this user',
  REJECTING_PROPERTY: 'Rejecting property',
  REJECTING: "<p style='font-weight:600' >Rejecting</p>",
  REMEMBER_CAM: 'Remember to allow the use of the camera if required.',
  REMEBER_HOURS_AVAILABLE:
    '*Remember that you must have blocks available to schedule.',
  REMEMBER_ME: 'Remember me',
  REMEMBER_PERSONAL_MAIL: 'Remember to use your personal email',
  REMINDER: 'Reminder',
  RENT_A_PROPERTY: 'Rent a property',
  RENT_PRICE: 'Rent price',
  RENT_PROCCESS_DESCRIPTION:
    "You will receive your rent net of commissions on <span class='text-color-secondary'>the 15th of each month</span> in the bank account indicated in your user profile.",
  RENT_PROPERY: 'Rent a property',
  RENT_START_DATE: 'Lease start date',
  RENT: 'Rent',
  RENTAL_PROPOSAL_INFO:
    'In this proposal you can see some data <br> of the renter and learn more about them',
  RENTAL_PROPOSAL: 'Rental Proposal',
  RENTED: 'Rented',
  REQUIRED_INFORMATION: 'Required information',
  RENTER_AUTHORIZED: 'Renter has been authorized',
  RENTER_DETAILS: 'Renter details',
  RENTER_EXPIRED_MESSAGE:
    'We have analyzed all your documents and unfortunately they have expired! But all is not lost! You can still update your profile in Tango. For this you just have to go to the form section in your profile and update your documents.',
  RENTER_INFORMATION: "Renter's information",
  RENTER_REJECTED_MESSAGE:
    'We have analyzed all your documents and unfortunately we cannot provide you with Tango services since you have not passed some of the minimum requirements needed to be able to rent a property. We are very sorry for the situation and we appreciate your interest in renting through Tango.',
  RENTER_REJECTED: 'Renter has been rejected',
  RENTER_EXPIRED: 'Renter has been expired',
  RENTER_RELATIONSHIP: 'Relationship',
  RENTER_SIGNING_TIME:
    "Now it is the renter's turn, the deadline to sign is 48 hours. We will notify you when this occurs.",
  RENTER_UPLOAD_FILES_TITLE:
    'To continue with the validation process, please attach the following documents.The file can be in PDF, JPG or PNG format',
  RENTER_UPLOAD_FILES_INDEPENDENT:
    'You indicated that you are a <span class="color-secondary">independent</span> worker so we need you to provide us with the following documents to verify your income level:',
  RENTER_UPLOAD_FILES_DEPENDENT:
    'You indicated that you are a worker <span class="color-secondary">dependent</span> so we need you to provide us with the following documents to verify your income level',
  INDEFINIED_CONTRACT:
    'INDEFINIED EMPLOYMENT CONTRACT (SENIORITY GREATER THAN 3 MONTHS) <span class="font-weight-bold">OR</span> EMPLOYER CERTIFICATE INDICATING POSITION, TYPE OF CONTRACT AND SENIORITY',
  UPLOAD_FILES_DOCUMENTS: 'Attach documents',
  LAST_SALARY:
    'LAST 3 SALARY SETTLEMENTS <span class="p-info-label">To upload more than 1 document at a time, you must select them all<span>',
  FORM_22: 'FORM 22 (LATEST)',
  LAST_PROVISIONAL_PAYMENTS:
    'LAST 6 MONTHLY PROVISIONAL PAYMENTS <span class="font-weight-bold">OR</span> FEE TICKETS ISSUED DURING THE LAST 3 MONTHS',
  AFP_CERTIFICATE:
    'AFP QUOTATION CERTIFICATE (PROVING AFFILIATION AND EMPLOYMENT SENIORITY)',
  ADMIN_UPLOAD_TRUORA_DICOM:
    'To continue with the renter approval process, attach the Truora and Dicom reports in PDF format',
  DICOM: 'Report of Dicom',
  TRUORA: 'Report of Truora',
  ONLY_PDF: 'You can only upload PDF files',
  CANNOT_UPLOAD_MANY_FILES: 'You cannot upload that many files',
  REMEMBER_FULL_FORM:
    "Remember that after completing all the fields and uploading the files, you must <span class='font-weight-bold'>SEND</span> your profile so that we can review it and you can continue your process without problems. It also applies in case of signing with guarantors and codebtors",
  RENTER: 'Renter',
  RENTER_AND_COSIGNER: 'Renter and Cosigner',
  SELECT_RENTER: 'Select renter',
  RENTERS: 'Renters',
  RENTING_EXPERIENCE: 'Let your renting <br> experience be a dance.',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  REPORT_DATE: 'Report date',
  REQUESTER: 'Requester',
  REQUIRED_FIELD: 'This field is required',
  RESCHEDULE_VISIT: 'Reschedule visit',
  RESCHEDULE: 'Reschedule',
  RESIDENCE: 'Residence',
  RESTORED: 'Restored',
  RESTORATION: 'Restoration',
  RETIRED: 'Retired',
  RIGUROUS_ANALYSES: 'Rigorous renter analyses',
  ROOFTOP: 'Rooftop',
  ROLE_IN_TANGO: 'Specify your role in tango',
  RUT: 'Identity card number',
  SAFEGUARD_GUARANTEE_INFO: '*Up to an amount equivalent to 6 months of rent',
  SALES_NOTES: 'Sales notes',
  SALES_NOTE: 'Sales note',
  SATURDAY: 'Saturday',
  SAUNA: 'Sauna',
  SAVE_MANAGE_SECURITY_DEPOSIT:
    "We save and manage your <span class='text-color-secondary'>security deposit</span>",
  SAVE: 'Save',
  SAVED: 'Saved',
  SAVING: 'Saving...',
  SCAN_DOCUMENT: 'Then, scan the document with the camera.',
  SCHEDULE_NEW_VISIT_INFO:
    'Schedule your new visit, you can only choose one block. Remember, the visit should take approximately 45 minutes.',
  SCHEDULE_NEW_VISIT: 'Schedule your new visit',
  SCHEDULE_NOT_AVAILABLE: 'There are no schedules available',
  SCHEDULE_TOUR: 'Schedule tour',
  SCHEDULE_NEW_VISIT_V2: 'Visit scheduling',
  SCHEDULE_VISIT_AGENT: 'Schedule tour with a Tanguero',
  SCHEDULE_VISIT_SUBTITLE:
    'Are you ready to visit your new home? Select a day and time below.<br> The tour should take approximately 45 minutes and will be guided by one of our Tangueros.',
  SCHEDULE_VISIT_VERIFIER_CARD: 'Schedule visit with photographer',
  SCHEDULE_VISIT_VERIFIER: 'Schedule visit',
  SCHEDULE_VISIT: 'Schedule visit',
  SCHEDULE: 'Schedule',
  SCHEDULED: 'Scheduled',
  SCHEDULED_VISIT: 'Scheduled visit',
  SCHEDULED_TOUR: 'Scheduled tour',
  SCORE: 'Score',
  SEARCH_FOR_HOME: 'Search for your next home!',
  SEARCH_INVITE: 'We invite you to continue looking for your new home.',
  SEARCH_PROPERTIES_VERIFIED_LISTINGS:
    "Search properties with <span class='text-color-secondary'>100% verified listings</span>",
  SEARCH: 'Search',
  SECOND_SURNAME: 'Second surname',
  SECURITY_CODE: 'Security code',
  SECURITY_DEPOSIT_INFO:
    '*After evaluation of the conditions of the property at the time of return',
  SEE_AVAILABLE_COMMUNES: 'See available communes',
  SEE_LEASE_CONTRACT: 'See lease contract',
  SEE_PROPERTY_INVENTORY: 'See property inventory',
  SEE_VIDEO: 'See our video',
  SELECT_DOCUMENT_TYPE: 'Select the type of your document',
  SELECT_FRONT_SIDE: 'First, select the front side document file type.',
  SELECT_TWO_IMAGES: 'Please select and upload two images at the same time',
  SEND_CODE_REFERRED: 'Send the generated code to your referral',
  SEND_MAIL: 'Send mail',
  SEND_PROPOSAL: 'Send proposal',
  SEND_YOUR_INFO:
    'Send us your information and make yourself known, as soon as there is a position in Tango that matches your skills and abilities, we will contact you.',
  SEND: 'Send',
  SENT: 'Sent',
  SEPARATE_PARKING: 'Please separate by spaces all the parking spaces.',
  SEPARATED: 'Separated',
  SEPTEMBER: 'September',
  SERIOUS_PROPOSAL:
    'This is a serious proposal, I agree to sign the contract if the owner approves it.',
  SERVICES_COVERAGE_DESCRIPTION:
    'For now, Tango services are offered to properties that meet the following 2 conditions:',
  SERVICES_COVERAGE: 'Services Coverage',
  SERVICES_WE_OFFER: 'What services do we offer?',
  SERVICES: ' Service Room/Bathroom',
  SHARE_CODE: 'You can share your code through your favorite social networks',
  SHARED_OTHERS: 'Yes, with someone else',
  SHOW_MORE: 'Show more',
  SIBLING_IN_LAW: 'Sibling-in-law',
  SIBLING: 'Sibling',
  SIGN_ADMINISTRATION_CONTRACT: 'Sign administration contract',
  SIGN_CONTRACT: 'Sign contract',
  SIGN_IN: 'Sign in',
  SIGN_LEASE_CONTRACT: 'Sign lease',
  SIGN_UP: 'Sign up',
  SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
  SIGN_UP_WITH_EMAIL: 'Sign up with Email',
  LOG_IN_WITH_GOOGLE: 'Log in with Google',
  SIGN_WITH_SOMEONE: 'Are you going to sign with someone else?',
  SIGNATURE: 'Signature',
  SIGNED_CONTRACT_DESCRIPTION:
    'The lease has been successfully signed by both parties!',
  SIGNED_CONTRACT: 'Signed contract',
  SIMPLE_THAT: 'As simple as that!',
  SINGLE: 'Single',
  SOCIOECONOMIC_SEGMENT: 'Socioeconomic segment',
  SOUTH: 'South',
  SPECIFY_REMODELING: 'Specify what was remodeled...',
  SPORT_FIELD: 'Sport field',
  SPORTS_FIELD: 'Sports field',
  SPOUSE: 'Spouse',
  START: 'Start',
  START_HOUR: 'Start hour',
  STATUS: 'Status',
  STEP_1: 'Step 1',
  STEP_2: 'Step 2',
  STEP_3: 'Step 3',
  STEP_4: 'Step 4',
  STEP_5: 'Step 5',
  STEP_6: 'Step 6',
  STILL_DOUBTS: '<strong>Do you still have doubts?</strong>',
  STILL_HAVE_DOUBTS: 'Do you still have doubts? Visit our ',
  STORAGE_NUMBER: 'Number',
  STORAGE: 'Storage room',
  STOVE: 'Stove',
  STREET_ADDRESS_EXTRA: 'Apartment / Unit / House',
  STREET_ADDRESS: 'Street address',
  STUDIO: 'Studio',
  SUCCESSFUL_LOGIN: 'Successful login',
  SUCCESSFUL_SIGNING: 'Successful signing!',
  SUCCESSFULLY_SIGNED: 'Successfully signed',
  SUITE_BEDROOMS_NUMBER: 'Bedrooms in suite',
  SUNDAY: 'Sunday',
  SWITCH_AGENT: 'Switch to Tanguero',
  SWITCH_MASTERADMIN: 'Switch to Master admin',
  SWITCH_OPERATION: 'Switch to Operation admin',
  SWITCH_OWNER: 'I am an Owner',
  SWITCH_RENTER: 'I am a Renter',
  SWITCH_VERIFIER: 'Switch to Photographer',
  SUCCESS_SIGN_WITHOUT_COSIGNERS: 'You have successfully signed the lease contract. Remember that <b>you have until the day before the lease at 20:30 hrs to pay.</b>',
  SUCCESS_SIGN_WITH_COSIGNERS: 'You have signed the lease. Now it is the turn of your guarantors. Remember that <b> they have until the day before the lease at 20:30 hrs to sign. </b>',
  SIGN_DOCUMENTS_SUCCESS: "You have successfully signed the administration contract and the lease contract. Now it is the renter turn. <b> He has until the day before the lease at 8:30 pm </b>. We will notify you once the tenant has signed.", 
  SIGN_LEASING_CONTRACT_SUCCESS: 'You have successfully signed the leasing contract.', 
  TRANSACTIONS: 'Transactions',
  TANGO_EXPLANATION:
    'Tango is an integral service that covers the entire rental process through an automated platform and a professional team prepared to solve all your problems',
  TANGO_IS_GREYSTAR:
    "Tango is part of <a href='https://www.greystar.com/' target='_blank'><span class='text-color-secondary underline-link'>Greystar</span></a>, <strong>the world's leading</strong> investment, development and management company in the residential rental market",
  TANGO_LEASE_HOURS:
    'Tango lease contracts have an initial duration of 12 months, with automatic renewals for equal consecutive periods.',
  TANGO_PLAN: 'Tango Full plan',
  TANGO_POINT_MESSAGE:
    "The Tango score is a reference value that estimates the renter's and their cosignees' ability to pay. It is calculated taking into account their income, credit behavior, among other variables. The Tango score ranges from 0 to 100, with 100 being the best score",
  TANGO_POINTS: 'Tango points',
  TANGO_SERVICES: 'Tango services',
  TAX_ANNOTATIONS: 'Tax annotations',
  TAX_ASSESSMENT: 'Taxable property valuation',
  TEAM_TANGO: 'Tango Team.',
  TERM_AND_POLICY_APP_TANGO:
    "I declare to have read <a href='#' style='text-decoration: none'><span  class='text-color-secondary'>terms, conditions</span></a> and <a href='#' style='text-decoration: none'><span class='text-color-secondary'>privacy policies</span></a> of Tango",
  TERM_AND_POLICY:
    "I declare to have read <a href='#' style='text-decoration: none'><span  class='text-color-black font-weight-bold'>terms, conditions</span></a> and <a href='#' style='text-decoration: none'><span class='text-color-secondary'>privacy policies</span></a> of Tango",
  TERM_AND_POLICY_INSTAPAGE:
    "I declare to have read <a href='#' style='text-decoration: none'><span  class='text-white-bold'>terms, conditions</span></a> and <a href='#' style='text-decoration: none'><span class='text-color-secondary'>privacy policies</span></a> of Tango",
  TERM_AND_POLICY_V2:
    "I have read and accept the <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-secondary'>terms and conditions</span></a> of Tango and I agree with its <a href='/terms-and-conditions' target='_blank' style='text-decoration: none'> <span class='text-color-secondary'>privacy policy</span></a>.",
  TERM_CONDITIONS: 'Terms and conditions',
  TERM_LAPSE_72HRS:
    ' You received a rental offer! Remember that you have 72 hours from the moment you received the offer to accept or reject it.',
  TERM_LEASE: 'Keep in mind that the term of the lease contract is 12 months.',
  TERMS: 'Terms',
  TERRACE_AREA: 'Terrace area',
  TERRACE: 'Terrace',
  THANK_YOU_MODAL: 'Thank you!',
  THANK_YOU_TANGO:
    '<p>Thank you very much <span class="font-weight-bold">{name}</span>  for sending us your information.</>',
  THE_BENEFITS_ARE_MANY_OWNER_1: 'Experience the Tango difference!',
  THE_BENEFITS_ARE_MANY: 'Experience the many benefits of renting with Tango!',
  THE_EASIEST_WAY:
    'Tango, the easiest way, without worries and with a team of professionals that will help you in everything.',
  THERE_ARE_NO_PROPOSALS: 'There are no proposals ',
  THERE_ARE_NO_PROPERTIES: 'There are no properties',
  THERMALPANEL: 'Insulated glazing',
  THIRTY_SECONDS_EXPLANATION: 'We explain it in 90 seconds',
  THIS_PROPERTY_IS_BROKERAGE: 'This property corresponds to a brokerage kind',
  THREE_FLOORS: 'Three floors',
  THREE_MONTH_EXCLUSIVITY: '3-month exclusivity',
  THURSDAY: 'Thursday',
  TIME_COSIGNEE_PLURAL:
    'The Guarantors and Co-debtors must sign within 48 hours.',
  THIS_BLOCK_IS_NOT_AVAILABLE:
    'This block is not available, but you can select one of the options below',
  TIME_COSIGNEE_SINGULAR:
    'The Guarantor and Co-debtor must sign within 48 hours.',
  TIME_VISIT: 'Approximately how many hours did this visit last?',
  TIME: 'Time',
  TITLE_ACCEPTED: 'Accepted',
  TITLE_ACTIVE: 'Active',
  TITLE_CANCELED: 'Canceled',
  TITLE_CREATE_PROPERTY: 'Create property in Tango',
  TITLE_FINISHED: 'Finished',
  TITLE_PENDING: 'Pending',
  TITLE_PROPERTIES: 'Properties',
  TITLE_PUBLISH_PROPERTY: 'Publish the property',
  TITLE_PUBLISHED: 'Published',
  TITLE_VERIFY_INFO: 'Verify and complete the information',
  TITLE_VISITS: 'Visits',
  TO: 'To',
  TOAST_OFFER_REJECTED: 'Offer rejected',
  TOAST_UNIT_CREATED: 'The unit has been created',
  TOAST_UNIT_NOT_CREATED: 'The unit could not be created',
  TOGETHER: 'Together',
  TOKEN_ERROR: 'Token error, check your token',
  TOTAL_AREA_DS: 'Square meters total surface',
  TOTAL_PAY: 'Total to pay',
  VISIT: 'Visit',
  TOUR: 'Tour',
  TOUR_CONFIRMED: 'Tour confirmed',
  TOUR_FINISHED: 'Tour finished',
  TOUR_TO_PROPERTY: 'Tour to property',
  TRUORA_ID_SCORE: 'Score by ID',
  TRUORA_IDENTITY: 'Identity',
  TRUORA_INTERNATIONAL: 'International',
  TRUORA_LEGAL: 'Legal',
  TRUORA_MEDIA: 'Media',
  TRUORA_NAME_SCORE: 'Score by name',
  TRUORA_OBSERVATIONS: 'Observations',
  TRUORA_PENAL_AND_CRIMINAL: 'Penal and criminal',
  TRUORA_SCORE: 'Truora Score',
  TRUORA_TAXES_AND_FINANCE: 'Taxes and finance',
  TRUORA_TRUST_LEVEL: 'Trust level',
  TRY_AGAIN: 'Try again. Error code: {error_code}',
  TRY_CODE_TOKEN_1: "Didn't you receive the email? Click ",
  HERE: ' here ',
  HERE_LINK: "<span  class='cursor-pointer'> here </span>",
  ACCOUNT_NOT_ACTIVE:
    'Your account is not active yet, request a new verification code',
  REMEMBER_TWO_HOURS:
    '*Remember that you have 2 hours to use it, otherwise you wil have to ask for another one',
    TOTAL: 'Total',
    REMEMBER_TWO_HOURS_LOGIN:
    '*Remember that you have 2 hours to activate your account, otherwise you will have to request a code again',
  VALIDATION_CODE_EXPIRED_2: "and we'll resend one to you.",
  OH_SORRY: 'Oh sorry!',
  TRY_CODE_TOKEN_2: 'and we will forward it to you',
  TUESDAY: 'Tuesday',
  TYPE_FLOOR_APARTMENT: 'How many floors does it have?',
  UNCLE_AUNT: 'Uncle/Aunt',
  UNEMPLOYED: 'Unemployed',
  UNIT_ALREADY_VERIFIED: 'The unit is already verified',
  UNIT_CREATED: "You've already created the unit!",
  UNIT_DEPT_HOUSE: 'Unit number',
  UNIT_DETAIL_VISIT_INFO:
    'On your visit to the property, complete all the available fields',
  UNIT_DETAIL: 'Unit detail',
  UNIT_TYPE: 'Kind of property',
  UNIT_USED_NOW: 'Currently who lives in the property?',
  UNIT_VERIFIED: 'The property was successfully verified',
  UNIT: 'm<sup>2</sup>',
  UNITS_UNDER_MANAGEMENT: 'Units under management',
  UNIVERSAL_ACCESS: 'Universal access',
  UNPAID_DEBTS_TOTAL_AMOUNT: 'Unpaid debts total amount',
  UNPAID_TOTAL_DEBTS: 'Unpaid total debts',
  UPDATE_AVAILABILITY: 'Update your availability',
  UPDATE_BLOCKS: 'Update blocks',
  UPDATE_DATA: 'Update data',
  UPDATED_PASSWORD: 'Your password has been updated',
  UPLOAD_BLOCKS: 'Upload blocks',
  UPLOAD_DATA_CONSIGNEE: 'Upload data',
  UPLOAD_DOCUMENT: 'Upload identity document',
  UPLOAD_DOCUMENTS_MAKE_OFFER:
    "Upload your documents and make an <span class='text-color-secondary'>offer online</span> without the need for a guarantor",
  SIGN_ONLINE_MAKE_PAYMENT:
    " <span class='text-color-secondary'>Sign the rental contract online</span> and make your payments with a credit or debit card",
  UPLOAD_DOCUMENTS_PARAGRAPH: 'Upload your document',
  UPLOAD_DOCUMENTS:
    'You need to complete the form and upload the documents to make the offer',
  UPLOAD_ID_DOCUMENT: 'Upload your identity documents',
  UPLOAD_ID_DOCUMENT_LINK:
    "Upload your identity documents <span class='text-color-secondary'>here</span>",
  UPLOAD_PHOTOS: 'Upload photos',
  UPLOAD_PHOTOS_INFO: 'Attach in high resolution all photos of the property',
  DELETE_PHOTO: 'Delete photo',
  UPLOAD_VIDEOS: 'Upload videos',
  UPLOAD_VIDEOS_INFO: 'Attach all videos of the property',
  DELETE_VIDEO: 'Delete video',
  UPLOADING_FILES: 'Uploading files ...',
  UPLOADING_UNIT: 'Uploading unit ...',
  UPLOADING: 'Uploading...',
  USABLE_AREA: 'Useful Area',
  USER_REGISTERED: 'User registered',
  USER_VERIFIED: 'User verified successfully',
  USER_REGISTRATION:
    'You are just a few steps away from renting with Tango ... and no worries!',
  USER_UPDATED: 'User updated successfully',
  USER_VALIDATED: 'User validated',
  UNREVERSE_ACTION: 'This action can not be undone',
  CLF: 'UF',
  SELECT_CLF_INFO:
    'By selecting this option, you accept that it will be the monetary unit in which your contract will be agreed at the time of leasing',
  MONETARY_UNIT_INFO: 'Lease monetary unit',
  VALID_ID_CARD: 'Valid identity document',
  VALIDATION_CODE: 'Verification code',
  VEHICLES_NUMBER: 'Registered vehicles',
  VERIFIED_CONTRACT_DESCRIPTION:
    'The contract has been validated upon receipt of the deposit by the lessee',
  VERIFIED_CONTRACT: 'Contract verified',
  VERIFIED_DESCRIPTION:
    'The unit is verified when one of our photographers visits the property and uploads all the information and photos necessary to create the publication.',
  VERIFIED: 'Verified',
  VERIFIER_DESCRIPTION:
    'Select a day and time for a photographer to visit your property and collect <br>all the necessary information. The visit could last 30 minutes or more',
  VERIFIER_ID: 'Photographer id',
  VERIFIER_INTRODUCTION:
    'The photographer is a listing expert. He/she will gather information and take pictures/videos of the property.',
  VERIFIER: 'Photographer',
  VERIFY_IDENTITY_WITH_SCAN: 'Verify your identity with a biometric scan',
  VERIFY_IDENTITY: 'Verify your identity',
  VERIFY: 'Verify',
  VETO: 'Veto',
  VIEW_CONTRACT: 'View contract',
  VIRTUAL_TOURS:
    "Virtual or face-to-face tours made by our <span class='text-color-secondary'>Tangueros</span>",
  VISIT_AGENT_SUCC:
    'Your tour has been scheduled! We sent you an email with everything you need to know about the tour.',
  VISIT_AGENT:
    'Your tour has been scheduled with {agent} at {date}! We sent you an email with everything you need to know about the tour.',
  VISIT_CANCELED: 'Visit canceled',
  VISIT_CHANGED: 'Visit changed succesfully',
  VISIT_CONFIRMED: 'Visit confirmed',
  VISIT_CREATED: 'Visit created',
  VISIT_DATE: 'Visit date',
  VISIT_FINISHED: 'Visit finished',
  VISIT_HISTORY: 'Tour history',
  VISIT_PARKING: 'Visitor parking',
  VISIT_SCHEDULED: 'Visit has been scheduled',
  VISIT_STATE: 'Visit state',
  VISIT_TO_DO: 'Visit to do',
  VISIT_VERIFIER_SUCC:
    '`Your visit has been scheduled! We sent you an email with everything you need to know about the photographer visit.',
  VISIT_VERIFIER:
    'A visit to your property has been scheduled at {date} with the photographer {verifier}.',
  VISIT_WITH: 'Visit with',
  VISTA: 'Vista',
  ELECTRONIC_CHECKBOOK: 'Electronic checkbook',
  SAVING_ACCOUNT: 'Saving',
  WALK_IN_CLOSET: 'Walk-in closet',
  WANT_LEASE: 'I want to rent',
  WANT_TO_KNOW_MORE: 'Want to know more',
  WANT_POST: 'I want to rent out a property',
  WANT_TO_REFER: 'Do you want to refer someone?',
  WASHING_MACHINE: 'Washing machine',
  WATER: 'water',
  WE_COVER:
    'We cover your rent in case of non-payment and we manage the eviction process*',
  WE_DELIVER_AND_REPLACE_PROPERTY:
    'We take care of delivering, maintaining and returning your property',
  WE_DEPOSIT_AND_COVER:
    'You receive your monthly rent and we cover you in case of non-payment',
  WE_EVALUATE_RENTER: 'Your next renter goes through our detailed analysis',
  WE_MANAGE_PROPOSALS_AND_CONTRACT:
    'You sign a digital lease; you manage everything 100% online',
  WE_SAFEGUARD_DEPOSIT:
    'We safeguard your deposit and deliver it at the end of the renting process**',
  WE_VERIFY_EVERY_PROPERTY:
    'We verify every listing to make sure the information is true',
  WE_WILL_CONTACT: 'We will get in touch with him / her',
  WEDNESDAY: 'Wednesday',
  WELCOME_ENTER_USER:
    'Welcome! Please, enter your username and password to start your session',
  WELCOME_TO_TANGO: 'Welcome to Tango',
  WEST: 'West',
  WHAT_WOULD_YOU_LIKE: 'Are you looking to list or lease a property?',
  WHEN__AND_HOW_RENT_RECEIVED:
    "When and how do you receive your rent<span class='text-color-secondary'>?</span>",
  WHEN_PROPERTY_IS_RENTED: 'When property is rented',
  WIDOWED: 'Widowed',
  WITH_TANGOBOX: 'With our Tangobox we show your property',
  WORK_WITHUS: 'Work with us',
  YEAR: 'Years',
  YEARS_EMPLOYEE: 'Length of service in current job',
  YES: 'Yes',
  YOU_ARE_RENTING: 'You are already in a leasing process',
  YOU_CREATE_PROPERTY_TANGO: "You've created your property in Tango!",
  ADDRESS_DUPLICATED:
    '<strong>The property is already created with this street address</strong>',
  YOU_DONT_HAVE_VISITS: "You don't have tours",
  YOU_HAVE_THREE_PROPOSAL: 'You can not make more proposals',
  YOU_CANNOT_SELECT_THAT_DATE: 'You cannot select that date',
  YOU_HAVE_TRHEE_PROPOSAL:
    'You have made 3 proposals already, you cannot make more',
  YOU_MADE_A_PROPOSAL: 'You made a proposal',
  YOU_WANT_LOCKBOX: 'Tangobox?',
  YOU_WILL_BE_INFORMED:
    'You will be informed when everyone signs for you to make the first payment',
  YOUR_PROPOSAL_DATE: 'Your proposal date',
  YOUR_VISIT_IS_PENDING: 'Your tour visit is pending',
  YOUR_VERIFICATION_IS_PENDING: 'Your verification is pending',
  PENDING_VERIFY: 'Pending verification',
  UPLOAD_ONE_VIDEO: 'You must upload at least one video',
  VIDEOS: 'Videos',
  DROP_VIDEOS_LIMIT: 'You can only upload up to 10 videos',
  PROPERTY: 'Property',
  REQUESTED_BY: 'Requested by',
  END_DATE_REQUESTED: 'End date requested',
  DELETION_ORDER: 'You must eliminate the power of attorney first',
  NO_VIDEOS: 'No video',
  RENTER_DUE: 'Due',
  RENTER_PAID: 'Paid',
  RENTER_PAID_TITLE: 'Renter paid',
  RENTER_DUE_TITLE: 'Renter due',
  PAYMENT_DUE: 'Payment due',
  DAYS_LATE: 'Days late',
  NO_DUE_RENTER: 'There are no defaulting debtors',
  NO_PAID_RENTER: 'There are no up to date renters',
  NO_NOTES: 'There are no sales notes',
  RENTS_PROPERTY_SINCE: 'Rents the property since',
  THANK_YOU:
    "Congratulations, you are one step closer to renting your property on <span class='text-color-secondary'>Tango</span>!",
  THE_LEASING_YOU_DESERVE: 'The leasing you deserve',
  YOU_RELAX: 'You relax,',
  TANGO_TAKES_CARE: 'Tango takes care of it.',
  ALREADY_LEASING: "I'm already leasing a property",
  FAST_GREATER_VALUE: 'Lease fast with greater value',
  FAST_GREATER_VALUE_INFO:
    'Our professional photographers get faster leases and at a better price',
  WE_TAKE_CARE_OF_YOUR_TIME: 'Optimize your time',
  WE_TAKE_CARE_OF_YOUR_TIME_INFO:
    'We take care of the tours, contracts and maintenance. Online signing and payment notices',
  LEASE_WITHOUT_RISK: 'Receibe your lease without risk',
  LEASE_WITHOUT_RISK_INFO:
    'Tango covers the lease of your property for up to 6 months in case of non-payment',
  ALREADY_LEASING_Q: 'Are you already leasing a property?',
  YOU_CAN_ALSO_BENEFIT:
    'You can also benefit from Tango, we can manage your lease for you',
  MANAGE_LEASED_PROPERTY: 'Manage an already leased property',
  TANGO_ADMIN_PLAN: 'Tango Administration plan',
  PROPERTY_REGISTERED: 'Registered',
  MANAGEMENT_PROPERTY_FIXES: 'Management of property fixes',
  GET_PLAN: 'Get',
  FULL_PLAN: 'Tango Full plan',
  ADMIN_PLAN: 'Tango Administration plan',
  SHOW_LESS: 'Show less',
  GLOBAL_LIDER_SUPPORT: 'The global leader supports us',
  ONLINE_CHAT: 'Online chat',
  RENTED_UNIT: 'Is the property currently leased?',
  CONTRACT_CONDITIONS: 'Lease contract terms',
  CONTRACT_DATE: 'Property lease contract start date',
  CURRENT_PRICE: 'Current lease price',
  RENTER_FULLNAME: 'Full name of renter',
  RENTER_RUT: 'Rut de arrendatario/a',
  RENTER_EMAIL: 'Correo electrónico de arrendatario/a',
  RENTER_EMAIL_INFO:
    'Solicitamos el correo electrónico del arrendatario/a para contactarlo/a solicitando la información financiera personal que nos permitirá analizar su capacidad y comportamiento de pago.',
  RENEGOTIATION: 'Renegotiations',
  PROOF_OF_PAYMENT: 'Proof of payment',
  LEASE_CONTRACT_INFO:
    'Attach the original lease contract signed in front of public notary',
  RENEGOTIATION_INFO:
    'Attach an email or signed document in which you have renegotiated the price or conditions of the lease with your renter.',
  PROOF_OF_PAYMENT_INFO:
    'Documents that prove the payment of the rent by the owner in the last 3 months. These can be bank deposit vouchers showing the name of the depositor.',
  MANAGE_YOUR_PROPERTY: 'Manage your property',
  IF_CREATED_RENTED_PROPERTY:
    'If you completed the form for a property that you are already leasing, wait for the approval of a Tango administrator.',
  NEW_RENTED_PROPERTY_TITLE: 'New leased property',
  FAILED_DOCUMENT_DELETION:
    'An error occurred while deleting the file, please refresh the page',
  LEASE_CONTRACT_FILE: 'Lease contract *',
  RENEGOTIATION_FILE: 'Renegotiations (optional)',
  PROOF_OF_PAYMENT_FILE: 'Proof of payments *',
  DOCUMENT_SUCCESSFULLY_ATTACHED: 'Document(s) succesfully attached',
  UP_TO_FIVE_FILES: 'You can upload only up to 5 files',
  UP_TO_TEN_FILES: 'You can upload only up to 10 files',
  ALERT_TO_RENTER: 'I have already notified my renter about this process',
  ALERT_TO_RENTER_INFO:
    'The renter must be aware of this process since a user must be created in Tango, they must send us financial information for analysis and must begin to pay the rent through Tango',
  PROPERTY_NOT_LEASED_1:
    'If your property is not leased, we can lease it for you! In this ',
  PROPERTY_NOT_LEASED_2: ' you can enter your property and we take care of it',
  BEFORE_CREATING_ADMINISTRATION:
    'You are about to start the new management of your property!',
  BEFORE_CREATING_ADMINISTRATION_2:
    'Let us give you more details about our service beforehand',
  TITLE_CREATE_PROPERTY_ADMIN: 'Enter your property in Tango',
  DESCRIPTION_CREATE_PROPERTY_ADMIN:
    'Enter basic data of your property, lease contracts and renter in 5 minutes!',
  TITLE_REVIEW_INFO: 'We review the contract and contact the renter',
  DESCRIPTION_REVIEW_INFO:
    'We will review the conditions of your lease to make sure they meet our standards. After approving it, we will request financial information from your renter.',
  TITLE_RENTER_AND_COSIGNER_APPROVAL:
    'Approval of the renter and  guarantors and co-debtors',
  DESCRIPTION_RENTER_AND_COSIGNER_APPROVAL:
    "We will review the renter's credit profile and payment capacity in order to assign a Tango score and approve it as a good payer within our system.",
  TITLE_SIGN_ADMIN_CONTRACT: 'Sign the administration contract',
  DESCRIPTION_SIGN_ADMIN_CONTRACT:
    "Review and <span class = 'text-color-secondary'> sign the Administration contract </span> with <span class='text-color-secondary'> Tango online </span>.",
  ADMINISTRATION_AND_INSURANCE_ADMIN:
    "We collect the rent payment through our platform. <br> <br/> We also give you a deposit in which <span class='text-color-secondary'> we cover the rent payment for up to 6 months </ span > rent in case the renter does not pay, and we take care of all legal processes in case of eviction.",
  CHARGE_ADMIN: 'The charge is deducted from the rent that we collect monthly',
  CHARGED_AMOUNT: 'Charged amount',
  CONDITIONS_DESCRIPTION_ADMIN:
    'The Tango Administration contract has a condition that is important for you to know:',
  PERIOD_ADMIN:
    "The Tango Administration contract has a minimum term of <span class='text-color-secondary'> 24 months </span>. <br><br/> This will be renewed automatically only if there is a lease contract valid through Tango. <br><br/> If the term of <span class='text-color-secondary'> 24 months </span> has passed and the property lease expires, the Management contract follow only if you request it",
  NOW_CREATE_PROPERTY_ADMIN: 'Now you can manage your property with',
  CREATE_MY_PROPERTY_ADMIN: 'Start to manage your property',
  CONTACT_US: '<strong>Contact us</strong>',
  THANKS_FOR_SENDING_UNIT_INFO:
    'Thanks for sending us the information of your property!',
  FILL_OUT_FORM:
    'Now we need you to please fill out your profile with your details while your property is being approved by a Tango administrator',
  YOU_WILL_BE_REDIRECTED: 'You will be redirected in a few seconds.',
  PROOF_OF_JOB_SENIORITY: 'Proof of job seniority',
  SALARY_SETTLEMENT: 'Salary settlement',
  AFP_QUOTATION_DEPENDENT: 'AFP quotation dependent',
  AFP_QUOTATION_INDEPENDENT: 'AFP quotation independent',
  SUCCESFULLY_SAVED_DATA: 'Your data has been succesfully saved.',
  CLICK: 'Click',
  INFO_UPLOAD_FILES_RENTER:
    'You can upload a pdf with all the files together or each one separately. The format can be in PDF, JPG or PNG format.',
  GO_TO_UPPER_TAB:
    'To fill in the details of your guarantor and co-debtor you must go to the top tab with the same name. But first, you must save your information.',
  UNDERSTAND: 'Understand',
  MAX_DATE: 'Date should not be after maximal date',
  MIN_DATE: 'Date should not be before minimal date',
  BIRTH_MIN_DATE: 'You must be at least 18 years old to register in Tango',
  INVALID_FORMAT_DATE: 'Invalid date format',
  UPLOADED_DOCUMENTS: 'Uploaded documents',
  NEXT: 'Next',
  PROFESSIONAL_PHOTOS_VIDEOS: 'Photos, videos and professional plans',
  OPTIONAL_TOURS: "Tours (you don't need to be there)",
  RENTER_ANALYSIS: 'Renter analysis',
  ONLINE_CONTRACT_SIGNING: 'Signing of contracts online',
  GUARANTEED_PAYMENT: 'On-time lease payment - guaranteed',
  VERIFIED_LISTINGS: 'Find 100% verified listings',
  NO_GUARANTORS: 'Lease without guarantors',
  REQUESTED_NOT_FOUND: 'The requested item could not be found',
  VIRTUAL_TOURS_BENEFIT: 'Virtual and face-to-face tours',
  PAY_ONLINE: 'Pay online with your credit or debit card',
  LEASE_WITH_TANGO:
    "Lease your property for the long term with <strong class='text-color-secondary'>Tango</strong>!",
  TANGO_DESCRIPTION:
    '<strong>A leasing and administration platform for owners and renters</strong> with technology and professional equipment that will make your <strong>process agile and safe.</strong>',
  CHOOSE_PROFILE: 'Choose the profile that identifies you',
  CHOOSE_PROFILE_HOME:
  "<h1 class='font-weight-bold'>In Tango we manage and lease your property</h1> <h2>Know what we can do together</h2>",
  I_AM_OWNER: 'I am owner',
  I_AM_RENTER: 'I am renter',
  RELAX_WE_TAKE_CARE: 'Relax, we take care of it!',
  DIFFERENT_LEASING_EXPERIENCE: 'A different leasing experience!',
  LEASE_MY_PROPERTY: 'Lease my property',
  ICON_TEXT_RENTER_1: 'Sign up and explore the properties',
  ICON_TEXT_RENTER_2: 'Schedule a virtual or face-to-face tour',
  ICON_TEXT_RENTER_3: 'Upload your documents and sign the contract online',
  ICON_TEXT_RENTER_4: 'Pay your lease online with a debit or credit card',
  SIGN_UP_RENTER: 'Lease with Tango without guarantor',
  SIGN_UP_OWNER:
    "You are just a few steps away from renting with Tango... <span class='font-weight-bold'>and don't worry!</span>",
  SIGN_UP_OWNER_2:
    'We need some information about the property, so our photographer can visit you!',
  SIGN_UP_OWNER_STEPS: 'In four steps your property will be published',
  ICON_TEXT_OWNER_1:
    'Register, create your property and schedule your visit with the photographer',
  ICON_TEXT_OWNER_2: 'We make the tours!',
  ICON_TEXT_OWNER_3:
    'Choose a renter approved by Tango and sign the contract online',
  ICON_TEXT_OWNER_4: 'Receive your guaranteed lease month by month',
  SIGNUP_INFO_OWNER: "You don't pay anything until your property is leased!",
  NAME_OWNER: 'Owner name',
  NAME_RENTER: 'Renter name',
  PAYMENT_AMOUNT: 'Amount to be paid',
  RENTER_PAYMENT_STATUS: 'Renter payment status',
  SEARCH_PROPERTIES: 'Search properties',
  PROPERTIES: 'Properties',
  NO_TRANSACTIONS: 'No transactions',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DURATION: 'Duration',
  ADMINISTERED: 'Administered',
  LISTED: 'Listed',
  PROMOTIONS: 'Promotions',
  TOTAL_RECEIVED: 'Total received',
  PROOF_OF_PAYMENT_PDF: 'Proof of payment',
  PROMOTION_LIST: 'Promotions List',
  CREATE_PROMOTION: 'Create promotion',
  EDIT_PROMOTION: 'Edit promotion',
  CREATE: 'Create',
  PROMOTION_NO_UPDATE: 'The Promotion can not be updated',
  DATA_EMPTY: "Values can't be empty",
  CHECK_VALUE: 'Check the value ',
  CHECK_VALUE_PERCENTAGE: 'The value must be between 0% to 100%',
  CHECK_VALUE_MONTH: 'The value must be between 1 to 12',
  CHECK_VALUE_NUMBER: 'The value must be a interger number',
  COUNTRY_MANAGER: 'Country Manager',
  TRANSACTIONS_TEXT: 'Review payment states and data related to the properties',
  PROMOTIONS_TEXT: 'Create, review and edit in the promotions list',
  CREATE_USER: 'Create profiles',
  CREATE_USER_TEXT: 'Create new users',
  MY_ACCOUNT_TEXT: 'Edit your data, change your password ',
  MENU: 'Menu',
  DIFFERENT_EXPERIENCE: 'A different leasing experience',
  WANT_TO_LEASE_MY_PROPERTY: 'I want to lease my property',
  FORM22: 'Form 22',
  PROVITIONAL_MONTHLY_PAYMENT: 'Provisional monthly payment',
  CREATE_PROPOSAL: 'Create proposal',
  CHECK_YOUR_PROFILE: 'Check your personal data',
  CHECK_YOUR_COSIGNER_PROFILE:
    'Check the details of your guarantor and co-debtor',
  CHECK_YOUR_COSIGNER_PROFILES:
    'Check the details of your guarantors and co-debtors',
  EXACT_ADDRESS: 'Personal address',
  INDICATE_FULL_ADDRESS: 'Please indicate the street/avenue name/number',
  EXTRA_ADDRESS_INFO: 'Optional',
  COUNTRY_OF_RESIDENCE: 'country of residence',
  SELECT_ADDRESS: 'Select an address from the list',
  INVENTORY_REJECTED: 'Inventory rejected',
  INTERNAL_SERVER_ERROR:
    'Internal server error,Reload the page or contact tango',
  CONNECTION_ERROR: 'Connection error',
  ATTACH_PROPERTY_LINK: 'Attach property link',
  SELECT_VISIT_DAY: 'Select day of vist',
  BLOCK_VISIT: 'Time block visit',
  HOURS_AVAILABLE: 'Hours available',
  TOUR_CREATED: 'Tour created successfully',
  ASSIGN_VISIT: 'Assign visit',
  INVALID_URL: 'Invalid link',
  SELECT_A_BLOCK: 'You must select a block',
  ENTER_ADDRESS: 'Enter address',
  EXACT_ADDRESS_PROPERTY: 'Enter exact address of the property',
  REVIEW_YOUR_DATA_CONTRACT:
    'To create this proposal we need you review your data below.',
  NOT_FOUND_ERROR: 'Error 404, request not found',
  REJECT_ANNEX_TITLE: 'Inventory rejection',
  REJECT_ANNEX_SUBTITLE:
    "We're sorry the inventory does not meet your requirements.",
  REJECT_ANNEX_PLACEHOLDER: 'Tell us the reasons so our Tanguero can update it',
  RENTER_PROFILE: 'Renter profile',
  INSERTED_PROPERTIES: 'Properties entered',
  SHOW_DETAILS: 'See detail',
  GENERATE: 'Generate',
  SEARCH_NOT_FOUND: 'Search not found',
  SEARCH_RESULT: 'Search result',
  NOT_HAVE: 'Does not have',
  FULLNAME: 'Full name',
  DATA_CONTRACT_ERROR: 'If there is any wrong information, please tell us',
  WILL_CONTACT_YOU: 'We will get in touch shortly',
  HAVE_DOUBT: 'If you have questions, please contact us',
  USERS_DETAILS: 'User details',
  INVENTORY: 'Inventory',
  UPLOAD_INVENTORY: 'Upload inventory',
  SEE_CURRENT_INVENTORY: 'See current inventory',
  ROWS_PER_PAGE: 'Rows per page',
  PREVIOUS_PAGE: 'Previous page',
  NEXT_PAGE: 'Next page',
  TYPE_OF_INCOME: 'Type of income',
  HASNT_REGISTERED_PROPERTIES: "Hasn't entered properties",
  UPLOAD_DOCUMENT_ID:
    'Now we need you send your document identity while your property is being approved by a Tango administrator',
  NO_DATA_TO_CONTINUE: '¡Ups! We need some of your data',
  INVITE_TO_COMPLETE_DATA: 'We invite you to complete them below',
  BEFORE_CONTINUE: '¡Ops! Before to continue...',
  CHECK_REQUIREMENTS:
    "Let's check that you have everything in order, you can select one of the options below",
  ID_DOCUMENTS: 'Identity documents',
  PROPERTY_DOCUMENTS: 'Property documents (optional)',
  PROFILE_DATA: 'Profile data',
  CHECK_YOUR_PROPERTY_DATA: 'Property information',
  STORAGE_ROOM_NUMBER: 'Storage',
  PARKING_SPACE_NUMBER: 'Parking',
  COMMUNE_AND_REGION: 'Commune / Region',
  BASIC_SERVICES: 'Basics Services',
  ANIMALS: 'Pets',
  CONFIRM_INFORMATION: 'Confirm information',
  REQUIREMENTS_COMPLETED_TITLE: 'Congratulations!',
  REQUIREMENTS_COMPLETED_SUBTITLE: 'Your documentation is complete. Now you can confirm your data to approve the proposal.',
  SIGN_TITLE: 'Advanced electronic signature',
  SIGN_SUBTITLE: 'You will then proceed to the advanced electronic signature process through an external Tango page. After signing you will return to Tango to review the process.', 
  BEFORE_SIGN: 'Before signing you must have:',
  UNIQUE_PASS: 'Unique key',
  ID_DOCUMENT_NEARBY: 'Your ID document nearby',
  EMAIL_DINAMIC_PASS: 'Email to receive dynamic key',
  GO_TO_SIGN: 'Go to sign', 
  REVIEW_DATA_OWNER:
    'To accept this proposal we need you review your data below.',
  REFUSE_PROPOSAL: 'Rejection of proposal',
  REFUSE_PROPOSAL_SURE: 'Are you sure to reject this proposal?',
  OWNER_DATA: 'Owner Data',
  DATA_FROM_ID: 'Data obtained from the identity document',
  FILL_FORM_DATA: 'Complete my profile',
  STATE_DOCUMENT: 'pending documentation approval by admin (optional)',
  WAIT_ADMIN_APPROVAL:
    'Now you only need to wait for a Tango administrator to approve your property so we can continue with the process',
  IF_ALREADY_APPROVED:
    'If your property was already approved, just ignore this message.',
  NOW_SCHEDULE_WITH_PHOTOGRAPHER:
    'Now you have to schedule a visit with a photographer',
  USER: 'User',
  REFERENCE: 'Reference',
  NO_DATA: 'No information to show',
  WHAT_DOES_TANGO_DO: 'What does Tango do?',
  USER_UPDATED_DATA: 'Updated data',
  SEND_YOU_EMAIL:
    'We will send you an email so that you can confirm your bank details',
  EMAIL_IS_SEND:
    'We send you an email so you can confirm and update your bank details',
  I_AM_OTHER: 'Other',
  SELECT_ROLE: 'What do you want to do in Tango?',
  ROLE: 'Role/s',
  NO_DOCUMENTS: 'Missing documents',
  CHECK_YOUR_DATA:
    'Check your data, it is important that they do not have errors',
  CONFIRM_UPDATE_BANK:
    'Do you confirm that these are your bank details and that they are correct?',
  BANK_DATA_SAVED: 'Bank data saved',
  RRSS_INVITATION_TITLE:
    'I chose you to access the benefits that I already enjoy in Tango! Lease your property, save time, it is online and 100% secure. Enter the reference code {referralCode} and sign up here ',
  REFERENCE_BODY: 'Enter the reference code {referralCode} and sign up here ',
  SUBJECT_INVITATION:
    'I chose you to access the benefits that I already enjoy in Tango! Lease your property, save time, it is online and 100% secure. ',
  EMAIL_BODY_REFERENCE:
    '{referring_user}, has invited you to be part of the Tango experience, copy and paste the following link in your browser',
  SUBJECT_REFERENCE: '{referring_user}, invites you to be part of Tango',
  LINKEDIN_SHARE:
    'When sharing on Linkedin press the ctrl + v keys to paste the code the reference code',
  CONFIRM_BTN: 'Confirm',
  CONFIRMED_DATA: 'Confirmed data',
  YOUR_DATA_BEEN_UPDATED: 'Your data has been updated correctly',
  THIS_EMAIL_USED: 'This email will be used for your bank details',
  ALL: 'All',
  LEASES: 'Leases',
  REFERENCES: 'References',
  OVERDUE_DATE: 'Overdue date',
  REFERENCEABLE_TYPE: 'Referenceable type',
  REFER: 'Refer',
  REFERENCE_STATUS: 'Reference status',
  REFERENCE_LIST: 'Reference list',
  CHANGE_STATUS_TO_PENDING:
    'Are you sure you want to change the status from Paid to Pending?',
  CHANGE_STATUS: 'Change Status',
  PARKING_SPACES_NUM:
    '{num, plural, =0 {No parking spaces} =1 {1 parking space} other {# parking spaces}}',
  REGISTERED_REFERENCE: 'Registered',
  ASSIGNED_REFERENCE: 'Assigned',
  PAID_REFERENCE: 'Paid',
  REGISTERED_REFERENCE_DESCRIPTION: 'referred user signed up with your code',
  ASSIGNED_REFERENCE_DESCRIPTION: 'referred user is now leasing a property',
  PAID_REFERENCE_DESCRIPTION: 'you received the referral payment',
  PROFILE_DECLINED:
    'Unfortunately your profile was declined. The good news is that you can still update it in order to be evaluated again',
  RENTER_DECLINED_MESSAGE_1:
    'Our team has analyzed your documentation and we regret to report that your profile does not meet all the requirements requested by Tango.Rent and has been declined.',
  RENTER_DECLINED_MESSAGE_2:
    'If you think you can add the necessary information to correct the situation, return to your profile by clicking the button below.',
  PAID_AT: 'Paid at',
  PAID_BY: 'Paid by',
  SIGNED_PROPERTY_REGISTER: 'Your lease contract is now administered by Tango!',
  BODY_SIGNED_PROPERTY_REGISTER:
    'From now you will be able to see a summary of your payments, vouchers, online lease and administration contract. Additionally, your lease is already covered by our non-payment insurance!',
  PHOTOGRAPHER_VISIT: 'Done! Now you can schedule a visit with a photographer',
  FILL_FORM_DATA_OPTIONAL: 'Or go to complete my profile (optional)',
  FILL_FORM_DATA_OPTIONAL_2: 'Go to complete my profile (optional)',
  MAKE_ALL_BLOCKS_AVAILABLE: 'Make all blocks available',
  ENABLING_BLOCKS_DESCRIPTION_1:
    'When activated, all blocks will be enabled for up to 2 weeks onward',
  ENABLING_BLOCKS_DESCRIPTION_2:
    'You can deactivate it at any time, you must remember that when doing so, the blocks already available must be deactivated manually',
  BLOCK_AVAILABILITY: 'Block availability',
  ACTIVATE_BLOCK_TWO_WEEKS:
    'Do you want to activate configuration to take blocks automatically?',
  DEACTIVATE_BLOCK_TWO_WEEKS:
    'Do you want to disable the setting to take blocks automatically? ',
  PHOTO_ORDER_INSTRUCTIONS:
    'Below you can choose the order of the photos you just uploaded:',
  YOU_MUST_SELECT_ALL_PHOTOS: 'You must order all photos!',
  WHAT_TO_DO_IN_TANGO: 'What can I do in Tango?',
  WANT_LEASE_PROPERTY: 'I want to rent a property',
  US: 'Us',
  LEGAL_INFORMATION: 'Legal information',
  OUR_NETWORKS: 'Our networks',
  POLICY_PRIVACY_TITLE_AND_CONDITIONS_TERMS: 'Privacy policies and CT',
  OWNER_IDENTITY_DOCUMENT_NO_PRESENT:
    'The owner has not uploaded the identity document',
  OWNER_FULL_ADDRESS_NO_PRESENT: 'The owner has not saved the address',
  TYPE: 'Type',
  GENERAL: 'General',
  UNPUBLISH: 'Unpublish post',
  ABOUT_TO_UNPUBLISH:
    "Do you confirm that you want to unpublish the property <br/><span class='font-weight-bold'>{address}</span>?",
  SUCCESSFULLY_UNPUBLISHED: 'The property was succesfully unpublished',
  CONFIRM: 'I confirm',
  YOU_CANT_SCHEDULE: "You can't schedule this time:",
  REQUIRED: 'Required',
  UPLOADING_PHOTOS: 'Uploading photo(s)...',
  MEANING_OF_COLORS:
    'Here you can understand the meaning of each color on your calendar',
  MEANING_PENDING: 'The property does not yet have a visit scheduled',
  MEANING_SCHEDULE:
    'The property has a visit scheduled, but it has not yet been finalized',
  MEANING_FINISHED_DELIVERS:
    'The property has a visit scheduled, but the delivery voucher has not yet been approved by admin, owner y renter',
  MEANING_FINISHED_RESTITUTIONS:
    'The property has a visit scheduled, but the restitution voucher has not yet been approved by admin, owner y renter',
  MEANING_OF_VISITS:
    'Here you can understand the meaning of each state of visits on the table',
  MEANING_1: 'This block can be made available',
  MEANING_2: 'This block is locked',
  MEANING_3: 'This block is available for visits',
  PER_MONTH: 'Month',
  PER_MONTHS: 'Months',
  PHOTO_ORDER_SENT: 'Photo order sent',
  PUBLISH_MY_PROPERTY: 'Publish my property',
  TOTAL_AREA: 'Total sfc',
  UNIT_NUMBER: 'House/Apt. nº',
  CHOOSE_VISIT_TIME: 'Choose visit time',
  UPDATE_PASSWORD: 'Change password',
  PASSWORD_DO_NOT_MATCH: 'Passwords are not same',
  PROMOTION_TITLE: 'Publish your property now and seize the promotion',
  PROMOTION_CONDITIONS:
    '*Promotional conditions stipulated in the administration contract, it applies to properties once they are leased',
  NEED_TO_VERIFY_EMAIL:
    'We are almost there! We only need to verify that we saved your email address.',
  CHECK_YOUR_INBOX:
    'For this we ask you to check your inbox and confirm it in the email that we just sent you.',
  USER_EXISTS:
    'User already exists, you must enter another email or log in by clicking the Login option.',
  FLOOR_TO_CEILING_HEIGHT: 'Floor to ceiling height',
  OWNER_TYPE: 'Are you the owner of the property?',
  SUITE_BEDROOM_COUNT: 'En suite bedrooms',
  BEDROOM_COUNT: 'Bedrooms',
  BATHROOM_COUNT: 'Bathrooms',
  RESIDENT_TYPE: 'Does someone currently live in the property?',
  INNER_AREA: 'Inner area',
  ESTIMATE_PRICE: 'Monthly Rent Amount',
  MISSING_FIELDS: 'Missing fields:',
  TERRAIN_AREA: 'Terrain area',
  FULL_NAME: "Renter's full name",
  PROMOTION_DESCRIPTION: 'This promotion consists on:',
  CHANGE_OF_PASSWORD: 'Change of password',
  CHANGE_OF_PASSWORD_SUCCESSFUL: 'Successful password change',
  PASSWORD_UPDATE: 'Your password has been updated successfully',
  VERIFY_REFERRED_CODE: 'Enter a valid referral code',
  IDENTITY_DOCUMENT_UPLOAD:
    'For a better experience you must upload your identity card.',
  USERLIST_REQUEST_SENT:
    'The request is being processed. In a moment the file will be sent to your email',
  SEND_USERLIST: 'Send user list',
  SIMPLE_DATA_SAVED: 'Data saved',
  PROFILE_REMINDER:
    "Remember that you have to <span class='font-weight-bold'>SEND</span> your profile so we can review it and you can continue with the process",
  PROFILE_HAS_BEEN_SAVED: 'Your data have been saved!',
  OK_LETS_GO: "Ok, let's go!",
  PROPERTY_DATA_TITLE: 'Data of your property',
  COMMON_EXPRENSES_PLACEHOLDER: 'Enter estimated amount',
  OTHER_SELECTED_ROLE_PLACEHOLDER: 'Broker, Service Provider, etc.',
  VISIT_SCHEDULED_SHORT: 'Your visit was scheduled!',
  WE_RECOMMEND_CREATING_ACCOUNT:
    'We recommend creating your account  in order to proceed faster in your process in Tango',
  CREATE_MY_ACCOUNT: 'Create my account',
  HAVE_A_REFERENCE_CODE: 'I have a reference code',
  COMMUNICATE_WITH_TANGO_MESSAGEBIRD:
    'Communicate with tango through chat by clicking ',
  SUCCESS_FEE_DISCOUNT_DETAIL: 'of success fee discount',
  ADMIN_FEE_DISCOUNT_DETAIL: 'of administration fee discount for ',
  WE_INVITE_COMPLETE_USER_PROFILE:
    'We invite you to complete the information on your profile (optional)',
  DO_IT_LATER: "I'll do it later",
  HOUSE_DPTO: 'House / Apt. nº',
  TOTAL_M2: 'Total m²',
  EXAMPLE_UNIT_NUMBER: 'e.g. Nº 10',
  WANT_RENT:
    'If you want to rent this property, you must attach all your documents',
  HAVE_ACCOUNT: 'Do you already have an account?',
  SIMPLE_STEPS_VISIT: 'Schedule your visit in simple steps',
  CONTACT_DATA: 'Indicate your contact data',
  UNIT_DATA: 'Enter the data of your property',
  SELECT_VISIT_TIME: "Select a time from our photographer's schedule",
  AND_THATS_IT: "And...That's it!",
  CHECK_INBOX_TO_CONTINUE: 'Check your inbox to continue with the process',
  OK: 'Ok!',
  VERIFICATION_MISSING:
    'You just need to verify your account. You can request the verification email by clicking ',
  ALREADY_VERIFIED_USER:
    'This email ​​is already registered with us. Login to your account with your email and password.',
  CANNOT_USE_GOOGLE_EMAIL:
    'Your email is already registered with Google, sign in using Google',
  DISABLED_ACCOUNT: 'Your account is disabled. Contact Tango by ',
  REGISTRATION_MISSING:
    'You just need to finish your registration. You can request the email to continue by clicking ',
  STEP_ALREADY_DONE: 'Did you complete this step? You can continue ',
  USER_DOES_NOT_EXIST: 'User does not exist',
  CONTINUE_FROM_THIS_STEP: 'Now you can continue from this step',
  ID_REDIRECTION_INSTRUCTION:
    'In order to send your profile, you need to upload your identity document',
  PERFECT: 'Perfect!',
  COMPLETE_YOUR_PROFILE: 'Complete your Profile',
  CREATE_PROPERTY: 'Create Property',
  REMIND_YOU: 'We also remind you that:',
  ID_UPLOADED: 'Identity document uploaded.',
  FULL_ADDRESS_FORM_SUGGESTION:
    'In order to continue we need you to complete the following information',
  FULL_ADDRESS_FORM_CONFIRMATION:
    "Do you confirm that this is your personal address and it's different from the property's address  you want us to administer?",
  REMEMBER_TO_SEND_PROFILE:
    "Do not forget to <span class='font-weight-bold'>SEND</span> your profile so it can be approved and you can lease with Tango",
  WATER_HEATING: 'Water heating',
  CAULDRON: 'Cauldron',
  CALEFONT: 'Calefont',
  CENTRAL: 'Central',
  ELECTRIC_WATER_HEATER: 'Electric water heater',
  OTHER_SYSTEM: 'Other',
  I_WANT_TO_SIGN_UP: 'I want to sign up',
  WATER_HEATING_SYSTEM: 'Water heating',
  PROFILE_STATE: 'Profile state',
  UPLOAD_YOUR_IDENTITY_DOCUMENT:
    '*Remember that for your data to be completed, you must upload your identity document',
  PROPERTY_WITH_TANGOBOX: '*Property with Tangobox',
  CANNOT_PUBLISH:
    'You must fill in all fields of the property before you can publish it',
  FINISHED: 'Finished',
  HOW_TO_LEASE_MY_PROPERTY: 'How to lease my property',
  REGISTER_OWNER: 'Register as a owner',
  REGISTER_RENTER: 'Register as a renter',
  HELP_TO_SCHEDULE_VISIT_MESSAGE:
    'Hi, I need help to schedule a visit in this property',
  REGISTRATION_PENDING:
    'You just need to finish your registration. We have sent you an email so you can continue',
  REGISTRATION_STEP_PENDING:
    'Oops! You need to finish a step before scheduling with a photographer',
  RENTER_HELP_TO_SCHEDULE_VISIT:
    "If there are no times available to schedule a tour, <span class='text-color-secondary cursor-pointer'>contact us here</span>",
  OWNER_HELP_TO_SCHEDULE_VISIT:
    "If there are no times available for your visit, <span class='text-color-secondary cursor-pointer'>contact us here</span>",
  DATA_EMPTY_REQUIRED:
    'There are fields without completing, please check the form to be able to send it.',
  UPLOAD_ONE_PHOTO: 'You must upload at least one photo',
  PROPERTY_NO_VERIFIED: 'The property can not be verified',
  USER_SEARCH: 'Search by email or name',
  LOW_AMOUNT:
    'Unfortunately the rental amount exceeds the amount approved for your profile, you can request a reevaluation by our Tango team ',
  VIDEO_REQUEST_ERROR:
    'Video could not be uploaded. Try with another video or check your internet connection',
  SEND_TEST_ID: 'Send test ID',
  I_WANT_RENT_AMOUNT_EXCEEDS:
    'Hi, I want to rent this property but the amount of the rent exceeds the one approved for my profile',
  ONCE_ACCOUNT_VALIDATED: 'Once your account is validated you can use Tango',
  DETAIL: 'Detail',
  STATE: 'State',
  CONTRACT_TYPE: 'Contract type',
  PROPERTY_ADDRESS: 'Property address',
  LEASE_RENEWAL: 'Lease renewal',
  CLOSURE_LEASE: 'Request lease closure',
  LEASE_TERMINATION: 'Lease termination',
  DAYS_LEFT_FOR_RENEWAL: "There are <span class='font-weight-bold'>{days_left} days</span> ({due_at}) left for the expiration of your lease.",
  SOME_OPTIONS: 'Select one of these options:',
  WE_ARE_SORRY: 'We are sorry that you do not want to renew this lease',
  TELL_YOUR_REASONS:
    'To continue with this process we need you to tell us your reasons:',
  WE_REMIND_YOU:
    'This information is confidential and can only be seen by the Tango team',
  RENEW_LEASE_AGREEMENT: 'Renew lease agreement',
  NO_RENEW_LEASE_AGREEMENT: 'No renew lease agreement',
  INDICATE_YOUR_REASONS: 'Here you can indicate your reasons',
  SUBMIT_RENEWAL_PROPOSAL: 'Submit renewal proposal',
  SUBMIT_RENEWAL_REJECTION: 'Send non-renewal of contract',
  RENEWAL_REJECTION_SENT: 'The lease will not be renewed',
  RENEWAL_REJECTION_SENT_MEDIATION: 'Lease contract was mediated',
  RENTER_HELP_TO_NEW_UNIT_CREATION:
    'Hello, I need help to register my property',
  ARE_YOU_SURE_TO_REJECT:
    'Are you sure to not renew this contract for the reasons indicated?',
  ARE_YOU_SURE_TO_DELETE_IMAGE:
    "Are you sure you want to delete the <span class='font-weight-bold'> image Nº{image_number} from the carousel</span>?",
  WE_HAVE_SENT_YOUR_INFORMATION:
    'A person from our team will contact you to accompany you in this process',
  CONTRACT_STATUS_REJECTED:
    'The lease for this property is in <span class="text-color-secondary">NOT RENEWED</span> status',
  CONTRACT_STATUS_MEDIATION:
    'The lease for this property is in <span class="text-color-secondary">MEDIATION</span> status',
  TAKE_INTO_CONSIDERATION:
    'Take into consideration the steps to follow to continue with Tango',
  AN_ADMIN_WILL_CONTACT_YOU:
    'An Administrator will contact you to give you information and respond to any question you may have',
  AN_ADMIN_WILL_CONTACT_YOU_REJECT:
    'An Administrator will contact you to schedule the Restitution of the property',
  AN_ADMIN_WILL_CONTACT_YOU_2: 'An Administrator will contact you',
  PROCESS_RESTITUTION: 'We will return the property',
  PROCESS_RESTITUTION_REJECT:
    'The process of Restitution of the property will begin',
  REPUBLICATION_PROCESS: 'We will republish the property',
  PROPERTY_INFORMATION_UPDATE:
    'We will update the information of your property',
  FIND_A_NEW_RENTER: 'We will find a new renter',
  WAIT_FOR_RENTER_ACCEPTANCE:
    "Now you must wait for the renter's response, a Tango admin will be with you in this process.",
  RENEWAL_PROPOSAL_SENT: 'Proposal sent',
  NEW_LEASING_PRICE: 'New leasing price',
  CONGRATS_FOR_RENEWING:
    'We are happy that you continue leasing your property with us!',
  PROPOSE_READJUSTMENT:
    'Propose a readjustment in the price of the lease to: ${priceSuggestion} according to the inflation (increase of {percentage}%)',
  KEEP_CURRENT_PRICE: 'Keep the current price ${currentPrice}',
  PROPOSE_NEW_PRICE: 'Propose a new price',
  NEW_PRICE_VALIDATION: 'The new price cannot be lower than zero',
  SELECT_RENEWAL_OPTIONS:
    'To continue with this process you must select one of the following options:',
  RENTER_ACCEPTS_CURRENT_PRICE:
    'I accept the renewal of the lease keeping the value of the lease to ${currentPrice}',
  RENTER_ACCEPTS_PROPOSED_PRICE:
    'I accept the renewal of the lease with a readjustment in the price of the lease to ${proposedPrice} ({variation} of {percentage}%)',
  THANKS_FOR_CONTINUING_WITH_US: 'We appreciate that you continue with us',
  RENEWAL_SENT: 'Renewal sent successfully',
  RENTER_ACCEPTS_READJUSTMENT:
    'You accept the lease renewal with the informed readjustment proposal',
  RENTER_INTERESTED_IN_REJECTING:
    'You are interested in renewing but wish to propose a different amount than the reported readjustment, or you can decide not to renew.',
  OWNER_PRICE_READJUSTMENT:
    '<strong>The owner has proposed a <span class="text-color-secondary">new lease price</span> of <span class="text-color-secondary">{proposedPrice}</span>, equivalent to a {percentage} {variation} (based on the inflation for the period)</strong>',
  CURRENCY_INFO: '<strong>By accepting the new lease price, you confirm that your contract was agreed in {currency}</strong>',
  PROPERTY_CONTRACTS_AND_ACTIONS: 'Contracts and property actions',
  PENDING_UPLOAD: 'upload pending',
  MEDIATION: 'Mediation',
  RENEWAL_MEDIATION_PROCESS: 'Lease renewal process',
  PROPERTY_HISTORY: 'Property history',
  PROPERTY_REGISTER: 'Tango property registration',
  VISIT_SCHEDULE_BY_PHOTOGRAPHER: 'Visit scheduling by photographer',
  PROPERTY_INFORMATION_REVIEW: 'Property information review',
  CONTRACT_STATUS_ACCEPTED:
    'The lease for this property has been <span class="text-color-secondary">{state}</span>',
  LEASE_ANNEX_AVAILABILITY: 'Thanks for staying with us',
  CONTRACT_STATUS_PENDING:
    'The lease renewal for this property is <span class="text-color-secondary">IN PROCESS</span>',
  ANNEX_CREATION: "Wait for {other_part}' response",
  RENTER_CONFIRMS_RENEWAL:
    'In order to continue with this process you must confirm your decision:',
  RENTER_PRICE_PROPOSAL:
    "I don't accept the proposed price, I wish to propose a new lease price",
  LEASE_RENEWAL_REJECTION: 'I do not want to continue with the lease',
  RENEWAL_REFUSAL: 'Do not renew',
  THE_LESSEE_RESPONDED_NEW_VALUE:
    '<strong>The renter has proposed a <span class="text-color-secondary">new lease price</span> of <span class="text-color-secondary">{proposedPrice}</span></strong> (Reference value: {reference}), <strong>equivalent to {variation} of {percentage}</strong>',
  THE_LESSEE_RESPONDED_NEW_VALUE_EQUIVALENT:
    '<strong>The renter has proposed a <span class="text-color-secondary">new lease price</span> of <span class="text-color-secondary">{proposedPrice}</span></strong> (Reference value: {reference}), <strong>equivalent to current lease</strong>',  
  INCREASE_TEXT: 'increase',
  DECREASE_TEXT: 'decrease',
  LEASE_CONTRACT_WILL_BE_RENEWD:
    'The lease contract will be renewed automatically when the period of 60 days prior to the expiration of the contract expires.',
  IMPORTANT_TEXT: 'Important',
  WE_UNDERSTAND_WITH_OPTIONS:
    'We are sorry that you do not want to renew this lease...Here we give you a few alternatives to confirm your decision',
  PERCENTAGE_INCREASE: 'This increase corresponds to a ',
  COUNTERPROPOSAL_TITLE: 'Counterproposal of lease price',
  COUNTERPROPOSAL_CONFIRMATION: 'Are you sure about sending this proposal?',
  COUNTERPROPOSAL_WARNING:
    'You are about to send a counterproposal with a lease price lower than the current price',
  CONTRACT_STATUS_NEGOTIATING:
    'The lease for this property is in a <span class="text-color-secondary">NEGOTIATION</span> status',
  MUTUAL_AGREEMENT: 'Agreement between the parties',
  POSSIBLE_RENEWAL: 'Lease renewal / No lease renewal',
  NO_RENEWAL_CASE:
    'If there is no lease renewal, profile information update; in case you want to continue renting in Tango',
  FIND_NEW_LEASE:
    'Find a new lease (in case you want to continue renting in Tango)',
  FIND_NEW_LEASE_REJECT: 'We will find your new home',
  PERCENTAGE_DECREASE: 'This decrease corresponds to a ',
  WE_ARE_HAPPY_YOU_RENEW: 'We are happy you want to renew your lease!',
  WAIT_FOR_OTHER_PART: "Now you must wait for the {other_part}'s response.",
  PART_OWNER: 'owner',
  PART_RENTER: 'renter',
  ADMIN_COMPANY: 'A Tango administrator will be with you during this process',
  COUNTERPROPOSAL_CONFIRMATION_QUESTION:
    'Are you sure you want to send this counterproposal to the owner?',
  KEYS_HANDOVER: 'Handover of keys',
  KEYS_HANDOVER_REJECT: 'We will coordinate the delivery of keys',
  PROFILE_UPDATE: 'Update of profile information',
  PROFILE_UPDATE_REJECT:
    'We will ask you for the information to update your profile information',
  RENEWAL_NEWS: 'News about your renewal',
  OWNER_SENT_PROPOSAL:
    'The owner of your lease sent a proposal for this process',
  CHECK_PROPOSAL: 'You can check it here',
  CHECK: 'Check',
  ACCEPT_RENEWAL: 'Accept renewal',
  AN_INCREASE: 'increase',
  A_DECREASE: 'decrease',
  RENEWED: 'Renewed',
  RENEWAL_REJECTED: 'The non-renewal of the contract was sent successfully',
  RENEWAL_UPDATE: 'The renewal proposal was sent successfully',
  ADMIN_MEDIATION:
    "<strong>The administrator has proposed a <span class='text-color-secondary'>new rental price</span> of <span class='text-color-secondary'>{price}</span></strong> (Valor referential {reference}), <strong>equivalent to {percentage_number}  of {percentage_text}.</strong>",
  CONTRACT_STATUS_RENEWED:
    'The lease for this property has been <span class="text-color-secondary">{state}</span>',
  ACCEPT_MEDIATION: "Accept administrator's proposal",
  REJECT_MEDIATION: "Reject administrator's proposal",
  NOT_ACCEPT: 'Not accept',
  MEDIATION_RESPONSE_SENT: 'Mediation response',
  PROPOSE_NEW_PRICE_ADMIN: 'Propose a new price',
  NEW_PROPOSED_PRICE: 'New proposed price',
  NEW_PROPOSED_PRICE_REASON: 'Reasons for new proposal (Optional)',
  NO_RENEWAL_REASON: 'Reasons for non-renewal',
  MEDIATION_PROCCESS: 'Mediation proccess',
  NEGOTIATING_STATE: 'IN THE NEGOTIATION PROCESS',
  MEDIATION_ACCEPTED_STATE: 'IN AWAITING REPLY',
  NEGOTIATING_PROCESS: 'IN NEGOTIATION PROCESS',
  IN_PROGRESS_STATE: 'IN PROCESS',
  MEDIATION_STATE: 'IN MEDIATION',
  LEASE_ANNEX_SIGNING: 'IN THE PROCESS OF SIGNING',
  NOT_ALLOWED_AMOUNT: 'Amount is not allowed, enter a higher amount',
  IF_YOU_HAVE_QUESTION: 'If you have any questions, you can contact us ',
  SIGN_CONTRACT_ANNEX: 'Sign contract annex',
  WAIT_SIGNING_OWNER: "Wait for owner's signature",
  SEE_CONTRACT_ANNEX: 'See contract annex',
  DELIVERY_CERTIFICATE: 'Delivery certificate',
  DELIVERY_CERTIFICATE_REJECT: 'The delivery certificate will be generated',
  OUR_TEAM_WILL_SEND_YOU_A_PROPOSAL:
    'Our team will send you a proposal with what was agreed by the parties',
  ANSWER_RECEIVED_PROPOSAL: 'Accept/Not accept received proposal',
  WAIT_OWNER_RESPONSE: "Wait for owner's response",
  WAIT_RENTER_RESPONSE: "Wait for renter's response",
  WAIT_SIGNING_RENTER: "Wait for renter's response",
  CONTACT_INFORMATION: '<strong>CONTACT INFORMATION</strong>',
  RENTER_CONTACT: 'Renter contact',
  OWNER_CONTACT: 'Owner contact',
  RENTER_NAME: 'Renter name',
  OWNER_NAME: 'Owner name',
  RENTER_EMAIL_2: 'Renter email',
  OWNER_EMAIL: 'Owner email',
  INFORMATION_CONSIDER: 'Information to consider',
  DAYS_LEFT_FOR_MEDIATION:
    '{days_left, plural, =1 {There is} other {There are}} <strong>{days_left, plural, =1 {1 day} other {# days}}</strong> for the end of the mediation period.',
  SUGGESTED_PRICE:
    "A rental price of <strong class='text-color-secondary'>{suggested_price}</strong> is suggested, which considers an inflation adjustment of {inflation}",
  CURRENT_INFLATION_VALUE:
    'The current value of inflation is a {inflation} (calculated based on the percentage change in the UF in the period of the lease)',
  CURRENT_LEASE_VALUE: 'The current lease price is {currentLeasePeriodPrice}',
  ENTER_REASON: 'Enter the reason for the selected option',
  ENTER_VALUE: 'Enter the proposal amount',
  SELECT_OPTION: 'Select an option',
  ARE_YOU_SURE_TO_REJECT_ADMIN: 'Are you sure not to renew the lease?',
  ARE_YOU_SURE_TO_PROPOSE_ADMIN:
    'Are you sure to send the proposal for the indicated price?',
  NEW_PRICE_PROPOSE: 'New price proposal',
  NO_RENEWAL: 'No renewal',
  NEW_LEASE_PROPOSAL: 'New lease price proposal',
  RENEWAL_REJECTED_SENT: 'No renewal sent',
  DATA_SENT_TO_RENTER_OWNER:
    'The information has been sent to the owner and the renter.',
  CONTINUE_PROCCESS:
    'To continue with this process, you must wait for the response from both parties.',
  SEND_MEDIATION_RESPONSE: 'Send mediation response',
  NO_CONTRACT_RENEWAL: 'No lease renewal',
  ARE_YOU_SURE_TO_REJECT_MEDIATION:
    'Are you sure you do not want to renewa this lease contract?',
  RESPONSE_SENT: 'Your response has been sent.',
  ADMIN_COMPANY_FOR_DOUBTS:
    'Remember that an administrator will be with you solving your doubts',
  DATA_NO_RENEWAL_SENT: 'Non-renewal information sent.',
  CONTACT_PHOTOGRAPHER:
    'You must contact the tanguero/photographer to continue with the process',
  SEND_NEW_PROPOSAL: 'Send new proposal',
  OWNER_PROPOSAL_ACCEPTED: 'Owner accepted the proposal',
  RENTER_PROPOSAL_ACCEPTED: 'Renter accepted the proposal',
  OWNER_PROPOSE:
    'Owner made a proposal for an amount of <strong>{price}</strong> corresponding to an inflation of <strong>{inflation}</strong>',
  RENTER_PROPOSE:
    'Renter made a proposal for an amount of <strong>{price}</strong> corresponding to an inflation of <strong>{inflation}</strong>,',
  OWNER_PROPOSE_UF:
    '<strong>{owner}</strong> sent a proposal for a new lease price of {price} <span class="text-color-tertiary">(Reference: {reference})</span> {date} {hour}',
  RENTER_PROPOSE_UF:
    '<strong>{renter}</strong> sent a proposal for a new lease price of {price} <span class="text-color-tertiary">(Reference: {reference})</span> {date} {hour}',
  SEND_COUNTERPROPOSAL: 'Send counterproposal',
  RENEWAL_ACCEPTED: 'Accepted lease renewal',
  MEDIATION_END: 'The mediation process is over',
  REJECT_COUNTERPROPOSAL: 'I do not agree with the proposal',
  WE_ARE_SORRY_COUNTERPROPOSAL_REJECTION:
    "We are sorry you do not agree with renter's counterproposal",
  MEDIATION_CONFIRMATION:
    'Continuing with this decision will start a mediation process.',
  MEDIATION_PROCESS: 'Mediation process',
  INFLATION_EXPLANATION:
    "{variation} of <span class='font-weight-bold'>{percentage}</span> based on the inflation for the period (estimated calculation, this may vary on the day the renewal occurs)",
  WAIT_RESPONSE_TEXT:
    "By sending your proposal you must wait for the {other_part}'s response",
  RECOMMENDED: 'Recommended',
  NEW_PRICE: 'New value',
  RENEW_WITH_PROPOSED_PRICE: 'Renewa with proposed price',
  REJECT_PROPOSED_PRICE: 'Reject the new lease price',
  RESTITUTION_WARNING:
    'By confirming your decision a property restitution process will begin',
  AN_ADMIN_WILL_CONTACT_YOU_LATER:
    'A Tango administrator will contact you later',
  REPUBLISH_PROPERTY: 'Republish the property',
  PROPOSE_A_PRICE: 'Propose a new value',
  BACK: 'Back',
  REJECTION_REASONS: 'Rejection reasons',
  ACCEPT_NEW_PRICE: 'Accept new lease price',
  POPOVER_INFLATION_INFO:
    'Calculated from the percentage value of the UF in the period of the lease. (CPI)',
  PRICE_CAN_VARY:
    'This price may vary according to the calculation to be made on the date on which the renewal will take place.',
  PRICE_DIFFERENCE:
    "{variation} of <span class='font-weight-bold'>{percentage}</span> based on the current lease price",
  OWNER_PRICE_WITHOUT_INFLATION:
    '<strong>The owner has proposed a <span class="text-color-secondary">new lease price</span> of <span class="text-color-secondary">{proposedPrice}</span></strong> (Reference value: {price}), <strong>equivalent to a {variation} of {percentage}</strong>',
  OWNER_PRICE_WITHOUT_INFLATION_EQUIVALENT:
  '<strong>The owner has proposed a <span class="text-color-secondary">new lease price</span> of <span class="text-color-secondary">{proposedPrice}</span></strong> (Reference value: {price}), <strong>equivalent to current lease</strong>',
  MEDIATION_DAYS_OVERDUE_FOR_ADMIN:
    'The mediation period has ended for the parties',
  MEDIATION_DAYS_OVERDUE:
    '{days_left, plural, =1 {There is} other {There are}} <strong>{days_left, plural, =1 {1 day} other {# days}}</strong>. The mediation period has ended',
  DAYS_LEFT_FOR_MEDIATION_ADMIN:
    '{days_left, plural, =1 {There is} other {There are}} <strong>{days_left, plural, =1 {1 day} other {# days}}</strong> for the end of the mediation period of the parties',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  HOURS: 'hours',
  AGO: 'ago',
  TRANSITION_TIME_ERROR: 'You must enter a value between 3 and 5 seconds',
  FROM_NOTIFIER: 'from',
  NEGOTIATION_DAYS_LEFT: "You have <span class='font-weight-bold'>{days} days</span> to take action. After this period, the lease will be automatically renewed",
  NEGOTIATION_DAYS_INFO: 'After this period, the lease will be automatically renewed with a price adjustment corresponding to the inflation for the period.',
  END_OF_LEASE: 'End of lease',
  TITLE_REPUBLISHED: 'Republished',
  PENDING_REPUBLISH: 'Pending republication',
  REQUISITION: 'Requisition',
  NO_REQUISITIONS: 'No requisitions',
  REPUBLISH: 'Republish',
  CURRENT_LEASE_PRICE: 'Current lease price',
  INITIAL_LEASE_PRICE: 'Initial lease price',
  LAST_RENEWAL_DATE: 'Last renewal period',
  LAST_LEASE_PRICE: 'Last lease price',
  BANK_DETAILS_SOMEONE_ELSE:
    'Do the bank details you entered belong to someone else?',
  LEASE_TERMINATION_ANNEX: 'Lease termination annex',
  LEASE_RENEWAL_PERIOD: 'Lease renewal',
  IS_IMPORTANT_YOU_INFORM_US:
    'It is important that you inform us if the data you entered is yours or belongs to another person. In this way we can avoid errors at the time of payments.',
  THIRD_PARTY_BANK: 'Third party bank details',
  SCHEDULE_TOUR_WITH_TANGO_MESSAGEBIRD:
    'To schedule a tour communicate with tango through messagebird chat',
  NEXT_PERIOD_DATE: 'Next period start date',
  SIGNED: 'Signed',
  LEASE_RENEWAL_PERIOD_DATE: 'Renewal date',
  SELECT_PAYMENT_METHOD: 'Select payment method',
  SERVIPAG_METHOD_TEXT:
    'Electronic payments charged to checking account, sight or Rut account. Electronic payments with cards from commercial houses (CMR, Ripley, Cencosud).',
  PADPOW_METHOD_TEXT:
    'Electronic payments with credit and debit cards from national banks.',
  NEAT_METHOD_TEXT: 'All national debit and credit cards',
  NEAT_METHOD_TEXT_DISABLED: "<span class='text-color-dark-gray'>All national debit and credit cards.</span>",
  NEAT_METHOD_INFO: "Payment with <span class='font-weight-bold'>Neat</span>, is it availabe <span class='font-weight-bold'>the first 5 days</span> of each month.",
  NEAT_METHOD_TITLE: 'Payment with Neat',
  PAYMENT_METHODS_FINTOC: 'Pay with Fintoc',
  PAYMENT_METHODS_FINTOC_SUCCESS: 'Payment successful, charges will be updated in a couple of minutes',
  PAYMENT_METHODS_FINTOC_FAILED: 'We are sorry, Fintoc is out of service at this moment',
  GO_TO_NEAT: 'Go to pay with Neat',
  NEAT_METHOD_INFO_POPUP: 'If you do not have a Neat account, you will need to register.',
  NEAT_METHOD_INFO_POPUP_2: 'Keep in mind that payments made in Neat will be reflected after 5 business days on the Tango website.',
  ADMIN_CONTRACT: 'Administration contract',
  ADMIN_CONTRACT_ANNEX: 'Administration contract annexes',
  LEASE_CONTRACT_ANNEXES: 'Lease contract annexes',
  DELIVERY_VOUCHER: 'Delivery voucher',
  RESTORATION_VOUCHER: 'Restoration voucher',
  RENEWAL_ACCEPTANCE: 'Renewal Acceptance',
  RENEWAL_REJECTION: 'Renewal rejection',
  DOCUMENT_TYPE: 'Document type',
  DOCUMENT: 'Document',
  UPLOAD: 'Upload',
  INFO_UPLOAD_DOCUMENTS: 'The supported format is PDF',
  FILE: 'File',
  NAME_DOCUMENT_INFO: 'Specific name of the document',
  SELECT_AN_OPTION: 'Select an option',
  SELECT_FILE_TO_UPLOAD: 'Choose the file to upload',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  ENABLE_DOCUMENT: 'Enable document',
  DISABLE_DOCUMENT: 'Disable document',
  PENDING_DOCUMENT_STATE: 'The document has not been approved or rejected',
  ENABLE_DOCUMENT_STATE: 'The document has been approved by an admin',
  DISABLE_DOCUMENT_STATE:
    'The document has been rejected by an admin or was replaced by another that was enabled',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  DOCUMENT_STATE: 'Document states',
  DOCUMENTS_LIST: 'Documents List',
  CREATED_DATE: 'Created date',
  UPDATED_DATE: 'Updated date',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  LEASE_DOCUMENTS_TITLE: 'Lease documents',
  PROPERTY_DOCUMENTS_TITLE: 'Property documents',
  REJECT_DOCUMENT: 'Reject document',
  APPROVE_DOCUMENT: 'Approve document',
  ENABLING_DOC_CONFIRMATION: 'Are you sure you want to enable this document?',
  SEE_DOCUMENT: 'See document',
  WRONG_VALUE: 'The entered value is not correct',
  PLAN_FULL: 'Full Tango',
  PLAN_BROKERAGE: 'Only brokerage',
  PLAN_ADMINISTRATION: 'Only administration',
  BEFORE_CREATING_BROKERAGE:
    'Before starting your lease, learn more about our service',
  BEFORE_CREATING_BROKERAGE_2:
    'We invite you to discover a new experience with us',
  TITLE_CREATE_PROPERTY_BROKERAGE: 'Register your property in Tango',
  DESCRIPTION_CREATE_PROPERTY_BROKERAGE:
    'This process will not take more than <span class="text-color-secondary">5 minutes<span>.',
  TITLE_VERIFY_AND_COMPLETE_INFO: 'Verify and complete the information',
  DESCRIPTION_VERIFY_AND_COMPLETE_INFO:
    'Make sure you enter the correct data so that your publication is complete.',
  TITLE_VISIT_SCHEDULE: 'Schedule a visit with one of our Photographers',
  DESCRIPTION_VISIT_SCHEDULE:
    'They will take professional photos and videos of your property, upload all the information in less than 48 hours and your property will be published.',
  TITLE_OPTIONAL_TANGO_BOX: 'Tangobox (Optional)',
  DESCRIPTION_OPTIONAL_TANGO_BOX:
    'If your property is empty without furniture or renters, we will install a Tangobox, a key storage device, which allows you to receive visits to your property without the need for you to be present.',
  DESCRIPTION_PROPERTY_PROPOSALS_BROKERAGE:
    'Each potential renter will go through an extensive credit analysis, where we will assign a Tango score, to ensure that we select the best candidates. <br> We will send you all the proposals through our platform, where you will be able to see the details of each candidate, so that you can make the best decision.',
  DESCRIPTION_SIGNATURE_BROKERAGE:
    "Review and <span class='text-color-secondary'>sign the Lease</span> contract <span class='text-color-secondary'>online with Tango</span>.",
  DONE: 'Done',
  DESCRIPTION_DONE: 'We take care of the rental of your property',
  MIN_PROPERTY_VALUE: '$200.000',
  DESCRIPTION_BROKERAGE_SERVICE:
    "We publish, show, lease and deliver your property.<br><br>We review the ability to pay and profile of potential candidates.<br><br>The <span class='text-color-secondary'>signing process contract is 100% online</span>.",
  COMMISSION: 'Commission',
  REGISTER_MY_PROPERTY: 'Register my property',
  NOW_REGISTER_PROPERTY: 'Now you can register your property in',
  SELECT_PLAN: 'Choose plan',
  SELECT_A_PLAN: 'Choose a plan',
  REGISTER_YOUR_PROPERTY: 'Register your property',
  PHOTOS_VIDEOS_SESION: 'Professional photo and video session.',
  PUBLICATION_MAIN_PORTALS: 'Publication in main portals.',
  SHOW_PROPERTY: 'Show property.',
  SIGN_CONTRACT_ONLINE: 'Online signing of the contract.',
  RENTER_ANALISYS: 'Rigorous analysis of the renter.',
  MANAGMENT_PROPERTY: 'Management of property arrangements.',
  NON_PAYMENT_COVERAGE:
    'Non-payment coverage and management of the eviction process.',
  MONTHLY_PAYMENT: 'Monthly rent payment on the 15th of each month.',
  REVIEW_APP: 'Online control panel to review applications and payments.',
  PLAN_FULL_TAB: 'Full Tango',
  TRANSACTIONS_PAYED: 'The transactions were payed',
  TRANSACTIONS_UNPAYED: 'The transactions have been returned to pending status',
  TANGO_TERMS_AND_CONDITIONS: 'Tango will update its terms and conditions.',
  ACCEPT_TERMS_AND_CONDITIONS: 'Accept terms and conditions',
  PLAN_TYPE: 'Tipo de plan',
  DATE_PAY_SERVICE: 'Service payment date',
  OFFER_ACEPTED_BROKERAGE:
    "The proposal has been accepted. The next step is <strong>digitally sign the contract</strong>.</br> You have 48 hours to do it, you just need your identity document and that's it!",
  PENDING_VOUCHERS: 'Pending vouchers',
  VOUCHER_ENABLED: 'Voucher enabled',
  RENTER_CONTACT_DATA: 'Renter data',
  LEASE_CONTACT_DATA: 'Contact data',
  SIMPLE_EMAIL: 'Email',
  OWNER_CONTACT_DATA: 'Owner data',
  SIMPLE_RUT: 'ID',
  TRANSFER_DATA: 'Transfer data',
  PLAN: 'Plan',
  INVITE_AND_EARN: 'Invite and earn',
  WHO_WE_ARE: 'Who we are',
  BLOG: 'Blog',
  LOG_IN: 'Log in',
  YOU_CAN_ALSO: 'Or you can also',
  TALK_SOMEONE_FROM_TANGO: 'Talk to someone from Tango',
  ANY_QUESTIONS: 'Any questions?',
  TALK_WITH_US: 'Lets talk',
  INTENTION_MESSAGE:
    'Hello, I need more information about your services on how to sign up',
  TANGO_REAL_ESTATE_PORTAL: 'Tango, your new Real Estate Portal',
  LOGO_ALT:
    'Tango real estate portal for administration and rental of properties in Santiago de Chile',
  PENDING_RESTORATION_VOUCHERS: 'Restoration vouchers',
  PENDING_DELIVERY_VOUCHERS: 'Delivery vouchers',
  SEE_RESTORATION_VOUCHER: 'See restoration voucher',
  MUST_SEE_DOCUMENT_BEFORE_VALIDATION:
    'You must view the document before validating it.',
  EMAIL_EXAMPLE: 'email@example.com',
  ENTER_PASSWORD: 'Enter your password',
  EVALUATION: 'Evaluation',
  MY_RENT: 'My rent',
  SEARCH_PROPERTY: 'Search properties',
  MY_DATA: 'My data',
  NOTIFICATIONS: 'Notifications',
  RECOVER: 'Recover',
  ALL_PROPERTY: 'All',
  RENT_A_PROPERTY_IN_TANGO: 'Rent your property in Tango and avoid worries',
  A_RENTAL_AND_ADMIN_PLATAFORM:
    'A rental and administration platform for owners and tenants with technology and a professional team that will make your process agile and secure.',
  ALTERNATIVE_TEXT_ZONA_PROPIA: 'Sell your property in record time with the Tango and Zona Propia alliance',
  FIND_YOU_NEW_HOME: 'Find your new home in Tango safely and reliably',
  PUBLISH_PROPERTY_HOME: 'Publish property',
  I_HAVE_A_PROPERTY: 'I have a property',
  RENT_A_PROPERTY_HOME: 'Rent a property',
  CLIENTS_TESTIMONIALS: 'Testimonials from our clients',
  TANGO_IS_GREYSTAR_HOME:
    "<strong>Tango is part of Greystar, the world's</strong> leading residential rental management company.",
  KNOW_OUR_ALLIANCES: 'Know our alliances',
  OUR_STRATEGIC_ALLIANCES:
    'Our strategic alliances allow us to empower and help our clients to better develop their investments:',
  YOU_HAVE_DOUBTS:
    'Do you still have doubts?<br/> <strong>Contact Us:</strong>',
  ONLINE_ADVICE: 'Online advice',
  EMAILHOME: 'Email',
  SIGN_UP_ON_TANGO: 'Sign up with Tango',
  ADDRESS_OF_PROPERTY: 'Property address',
  PROPERTY_BASIC_DATA: 'Property basic data',
  BROKERAGE_PAYMENTS: 'Brokerage payments',
  DOUBTS: 'Do you have doubts?',
  LETS_TALK: "Let's talk",
  REF_VALUE: 'Reference value',
  INDICATOR: 'Indicator',
  UF_INDICATOR_LABEL: 'The rental price corresponds to the value of the UF calculated on the first day of each month. For more information check the following',
  CLF_CONVERSION_INFO: "Reference value <strong>{price}</strong>",
  PRE_EVALUATION: 'We will carry out a pre-evaluation with your information, to confirm the visit to the selected property.',
  SCHEDULE_VISIT_INFORMATION: 'To schedule a visit we need the following information',
  MUST_BE_GREATER_THAN: 'Must be greater than {min} characters',
  SELECT_DAY_AND_TIME_RANGE: 'Choose the day and time range in which you want to schedule your visit',
  CHANGE_VISIT_DATE: 'Change visit',
  YOU_WILL_RECEIVE_AN_EMAIL: 'You will receive an email confirming your visit.',
  THESE_ARE_THE_DETAILS: 'These are the details of your visit',
  THANK_YOU_FOR_TRUSTING_TANGO: 'Thank you for trusting Tango!',
  ENTER_PHONE: 'Enter your phone',
  RENTER_LOOKING_RENT: "I'm looking to rent a property",
  OWNER_LOOKING_POST: 'I want to publish a property',
  ERROR_MAX_VALUE: 'The maximum value cannot be less than the minimum value',
  ORIGINAL_LEASE_VALUE: "The lease current price is  <span class='text-color-secondary font-weight-bold'>{currentLeasePeriodPrice}*</span>",
  CURRENT_UF_VALUE: 'Today UF value',
  CONTRACT_WILL_NOT_BE_RENEWED:
    'If no decision is made, this contract will not be renewed.',
  REF_VALUE_INFO: '(reference value: {price})',
  PROPOSE_NEW_VALUE: 'Propose a new value',
  REASON_PROPOSAL: 'You can add comments for the proposal of a new price',
  ADMIN_CONTACT: 'A Tango administrator will contact you',
  MEDIATION_HISTORY: 'Mediation history',
  ADMIN_PROPOSE:
    '<strong>{admin}</strong> sent a proposal for an amount of {price}, {date} {hour}',
  ADMIN_PROPOSE_UF:
    '<strong>{admin}</strong> sent a proposal for a <span class="text-color-secondary">new rental price</span> of <span class="text-color-secondary">{price}</span> <span class="text-color-tertiary">(reference: {reference})</span> {date } {hour}',
  CHANGE_CONDITION_CURRENCY: '<strong>, with a currency change to {currency}</strong>',
  ACCEPT_NEW_CURRANCY_CONDITION: 'By accepting the new rental price, you confirm that your contract will be agreed in ',
  NO_RENEW_LEASE_AGREEMENT_ADMIN: 'Do not renew lease',
  REF_VALUE_INFO_NO_PARENTHESES: 'Reference {price}',
  MONTHS: 'months',
  ENTER_YOUR_NAMES: 'Enter your first and last name',
  EMAIL_SAMPLE: 'email@example.com',
  WE_NEED_THIS_DATA: 'To register in Tango, we need the following data',
  MORE_INFORMATION: 'Hi, I would like to have more information about my credit status. My email is ${email}',
  REQUESTS: 'Requests',
  PENDING_REQUESTS: 'Pending requests',
  RENTER_WAIT_PRE_APPROVED: 'Pending pre-approval',
  RENTER_WAIT_PRE_EVALUATION: 'Pending pre-evaluation',
  RENTER_CONFIRM_PRE_APPROVED: 'Confirm pre-approval',
  RENTER_CONFIRM_PRE_APPROVED_TEXT: `Are you sure you want to change the user's status to <b>{state}</b>? This action cannot be undone`,
  OPTIONAL_COMMENTS: 'Comments (optional)',
  WRITE_COMMENTS_REJECT_APPROVED: 'Write the reasons for rejecting the pre-evaluation',
  REFERRED: 'Referred',
  KNOW_PERSON: 'Do you know this person?',
  REFERRED_CONFIRMATION_BUTTON: 'Yes, he invited me',
  REFERRED_REJECTION_BUTTON: "I don't know her",
  REFERRED_FULL_NAME: 'Referred you <strong>{full_name}</strong>',
  GO_TO_REGISTRATION: 'Go to registration',
  REFERRED_INFORMATION_1: 'To redeem your referral code',
  REFERRED_INFORMATION_2: 'you must <strong>register</strong> in <strong>Tango</strong>',
  REFERRED_INFORMATION_3: 'and <strong>publish your property!</strong>',
  GO_PAY: 'Go pay',
  NEAT_PAYMENT_ATTEMPT_INFO: "We register a payment attempt with Neat, before continuing check your bank account so as not to execute another payment. If you didn't pay for Neat ignore this message.",
  NEAT_PAYMENT_ATTEMPT: 'Payment attempt with Neat',
  REFERRED_CODE_NOT_FOUND: "Oops!, we couldn't find your referral code",
  SKIP: 'Skip',
  ENTER_NEW_REFERRED_CODE: 'Enter your code here',
  INVALID_REFERRED_CODE: 'Wrong code, try again',
  COMMUNE: 'Commune',
  END_TIME_TITLE: 'End',
  START_TIME_TITLE: 'Start',
  GENERAL_CAROUSEL_SETTINGS: 'General Carousel Settings',
  UPLOAD_IMAGES_ON_DEVICES: 'Upload images on devices',
  TRANSITION_TIME: 'Transition time',
  TRANSITION_TIME_INFO: '(duration of each slider. min 3 sec - max 5 sec.)',
  IMAGE_DESKTOP_INFO: 'Upload a 1440 x 600 px image. Maximum weight. 400kb in JPEG PNG format',
  IMAGE_MOBILE_INFO: 'Upload a 360 x 300 px image. Maximum weight. 400kb in JPEG PNG format',
  IMAGE_TABLET_INFO: 'Upload a 994 x 460 px image. Maximum weight. 400kb in JPEG PNG format',
  CAMPAIGNS: 'Campaigns',
  CAROUSEL_IMAGES: 'Carousel images',
  DELETE: 'Delete',
  ADD_IMAGES: 'Add images',
  PRIORITY: 'Priority',
  SCHEDULE_NOT_AVAILABLE_VISIT: 'There are no times available for your visit',
  SCHEDULE_WITH_AN_TANGUERO: 'Schedule with an executive',
  MESSAGEBIRD_PERSONAL_DATA: "Hi, I'm {name}, rut: {rut}, phone {phone_number}, email {email}, and I need to schedule a tour at {address} property. {route}",
  LEASE_AVAILABLE_FROM: 'Lease available from: ',
  NO_TOC_MODULE: 'The information has not been loaded',
  UPLOAD_SUCCESS: 'Uploaded successfully',
  NO_FORGET_UPDATE_PROFILE: `Don't forget to complete your profile`,
  GO_TO_PROFILE: 'Go to profile',
  I_NEED_HELP: 'Hi, I need help',
  CURRENT_PROMOTIONS: 'Current promotion',
  ACTIVE_PROMOTIONS: 'Active promotions',
  NO_ACTUAL_PROMOTION: 'Dont have a current promotion',
  NO_AVAILABLE_PROMOTIONS: 'There is not available promotions',
  PROMOTION_ACTIVE: 'Active',
  PROMOTION_FINISHED: 'Finished',
  PROMOTION_UNINITIATED: 'Uninitiated',
  CHANGE_PROMOTION: 'Change Promotion',
  CONFIRM_CHANGE_PROMOTION: 'Are you sure that you want to assign <span class="font-weight-bold">{promotion_name}</span>  to the propierty?',
  PROMOTION_START: 'Start',
  PROMOTION_END: 'End',
  REGISTER_TYPE_NEW: 'Full tango',
  REGISTER_TYPE_REGISTERED: 'Registered',
  REGISTER_TYPE_BROKERAGE: 'Brokerage',
  BUTTON_CHANGE_PROMOTION: 'Change Promotion',
  BUTTON_CANCEL_PROMOTION: 'Cancel',
  BUTTON_ASSIGN_PROMOTION: 'Assign',
  TITLE: 'Title',
  ALTERNATIVE_TEXT: 'Alternative text',
  WRONG_IMAGE_DIMENSIONS: 'The image you attached does not correspond to the supported dimension',
  WRONG_IMAGE_TYPE_OR_DIMENSIONS: 'The image you attached is not on the supported file type or the suggested dimensions',
  WRONG_IMAGE_WEIGHT: 'The image you are attaching exceeds the maximum size allowed (50MB)',
  POSITION: 'Position',
  ERROR_UNSAVED_CHANGES: 'You have unsaved changes',
  LAST_POST_PUBLISHED: 'Last post: ',
  LINK: 'Link',
  EARLY_LEASE_TERMINATION_TITLE: 'Request for early termination contract',
  STATE_OF_REQUISITION: 'State of requisition: <span class="text-color-secondary "><b>{state}</b></span>',
  EARLY_LEASE_TERMINATION_WARNING_CLP: "If you terminate your lease before the renewal date, you will have to pay a <strong>fine of approximately {clp}</strong> equivalent to <strong>1,5 months of rent</strong>",
  EARLY_LEASE_TERMINATION_WARNING_CLF: "If you terminate your lease before the renewal date, you will have to pay a <strong>fine of{clf}</strong> ({clp}, today), equivalent to <strong>1,5 months of rent. This amount will be recalculated the last day of the rental</strong>",
  ADMIN_EARLY_LEASE_TERMINATION_WARNING_CLP: "If you terminate your lease before the renewal date, a <strong>fine of approximately {clp}</strong> will be charged equivalent to <strong>1,5 months of rent</strong> that will be paid by the owner, renter or both",
  ADMIN_EARLY_LEASE_TERMINATION_WARNING_CLF: "If you terminate your lease before the renewal date, a <strong>fine of {clf}</strong> ({clp}, today) will be charged, equivalent to <strong>1,5 months of rent. This amount will be recalculated the last day of the rental</strong> that will be paid by the owner, renter or both",
  EARLY_LEASE_TERMINATION_MOTIVE: '<strong>{motiveText}</strong> <span class="text-color-tertiary"> (required)</span>',
  EARLY_LEASE_TERMINATION_DUE_DATE: 'Your current contract is <strong>valid</strong> until {dueDate}',
  EARLY_LEASE_TERMINATION_TIME_CONSIDER: 'you should considerer at least {minimumDays} days from the date of the request',
  ADMIN_EARLY_LEASE_TERMINATION_DUE_DATE: 'The current contract is <strong>valid</strong> until {dueDate}',
  SEND_SOLICITUDE: 'Send solicitude',
  MAX_CHARACTERS: 'Max. 200 characters',
  NO_PUBLICATIONS: 'No published campaigns',
  ANTICIPATED_CONTRACT_TERM: 'Early contract termination',
  REQUEST_ANTICIPATED_CONTRACT_TERM: 'Request of early contract termination',
  REQUEST_ANTICIPATED_CONTRACT_TERM_TEXT: "A request has been issued by <b>{requester}</b> to the property <b>{full_address}</b>, with contract termination date <b> on {contract_end_date}</b>",
  CURRENT_LEASE_DATA: 'Current lease data',
  SIGNED_DATE: 'Signed date',
  STARTED_DATE: 'Started date',
  FINE: 'Fine',
  FINE_TEXT: "It's the amount charged by anticipated termination,<br/>equals 1.5 months of rent",
  FINE_SUBTITLE: 'Who will the fine apply to?',
  BOTH: 'Both',
  NONE: 'None',
  FINE_VALUE: 'Fine amount',
  FINISH_CONTRACT: 'Finish contract',
  REJECT_REQUEST: 'Reject request',
  CONFIRM_REJECT_REQUEST: 'Confirmation of rejection of contract term',
  ENDED_DATE: 'Finish date',
  CONFIRM_REQUEST_REQUISITION: "<div class='pb-2'> Are you sure about early terminate the lease contract in the property of <b> {address} </b>, the day <b> {request_day} ?</b> </div> <div> the fine may be arround {fine_amount}, equivalent to {fine_multiplicator} months of lease </div>",
  SEND_REQUEST: 'Send request',
  RENTER_LEASE_DATA_TITLE: 'Lease information',
  RENTER_WHEN_WILL_DESOCUPATE: 'When will you leave the property?',
  RENTER_MOTIVE_TEXT: 'We are saddened that you are leaving, tell us your reasons',
  OWNER_LEASE_DATA_TITLE: 'Information of your rented property',
  OWNER_WHEN_WILL_DESOCUPATE: 'When you want your property desocupated?',
  OWNER_MOTIVE_TEXT: 'Tell us the reasons of your request',
  ADMIN_LEASE_DATA_TITLE: 'Current lease data',
  ADMIN_WHEN_WILL_DESOCUPATE: 'When will be the last lease day?',
  ADMIN_MOTIVE_TEXT: 'Reason of the lease termination request',
  ADMIN_CLOSURE_LEASE_SUBTITLE: 'You are going to end the lease of <strong>{address}</strong>',
  CLF_LEASE_VALUE: '{clf} / <span class="secondary-price">{clp}</span>',
  REQUISITION_WARING_DAY: '<p class="text-danger MuiFormHelperText-contained">There are less than {minimumDays} days for your lease to end, so you can\'t sent a requisition for early lease termination</p>',
  WE_RECIBE_REQUEST: 'We received your request',
  REJECTED_REQUEST: 'Your request was rejected',
  PENDING_REQUISITION_INFO: 'Your request is pending review, we will notify you the result very soon',
  DELETE_REQUEST: 'Delete request',
  NEW_REQUEST: 'Send new request',
  CONFIRM_DELETE_REQUEST: 'Are you sure to delete your contract term request?',
  CONFIRM_DELETE_REQUEST_INFO: 'By eliminating the application: your rental contract will keep it\'s validity, renewal date and you will be able to generate a new contract termination',
  ARE_YOU_SURE_ABOUT_REJECT: '<strong>Are you sure you want to reject the early contract termination request?</strong>',
  YOUR_DECISION_WILL_BE_NOTIFIED: 'Your decision will be notified to the requester',
  THIS_ACTION_CANT_BE_UNDONE: `<Strong>this action can't be undone</strong>`,
  REJECTION_EARLY_REQUISITION: 'Proposal Rejected',
  REASONS_OF_YOUR_DECISION: "Reasons of your decision <span class='text-color-gray font-weight-normal'>(Obligatorio)</span>",
  CLOSING_COMMENT: 'Administrator decision reasons',
  REASONS_OF_CONTRACT_TERMINATION: 'Reasons for requesting a contract termination',
  REQUEST_HISTORY: 'Request history',
  GLOSSARY_OF_STATES: 'Glossary of states',
  GLOSSARY_OF_REQUISITION_APPROVED: '<b>Approved: </b> admin confirmed the contract term',
  GLOSSARY_OF_REQUISITION_REJECTED: '<b>Rejected: </b> admin rejected the request',
  GLOSSARY_OF_REQUISITION_FINISHED: '<b>Finished: </b> admin marked all the charges associated with the request as paid',
  ARE_YOU_SURE_ABOUT_FINISH_CONTRACT: "Are you sure you want to finish this contract on the day <span class='color-secandary'>{day}</span>?",
  CONFIRM_FINISH_CONTRACT: 'Contract termination confirmation',
  THE_FOLLOWING_FINE_WILL_APPLY: 'The following charges associated with the fine will apply:',
  SUCCESS_FINISH_CONTRACT: 'Contract finished successfully',
  PENDING_CHARGES: 'Pending charges',
  ASSOCIATED_DISCOUNTS: 'Associated discounts',
  PENDING_FINE: 'Pending fine',
  ENTER_RENTER_PAYMENTS: 'Enter renter payments',
  ENTER_OWNER_PAYMENTS: 'Enter owner payments',
  SELECT_PAYMENT_PROVIDER: 'Select provider',
  SELECT_PAYMENT_KIND: 'Select payment kind',
  ACCEPTED_REQUEST_REQUISITION: `We accept the term of contract in the property located in <span class="color-secandary"> {address} </span>`,
  REJECTED_REQUEST_REQUISITION: `We rejected the contract termination in the property located in <span class="color-secandary"> {address} </span>`,
  AUTOMATIC_DISCOUNT_FOR_OWNER: 'The fine will be automatically discounted at the next payment of your rent',
  WE_CONTACT_WITH_YOU_FOR_REPUPLISH_PROCESS: 'We will contact you to start the republication process',
  WE_CONTACT_WITH_YOU_FOR_RESTITUTION_PROCESS: 'We will contact you to start the restitution process',
  YOUR_REQUEST_WAS_APPROVED: 'Your request was approved',
  CHECK_YOUR_PAYMENTS_HISTORY: 'Check your payments history',
  CHECK_YOUR_PENDING_PAYMENTS: 'Check your pending payments',
  EXIST_A_REQUEST_APPROVED: 'There is an approved application',
  PAGE_NOT_EXIST_OR_UNAVAILABLE: '¡Ups! The page you seek does not exist or is not available right now.',
  SIGNTIME_OVER_TITLE: 'Time to sigh has ended',
  SIGNTIME_OVER_BODY: 'The time window to sign this contract as cosigner is over.',
  SIGNTIME_OVER_BODY_NOTIFY_RENTER: 'Notify {renter} that a new lease process is the way to go.',
  TANGO_CAN_DOS: 'In Tango you can:',
  TANGO_CAN_ONE: 'Lease a property fast, safe and 100% digital', 
  TANGO_CAN_TWO: 'Publish a property free for different leasing portals', 
  TANGO_CAN_THREE: 'Personalized advisory from our executives and much more...',
  I_WANT_TO_KNOW_MORE: 'I wan to know more',
  THE_RENTER: 'the renter',
  COSIGNER_SIGN_SUCCESSFUL_TITLE: 'Sign process successful',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_ONE: 'You have completed the sign process. Remind {renter} that has ',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_TWO: 'until the day before leasing starts at 20:30 hours',
  COSIGNER_SIGN_SUCCESSFUL_BODY_PART_THREE: 'to make first payment.',
  RETRY: 'Retry',
  STATUS_MODAL_ERROR_TITLE: '¡Ups! a wild error has appeared!',
  STATUS_MODAL_ERROR_BODY_400: 'There is something wrong with the sign process. Please try again.',
  STATUS_MODAL_ERROR_BODY_500: 'There is something wrong with the server. Please try again in a few minutes.',
  ECERT_ERROR: 'Ecert error',
  OWNER_BROKERAGE_FEE: 'Owner Brokerage Fee',
  BROKERAGE_INFO: 'This is the % of commission charged in property brokerage.',
  FINISH_PROMOTION: 'Finish promotion',
  FINISH_PROMOTION_CONFIRMATION: 'Do you really want to finish this promotion?',
  RENTER_BROKERAGE_FEE: 'Owner Brokerage Fee',
  GUARANTEE_INFO: 'These are the months of guarantee requested at the beginning of the lease',
  WAIT_CONTRACT: 'It may take a few minutes for the contract you have just signed to be displayed.',
  WAIT_CONTRACT_OFFERS: 'When you sign a contract, it may take a few minutes to appear in this section.'
}
