export const tableUserDetail = {
  titlesHeader: [
    { name: 'ADDRESS', class: 'table-border-left' },
    { name: 'PROPERTY_TYPE' },
    { name: 'TYPE_OF_INCOME' },
    { name: 'STATUS' },
  ],
}

export const userSearchTable = {
  titlesHeader: [
    { name: 'NAME', class: 'table-border-left' },
    { name: 'EMAIL' },
    { name: 'RUT' },
    { name: 'ROLE' },
    { name: 'RENTER_PROFILE' },
    { name: 'INSERTED_PROPERTIES' },
    { name: 'SHOW_DETAILS', class: 'table-border-right' },
  ],
}
