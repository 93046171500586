import { rejectPostStart, rejectPostSuccess, rejectPostError } from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const rejectPost = (post_id: number, location?: any) => {
  return (dispatch: any) => {
    dispatch(rejectPostStart())
    putData(`posts/${post_id}/reject`)
      .then((result: any) => {
        toast(intl.get('REJECTING_PROPERTY'))
        dispatch(rejectPostSuccess(result.data))
        location.push('/')
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(rejectPostError(e))
      })
  }
}

export default rejectPost
