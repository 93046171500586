export const updateChargeLinesStart = () => {
    return {
        type: 'UPDATE_CHARGE_LINES_STARTED',
    };
};

export const updateChargeLinesSuccess = (data: any) => {
    return {
        type: 'UPDATE_CHARGE_LINES_SUCCESS',
        payload: { data },
    };
};

export const updateChargeLinesClear = () => {
    return {
        type: 'UPDATE_CHARGE_LINES_CLEAR',
    };
};

export const updateChargeLinesError = (error: any) => {
    return {
        type: 'UPDATE_CHARGE_LINES_ERROR',
        payload: {
            error,
        },
    };
};
