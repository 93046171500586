export const getPendingVisitsIdsStart = (role: string) => {
  if (role === 'verify') {
    return {
      type: 'GET_PENDING_VISITS_VERIFY_IDS_STARTED',
    }
  } else {
    return {
      type: 'GET_PENDING_VISITS_AGENT_IDS_STARTED',
    }
  }
}

export const getPendingVisitsAgentIdsSuccess = (result: Array<any>) => {
  return {
    type: 'GET_PENDING_VISITS_AGENT_IDS_SUCCESS',
    payload: { availableVisitors: result },
  }
}

export const getPendingVisitsVerifyIdsSuccess = (result: Array<any>) => {
  return {
    type: 'GET_PENDING_VISITS_VERIFY_IDS_SUCCESS',
    payload: { availableVisitors: result },
  }
}

export const getPendingVisitsIdsError = (error: any) => {
  return {
    type: 'GET_PENDING_VISITS_ERROR',
    payload: {
      error,
    },
  }
}
