import { putData } from '../../helpers/apiConnections';
import { ConfirmEarlyRequisitionStart, ConfirmEarlyRequisitionError, ConfirmEarlyRequisitionSuccess } from './types';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { handleError } from '../../helpers/handleErrors';

const ConfirmEarlyRequisition = (
    requisitionID: number,
    comment: string,
    fine_values: { owner: number; renter: number },
    fine_target: string,
) => {
    return (dispatch: any) => {
        dispatch(ConfirmEarlyRequisitionStart());

        putData(`requisitions/${requisitionID}/accept`, {
            closing_comment: comment,
            total_amount_owner: fine_target === 'Owner' || fine_target === 'both' ? fine_values.owner : null,
            total_amount_renter: fine_target === 'Renter' || fine_target === 'both' ? fine_values.renter : null,
        })
            .then((result: any) => {
                toast(intl.get('SUCCESS_FINISH_CONTRACT'));
                dispatch(ConfirmEarlyRequisitionSuccess(result.data));
            })
            .catch((error) => {
                toast(handleError(error));
                return dispatch(ConfirmEarlyRequisitionError(error));
            });
    };
};

export default ConfirmEarlyRequisition;
