import { getData } from '../../helpers/apiConnections';
import { getRequisitionStart, getRequisitionSuccess, getRequisitionError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getRequisition = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getRequisitionStart());
        getData('requisitions/' + id)
            .then((result) => {
                return dispatch(getRequisitionSuccess(result.data.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getRequisitionError(e));
            });
    };
};
export default getRequisition;
