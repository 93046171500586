import { getData } from '../../../tango-react-base/helpers/apiConnections'
import {
  getSalesNotesStart,
  getSalesNotesSuc,
  getSalesNotesError,
} from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getSalesNotes = () => {
  return (dispatch: any) => {
    dispatch(getSalesNotesStart())
    getData('electronic_documents')
      .then((result) => {
        dispatch(getSalesNotesSuc(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getSalesNotesError(e))
      })
  }
}
export default getSalesNotes
