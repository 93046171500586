export const getRenterPreApprovedStart = () => {
    return {
        type: 'GET_RENTER_PRE_APPROVED_START',
    };
};

export const getRenterPreApprovedSuccess = (RenterPreApproved: any) => {
    return {
        type: 'GET_RENTER_PRE_APPROVED_SUCCESS',
        payload: RenterPreApproved,
    };
};

export const getRenterPreApprovedError = () => {
    return {
        type: 'GET_RENTER_PRE_APPROVED_ERROR',
    };
};
