export const contractHeader = {
    titles: [
      { name: 'PROPERTY_ADDRESS', class: 'table-border-left' },
      { name: 'OWNER' },
      { name: 'RENTER' },
      { name: 'CONTRACT_TYPE' },
      { name: 'START_DATE' },
      { name: 'END_DATE' },
      { name: 'STATE' },
      { name: 'DETAIL', class: 'table-border-right' },
    ],
  }