export const getClosureLeaseDataStart = () => {
    return {
        type: 'GET_CLOSURE_LEASE_DATA_STARTED',
    };
};

export const getClosureLeaseDataSuccess = (result: any) => {
    return {
        type: 'GET_CLOSURE_LEASE_DATA_SUCCESS',
        payload: { result },
    };
};
export const getClosureLeaseDataError = (error: any) => {
    return {
        type: 'GET_CLOSURE_LEASE_DATA_ERROR',
        payload: {
            error,
        },
    };
};
