import { postData, getData } from '../../helpers/apiConnections';
import { getPostsToRepublishStart, getPostsToRepublishError, getPostsToRepublishSuccess } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';

const getPostsToRepublish = () => {
    return (dispatch: any) => {
        dispatch(getPostsToRepublishStart());
        getData('unit_parents/pending_republication_units')
            .then((result) => {
                dispatch(getPostsToRepublishSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                dispatch(getPostsToRepublishError(e));
            });
    };
};
export default getPostsToRepublish;
