const initialState: any = {
  countries: [],
  fetchingCountries: 'NO_COUNTRIES',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_COUNTRIES_STARTED':
      return {
        ...state,
        countries: [],
        fetchingCountries: 'FETCHING_COUNTRIES',
      }

    case 'GET_COUNTRIES_SUCCESS':
      return {
        ...state,
        countries: action.payload,
        fetchingCountries: 'FETCHED_COUNTRIES',
      }
    case 'GET_COUNTRIES_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingCountries: 'ERROR_FETCHING_COUNTRIES',
      }
    default:
      return state
  }
}
