import { disableDocumentStart, disableDocumentSuccess, disableDocumentError } from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { getLeaseDocuments } from '../../../tango-react-base/reduxActions'
const disableDocument = (id: number, rentId: number) => {
  return (dispatch: any) => {
    dispatch(disableDocumentStart())
    putData(`lease_documents/disable_document?document_id=${id}`)
      .then((result: any) => {
        dispatch(disableDocumentSuccess())
        dispatch(getLeaseDocuments(rentId))
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(disableDocumentError(e))
      })
  }
}
export default disableDocument
