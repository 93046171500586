import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import intl from 'react-intl-universal'
import { tableUserDetail } from './createTable'
import { CircularLoading, TableTango } from '../../tango-react-base/components'
import {
  agentProfile,
  verifierProfile,
  renterProfile,
  ownerProfile,
} from '../../tango-react-base/resources'
import { getUsersDetail } from '../../redux/actions'

interface UserDetailsProps {
  onSetTemplateChild: (child: Object) => void
  onGetUserDetail: (id: number) => void
  user: any
  fetch: string
}
/**
 * Here the type of each Table is defined, this prop is similar to 'UserDetailsProps' but 'UserDetailsTypes' is for the documentation
 *  @typedef UserDetailsTypes
 *  @type {function|Array|String}
 *  @property {function} onSetTemplateChild - is a Function.
 *  @property {function} onGetUserDetail - is a Function.
 *  @property {Array} user - is a Array.
 *  @property {string} fetch - is a String.
 */

/**
 * UserDetails is a functional component with props.
 * The main function of this component is to render user dashboard .
 *  @function
 *  @param {UserDetailsTypes}  onSetTemplateChild - is a Function
 *  @param {UserDetailsTypes}  onGetUserDetail - Returns the user's detail
 *  @param {UserDetailsTypes}  fetch - State redux
 *  @param {UserDetailsTypes}  user - user detail
 *  @returns {(ReactComponent)} Returns user dashboard
 */

const UserDetails = ({
  onSetTemplateChild,
  onGetUserDetail,
  user,
  fetch,
}: UserDetailsProps) => {
  const history = useHistory()
  const { id }: any = useParams()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('USERS_DETAILS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
        </>,
      )
      onGetUserDetail(id)
    }
  }, [history, onSetTemplateChild, id])

  const { attributes } = user
  const {
    full_name,
    email,
    posts_list,
    owner_profile,
    owner_profile_present,
    renter_profile_present,
    renter_profile_state,
    roles,
  } = attributes || ''

  const validationSrc = (rol: string) => {
    if (rol === 'agent') return agentProfile
    if (rol === 'verifier') return verifierProfile
    if (rol === 'owner') return ownerProfile
    if (rol === 'renter') return renterProfile
  }
  const rolesMap = roles &&
    roles.map((rol: string) => {
      if (rol === 'owner' || rol === 'renter') return {}
      return { name: rol, id: null, src: validationSrc(rol),redirect:`/calendar/${id}?role=${rol}` }
    })

  const profiles = [owner_profile_present ? 'owner' : '',renter_profile_present ? 'renter' : '']

  const profilesMap = profiles &&
    profiles.map((rol: string) => {
      if (rol === '') return {}
      const idProfile = parseInt(rol === 'renter' ? id : owner_profile.id)
      return { name: rol,id: idProfile,src: validationSrc(rol),redirect:`/${rol}s/${idProfile}`}
    })

  const rolesData = roles && profilesMap && [...profilesMap, ...rolesMap].filter((el: any) => Object.keys(el).length)
  const rolesDateLength = rolesData && rolesData.length > 2
  const UserContent = () => {
    const arrayIds = posts_list?.map(({ post_id }: any) => post_id)
    const rowTable = posts_list?.map(
      ({ post_id, register_type_of_unit, ...keepAttrs }: any) => keepAttrs,
    )

    return (
      <div className='mt-5 mx-0'>
        <div className='row'>
          <div className='cards-admin'>
            {rolesData && rolesData.map((rol: any) => {
              const rolName = rol.name.toUpperCase()
                return (
                  <div
                    className={`${rolesDateLength?'card-admin-roles':'card-admin-two-roles'} pointer`}
                    onClick={() => history.push(rol.redirect)}
                  >
                    <div className='card'>
                      <div className='card-body'>
                        {rolesDateLength  ?
                          <span>{intl.get(rolName)}</span> :
                          <h1>{intl.get(`${rolName === 'RENTER' ? 'RENTER_AND_COSIGNER' : rolName}`)}</h1>
                        }
                        <img src={rol.src} width={`${rolesDateLength ? '60' : '90'}`} alt='img' />
                        {renter_profile_present && (
                          <>
                          <p className='mt-3'>{intl.get('PROFILE_STATE')}</p>
                          <p className='font-weight-bold'>{renter_profile_state}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            )
          }
          </div>
          <div className={`${rolesData && !rolesData.length?'col-12':'col-md-9'} plm-0 d-flex align-center`}>
            <TableTango
              listTitle={tableUserDetail.titlesHeader}
              listBody={rowTable}
              redirecTo={'/posts'}
              arrayId={arrayIds}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='container-home pd-1 mt-5'>
      <div className='template-line-title' />
      <h2 className='text my-2'>{intl.get('SEARCH_RESULT')}:</h2>
      {fetch === 'LOADING' && (
        <p>
          <CircularLoading />
        </p>
      )}
      {fetch === 'LOADED' && (
        <>
          {[full_name, email].map((info: any, i: number) => (
            <p className='text-left font-weight-bold mb-0'>
              {intl.get(i === 0 ? 'NAME' : 'EMAIL')}: <small>{info}</small>
            </p>
          ))}
          <UserContent />
        </>
      )}
      {fetch === 'ERROR' && <p>{intl.get('ERROR')}</p>}
    </div>
  )
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an a action that usually changes a reducer state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetUserDetail: (id: number) => {
      dispatch(getUsersDetail(id))
    },
  }
}
/**
 *@function
 *@param {MapToProps}  state - By state the redux data can be accesssed.
 *@param {MapToProps}  usersSearch - the search result is stored.
 */
const mapStateToProps = (state: any) => {
  const { user, fetch } = state.usersSearch
  return {
    user,
    fetch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
