export const approveRenterStart = () => {
  return {
    type: 'APPROVE_RENTER_STARTED',
  }
}

export const approveRenterSuccess = (renter_validation: any) => {
  return {
    type: 'APPROVE_RENTER_SUCCESS',
    payload: { renter_validation },
  }
}
export const approveRenterError = (error: any) => {
  return {
    type: 'APPROVE_RENTER_ERROR',
    payload: {
      error,
    },
  }
}

export const clearApproveRenter = () => {
  return {
    type: 'CLEAR_APPROVE_RENTER',
  }
}