import { ConsigneeState } from '../../../tango'

const initialState: ConsigneeState = {
  consignees: [],
  fetchingConsignee: 'LOADED',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_PROFILE_STARTED':
      return {
        ...state,
        fetchingConsignee: 'LOADING',
      }
    case 'UPDATE_PROFILE_SUCCESS':
      let filter = state.consignees.filter(
        (value: any) => value.id !== action.payload.profile.data.id,
      )
      return {
        ...state,
        consignees: filter.concat(action.payload.profile.data),
        fetchingConsignee: 'LOADED',
      }
    case 'UPDATE_PROFILE_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingConsignee: 'ERROR',
      }
    default:
      return state
  }
}
