export const getUsersDetailStart = () => {
  return {
    type: 'GET_USERS_DETAIL_STARTED',
  }
}
export const getUsersDetailSuc = (users: any) => {
  return {
    type: 'GET_USERS_DETAIL_SUCCESS',
    payload: users,
  }
}
export const getUsersDetailError = (error: any) => {
  return {
    type: 'GET_USERS_DETAIL_ERROR',
    payload: {
      error,
    },
  }
}
