const initialState: any = {
  leaseDocuments: [],
  fetchLeaseDocuments: 'NO_FETCH',
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_LEASE_DOCUMENTS_STARTED':
      return {
        ...state,
        fetchLeaseDocuments: 'FETCHING',
      }
      case 'GET_LEASE_DOCUMENTS_SUCCESS':
        return {
        ...state,
        leaseDocuments: action.payload.result.data.data,
        fetchLeaseDocuments: 'FETCHED',
      }
    case 'GET_LEASE_DOCUMENTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchLeaseDocuments: 'ERROR',
      }
    default:
      return state
  }
}
