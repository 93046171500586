const initialState: any = {
  status: '',
  error: '',
  id: null
}
export default (state = initialState, action: any) => {

  switch (action.type) {
    case 'CLOSURE_LEASE_CONFIRMATION_STARTED':
      return {
        ...state,
        status: 'STARTED',
      }
    case 'CLOSURE_LEASE_CONFIRMATION_SUCCESS':
      return {
        ...state,
        status: 'SUCCESS',
        id: action.payload.result.data.id
      }
    case 'CLOSURE_LEASE_CONFIRMATION_ERROR':
      return {
        ...state,
        error: action.payload.error,
        status: 'ERROR',
      }
    default:
      return state
  }
}
