import React from 'react';
import intl from 'react-intl-universal';
import { ButtonRedirect, CircularLoading, TableTango } from '../../tango-react-base/components';

export type Requisitions = {
  id: number;
  attributes: {
    full_address: string;
    requester_kind: string;
    lease_closing_at_with_format: string;
  };
};
/**
 * Here the type of each Table is defined, this prop is similar to 'TableTangoProps' but 'TableTangoTypes' is for the documentation
 *  @typedef  Requisitions
 * @property {Number} id - id of the requisition
 * @property {Object} attributes - attributes of the requisition
 * @property {string} attributes.full_address - full address of the requisition
 * @property {string} attributes.requester_kind - kind of requester of the requisition
 * @property {string} attributes.lease_closing_at_with_format - date of the requisition
 */

export type RequisitionsProps = {
  requisitions: Array<{
    type: string;
    requisitions: Array<Requisitions>;
    fetchingDataRequisitions: string;
  }>;
  onSetTemplateChild: (child: Object) => void;
  onGetRequisitions: (type: string) => void;
  fetchingDataRequisitions: any;
};
/**
 * Here the type of each Table is defined, this prop is similar to 'TableTangoProps' but 'TableTangoTypes' is for the documentation
 *  @typedef  RequisitionsProps
 *  @type {(function | string | array)}
 *  @property {Array<Requisitions>} requisitions - Array of requisitions
 * @property {function} onSetTemplateChild - Function to set the template child
 * @property {function} onGetRequisitions - Function to get requisitions
 * @property {string} fetchingDataRequisitions - String to know if requisitions are loading or loaded
 */

const RenderContent = ({
  currentRequisition,
  titles,
  tableRow,
}: {
  currentRequisition: Array<Requisitions>;
  titles: Array<Object>;
  tableRow: any;
}) => {
  return (
    <>
      {!!currentRequisition.length && (
        <>
          <div className='row '>
            <TableTango listTitle={titles} listBody={tableRow} elevation={0} paginator={false} />
          </div>
        </>
      )}
    </>
  );
};

/**
 * @fuction RequisitionIndexTable
 * @param {Object} currentRequisition - Object with requisitions and fetchingDataRequisitions
 * @param {Array<Object>} titles - Array of titles
 * @returns {(ReactComponent)} Returns a TableMui
 * @component Function component to return a tango table for requisitions
 */
const RequisitionIndexTable = ({
  className,
  currentRequisition,
  titles,
}: {
  className?: string;
  currentRequisition: {
    type: string;
    requisitions: Array<Requisitions>;
    fetchingDataRequisitions: string;
  };
  titles: Array<Object>;
}) => {
  const noRequisitions = !currentRequisition.requisitions.length;
  const tableRowPending = currentRequisition.requisitions?.map(
    ({ id, attributes: { full_address, requester_kind, lease_closing_at_with_format } = {} }) => [
      `${full_address}`,
      <a>{requester_kind}</a>,
      lease_closing_at_with_format,
      <ButtonRedirect route={`/requisitions/${id}`} label={'DETAILS'} />,
    ],
  );

  return (
    <div className={className}>
      {currentRequisition.fetchingDataRequisitions === 'LOADING' && <CircularLoading />}
      {!noRequisitions && currentRequisition.fetchingDataRequisitions === 'LOADED' && (
        <RenderContent
          currentRequisition={currentRequisition.requisitions}
          titles={titles}
          tableRow={tableRowPending}
        />
      )}
      {(currentRequisition.fetchingDataRequisitions === 'LOADED' ||
        currentRequisition.fetchingDataRequisitions === 'ERROR') &&
        noRequisitions &&
        intl.get('NO_REQUISITIONS')}
    </div>
  );
};

export default RequisitionIndexTable;
