import { editAdvertStart, editAdvertSuccess, editAdvertError } from './types'
import intl from 'react-intl-universal'
import { putData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'

const editAdvert = (data: Object, advertId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(editAdvertStart())
    putData(`adverts/${advertId}`, data)
      .then((result) => {
        toast(intl.get('EDITED_ADVERT'))
        return dispatch(editAdvertSuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(editAdvertError(e))
      })
  }
}
export default editAdvert
