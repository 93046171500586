import {
  rejectRenterAmountStart,
  rejectRenterAmountSuccess,
  rejectRenterAmountError,
} from './types'
import { putData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const rejectRenter = (user_id: number, comment: string) => {
  return (dispatch: any) => {
    dispatch(rejectRenterAmountStart())
    let renter_profile = {
      renter_profile: { comments: comment },
    }
    putData(`renter_profiles/${user_id}/reject`, renter_profile)
      .then((result: any) => {
        toast(intl.get('RENTER_REJECTED'))
        dispatch(rejectRenterAmountSuccess(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(rejectRenterAmountError(e))
      })
  }
}
export default rejectRenter
