import { publishPostStart, publishPostSuccess, publishPostError } from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUnit = (post_id: number, history?: any) => {
  return (dispatch: any) => {
    dispatch(publishPostStart())
    putData(`posts/${post_id}/publish`)
      .then((result: any) => {
        toast(intl.get('PUBLISHING_PROPERTY'))
        dispatch(publishPostSuccess(result.data)) &&
          history && history.push('/published_post', {redirect: '/posts'})
      })
      .catch((e: any) => {
        toast(handleError(e))
        return dispatch(publishPostError(e))
      })
  }
}
export default getUnit
