export const rejectPropertyDocumentStart = () => {
  return {
    type: 'REJECT_PROPERTY_DOCUMENT_STARTED',
  }
}

export const rejectPropertyDocumentSuccess = (document: any) => {
  return {
    type: 'REJECT_PROPERTY_DOCUMENT_SUCCESS',
    payload: { document },
  }
}

export const rejectPropertyDocumentError = (error: any) => {
  return {
    type: 'REJECT_PROPERTY_DOCUMENT_ERROR',
    payload: { error },
  }
}
