import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { setTemplateChild, getPosts } from '../../tango-react-base/reduxActions'
import { ButtonsHeader } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { Post } from '../../tango'
import { redirect_tabs, REGISTERED_POST_TABS } from './buttonOptions'
import { PostCardAdmin } from '../../components'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import PostsButtons from './postsButtons'

interface PostRegisteredProps {
  posts: Array<Post>
  fetchingPosts: string
  onSetTemplateChild: (child: Object) => void
  onGetPostsRegistered: (filter: any, states: any) => void
}

/**
 * Here it is defined the interface of the property, this prop is similar to 'PostRegisteredProps' but 'PostRegisteredProps' is for the documentation
 * @typedef PostRegisteredProps
 * @type {(function|string|object)}
 * @property {object} posts - is a Object.
 * @property {String} fetchingPosts - is a String.
 * @property {function} onGetPostsRegistered - is a Function.
 * @property {function} onSetTemplateChild - is a Function.
 */

/**
 * ContractRented is a functional component and have many props and he can render a contract
 *@function
 *@param {PostRegisteredProps}  posts - Data of the post.
 *@param {PostRegisteredProps}  fetchingPosts - Returns the call status
 *@param {PostRegisteredProps}  onGetPostsRegistered - Get the properties rergistered with a function
 *@param {PostRegisteredProps}  onSetTemplateChild - Get the admin contract with a function

 */
const PostRegistered = ({
  posts,
  fetchingPosts,
  onGetPostsRegistered,
  onSetTemplateChild,
}: PostRegisteredProps) => {
  const history = useHistory()
  const [buttonSelected, setButtonSelected] = useState('pending')
  const [state, setState] = useState('pending')
  const showBadge = state.includes('pending')

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'registered'}
          />
        </>,
      )

      switch (buttonSelected) {
        case 'pending':
          setState('pending')
          onGetPostsRegistered(null, {
            unit_state: 'pending',
            register_type: 'registered',
          })
          break
        case 'rented':
          setState('rented')
          onGetPostsRegistered(null, {
            unit_state: 'rented',
            register_type: 'registered',
          })
          break
        case 'approved':
          setState('approved')
          onGetPostsRegistered(null, {
            unit_state: 'approved',
            register_type: 'registered',
          })
          break
        default:
          break
      }
    }
  }, [buttonSelected, history, onGetPostsRegistered, onSetTemplateChild])

  const renderButton = (id: number, nameButton = 'DETAILS') => {
    return (
      <button
        className='button-fourth col-12'
        onClick={() => {
          history.push(`posts/${id}`)
        }}
      >
        {intl.get(nameButton)}
        {showBadge && <span className='badge'>!</span>}
      </button>
    )
  }

  /** RegisteredPostsContent : This function renders an iteration of PostCardAdmin component
   *  If there are no posts it renders a paragraph message
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const RegisteredPostsContent = () => {
    return posts.length > 0 ? (
      <>
        {posts.map((post: any, index: number) => (
          <Fragment key={`post-${index}`}>
            <PostCardAdmin {...post.attributes}>
              {renderButton(post.id)}
            </PostCardAdmin>
          </Fragment>
        ))}
      </>
    ) : (
      <p>{intl.get('NO_PROPERTY_SECTION')}</p>
    )
  }

  return (
    <div className='container-home pd-1'>
      <PostsButtons
        buttonSelected={buttonSelected}
        setButtonSelected={setButtonSelected}
        buttonsArray={REGISTERED_POST_TABS}
      />
      <div className='template-line-title' />
      <h2 className='text-left my-2'>{intl.get('PROPERTY_REGISTERED')}</h2>
      <div className='row'>
        {fetchingPosts === 'LOADING' && (
          <div className='col-md-6'>
            <CircularLoading />
          </div>
        )}
        {fetchingPosts === 'ERROR' && <p>{intl.get('ERROR')}</p>}
        {fetchingPosts === 'LOADED' && <RegisteredPostsContent />}
      </div>
    </div>
  )
}

/**
 * Is used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => {
  const { posts, fetchingPosts } = state.posts
  return {
    posts,
    fetchingPosts,
  }
}
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPostsRegistered: (filter: any, states: any) => {
      dispatch(getPosts(filter, states))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostRegistered)
