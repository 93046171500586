export const redirect_tabs = [
  {
    containerName: 'col-12 p-0 d-flex flex-wrap',
    buttons: [
      {
        label: 'renters',
        viewLabel: 'RENTERS',
        to: '/payments/renters',
      },
      {
        label: 'sales notes',
        viewLabel: 'SALES_NOTES',
        to: '/payments/sales-notes',
      },

    ],
  },
]
