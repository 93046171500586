import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  setTemplateChild,
  getPostsToRepublish,
} from '../../tango-react-base/reduxActions'
import { ButtonsHeader } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { Post } from '../../tango'
import { redirect_tabs } from './buttonOptions'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import { PostCardAdmin } from '../../components'

/**
 * Here the type of each property is defined, this prop is similar to 'PostRepublishedProps' but 'PostRepublishedTypes' is for the documentation
 * @typedef PostRepublishedTypes
 * @type {(function|string|array)}
 * @property {array} posts - is an Array.
 * @property {string} fetchingPosts - is a String.
 * @property {function} onGetPostsToRepublish - is a Function.
 * @property {function} onSetTemplateChild - is a Function.
 */

interface PostRepublishedProps {
  postsToRepublish: Array<Post>
  fetchingPostsToRepublish: string
  onGetPostsToRepublish: () => void
  onSetTemplateChild: (child: Object) => void
}

/**
 * PostRepublished is a functional component which has many props.
 * The main function of this component is to render the properties to be republished.
 * This component connects itself with validation endpoints.
 *@function
 *@param {PostRepublishedTypes}  posts - Returns an array of posts
 *@param {PostRepublishedTypes}  fetchingPosts - Returns a string representing the fetching state of the posts
 *@param {PostRepublishedTypes}  onGetPostsToRepublish - Gets the  posts by an Redux action
 *@param {PostRepublishedTypes}  onSetTemplateChild  - Gets the template for the header
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

const PostRepublished = ({
  postsToRepublish,
  fetchingPostsToRepublish,
  onGetPostsToRepublish,
  onSetTemplateChild,
}: PostRepublishedProps) => {
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('TITLE_PROPERTIES')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'pending republish'}
          />
        </>,
      )
      onGetPostsToRepublish()
    }
  }, [history, onGetPostsToRepublish, onSetTemplateChild])

  /** detailsButton: This function renders a button element that redirects to the post details
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const detailsButton = (id: number, nameButton = 'REPUBLISH') => {
    return (
      <button
        className='button-fourth col-12'
        onClick={() => {
          window.location.replace(`${process.env.REACT_APP_ADMIN_URL}republish/${id}`)
        }}
      >
        {intl.get(nameButton)}
      </button>
    )
  }

  /** RepublishedPosts : This function renders an iteration of PostCardAdmin component
   * If there are no posts it renders a paragraph message
   * these post are the psot pending to be republished
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const PendingToRepublishPosts = () => {
    return postsToRepublish.length > 0 ? (
      <>
        {postsToRepublish.map((post: any, index: number) => (
          <Fragment key={`post-${index}`}>
            <PostCardAdmin postId={post.id} {...post.attributes}>
              {detailsButton(post.attributes.unit_version_id)}
            </PostCardAdmin>
          </Fragment>
        ))}
      </>
    ) : (
      <p>{intl.get('NO_PROPERTY_SECTION')}</p>
    )
  }
  return (
    <div className='container-home pd-1'>
      <div className='template-line-title' />
      <h2 className='text my-2'>{intl.get('PENDING_REPUBLISH')}</h2>
      <div className='row'>
        {fetchingPostsToRepublish === 'LOADING' && (
          <div className='col-md-6'>
            <CircularLoading />
          </div>
        )}
        {fetchingPostsToRepublish === 'ERROR' && <p>{intl.get('ERROR')}</p>}
        {fetchingPostsToRepublish === 'LOADED' && <PendingToRepublishPosts />}
      </div>
    </div>
  )
}
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatches an a action that usually changes a reducer state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPostsToRepublish: () => {
      dispatch(getPostsToRepublish())
    },
  }
}

/**
 *@function
 *@param {MapToProps}  state - By state the redux data can be accesssed.
 */
const mapStateToProps = (state: any) => {
  const { postsToRepublish, fetchingPostsToRepublish } = state.postsAdmin
  return {
    postsToRepublish,
    fetchingPostsToRepublish,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostRepublished)
