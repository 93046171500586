export const getUserStart = () => {
  return {
    type: 'GET_USER_STARTED',
  }
}

export const getUserSuccess = (result: any) => {
  return {
    type: 'GET_USER_SUCCESS',
    payload: { result },
  }
}
export const getUserError = (error: any) => {
  return {
    type: 'GET_USER_ERROR',
    payload: {
      error,
    },
  }
}
