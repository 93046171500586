const initialState: any = {
    notes: [],
    fetchingNotes: 'NO_NOTES',
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_NOTES_STARTED':
            return {
                ...state,
                notes: [],
                fetchingNotes: 'FETCHING_NOTES',
            }

        case 'GET_NOTES_SUCCESS':
            return {
                ...state,
                notes: action.payload,
                fetchingNotes: 'FETCHED_NOTES',
            }
        case 'GET_NOTES_ERROR':
            return {
                ...state,
                error: action.payload.error,
                fetchingNotes: 'ERROR_FETCHING_NOTES',
            }
        default:
            return state
    }
}