export const titlesHeader = [
  { name: 'NAME', class: 'table-border-left' },
  { name: 'PHONE' },
  { name: 'EMAIL' },
  { name: 'RENTER_DETAILS', class: 'table-border-right' },
]

export const titlesHeaderPreApproved = [
  { name: 'NAME', class: 'table-border-left' },
  { name: 'SIMPLE_RUT'},
  { name: 'EMAIL' },
  { name: 'PHONE' },
  { name: 'STATUS', class: 'table-border-right' },
]
