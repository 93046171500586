import React, { FC, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'
import { dragAndDrop } from '../../tango-react-base/resources'
import { PopupWithIcon } from '../../tango-react-base/components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

/**
 * @interface DataType
 * This interface is used to store data for campaign
 * @property {number} id - id of campaign
 * @property {string} name - name of campaign
 * @property {string} link - link of campaign
 * @property {string} image - image of campaign
 * @property {number} priority - priority of campaign
 */
interface DataType {
  name: string
  link: string
  image: string
  priority: number
  id: number
}

/**
 * @interface PropsButton
 * This interface is used to store data for button
 * @property {string} className - className of button
 * @property {number} advertId - id of campaign
 */
interface PropsButton {
  className?: string
  advertId: number
}

/**
 * @interface PropsOpenPopupButton
 * This interface is used open data for button in Props
 * @property {number} advertId - id of campaign
 * @property {number} imageNumber - number of image
 */
interface PropsOpenPopupButton {
  advertId: number
  imageNumber: number
}

/**
 * Define the props of the component
 * @typedef  Props
 * @type {(Object)}
 * @property {Object} element - is an Object
 * @property {string} element.className - className of button
 * @property {number} element.advertId - id of campaign
 * @property {Array<DataType>} data - is an Array of DataType
 * @property {number} data.id - id of campaign
 * @property {string} data.name - name of campaign
 * @property {string} data.link - link of campaign
 * @property {string} data.image - image of campaign
 * @property {number} data.priority - priority of campaign
 * @property {number} transitionDuration - duration of transition
 * @property {string} fetchingStatusDeleteAdvert - status of delete advert
 * @property {number} advertGalleryId - id of advert gallery
 * @property {function} onDeleteAdvert - function to delete advert
 * @property {function} onChangeAdvertPriority - function to change priority of advert
 * @property {function} onResetData - function to reset data
 */
interface Props {
  element: JSX.Element
  data: Array<DataType>
  onDeleteAdvert: (
    advertId: number,
    advertGalleryId: number,
    lastGalleryState: string,
    transitionDurationGallery: number,
  ) => void
  onChangeAdvertPriority: (priorities: any) => void
  transitionDuration: number
  fetchingStatusDeleteAdvert: string
  advertGalleryId: number
  onResetData: () => void
  lastGalleryState: string
}

/**
 * RenderImages is a functional component with props.
 * The main function of this component is to render elements in the view.
 *  @function
 *  @param {Props} element - return a react element
 *  @returns {(JSX.Element)} Returns a view with two tables
 */

const RenderImages: FC<Props> = ({
  element,
  data,
  onDeleteAdvert,
  onChangeAdvertPriority,
  transitionDuration,
  fetchingStatusDeleteAdvert,
  advertGalleryId,
  onResetData,
  lastGalleryState,
}) => {
  const { width } = useWindowSize()
  const history = useHistory()

  /* doubleClickDelete: It prevents the user from making successive clicks when
  pressing the delete button. */
  const [doubleClickDelete, setDoubleClickDelete] = useState(false)
  // temoralData is used to store data for campaign
  let temporalData = data
  // newData: It is used to store the new data of the campaign
  const [newData, setNewData] = useState(data || [])
  // unSaveData: It is used to store the unsaved data of the campaign
  const [unSavedData, setUnSavedData] = useState(data || [])
  let dragIitem = -1
  let ChangeX = 0
  let ChangeY = 0

  const replaceAndMoveList = (
    oldIndex: number,
    newIndex: number,
    list: any,
  ) => {
    const newList = [...list]
    const [removed] = newList.splice(oldIndex, 1)
    newList.splice(newIndex, 0, removed)
    newList.forEach((item, index) => {
      item.priority = index + 1
    })
    return newList
  }

  const handleDragStart = (e: any, i: number) => {
    dragIitem = i
    e.target.classList.add('dragging')
  }
  const handleTouchStart = (e: any, i: number) => {
    dragIitem = i
    document.documentElement.style.overflow = 'hidden'
    e.target.classList.add('dragging')
  }

  const handleDragEnd = (e: any, i: number) => {
    e.target.classList.remove('dragging')
  }
  const handleTouchEnd = (e: any, i: number) => {
    e.target.classList.remove('dragging')
    document.documentElement.style.overflow = 'auto'
    const elementID: any = document.elementFromPoint(ChangeX, ChangeY)
      ?.parentElement?.id
    if (elementID) {
      const elementIDNumber = parseInt(elementID.match(/\d+/)[0])
      if (i !== elementIDNumber) {
        temporalData = replaceAndMoveList(i, elementIDNumber, temporalData)
        setNewData(temporalData)
        e.stopPropagation() // stops the browser from redirecting.
      }
    }
    const itemQuery: any = document.getElementById(`card-drag-${i}`)
    itemQuery.style.position = 'relative'
    itemQuery.style.left = 0
    itemQuery.style.top = 0
  }

  const handleDragOver = (e: any, i: number) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    return false
  }
  const handleTouchMove = (e: any, i: number) => {
    const touchLocation = e.targetTouches[0]
    const itemQuery: any = document.getElementById(`card-drag-${i}`)
    itemQuery.style.position = 'absolute'
    itemQuery.style.left = touchLocation.pageX + 20 + 'px'
    itemQuery.style.top = touchLocation.pageY - 100 + 'px'
    ChangeX = touchLocation.clientX
    ChangeY = touchLocation.clientY
  }

  const handleDrop = (e: any, i: number) => {
    if (dragIitem !== i) {
      temporalData = replaceAndMoveList(dragIitem, i, temporalData)
      setNewData(temporalData)
      e.stopPropagation() // stops the browser from redirecting.
    }
    return false
  }

  const changeSelectorData = (index: number, newPriority: any) => {
    const temp = [...unSavedData]
    const currentElementIndex = temp.findIndex(
      (item: any) => item.priority === newPriority,
    )
    const currentElement = temp[currentElementIndex]
    const element = temp[index]
    const elementPriority = element.priority
    element.priority = newPriority
    currentElement.priority = elementPriority
    temp[index] = element
    temp[currentElementIndex] = currentElement
    setUnSavedData(temp)
  }

  useEffect(() => {
    setNewData(newData)
    onChangeAdvertPriority(newData)
  }, [newData])

  useEffect(() => {
    setUnSavedData(unSavedData)
    onChangeAdvertPriority(unSavedData)
  }, [unSavedData])
  
  useEffect(() => {
    const isMobile = (/ Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /.test (navigator.userAgent))
    if (!isMobile) {
      let items = document.querySelectorAll('.carousel-card')
      items.forEach(function (item, i) {
        item.addEventListener('dragstart', (e) => handleDragStart(e, i))
        item.addEventListener('dragend', (e) => handleDragEnd(e, i))
        item.addEventListener('dragover', (e) => handleDragOver(e, i))
        item.addEventListener('dragend', (e) => handleDrop(e, i))
        item.addEventListener('drop', (e) => handleDrop(e, i))
        item.addEventListener('touchmove', (e) => handleTouchMove(e, i))
        item.addEventListener('touchstart', (e) => handleTouchStart(e, i))
        item.addEventListener('touchend', (e) => handleTouchEnd(e, i))
      })
    }
  }, [])

  const [openPopup, setOpenPopup] = useState(false)
  const [advertId, setAdvertId] = useState(0)
  const [imageNumber, setImageNumber] = useState(0)

  useEffect(() => {
    if (
      fetchingStatusDeleteAdvert === 'DELETED' ||
      fetchingStatusDeleteAdvert === 'ERROR'
    ) {
      setDoubleClickDelete(false)
      setOpenPopup(false)
    }
  }, [fetchingStatusDeleteAdvert])

  const DeleteButton = ({
    className = 'button-secondary mt-2 mx-2 justify-content-center col-lg-4 col-md-5 col-12',
    advertId,
  }: PropsButton) => (
    <button
      className={className}
      disabled={doubleClickDelete}
      onClick={() => {
        setDoubleClickDelete(true)
        onDeleteAdvert(advertId, advertGalleryId, lastGalleryState, transitionDuration)
      }}
    >
      {intl.get('DELETE')}
    </button>
  )

  const OpenPopupButton = ({ advertId, imageNumber }: PropsOpenPopupButton) => (
    <>
      <div className='col-md-6 col-12 p-0'>
        <button
          className='w-100 button-fourth'
          onClick={() => (
            setAdvertId(advertId),
            setImageNumber(imageNumber),
            setOpenPopup(true)
          )}
        >
          {intl.get('DELETE')}
        </button>
      </div>
    </>
  )

  const EditButton = ({
    className = 'w-100 button-secondary',
    advertId,
  }: PropsButton) => (
    <div className='col-md-6 col-12 p-0'>
      <button
        className={className}
        onClick={() => {
          history.push(`/gallery/${advertGalleryId}/advert/${advertId}`)
          localStorage.setItem('transitionDuration', String(transitionDuration))
          localStorage.setItem('lastGalleryState', lastGalleryState)
          onResetData()
        }}
      >
        {intl.get('EDIT')}
      </button>
    </div>
  )

  const CancelButton = () => (
    <button
      className='button-sixth mt-2 mx-2 justify-content-center col-lg-4 col-md-5 col-12'
      onClick={() => {
        setOpenPopup(false)
      }}
    >
      {intl.get('CANCEL')}
    </button>
  )

  const renderPopupDeleteConfirmation = () => (
    <PopupWithIcon
      title='DELETE_IMAGE'
      lineUp={true}
      open={openPopup}
      className='mt-3 pb-7'
      handleClose={() => setOpenPopup(false)}
      classNameDiv='max-width-modal'
      classNameTitle={`${
        width < 768 ? 'popup-medium-font' : ''
      } font-weight-bold`}
    >
      <span
        className={`${
          width < 768 ? 'popup-content-mobile' : 'popup-content'
        } d-flex pb-3`}
      >
        {intl.getHTML('ARE_YOU_SURE_TO_DELETE_IMAGE', {
          image_number: imageNumber,
        })}
      </span>
      <span
        className={`${
          width < 768 ? 'popup-content-mobile' : 'popup-content'
        } pb-1`}
      >
        {intl.getHTML('UNREVERSE_ACTION')}
      </span>
      {width < 768 ? (
        <div className='row justify-content-center'>
          <DeleteButton advertId={advertId} />
          <CancelButton />
        </div>
      ) : (
        <div className='row justify-content-center mt-4'>
          <CancelButton />
          <DeleteButton advertId={advertId} />
        </div>
      )}
    </PopupWithIcon>
  )

  const redirectToURL = (url: string) => {
    if (url.includes('https://' || 'http://')) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
    window.open(`https://${url}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <div>
      {renderPopupDeleteConfirmation()}
      <h2 className='title-carousel-images'>{intl.get('CAROUSEL_IMAGES')}</h2>
      <div className='template-line mb-4' />
      {width < 768 && element}
      {width >= 768 && (
        <>
          <p className='text-left pl-2 font-weight-bold'>
            {intl.get('POSITION')}
          </p>
        </>
      )}
      <div>
        <section>
          <div className='container-carousel-images'>
            {newData.map(({ priority, name, link, image, id }, i) => (
              <section key={i} className='row py-2 py-md-0'>
                <div className='carousel-card-id-number'>
                  <div className='carousel-card__number'>
                    {width < 768 && (
                      <p className='text-left'>{intl.get('POSITION')}</p>
                    )}
                    <div className='justify-content-center'>
                      {width >= 768 ? (
                        <span>{i + 1}</span>
                      ) : (
                        <Select
                          labelId='demo-simple-select-outlined-label'
                          id='demo-simple-select-outlined'
                          value={unSavedData[i].priority}
                          onChange={(e) => {
                            changeSelectorData(i, e.target.value)
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {newData.map((item, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className='carousel-card'
                  id={`card-drag-${i}`}
                  draggable='true'
                >
                  {width >= 768 && (
                    <div className='carousel-card__drag-icon'>
                      <img src={dragAndDrop} alt='drag and drop' />
                    </div>
                  )}
                  <div className='carousel-card__image'>
                    <div className='w-100'>
                      <img
                        src={image}
                        alt={`img-${id}-${priority}`}
                        className='w-100'
                      />
                    </div>
                  </div>
                  <div className='carousel-card__actions'>
                    <div className='row display-center-items h-100 '>
                      <div className='col-lg-5 col-md-12 text-left'>
                        <h3 className='font-sm-12'>{name}</h3>
                        <span className='font-sm-09'>
                          <a onClick={() => redirectToURL(link)}>{link}</a>
                        </span>
                      </div>
                      <div className='col-lg-7 col-md-12'>
                        <div className='row'>
                          <EditButton advertId={id} />
                          <OpenPopupButton
                            advertId={id}
                            imageNumber={priority}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

export default RenderImages
