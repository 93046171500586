import React, { FC } from 'react'
import { TableTango, CircularLoading } from '../../tango-react-base/components'
import { titlesHeader } from './tableHeader'
import RenderUserVisits from '../../views/visits/users'
import intl from 'react-intl-universal'

/**
 * Define the props of the component
 * @typedef  Props
 * @type {(Array|string|Function)}
 * @property {Array} data - is an Array.
 * @property {string} role - is an string
 * @property {boolean} handleEdit - is a boolean.
 * @property {Function} handleChange - is a Function.
 * @property {boolean} loading - is a boolean.
 */
interface Props {
  data: Array<any>
  handleEdit: boolean
  handleChange: Function
  loading: boolean
}

/**
 * Visits is a functional component with props.
 * Render the table with the data.
 *  @function
 *  @param {Props} data - function onSetTemplateChild
 *  @param {Props} handleEdit - boolean to handle the edit button
 *  @param {Props} handleChange - function handleChange
 *  @param {Props} loading - boolean to handle the table view
 *  @returns {(JSX.Element)} Returns a functional component
 */
const SortTable: FC<Props> = ({ data, handleEdit, handleChange, loading }) => {
  const renderAvailableVisitors = (
    availableVisitors: Array<any>,
    visitorFullName: string,
    unitVisitId: number,
    visitType: string,
  ) => {
    return (
      <RenderUserVisits
        visitors={availableVisitors}
        handleEdit={handleEdit}
        visitor={visitorFullName}
        unitVisitId={unitVisitId}
        handleChange={handleChange}
        visitType={visitType}
      />
    )
  }

  const bodyTable = data?.map(
    ({
      id,
      attributes: {
        visit_type,
        address,
        commune,
        visit_date,
        starts_time,
        ends_time,
        visitor_full_name,
        available_visitors,
      },
    }) => [
      address,
      commune,
      visit_date,
      starts_time,
      ends_time,
      renderAvailableVisitors(
        available_visitors,
        visitor_full_name,
        parseInt(id),
        visit_type,
      ),
    ],
  )

  return (
    <>
      {loading ? (
        <>
          <p className='w-100 text-center'>
            <CircularLoading />
            {intl.get('LOADING')}
          </p>
        </>
      ) : (
        <TableTango
          sortByHeader
          verticalCentered
          listTitle={titlesHeader}
          listBody={bodyTable}
          elevation={0}
          paginator={false}
          maxHeightBody={'20rem'}
          disableSort={!handleEdit}
        />
      )}
    </>
  )
}

export default SortTable
