import {
  getPostsRentedStart,
  getPostsRentedSuccess,
  getPostsRentedError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getPostRented = () => {
  return (dispatch: any) => {
    dispatch(getPostsRentedStart())
    getData(`posts?unit_state=rented`)
      .then((result) => {
        return dispatch(getPostsRentedSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPostsRentedError(e))
      })
  }
}
export default getPostRented
