export const createAdvertGalleryStart = () => {
  return {
    type: 'CREATE_ADVERT_GALLERY_START',
  }
}

export const createAdvertGallerySuccess = (result: any) => {
  return {
    type: 'CREATE_ADVERT_GALLERY_SUCCESS',
    payload: { result },
  }
}
export const createAdvertGalleryError = (error: any) => {
  return {
    type: 'CREATE_ADVERT_GALLERY_ERROR',
    payload: {
      error,
    },
  }
}
