import {
  deleteAdvertStart,
  deleteAdvertSuccess,
  deleteAdvertError,
} from './types'
import intl from 'react-intl-universal'
import { deleteData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
import { createAdvertGallery } from '../../'
const deleteAdvert = (
  advertId: number,
  advertGalleryId: number,
  lastGalleryState: string,
  transitionDurationGallery: number,
) => {
  return (dispatch: any, getState: any) => {
    dispatch(deleteAdvertStart())
    deleteData(`adverts/${advertId}?advert_gallery_id=${advertGalleryId}`)
      .then((result) => {
        toast(intl.get('DELETED_ADVERT'))
        dispatch(deleteAdvertSuccess(result))
        if (lastGalleryState === 'published') {
          dispatch(createAdvertGallery('carousel', transitionDurationGallery))
        }
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(deleteAdvertError(e))
      })
  }
}
export default deleteAdvert
