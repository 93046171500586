import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CircularLoading, CloseLeaseEarlyComponent } from '../../tango-react-base/components';
import {
  getClosureLeaseData,
  postClosureLeaseConfirmation,
  setTemplateChild,
} from '../../tango-react-base/reduxActions';

/**
 * Here the type of each Cardpromotion for the documentation
 * @typedef ClosureLeaseProps
 * @type {(function|string|any)}
 * @property {function} onSetTemplateChild - function that sets the header children
 * @property {any} closureLeaseData - Data for closure lease
 * @property {any} closureLeaseConfirmation - Data for closure lease confirmation, status of the request
 * @property {function} onGetClosureLeaseData - function that use redux action to get closure lease data
 * @property {function} onPostClosureLeaseConfirmation - function when the closure lease solicitude is sent to back
 */
interface ClosureLeaseProps {
  onSetTemplateChild: (child: Object) => void;
  closureLeaseData: any;
  closureLeaseConfirmation: any;
  onGetClosureLeaseData: (leasePeriodId: number) => void;
  onPostClosureLeaseConfirmation: (leasePeriodId: number, leaseClosingAt: Date, requesterComment: string) => void;
}

/**
 * Navigation component functions
 * @param ClosureLease
 * @returns jsx.function
 * @describe shows the lease termination request
 */
const ClosureLease = ({
  onSetTemplateChild,
  closureLeaseData,
  closureLeaseConfirmation,
  onGetClosureLeaseData,
  onPostClosureLeaseConfirmation,
}: ClosureLeaseProps) => {
  const { id }: any = useParams();
  const [closureLease, setClosureLease] = useState({
    minDate: new Date(),
    maxDate: null,
    dueDate: '',
    fineAmount: '',
    leaseData: [{}],
    registeredCurrency: '',
    address: '',
    redirections: [{}],
  });
  const history = useHistory();
  useEffect(() => {
    onGetClosureLeaseData(id);
  }, [id]);
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('ANTICIPATED_CONTRACT_TERM')}</h1>
        </>,
      );
    }
  }, [onSetTemplateChild]);

  useEffect(() => {
    if (closureLeaseData.status !== 'SUCCESS') return;

    const requisitionId = closureLeaseData.closureLeaseData?.requisition_data?.id;
    if (requisitionId !== null) return history.push(`/requisitions/${requisitionId}`);

    const {
      fine_amount,
      full_address_with_street_address_extra,
      plan_name,
      registered_currency,
      lease_amount,
      contract_sign_date,
      lease_period_start_date,
      lease_period_end_date,
      customers,
    } = closureLeaseData.closureLeaseData;

    const formattedRegisteredCurrency = registered_currency.toLowerCase();
    const leaseData = [
      { label: intl.get('PLAN'), value: plan_name },
      {
        label: intl.get('ESTIMATE_PRICE'),
        value:
          formattedRegisteredCurrency === 'clf' ? intl.getHTML('CLF_LEASE_VALUE', lease_amount) : lease_amount['clp'],
      },
      { label: intl.get('SIGNED_CONTRACT_AT'), value: contract_sign_date['with_format'] },
      { label: intl.get('LEASE_START_AT'), value: lease_period_start_date['with_format'] },
      { label: intl.get('DUE_AT'), value: lease_period_end_date['with_format'] },
    ];

    const customersRedirections = [
      {
        redirection: `/owners/${customers?.owner?.profile_id}`,
        label: intl.get('OWNER'),
        value: customers?.owner?.full_name,
      },
      {
        redirection: `/renters/${customers?.renter?.id}`,
        label: intl.get('RENTER'),
        value: customers?.renter?.full_name,
      },
    ];

    const minDate =
      new Date() < new Date(lease_period_start_date['without_format'])
        ? new Date(lease_period_start_date['without_format'])
        : new Date();

    setClosureLease({
      minDate: minDate,
      maxDate: lease_period_end_date['without_format'],
      dueDate: lease_period_end_date['with_format'],
      fineAmount: fine_amount,
      leaseData: leaseData,
      registeredCurrency: formattedRegisteredCurrency,
      address: full_address_with_street_address_extra,
      redirections: customersRedirections,
    });
  }, [closureLeaseData]);

  useEffect(() => {
    if (closureLeaseConfirmation.status !== 'SUCCESS') return;

    onGetClosureLeaseData(id);
  }, [closureLeaseConfirmation]);

  const leaseDataTitle = intl.get('ADMIN_LEASE_DATA_TITLE');
  const whenWillVacate = intl.get('ADMIN_WHEN_WILL_DESOCUPATE');
  const motiveText = intl.get('ADMIN_MOTIVE_TEXT');

  const onConfirmation = (date: any, motive: any) => {
    onPostClosureLeaseConfirmation(id, date, motive);
  };

  const switchRender = () => {
    switch (closureLeaseData.status) {
      case 'SUCCESS':
        return (
          <CloseLeaseEarlyComponent
            {...closureLease}
            yourPropertyText={leaseDataTitle}
            whenWillVacate={whenWillVacate}
            motiveText={motiveText}
            subtitle={intl.getHTML('ADMIN_CLOSURE_LEASE_SUBTITLE', { address: closureLease.address })}
            customWarning={intl.getHTML(
              `ADMIN_EARLY_LEASE_TERMINATION_WARNING_${closureLease.registeredCurrency.toUpperCase()}`,
              closureLease.fineAmount,
            )}
            onConfirmation={onConfirmation}
            sendingRequisition={closureLeaseConfirmation.status === 'STARTED'}
            noPopup
            customCardTitle={intl.getHTML('ADMIN_EARLY_LEASE_TERMINATION_DUE_DATE', { dueDate: closureLease.dueDate })}
          />
        );
      case 'STARTED':
        return <CircularLoading />;
      case 'ERROR':
        return <h2>{intl.get('REQUESTED_NOT_FOUND')}</h2>;
      default:
        return <h2>{intl.get('REQUESTED_NOT_FOUND')}</h2>;
    }
  };

  return <div className='container-home pd-1'>{switchRender()}</div>;
};

const BuildingView = () => {
  return (
    <div className='container-home mt-5 px-2'>
      <h2>{intl.get('BUILDING_SITE')}</h2>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    closureLeaseData: state.closureLeaseData,
    closureLeaseConfirmation: state.closureLeaseConfirmation,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onGetClosureLeaseData: (leasePeriodId: number) => {
      dispatch(getClosureLeaseData(leasePeriodId));
    },
    onPostClosureLeaseConfirmation: (leasePeriodId: number, leaseClosingAt: Date, requesterComment: string) => {
      dispatch(postClosureLeaseConfirmation(leasePeriodId, leaseClosingAt, requesterComment));
    },
  };
};

export { BuildingView };
export default connect(mapStateToProps, mapDispatchToProps)(ClosureLease);
