import {
  updateProfileStart,
  updateProfileSuc,
  updateProfileError,
} from './types'
import putData from '../../../tango-react-base/helpers/apiConnections/putData'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const createProfile = (data: any, id: string) => {
  let auxlast_payment_due: any
  if (data.last_payment_due === null) {
    auxlast_payment_due = ''
  } else auxlast_payment_due = data.last_payment_due
  return (dispatch: any) => {
    dispatch(updateProfileStart())
    let renter_profile = {
      renter_profile: {
        names: data.names,
        first_surname: data.first_surname,
        surnames: data.surnames,
        second_surname: data.second_surname,
        birth_date: data.birth_date,
        street_address: data.street_address,
        street_address_extra: data.street_address_extra,
        ocupation: data.ocupation,
        employee: data.employee,
        employee_age: data.employee_age,
        phone_number: data.phone_number,
        family_group: data.family_group,
        animals: data.animals,
        country_of_residence: data.country_of_residence,
        nationality: data.nationality,
        marital_status: data.marital_status,
        average_income_last_3_months: data.average_income_last_3_months_amount,
        average_income_last_3_months_currency: data.average_income_last_3_months_currency,
        valid_id_card: data.valid_id_card,
        report_date: data.report_date,
        truora_report_date: data.truora_report_date,
        socioeconomic_level: data.socioeconomic_level,
        vehicles_number: data.vehicles_number,
        properties_number: data.properties_number,
        tax_assessment: data.tax_assessment_amount,
        tax_assessment_currency: data.tax_assessment_currency,
        partner_relationships: data.partner_relationships,
        non_payment_orders: data.non_payment_orders,
        tax_annotations: data.tax_annotations,
        current_score: data.current_score,
        average_score_last_12_months: data.average_score_last_12_months,
        everclean_indicator: data.everclean_indicator,
        unpaid_total_debts: data.unpaid_total_debts,
        unpaid_debts_total_amount: data.unpaid_debts_total_amount_amount,
        unpaid_debts_total_amount_currency: data.unpaid_debts_total_amount_currency,
        last_payment_due: auxlast_payment_due,
        last_payment_debt_type: data.last_payment_debt_type,
        last_payment_amount: data.last_payment_amount_amount,
        last_payment_amount_currency: data.last_payment_amount_currency,
        debt_unpaid_detail: data.debt_unpaid_detail,
        job_newsletter: data.job_newsletter,
        renter_relationship:
          data.original_renter_relationship === 'OTHER'
            ? data.renter_relationship_other
            : data.original_renter_relationship,
        truora_trust_level: data.truora_trust_level,
        truora_name_score: data.truora_name_score,
        truora_id_score: data.truora_id_score,
        truora_identity: data.truora_identity,
        truora_penal_and_criminal: data.truora_penal_and_criminal,
        truora_legal: data.truora_legal,
        truora_international: data.truora_international,
        truora_media: data.truora_media,
        truora_taxes_and_finance: data.truora_taxes_and_finance,
        truora_criminal_record: data.truora_criminal_record,
        truora_observations: data.truora_observations,
        truora_lawsuits: data.truora_lawsuits,
        tango_points: data.tango_points,
      },
    }
    putData(`renter_profiles/${id}`, renter_profile)
      .then((result) => {
        toast(intl.get('PROFILE_SAVED'))
        dispatch(updateProfileSuc(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(updateProfileError(e))
      })
  }
}
export default createProfile
