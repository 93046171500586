export const getDatasContractsStart = () => {
    return {
        type: 'GET_DATAS_CONTRACTS_STARTED',
    };
};

export const getDatasContractSuccess = (result: any) => {
    return {
        type: 'GET_DATAS_CONTRACTS_SUCCESS',
        payload: { result },
    };
};
export const getDatasContractsError = (error: any) => {
    return {
        type: 'GET_DATAS_CONTRACTS_ERROR',
        payload: {
            error,
        },
    };
};
