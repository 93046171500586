import { AdvertGalleryState } from '../../../tango'

// POSIBLE REFACTOR: REALIZAR SOLO UN FETCHING STATUS DE ACCIONES.
// ANTES PROBAR QUE TAL FUNCIONA DE ESTA FORMA.
const initialState: AdvertGalleryState = {
  advertGallery: {},
  fetchingStatus: 'NO_LOADED',
  fetchingStatusPublish: 'NO_PUBLISHED',
  fetchingStatusCreate: 'NO_CREATED',
  fetchingStatusEdit: 'NO_EDITED',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    // GET ADVERT GALLERY
    case 'GET_ADVERT_GALLERY_START':
      return {
        ...state,
        fetchingStatus: 'LOADING',
      }
    case 'GET_ADVERT_GALLERY_SUCCESS':
      if (action.payload.result.status === 204) {
        return {
          ...state,
          fetchingStatus: 'LOADED',
        }
      } else {
        return {
          ...state,
          advertGallery: action.payload.result.data.data,
          fetchingStatus: 'LOADED',
        }
      }
    case 'GET_ADVERT_GALLERY_ERROR':
      return {
        ...state,
        fetchingStatus: 'ERROR',
      }
    // PUBLISH ADVERT GALLERY
    case 'PUBLISH_ADVERT_GALLERY_START':
      return {
        ...state,
        fetchingStatusPublish: 'PUBLISHING',
      }
    case 'PUBLISH_ADVERT_GALLERY_SUCCESS':
      return {
        ...state,
        fetchingStatusPublish: 'PUBLISHED',
      }
    case 'PUBLISH_ADVERT_GALLERY_ERROR':
      return {
        ...state,
        fetchingStatusPublish: 'ERROR',
      }
    // CREATE ADVERT GALLERY
    case 'CREATE_ADVERT_GALLERY_START':
      return {
        ...state,
        fetchingStatusCreate: 'CREATING',
      }
    case 'CREATE_ADVERT_GALLERY_SUCCESS':
      return {
        ...state,
        advertGallery: action.payload.result.data.data,
        fetchingStatusCreate: 'CREATED',
      }
    case 'CREATE_ADVERT_GALLERY_ERROR':
      return {
        ...state,
        fetchingStatusCreate: 'ERROR',
      }
    // EDIT ADVERT GALLERY
    case 'EDIT_ADVERT_GALLERY_START':
      return {
        ...state,
        fetchingStatusEdit: 'EDITING',
      }
    case 'EDIT_ADVERT_GALLERY_SUCCESS':
      return {
        ...state,
        fetchingStatusEdit: 'EDITED',
      }
    case 'EDIT_ADVERT_GALLERY_ERROR':
      return {
        ...state,
        fetchingStatusEdit: 'ERROR',
      }
    // RESET GALLERY
    case 'RESET_DATA':
      return {
        advertGallery: {},
        fetchingStatus: 'NO_LOADED',
        fetchingStatusPublish: 'NO_PUBLISHED',
        fetchingStatusCreate: 'NO_CREATED',
        fetchingStatusEdit: 'NO_EDITED',
      }
    default:
      return state
  }
}
