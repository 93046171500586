import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularLoading, CustomToolTip } from '../../tango-react-base/components';
import { formatNumber } from '../../tango-react-base/helpers/numberFormat';
import { useCurrencyConvertor } from '../../tango-react-base/hooks';
import {
  getCurrentRenewalPeriod,
  proposePriceByAdmin,
  rejectLeaseRenewalByAdmin,
  reproposePriceByAdmin,
  setTemplateChild,
} from '../../tango-react-base/reduxActions';
import { alertInfoIcon, leaseRenewalIcon, noLeaseRenewalIcon } from '../../tango-react-base/resources';
import ConfirmationModal from './confirmationModal';
import DataSentModal from './dataSentModal';
import DataModal from './ownerRenterDataForm';
import RenewalDataForm from './renewalDataForm';
import ResponseHistory from './responseHistory';

/**
 * Here it is defined the type of the props
 * @typedef MediationPropsTypes
 * @type {(function)}
 * @property {number} renewalPeriod - is a number.
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onProposePrice - is a function.
 * @property {function} onReproposePrice - is a function.
 * @property {function} onRejectLeaseRenewal - is a function.
 * @property {function} onGetCurrentRenewalPeriod - is a function.
 * @property {object} renewalPeriodData - is a object.
 * @property {string} renewalPeriodDataStatus - is a string.
 * @property {string} reproposePriceStatus - is a string.
 * @property {string} rejectLeaseRenewalStatus - is a string.
 * @property {React.ReactNode} children - is a children.
 * @return {component} Card admin component
 */

/**
 *Mediation is a functional component
 *@function
 *@param {MediationPropsTypes}  renewalPeriod - the renewal period id that you are find for
 *@param {MediationPropsTypes}  onSetTemplateChild - returns a template
 *@param {MediationPropsTypes}  onProposePrice - to propose a new price at the first time
 *@param {MediationPropsTypes}  onReproposePrice - to propose a new price after the frist time and then
 *@param {MediationPropsTypes}  onRejectLeaseRenewal - to reject a renewal lease contract
 *@param {MediationPropsTypes}  onGetCurrentRenewalPeriod - returns the current renewal period data
 *@param {MediationPropsTypes}  renewalPeriodData - is the current renewal period data object
 *@param {MediationPropsTypes}  renewalPeriodDataStatus - current renewal period state
 *@param {MediationPropsTypes}  reproposePriceStatus - returns the reproposition price state
 *@param {MediationPropsTypes}  proposePriceStatus -returns the proposition price state
 *@param {MediationPropsTypes}  rejectLeaseRenewalStatus -returns the rejecting renewal lease state
 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

interface CLFInterface {
  clf: number;
  status: string;
  date: { date: string };
}

type MediationProps = {
  renewalPeriod: { id: number };
  onSetTemplateChild: (child: Object) => void;
  onProposePrice: (data: any, id: number) => void;
  onReproposePrice: (data: any, id: number) => void;
  onRejectLeaseRenewal: (data: any, id: number) => void;
  onGetCurrentRenewalPeriod: (renewalPeriodId: number) => void;
  renewalPeriodData: {
    attributes: {
      current_lease_period_price: number;
      owner_data: { phone_number: number; full_name: string; email: string };
      renter_data: { phone_number: number; full_name: string; email: string };
      suggested_price: string;
      days_left: string;
      inflation: string;
      renewal_state: string;
      proposed_price_admin_amount: number;
      renter_counterproposal: {
        inflation: string;
        price: number;
        response: boolean;
        currency: string;
        date: Date;
      };
      owner_proposal: {
        inflation: string;
        price: number;
        response: boolean;
        currency: string;
        date: Date;
      };
      mediation_response: {
        owner: boolean;
        renter: boolean;
      };
      legal_mediation_days_left: number;
      mediation_days_left: number;
      due_at_current_active_contract: any;
      price_currency: string;
      mediation_history: any;
    };
  };
  renewalPeriodDataStatus: string;
  reproposePriceStatus: string;
  proposePriceStatus: string;
  rejectLeaseRenewalStatus: string;
};

const Mediation = ({
  onSetTemplateChild,
  onProposePrice,
  onReproposePrice,
  onRejectLeaseRenewal,
  onGetCurrentRenewalPeriod,
  renewalPeriodData,
  renewalPeriodDataStatus,
  reproposePriceStatus,
  proposePriceStatus,
  rejectLeaseRenewalStatus,
}: MediationProps) => {
  const history = useHistory();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSent, setOpenSent] = useState(false);
  const { id: renewalPeriodId } = useParams();
  const [data, setData] = useState({});
  const [option, setOption] = useState('');
  const [proposedPrice, setProposedPrice] = useState('0');
  const [isProposed, setIsProposed] = useState(false);
  const [isReproposed, setIsReproposed] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [currencyIsoValue, setCurrencyIsoValue] = useState('');
  const {
    current_lease_period_price,
    owner_data,
    renter_data,
    suggested_price,
    inflation,
    renewal_state,
    owner_proposal,
    renter_counterproposal,
    mediation_response,
    proposed_price_admin_amount,
    mediation_days_left: mediationDays,
    legal_mediation_days_left: legalMediationDays,
    due_at_current_active_contract,
    price_currency: originalCurrentPrice,
    mediation_history,
    days_left,
  } = renewalPeriodData?.attributes || '';

  const currentLeasePeriodPrice = `${originalCurrentPrice === 'CLP' ? '$' : 'UF'} ${formatNumber(
    current_lease_period_price,
  )}`;

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) {
      history.push('/login');
    }
  }, []);

  useEffect(() => {
    onSetTemplateChild(
      <>
        <h2>{intl.get('MEDIATION')}</h2>
      </>,
    );
    onGetCurrentRenewalPeriod(renewalPeriodId);
  }, [onSetTemplateChild, onGetCurrentRenewalPeriod, renewalPeriodId]);

  useEffect(() => {
    if (
      (proposePriceStatus === 'SENT_PROPOSE_PRICE' && isProposed) ||
      (reproposePriceStatus === 'SENT_REPROPOSE_PRICE' && isReproposed) ||
      (rejectLeaseRenewalStatus === 'SENT_REJECT_LEASE_RENEWAL' && isRejected)
    ) {
      setOpenSent(true);
    }
  }, [reproposePriceStatus, proposePriceStatus, rejectLeaseRenewalStatus, isReproposed, isProposed, isRejected]);

  const [, priceConversion, , currentUF] = useCurrencyConvertor({
    currencyIsoValue: originalCurrentPrice,
    value: current_lease_period_price,
  });

  /**
   * handleCloseConfirmation to close the confirmation modal
   */
  const handleCloseConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  /**
   * handleConfirm to confirm the action with the modal button, it can propose, reporpose and reject, it depends on the case
   */
  const handleConfirm = () => {
    setOpenConfirmationModal(false);
    if (option === 'newPrice') {
      if (renewal_state === 'mediating' && proposed_price_admin_amount) {
        setIsReproposed(true);
        setIsProposed(false);
        setIsRejected(false);
        onReproposePrice(data, renewalPeriodId);
      } else {
        setIsReproposed(false);
        setIsProposed(true);
        setIsRejected(false);
        onProposePrice(data, renewalPeriodId);
      }
    } else {
      setIsReproposed(false);
      setIsProposed(false);
      setIsRejected(true);
      onRejectLeaseRenewal(data, renewalPeriodId);
    }
  };

  /**
   * handleCloseSent to close the madal tha confirm the data was sent
   */
  const handleCloseSent = () => {
    setOpenSent(false);
    onGetCurrentRenewalPeriod(renewalPeriodId);
  };

  /**
   * sendPriceProposal to propose new price for renewal lease
   */
  const sendPriceProposal = (data: any) => {
    setOpenConfirmationModal(true);
    setData(data);
    setOption(data.option);
  };
  /**
   * sendRejection to reject the renewal lease
   */
  const sendRejection = (data: any) => {
    setOpenConfirmationModal(true);
    setData(data);
    setOption(data.option);
  };

  /**
   * onSubmit the submit of the confirm button
   */
  const onSubmit = (values: any) => {
    setCurrencyIsoValue(values.currencyIsoValue);
    setProposedPrice(values.proposed_price);

    if (!values.option) toast(intl.get('SELECT_OPTION'));

    if (values.option === 'reject') {
      if (!values.reasonNoRenewal) toast(intl.get('ENTER_REASON'));
      else sendRejection(values);
    }

    if (values.option === 'newPrice') {
      if (!values.proposed_price) toast(intl.get('ENTER_VALUE'));
      else sendPriceProposal(values);
    }
  };

  /**
   * TextView is a component
   * @function
   * @returns {(ReactComponent)} Returns a react component with the view text
   */

  const TextView = () => {
    return (
      <div>
        <p className='text my-4'>
          {intl.getHTML('DAYS_LEFT_FOR_RENEWAL', {
            due_at: due_at_current_active_contract,
            days_left,
          })}
        </p>

        {renewal_state === 'mediating' && (
          <>
            <p className='text my-4'>
              {legalMediationDays > 0
                ? intl.getHTML('DAYS_LEFT_FOR_MEDIATION_ADMIN', {
                    days_left: legalMediationDays,
                  })
                : intl.getHTML('MEDIATION_DAYS_OVERDUE_FOR_ADMIN')}
            </p>
            <p className='text my-4'>
              {mediationDays > 0
                ? intl.getHTML('DAYS_LEFT_FOR_MEDIATION', {
                    days_left: mediationDays,
                  })
                : intl.getHTML('MEDIATION_DAYS_OVERDUE', {
                    days_left: legalMediationDays,
                  })}
            </p>
          </>
        )}
        <ul className='text my-4'>{intl.get('INFORMATION_CONSIDER')}:</ul>
        {originalCurrentPrice === 'CLP' ? (
          <>
            <li className='text my-4 pl-2'>
              {intl.getHTML('SUGGESTED_PRICE', {
                suggested_price,
                inflation: inflation,
              })}
            </li>
            <li className='text my-4 pl-2'>
              {intl.get('CURRENT_INFLATION_VALUE', {
                inflation: inflation,
              })}
              <CustomToolTip
                headerText={intl.get('IMPORTANT_TEXT')}
                bodyText={intl.get('PRICE_CAN_VARY')}
                icon={alertInfoIcon}
                classImage='ml-2'
              />
            </li>
            <li className='text my-4 pl-2'>
              {intl.get('CURRENT_LEASE_VALUE', {
                currentLeasePeriodPrice,
              })}
            </li>
          </>
        ) : (
          <>
            <div className='text-left pl-2 pl-md-4'>
              {intl.getHTML('ORIGINAL_LEASE_VALUE', {
                currentLeasePeriodPrice,
              })}{' '}
              {intl.getHTML('REF_VALUE_INFO', {
                price: priceConversion,
              })}
            </div>
            <div className='text-left pl-2 pl-md-4'>
              <a
                className='underline-link'
                href={process.env.REACT_APP_UF_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                *{intl.get('CURRENT_UF_VALUE')}
              </a>{' '}
              $ {formatNumber(currentUF)}
            </div>
          </>
        )}
      </div>
    );
  };

  /**
   * MediationView is a component
   * @function
   * @returns {(ReactComponent)} Returns a react component with the mediation view
   */

  const MediationView = () => {
    return (
      <>
        <div className='container-home pd-1'>
          <div className='template-line-title mt-5' />
          <h2 className='text my-2'>{intl.get('MEDIATION_PROCCESS')}</h2>

          {renewal_state && renewal_state !== 'rejected' && renewal_state !== 'accepted' && <TextView />}

          {(renewal_state && renewal_state === 'rejected') || renewal_state === 'accepted' ? (
            <>
              <RejectedAcceptedView />
            </>
          ) : (
            <>
              <RenewalDataForm
                onSubmit={onSubmit}
                currentPrice={current_lease_period_price}
                mediationStatus={renewal_state}
                response={mediation_response}
                renewalPeriodDataStatus={renewalPeriodDataStatus}
                proposePriceStatus={proposePriceStatus}
                reproposePriceStatus={reproposePriceStatus}
                originalCurrentPrice={originalCurrentPrice}
              ></RenewalDataForm>
            </>
          )}
          <DataModal renter={renter_data} owner={owner_data} />
          <ResponseHistory
            owner={owner_proposal}
            renter={renter_counterproposal}
            response={mediation_response}
            renterNames={renter_data}
            ownerNames={owner_data}
            history={mediation_history}
          />
        </div>

        {openConfirmationModal && (
          <ConfirmationModal
            handleCloseConfirmation={handleCloseConfirmation}
            openConfirmationModal={openConfirmationModal}
            handleConfirm={handleConfirm}
            optionSeleted={option}
            proposedPrice={proposedPrice}
            currencyIsoValue={currencyIsoValue}
          ></ConfirmationModal>
        )}
        {openSent && (
          <DataSentModal handleCloseSent={handleCloseSent} openSent={openSent} optionSeleted={option}></DataSentModal>
        )}
      </>
    );
  };
  /**
   * RejectedAcceptedView shows a view  when the renewal period state is rejected or accepted
   */
  const RejectedAcceptedView = () => {
    return (
      <div className='rejected-lease-renewal-container'>
        <div className='pl-5'>
          <img
            src={renewal_state === 'rejected' ? noLeaseRenewalIcon : leaseRenewalIcon}
            className='img-center'
            alt='noLeaseRenewalIcon'
            height='50'
          />
        </div>
        <div className='pl-4'>
          <h2 className='lease-renewal-text py-2'>
            <strong>
              {renewal_state === 'rejected' ? intl.get('DATA_NO_RENEWAL_SENT') : intl.get('RENEWAL_ACCEPTED')}
            </strong>
          </h2>
          <h2 className='lease-renewal-text'>
            {renewal_state === 'rejected' ? intl.get('CONTACT_PHOTOGRAPHER') : intl.get('MEDIATION_END')}
          </h2>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renewalPeriodDataStatus === 'FETCHED' ? (
        <>
          <MediationView />
        </>
      ) : (
        <CircularLoading />
      )}
    </div>
  );
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function.
 */

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatches an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      return dispatch(setTemplateChild(child));
    },
    onProposePrice: (data: any, id: number) => {
      return dispatch(proposePriceByAdmin(data, id));
    },
    onReproposePrice: (data: any, id: number) => {
      return dispatch(reproposePriceByAdmin(data, id));
    },
    onRejectLeaseRenewal: (data: any, id: number) => {
      return dispatch(rejectLeaseRenewalByAdmin(data, id));
    },
    onGetCurrentRenewalPeriod: (id: number) => {
      dispatch(getCurrentRenewalPeriod(id));
    },
  };
};

const mapStateToProps = (state: any) => ({
  renewalPeriodData: state.currentRenewalPeriod.renewalPeriod,
  renewalPeriodDataStatus: state.currentRenewalPeriod.fetchingRenewalPeriod,
  proposePriceStatus: state.proposePrice.priceProposalStatus,
  reproposePriceStatus: state.proposePrice.priceReproposalStatus,
  rejectLeaseRenewalStatus: state.rejectRenewal.rejectLeaseRenewalStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(Mediation);
