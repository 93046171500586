import React, { useRef } from 'react'
import intl from 'react-intl-universal'
import { CustomToolTip } from '../../tango-react-base/components'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize/index'


const ContractStatesExplanation = () => {
  const tootilRef =  useRef<HTMLInputElement>(null)
  const sizeWidth = useWindowSize().width
  const contractStates = [
    { state: 'EMITTED_STATE', text: 'EMITTED_STATE_EXPLANATION' },
    { state: 'SIGNED_STATE', text: 'SIGNED_STATE_EXPLANATION' },
    { state: 'ACTIVE_STATE', text: 'ACTIVE_STATE_EXPLANATION' },
    { state: 'NULLIFIED_STATE', text: 'NULLIFIED_STATE_EXPLANATION' },
    { state: 'CANCELED_STATE', text: 'CANCELED_STATE_EXPLANATION' },
    { state: 'NOTIFIED_STATE', text: 'NOTIFIED_STATE_EXPLANATION' },
    { state: 'NEGOTIATING_STATE_2', text: 'NEGOTIATING_STATE_EXPLANATION' },
    { state: 'RENEWAL_ACCEPTED_STATE', text: 'RENEWAL_ACCEPTED_STATE_EXPLANATION' },
    { state: 'RENEWAL_REJECTED_STATE', text: 'RENEWAL_REJECTED_STATE_EXPLANATION' },
    { state: 'RENEWAL_ACTIVE_STATE', text: 'RENEWAL_ACTIVE_STATE_EXPLANATION' },
    { state: 'RENEWAL_MEDIATING_STATE', text: 'RENEWAL_MEDIATING_STATE_EXPLANATION' },
    { state: 'FINISHED_STATE', text: 'FINISHED_STATE_EXPLANATION' },
  ]
  const scrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: 'smooth'
    })
  }
  return (
    <div className='contract-states' ref={tootilRef} onClick={() => sizeWidth >= 768 ? tootilRef?.current?.scrollIntoView({behavior: 'smooth'}): scrollToTop() }>
      <div>
        <CustomToolTip
              classImage='ml-2'
              toolTipText={intl.get('CONTRACT_STATES_TITLE')}
              popOver
              arrowPlacement={sizeWidth >= 768 ? 'right' : 'bottom'} 
        >
          <ul className='contract-states-list'>
              {contractStates.map(({ text, state }, index) => (
                <li className='my-2' key={index}>
                  <span className='font-weight-bold contract-state'>{intl.get(state)}: </span>
                  <span>{intl.get(text)}</span>
                </li>
              ))}
          </ul>
        </CustomToolTip>
      </div>
    </div>
  )
}

export default ContractStatesExplanation