import {
    renterDocumentsCountSuc,
    renterDocumentsCountStart
  } from './types'
  const renterDocumentsCount = (data: any) => {
    return (dispatch: any) => {
      dispatch(renterDocumentsCountStart())
      dispatch(renterDocumentsCountSuc(data))
    }
  }
  
  export default renterDocumentsCount