import React from 'react'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'

interface InputUserSearchProps {
  value: any
  id: string
  label: string
  handleChange: any
}

const borderColor = '#A8123E'
const BootstrapInput = withStyles({
  root: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: borderColor,
    },
    '& .MuiTextField-root': {
      color: borderColor,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: borderColor,
    },
    '& label.Mui-focused': {
      color: 'transparent',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor,
      },
      '&:hover fieldset': {
        borderColor: borderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: borderColor,
      },
    },
    '& .MuiFormLabel-root': {
      zIndex: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: 'small',
      marginLeft: 4,
      position: 'absolute',
      bottom: -20,
    },
  },
})(TextField)

const InputUserSearch = ({
  value,
  id,
  label,
  handleChange,
}: InputUserSearchProps) => {
  return (
    <Field>
      {({ form }: any) => {
        return (
          <>
            <BootstrapInput
              id={id}
              value={value ? value : undefined}
              type='text'
              label={label}
              onChange={handleChange}
            />
          </>
        )
      }}
    </Field>
  )
}

export default InputUserSearch
