import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { getPaidOrDueStart, getPaidOrDueSuccess, getPaidOrDuenError } from './types';
import { handleError } from '../../helpers/handleErrors';
const getPaidOrDueRenter = (state?: string) => {
    return (dispatch: any) => {
        dispatch(getPaidOrDueStart());
        getData(`charges?state=${state}`)
            .then((result) => {
                return dispatch(getPaidOrDueSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPaidOrDuenError(e));
            });
    };
};
export default getPaidOrDueRenter;
