import {
  rejectDocumentStart,
  rejectDocumentSuc,
  rejectDocumentError,
} from './types'
import {
  renterDocumentsCountStart,
  renterDocumentsCountModified,
} from '../renterDocumentsCount/types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUnit = (
  doc_id: number,
  index: string,
  alternative_url?: string,
  renterProfileIndex?: any,
  documentType?: any,
) => {
  const uri =
    alternative_url === ''
      ? `unit_documents/${doc_id}/reject`
      : `${alternative_url}/${doc_id}/reject`
  return (dispatch: any) => {
    dispatch(rejectDocumentStart(index))
    putData(uri)
      .then((result: any) => {
        toast(intl.get('DOCUMENT_REJECTED'))
        dispatch(
          rejectDocumentSuc(result.data, doc_id, index, renterProfileIndex),
        )
        if (typeof renterProfileIndex === 'number' && documentType) {
          dispatch(renterDocumentsCountStart())
          dispatch(
            renterDocumentsCountModified(renterProfileIndex, documentType, 1),
          )
        }
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(rejectDocumentError(e, index))
      })
  }
}

export default getUnit
