import { PostsAdminState } from '../../../tango'

const initialState: PostsAdminState = {
  posts: [],
  fetchingPosts: 'NO_POSTS',
  postsToRepublish: [],
  fetchingPostsToRepublish: 'NO_POSTS',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_POSTSADMIN_STARTED':
    case 'GET_POSTS_REGISTERED_STARTED':
      return {
        ...state,
        fetchingPosts: 'LOADING',
        fetchingPostsToRepublish: 'NO_POSTS',
        postsToRepublish: [],
      }
    case 'GET_POSTSADMIN_SUCCESS':
    case 'GET_POSTS_REGISTERED_SUCCESS':
      return {
        ...state,
        fetchingPosts: 'LOADED',
        posts: action.payload.result.data,
        fetchingPostsToRepublish: 'NO_POSTS',
        postsToRepublish: [],
      }
    case 'GET_POSTSADMIN_ERROR':
    case 'GET_POSTS_REGISTERED_ERROR':
      return {
        ...state,
        fetchingPosts: 'ERROR',
        fetchingPostsToRepublish: 'NO_POSTS',
        postsToRepublish: [],
      }

    case 'GET_POSTS_TO_REPUBLISH_STARTED':
      return {
        ...state,
        fetchingPosts: 'NO_POSTS',
        fetchingPostsToRepublish: 'LOADING',
      }

    case 'GET_POSTS_TO_REPUBLISH_SUCCESS':
      return {
        ...state,
        fetchingPosts: 'NO_POSTS',
        fetchingPostsToRepublish: 'LOADED',
        posts: [],
        postsToRepublish: action.payload.posts.data,
      }

    case 'GET_POSTS_TO_REPUBLISH_ERROR':
      return {
        ...state,
        fetchingPosts: 'NO_POSTS',
        fetchingPostsToRepublish: 'ERROR',
      }
    default:
      return state
  }
}
