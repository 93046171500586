export const publishUnitVersionStart = () => {
    return {
        type: 'PUBLISH_UNIT_VERSION_STARTED',
    };
};
export const publishUnitVersionSuccess = (result: any) => {
    return {
        type: 'PUBLISH_UNIT_VERSION_SUCCESS',
        payload: result,
    };
};

export const publishUnitVersionError = (error: any) => {
    return {
        type: 'PUBLISH_UNIT_VERSION_ERROR',
        payload: {
            error,
        },
    };
};
