import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { UnitVersionInterface } from '../../tango-react-base/model_interfaces/UnitVersionValidationClasses';

import intl from 'react-intl-universal';
import { RepublishForm } from '../../components';
import DocumentsLink from '../../components/documentsLink/DocumentsLink';
import OwnerLink from '../../components/ownerLink/OwnerLink';
import { getUser } from '../../redux/actions';
import {
  AccordionItem,
  AllPhotos,
  ButtonLoading,
  CircularLoading,
  VideosCarousel,
} from '../../tango-react-base/components';
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize';
import {
  getUnitVersion,
  publishUnitVersion,
  setTemplateChild,
  updateUnitVersion,
} from '../../tango-react-base/reduxActions';

interface RepublishPostProps {
  unitVersion: UnitVersionInterface;
  onSetTemplateChild: (child: Object) => void;
  onGetUnitVersion: (unitId: any) => void;
  onGetUser: (OwnerId: number) => void;
  fetchingUnitVersion: string;
  savingUnitVersion: string;
  user: any;
  name: string;

  children: any;
  onUpdateUnitVersion: (id: number, values: any) => void;
  onPublishUnitVersion: (id: number) => void;
  toRepublish: boolean;
  publishingUnitVersion: string;
}

const RepublishPost = ({
  unitVersion,
  onSetTemplateChild,
  onGetUnitVersion,
  onGetUser,
  fetchingUnitVersion,
  savingUnitVersion,
  publishingUnitVersion,
  user,
  name,

  onUpdateUnitVersion,
  onPublishUnitVersion,
}: RepublishPostProps) => {
  const history = useHistory();
  const { id }: any = useParams();
  const [toRepublish, setToRepublish] = useState(true);
  const [fullFieldsBasic, setFullFieldsBasic] = useState(false);
  const [fullFieldsEquipment, setFullFieldsEquipment] = useState(false);
  const [fullFieldsUnitDetail, setFullFieldsUnitDetail] = useState(false);
  const [fullFieldsCommonSpaces, setFullFieldsCommonSpaces] = useState(false);
  const [fullFieldsTerms, setFullFieldsTerms] = useState(false);
  const [fullFields, setFullFields] = useState(false);
  const size = useWindowSize();
  // This is to know where the scroll to hide the floating button is
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let containerHeight = document?.getElementById('container')?.offsetHeight;
  let childrenHeight = document?.getElementById('children-div')?.offsetHeight;
  let TotalHeight =
    containerHeight && childrenHeight ? containerHeight - (childrenHeight + size.width > 577 ? 600 : 100) : 0;
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('POST_DETAILS')}</h1>
        </>,
      );
      onGetUnitVersion(id);
    }
  }, [id, history, onGetUnitVersion, onSetTemplateChild]);

  useEffect(() => {
    if (fetchingUnitVersion === 'LOADED') {
      onGetUser(unitVersion.attributes.owner_id);
    }
  }, [fetchingUnitVersion, onGetUser, unitVersion]);

  const unitVertionState = unitVersion?.attributes?.aasm_state;

  useEffect(() => {
    setFullFields(
      fullFieldsBasic && fullFieldsEquipment && fullFieldsUnitDetail && fullFieldsCommonSpaces && fullFieldsTerms,
    );
  }, [fullFieldsTerms, fullFieldsCommonSpaces, fullFieldsUnitDetail, fullFieldsEquipment, fullFieldsBasic]);

  const renderLoading = () => (
    <div>
      {fetchingUnitVersion === 'LOADING' && <span>{<CircularLoading />}</span>}
      {fetchingUnitVersion === 'ERROR' && <span>{intl.get('ERROR')}</span>}
    </div>
  );

  const isFetching = savingUnitVersion === 'SAVING' || savingUnitVersion === 'ERROR';

  const renderPhotos = (photos: any) => {
    return (
      <>
        <div className='wrap'>{photos.length ? <AllPhotos photos={photos} /> : intl.get('NO_PHOTOS')}</div>
      </>
    );
  };

  const renderVideos = () => {
    if (unitVersion.attributes.videos?.length !== 0) {
      const videosArray = unitVersion.attributes.videos || [];
      return (
        <>
          <div className='wrap'>
            <VideosCarousel videos={videosArray} />
          </div>
        </>
      );
    } else {
      return <>{intl.get('NO_VIDEOS')}</>;
    }
  };

  const publishUnitVersion = () => {
    onPublishUnitVersion(id);
  };

  const isFetchingRepublishing = publishingUnitVersion === 'PUBLISHING' || publishingUnitVersion === 'ERROR';

  const renderForm = () => {
    const { photos, owner_profile, owner_id, can_be_approved, rent_id } = unitVersion?.attributes;

    const ownerProfilePresent = user?.owner_profile_present;
    const ownerId = owner_profile?.id;
    return (
      <div id='container' className='container-home pd-1 mt-3'>
        <div className='d-flex justify-content-start flex-column'>
          <AccordionItem expanded={true} title={intl.get('OWNER')}>
            <OwnerLink ownerProfile={ownerProfilePresent} ownerId={ownerId || owner_id}></OwnerLink>
          </AccordionItem>
          <AccordionItem expanded={true} title={intl.get('PROPERTY_DOCUMENTATION')}>
            <DocumentsLink unitId={unitVersion.id} rentId={rent_id} />
          </AccordionItem>
        </div>
        <RepublishForm
          unitVersion={unitVersion}
          disabled={false}
          onSubmit={onUpdateUnitVersion}
          toRepublish={toRepublish}
          setFullFieldsBasic={setFullFieldsBasic}
          setFullFieldsEquipment={setFullFieldsEquipment}
          setFullFieldsUnitDetail={setFullFieldsUnitDetail}
          setFullFieldsCommonSpaces={setFullFieldsCommonSpaces}
          setFullFieldsTerms={setFullFieldsTerms}
        >
          {
            <>
              <AccordionItem title={intl.get('PHOTOS')}>{renderPhotos(photos)}</AccordionItem>
              <AccordionItem title={intl.get('VIDEOS')}>{renderVideos()}</AccordionItem>
              <div id='children-div'>
                <div className='d-flex justify-content-between flex-wrap mx-2 mb-5'>
                  <>
                    {TotalHeight < scrollPosition ? (
                      <button disabled={unitVertionState === 'available'} className='button-primary col-md-5 mt-4'>
                        {isFetching ? <ButtonLoading /> : intl.get('SAVE')}
                      </button>
                    ) : (
                      <div className='btn-floating-container'>
                        {size.width > 577 ? (
                          <button
                            type='submit'
                            disabled={unitVertionState === 'available'}
                            className='button-primary px-4'
                          >
                            {isFetching ? <ButtonLoading /> : intl.get('SAVE')}
                          </button>
                        ) : (
                          <button
                            type='submit'
                            disabled={unitVertionState === 'available'}
                            className='btn-img px-4'
                          ></button>
                        )}
                      </div>
                    )}

                    <button
                      type='submit'
                      className={`col-md-5 mt-4 ${
                        !fullFields || unitVertionState === 'available'
                          ? 'button-secondary-disabled-no-cursor'
                          : 'button-secondary'
                      }`}
                      onClick={() => publishUnitVersion()}
                      disabled={!can_be_approved || !fullFields || unitVertionState === 'available'}
                    >
                      {isFetchingRepublishing ? <ButtonLoading /> : intl.get('REPUBLISH_PROPERTY')}
                    </button>
                  </>
                </div>
              </div>
            </>
          }
        </RepublishForm>
        {unitVertionState === 'available' && (
          <div className='my-4'>
            <span>{intl.get('PROPERTY_PUBLISHED')}</span>
          </div>
        )}
      </div>
    );
  };

  return <>{fetchingUnitVersion !== 'LOADED' ? renderLoading() : renderForm()}</>;
};

const mapStateToProps = (state: any) => {
  const {
    unitVersion,
    fetchingUnitVersion,
    publishingUnitVersion,
    documentsProcessed,
    savingUnitVersion,
    error,
  } = state.unitVersion;

  const { name, rut, fetchingUser, user } = state.userProperty;
  return {
    unitVersion,
    fetchingUnitVersion,
    savingUnitVersion,
    publishingUnitVersion,
    documentsProcessed,
    error,
    name,
    rut,
    fetchingUser,
    user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetUnitVersion: (unitId: number) => {
      dispatch(getUnitVersion(unitId));
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },

    onGetUser: (ownerID: number) => {
      dispatch(getUser(ownerID));
    },
    onPublishUnitVersion: (unitId: number) => {
      dispatch(publishUnitVersion(unitId));
    },

    onUpdateUnitVersion: (id: number, values: any) => {
      dispatch(updateUnitVersion(id, values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RepublishPost);
