// TODO Cambiar a dir 'actionCreators'!

export const rejectDocumentStart = (index: string) => {
  return {
    type: 'REJECT_DOCUMENT_STARTED',
    payload: { index },
  }
}

export const rejectDocumentSuc = (document: any, id: number, index: string, renterProfileIndex: number) => {
  return {
    type: 'REJECT_DOCUMENT_SUCCESS',
    payload: { 
      document,
      id,
      index,
      renterProfileIndex
    },
  }
}
export const rejectDocumentError = (error: any, index: string) => {
  return {
    type: 'REJECT_DOCUMENT_ERROR',
    payload: {
      error,
      index,
    },
  }
}
