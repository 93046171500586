export const getSalesNotesStart = () => {
    return {
        type: 'GET_NOTES_STARTED',
    }
}

export const getSalesNotesSuc = (notes: any) => {
    return {
        type: 'GET_NOTES_SUCCESS',
        payload: notes,
    }
}

export const getSalesNotesError = (error: any) => {
    return {
        type: 'GET_NOTES_ERROR',
        payload: {
            error,
        },
    }
}