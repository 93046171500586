const initialState: any = {
  priceProposalStatus: 'PROPOSAL_NOT_SENT',
  priceReproposalStatus: 'REPROPOSAL_NOT_SENT',
  error: '',
}
export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'PROPOSE_PRICE_STARTED':
      return {
        ...state,
        priceProposalStatus: 'SENDING_PROPOSE_PRICE',
        priceReproposalStatus: 'REPROPOSAL_NOT_SENT',
      }
    case 'PROPOSE_PRICE_SUCCESS':
      return {
        ...state,
        priceProposalStatus: 'SENT_PROPOSE_PRICE',
        priceReproposalStatus: 'REPROPOSAL_NOT_SENT',
      }
    case 'PROPOSE_PRICE_ERROR':
      return {
        ...state,
        error: action.payload.error,
        priceProposalStatus: 'ERROR_SENDING_PROPOSE_PRICE',
        priceReproposalStatus: 'REPROPOSAL_NOT_SENT',
      }
    case 'REPROPOSE_PRICE_STARTED':
      return {
        ...state,
        priceReproposalStatus: 'SENDING_REPROPOSE_PRICE',
        priceProposalStatus: 'PROPOSAL_NOT_SENT',
      }
    case 'REPROPOSE_PRICE_SUCCESS':
      return {
        ...state,
        priceReproposalStatus: 'SENT_REPROPOSE_PRICE',
        priceProposalStatus: 'PROPOSAL_NOT_SENT',
      }
    case 'REPROPOSE_PRICE_ERROR':
      return {
        ...state,
        error: action.payload.error,
        priceReproposalStatus: 'ERROR_SENDING_REPROPOSE_PRICE',
        priceProposalStatus: 'PROPOSAL_NOT_SENT',
      }
    default:
      return state
  }
}
