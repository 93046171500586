export const detailsLabels = (plan: any, value: any, signed_date: any, start_date: any, end_date: any) => [
  {
    label: 'PLAN',
    value: plan,
  },
  {
    label: 'LABEL_VALUE',
    value: value,
  },
  {
    label: 'SIGNED_DATE',
    value: signed_date,
  },
  {
    label: 'STARTED_DATE',
    value: start_date,
  },
  {
    label: 'END_DATE',
    value: end_date,
  },
];

export const selectOptions = [
  {
    label: 'OWNER',
    value: 'Owner',
  },
  {
    label: 'RENTER',
    value: 'Renter',
  },
  {
    label: 'BOTH',
    value: 'both',
  },
  {
    label: 'NONE',
    value: 'none',
  },
];

export const tabStatusOptions = [
  {
    label: 'APPROVED_UNIT',
    value: 'accepted',
  },
  {
    label: 'DENIED',
    value: 'rejected',
  },
  {
    label: 'TITLE_FINISHED',
    value: 'finished',
  },
];

export const paymentLables = (pay_date: any, provider: any, payment_method: any) => [
  {
    label: 'PAYMENT_DATE',
    value: pay_date,
  },
  {
    label: 'PROVIDER',
    value: provider,
  },
  {
    label: 'PAYMENT_KIND',
    value: payment_method,
  },
];
