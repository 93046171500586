export const uploadFileStart = () => {
    return {
        type: 'SAVE_DOCUMENT_STARTED',
        payload: {},
    };
};
export const uploadFileSuccess = (file: any) => {
    return {
        type: 'SAVE_DOCUMENT_SUCCESS',
        payload: { file },
    };
};
export const uploadFileError = (error: any) => {
    return {
        type: 'SAVE_DOCUMENT_ERROR',
        payload: {
            error,
        },
    };
};
