const initialState: any = {
    adminContract: {},
    fetchAdminContract: 'NO_FETCH',
    fetchAdminContractDetail: 'NO_FETCH',
    fetchAdminContractHistory: 'NO_FETCH',
    adminContractDetail: {},
    adminContractHistory: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_ADMIN_CONTRACT_STARTED':
            return {
                ...state,
                fetchAdminContract: 'FETCHING',
            };
        case 'GET_ADMIN_CONTRACT_SUCCESS':
            return {
                ...state,
                adminContract: action.payload.result.data.data,
                fetchAdminContract: 'FETCHED',
            };
        case 'GET_ADMIN_CONTRACT_ERROR':
            return {
                ...state,
                error: action.payload.error,
                fetchAdminContract: 'ERROR',
            };
        case 'GET_ADMIN_CONTRACT_HISTORY_STARTED':
            return {
                ...state,
                fetchAdminContractHistory: 'FETCHING_ADMIN_CONTRACT_HISTORY',
                adminContractDetail: {},
                adminContractHistory: {},
                fetchAdminContractDetail: 'NO_FETCH',
            };
        case 'GET_ADMIN_CONTRACT_HISTORY_SUCCESS':
            return {
                ...state,
                adminContractHistory: action.payload.result.data.data,
                fetchAdminContractHistory: 'FETCHED_ADMIN_CONTRACT_HISTORY',
                adminContractDetail: {},
            };
        case 'GET_ADMIN_CONTRACT_HISTORY_ERROR':
            return {
                ...state,
                error: action.payload.error,
                fetchAdminContractHistory: 'ERROR_ADMIN_CONTRACT_HISTORY',
            };

        case 'GET_ADMIN_CONTRACT_DETAIL_STARTED':
            return {
                ...state,
                fetchAdminContractDetail: 'FETCHING_ADMIN_CONTRACT_DETAIL',
                adminContractDetail: {},
                adminContractHistory: {},
                fetchAdminContractHistory: 'NO_FETCH',
            };
        case 'GET_ADMIN_CONTRACT_DETAIL_SUCCESS':
            return {
                ...state,
                adminContractDetail: action.payload.result.data.data,
                fetchAdminContractDetail: 'FETCHED_ADMIN_CONTRACT_DETAIL',
                adminContractHistory: {},
            };
        case 'GET_ADMIN_CONTRACT_DETAIL_ERROR':
            return {
                ...state,
                error: action.payload.error,
                fetchAdminContractDetail: 'ERROR_ADMIN_CONTRACT_DETAIL',
            };
        default:
            return state;
    }
};
