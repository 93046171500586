import React, { useEffect } from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { PriceInput } from '../../tango-react-base/components'
import { CURRENCY_OPTIONS } from '../../tango-react-base/helpers/selectOptions/options'
import {
  priceNumberValidation,
} from '../../tango-react-base/helpers/priceValidation'
import intl from 'react-intl-universal'
import { setLocale } from 'yup'

interface AuthorizedAmountProps {
  onSubmit: any
  idUser?: string
  children: any
  disabled: boolean
  price?: number
  setUnauthorizedAmount: (value: boolean) => void
}

interface IncomeData {
  authorized_amount_currency: string
  authorized_amount_cents: number
}

const InnerForm: React.FunctionComponent<
  AuthorizedAmountProps & FormikProps<IncomeData>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  disabled,
  price,
  setUnauthorizedAmount
}) => {

  useEffect(()=> {
    setUnauthorizedAmount(priceNumberValidation(values.authorized_amount_cents))
  }, [values.authorized_amount_cents])


  return (
    <div className='container-home post-validation-form'>
      <Form>
        {price && (
          <p className='text-span text-left'>
            *{intl.get('RENT_PRICE')}: <span>{price}</span>
          </p>
        )}
        <PriceInput
          id='authorized_amount_cents'
          id_currency='authorized_amount_currency'
          currency_options={CURRENCY_OPTIONS}
          currency_iso_value={values.authorized_amount_currency}
          label='AUTHORIZED_AMOUNT'
          value={values.authorized_amount_cents}
          onChange={handleChange}
          onBlur={handleBlur}
          min='200000'
          disabled={disabled}
        />
        {children}
      </Form>
    </div>
  )
}
const FormAmount = () => {
  setLocale({
    mixed: {
      required: intl.get('REQUIRED_FIELD'),
    },
    number: {
      min: intl.get('MINIMUM_AUTHORIZED_AMOUNT'),
    },
  })

  return Yup.object().shape({
    authorized_amount_cents: Yup.number().nullable().min(200000),
  })
}

const AuthorizedAmount = withFormik<AuthorizedAmountProps, IncomeData>({
  mapPropsToValues: (props: any) => {
    return { ...props, idUser: props.idUser, onSubmit: props.onSubmit }
  },

  validationSchema: FormAmount(),

  handleSubmit(values: IncomeData, { props, setSubmitting, setErrors }) {
    props.onSubmit(values)
  },
})(InnerForm)

export default AuthorizedAmount
