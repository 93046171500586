import React, { useState, useEffect } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import es from 'date-fns/esm/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import ToggleButton from '@material-ui/lab/ToggleButton'
import intl from 'react-intl-universal'
import { calendar } from '../../tango-react-base/resources'
import { CircularLoading } from '../../tango-react-base/components'

const BootstrapInput = withStyles({
  root: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#A8123E',
      Width: '100%',
    },
    '& .MuiTextField-root': {
      color: '#A8123E',
      Width: '100%',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#A8123E',
      Width: '100%',
    },
    '& label.Mui-focused': {
      color: '#A8123E',
      Width: '100%',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#A8123E',
      Width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#A8123E',
        Width: '100%',
      },
      '&:hover fieldset': {
        Width: '100%',
        borderColor: '#A8123E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#A8123E',
        Width: '100%',
      },
    },
  },
})(KeyboardDatePicker)
const today = moment()

type CalendarProps = {
  isDisabled: any
  blockType: any
  fetchingBlocks: any
  blocksTaken: any
  onSubmit: any
}

export const CalendarMobile = ({
  isDisabled,
  blockType,
  fetchingBlocks,
}: CalendarProps) => {
  let threeMonths = moment().add(3, 'month')

  const [selectedDate, setSelectedDate] = useState(today.startOf('week'))

  const [showBlocks, setShowBlocks] = useState(false)

  useEffect(() => {
    if (!showBlocks && selectedDate) {
      setShowBlocks(true)
    }
  }, [selectedDate])

  const classBtnSchedule = (action: any) => {
    return isDisabled(action) && disabledBtn
      ? 'view-btn-schedule-mobile'
      : 'only-view-available'
  }

  const disabledBtn =
    selectedDate.day() !== 0 &&
    threeMonths > selectedDate &&
    selectedDate > today

  const DaysBlock = () =>
    showBlocks ? (
      <div className='calendar-mobile-visit'>
        {blockType.map(({ name, block, action }: any) => {
          return (
            <div className={`${classBtnSchedule(action(selectedDate))}`}>
              <ToggleButton
                value='check'
                disabled={!disabledBtn || !showBlocks}
                className={`h-sm-4 mt-1 mr-2 ${
                  !disabledBtn && 'btn-disabled'
                } `}
              >
                <p className='columns m-0'>
                  <span>{intl.get(name.toUpperCase())}</span>
                  <br />
                  <span>{block.split(' ').join('')}</span>
                </p>
              </ToggleButton>
            </div>
          )
        })}
      </div>
    ) : (
      <></>
    )
  const disableWeekends = (date: any) => {
    return date.getDay() === 0 || threeMonths < date
  }

  const handleDateChange = (date: any) => {
    setSelectedDate(moment(date))
    setShowBlocks(true)
  }

  let now = moment()

  return (
    <>
      {fetchingBlocks === 'FETCHED_BLOCKS' ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Grid container justify='space-around'>
            <BootstrapInput
              margin='normal'
              disableToolbar={true}
              id='date-picker-dialog'
              variant='static'
              format='dd/MM/yyyy'
              value={selectedDate}
              minDate={now}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              shouldDisableDate={disableWeekends}
              keyboardIcon={
                <img
                  className='ico-image-calendar'
                  src={calendar}
                  alt='Calendar'
                />
              }
            />
            <DaysBlock />
          </Grid>
        </MuiPickersUtilsProvider>
      ) : (
        <CircularLoading />
      )}
    </>
  )
}
