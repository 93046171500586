import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getOwnerProfile, updateOwnerProfile } from '../../redux/actions'
import { useHistory, useParams } from 'react-router-dom'
import intl from 'react-intl-universal'
import {
  getCountries,
  setTemplateChild,
  getBankResource,
} from '../../tango-react-base/reduxActions'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import SaveUserDataForms from './save'
import BankAccountDataForm from '../../tango-react-base/components/bankAccountDetail/index'

/**
 * Here it is defined the type of the props for the interface "OwnerDetail"
 * @typedef OwnerDetailTypes
 * @type {(function|string|object)}
 * @property {function} onSetTemplateChild - is an function.
 * @property {function} onGetOwnerData - is an function.
 * @property {function} onGetCountries - is an function.
 * @property {function} onGetBankResource - is an function.
 * @property {object} countries - is an object.
 * @property {object} ownerProfile - is an object.
 * @property {object} bankResource - is an object.
 * @property {string} fetchingCountries - is an string.
 * @property {string} fetchingOwner - is an string.
 */

interface OwnerDetailsProps {
  onSetTemplateChild: (child: Object) => void
  onGetOwnerData: (id: number) => void
  onGetCountries: () => void
  onUpdateOwnerProfile: (id: string, values: object) => void
  onGetBankDetail: () => void
  onGetBankResource: () => void
  countries: any
  fetchingCountries: string
  ownerProfile: any
  fetchingOwner: string
  bankResource: any
}

/**
 * shows the owner's detail
 *@function
 *@param {OwnerDetailsProps}  onSetTemplateChild - Default template
 *@param {OwnerDetailsProps}  onGetOwnerData - function that calls the endpoint owner_data
 *@param {OwnerDetailsProps}  onGetCountries - function that calls the endpoint countries
 *@param {OwnerDetailsProps} onGetBankResource - this function execute onGetBankResource
 *@param {OwnerDetailsProps}  countries - returns an array with countries
 *@param {OwnerDetailsProps}  ownerProfile - return an array with owner's data
 *@param {OwnerDetailsProps}  fetchingCountries - returns an string with the call status
 *@param {OwnerDetailsProps}  fetchingOwner - returns an string with the call status
 *@param {OwnerDetailsProps} bankResource - returns a object with bankResource
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const OwnerDetails = ({
  onSetTemplateChild,
  onGetOwnerData,
  onGetCountries,
  onGetBankResource,
  countries,
  fetchingCountries,
  ownerProfile,
  fetchingOwner,
  onUpdateOwnerProfile,
  bankResource,
}: OwnerDetailsProps) => {
  const { id }: any = useParams()
  const history = useHistory()
  const bankDetailOwner = ownerProfile.attributes?.bank_data.data
  const bankResourceStatus = bankResource.status

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(<h1>{intl.get('FORM')}</h1>)
      onGetOwnerData(id)
      if (fetchingCountries !== 'FETCHED_COUNTRIES') onGetCountries()
      onGetBankResource()
    }
  }, [
    fetchingCountries,
    history,
    id,
    onGetBankResource,
    onGetCountries,
    onGetOwnerData,
    onSetTemplateChild,
  ])

  const fetching = [fetchingOwner, fetchingCountries, bankResourceStatus]
  const fetchedValidation = fetching.every((fetch) => fetch.includes('FETCHED'))

  /**
   * submitOwnerUpdate: This function receives the owner_profile id and the form values as arguments
   * Its main purpose is to send a PUT request so as to update the an individual owner_profile
   * @function
   */
  const submitOwnerUpdate = (id: string, values: object) => {
    onUpdateOwnerProfile(id, values)
  }

  /** renderComponent: This function renders an owner_profile displayed in a form component
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const RenderComponent = () => {
    const { id } = ownerProfile
    const { data_for_contract, ...ownerData } = ownerProfile.attributes
    return (
      <div className='container-home mt-5 position-relative'>
        <SaveUserDataForms
          {...ownerProfile.attributes}
          {...countries}
          owner={ownerData}
          gridForm={10}
          ownerId={id}
          onSubmit={submitOwnerUpdate}
        />
        <div className='template-line' />
        <BankAccountDataForm
          bankResource={bankResource}
          disabled
          classContainer=''
          bankDetailUser={bankDetailOwner}
        />
      </div>
    )
  }

  return <>{fetchedValidation ? <RenderComponent /> : <CircularLoading />}</>
}

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = ({
  ownerProfileReducer,
  contries,
  bankResource,
}: any) => ({
  ...ownerProfileReducer,
  ...contries,
  bankResource,
})

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any, getState: any) => ({
  onGetCountries: () => {
    return dispatch(getCountries())
  },
  onGetOwnerData: (id: number) => {
    return dispatch(getOwnerProfile(id))
  },
  onSetTemplateChild: (child: Object) => {
    return dispatch(setTemplateChild(child, '/'))
  },
  onUpdateOwnerProfile: (id: string, values: Object) => {
    return dispatch(updateOwnerProfile(id, values))
  },
  onGetBankResource: () => {
    return dispatch(getBankResource())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDetails)
