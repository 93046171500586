import { AdvertImageState } from '../../../tango'

const initialState: AdvertImageState = {
  fetchingStatusCreateAdvertImage: 'NO_CREATED',
  fetchingStatusCreateAdvertImages: 'NO_STARTED',
  fetchingStatusCreateAdvertImageError: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    // CREATE ADVERT IMAGE
    case 'CREATE_ADVERT_IMAGE_START':
      return {
        ...state,
        fetchingStatusCreateAdvertImage: 'CREATING',
      }
    case 'CREATE_ADVERT_IMAGE_SUCCESS':
      return {
        ...state,
        fetchingStatusCreateAdvertImage: 'CREATED',
      }
    case 'CREATE_ADVERT_IMAGE_ERROR':
      return {
        ...state,
        fetchingStatusCreateAdvertImage: 'ERROR',
        fetchingStatusCreateAdvertImageError: true,
      }
    // START UPLOAD IMAGES
    case 'CREATE_ADVERT_IMAGES_START':
      return {
        ...state,
        fetchingStatusCreateAdvertImages: 'STARTING',
      }
    // SUCCESS UPLOAD IMAGES
    case 'CREATE_ADVERT_IMAGES_SUCCESS':
      return {
        ...state,
        fetchingStatusCreateAdvertImages: 'SUCCESS',
      }
    // RESET IMAGES
    case 'RESET_DATA':
      return {
        fetchingStatusCreateAdvertImage: 'NO_CREATED',
        fetchingStatusCreateAdvertImages: 'NO_STARTED',
        fetchingStatusCreateAdvertImageError: false,
      }
    default:
      return state
  }
}
