export const renterDocumentsCountStart = () => {
  return {
    type: 'RENTER_DOCUMENTS_COUNT_START'
  }
}

export const renterDocumentsCountSuc = (data: any) => {
  return {
    type: 'RENTER_DOCUMENTS_COUNT_SUC',
    payload: data
  }
}

export const renterDocumentsCountModified = (renterProfileIndex: any, documentType: any, action: any) => {
  let data = {
    renterProfileIndex,
    documentType,
    action
  }
  switch (action) {
    case 1:
      return {
        type: 'RENTER_DOCUMENTS_COUNT_MODIFIED_REJECTED',
        payload: data
      }
    case 2:
      return {
        type: 'RENTER_DOCUMENTS_COUNT_MODIFIED_PENDING',
        payload: data
      }
    default:
      return {
        type: 'RENTER_DOCUMENTS_COUNT_MODIFIED_APPROVED',
        payload: data
      }
  }
}