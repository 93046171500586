import React from 'react';
import { RadioButtonTangoLink } from '../../tango-react-base/components';
/** Here it is defined the type of the props for the interface "DocumentsLinkProps"
 * @typedef DocumentsLinkProps
 * @type {(function|object|string)}
 * @property {number} rentId - is a number.
 * @property {number} unitId - is a number.
 * @property {number} postId - is a number.
 */
interface DocumentsLinkProps {
  unitId?: number;
  rentId?: number;
  postId?: number;
}
/**
 * ButtonsActions is a function that shows the property details
 *@function
 *@param {ButtonsActionsTypes} rentId - number id of the rent
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */

/** this returns part of the form with the button to go to the documents lists */
const DocumentsLink = ({ unitId, rentId, postId }: DocumentsLinkProps) => {
  const option = [{ label: 'SHOW_DETAILS' }];
  return (
    <>
      <RadioButtonTangoLink
        disabled={false}
        id='promotions'
        label='PROMOTIONS'
        options={option}
        className='d-flex justify-content-left pt-0'
        onClick={() => {
          window.location.href =
            window.location.origin + '/posts/' + `${postId}/promotion${rentId ? `?rentId=${rentId}` : ''}`;
        }}
      />
      <RadioButtonTangoLink
        disabled={false}
        id='document'
        label='DOCUMENTS'
        options={option}
        className='d-flex justify-content-left pt-0'
        onClick={() => {
          window.location.href =
            window.location.origin + '/posts/' + `${postId}/documents${rentId ? `?rentId=${rentId}` : ''}`;
        }}
      />
      <RadioButtonTangoLink
        disabled={rentId === 0}
        id='payments'
        label='PAYMENTS'
        options={option}
        className='d-flex justify-content-left pt-0'
        onClick={() => {
          rentId !== 0 &&
            (window.location.href =
              window.location.origin + '/posts/' + `${postId}/payments${rentId ? `?rentId=${rentId}` : ''}`);
        }}
      />
    </>
  );
};
export default DocumentsLink;
