export const getLocationDataStart = () => {
    return {
        type: 'GET_LOCATION_DATA_STARTED',
    };
};

export const getLocationDataSuccess = (data: any) => {
    return {
        type: 'GET_LOCATION_DATA_SUCCESS',
        payload: { data },
    };
};

export const getLocationDataError = (error: any) => {
    return {
        type: 'GET_LOCATION_DATA_ERROR',
        payload: {
            error,
        },
    };
};
