import { getData } from '../../helpers/apiConnections';
import { getChargeLinesStart, getChargeLinesError, getChargeLinesSuc } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getChargeLines = (id: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getChargeLinesStart());
        getData('charge_lines/first_payments?rent_id=' + id)
            .then((result) => {
                dispatch(getChargeLinesSuc(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                dispatch(getChargeLinesError(e));
            });
    };
};
export default getChargeLines;
