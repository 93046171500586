export const getContractDetailStart = () => {
  return {
    type: 'GET_CONTRACT_DETAIL_STARTED',
  }
}

export const getContractDetailSuccess = (result: any) => {
  return {
    type: 'GET_CONTRACT_DETAIL_SUCCESS',
    payload: { result },
  }
}
export const getContractDetailError = (error: any) => {
  return {
    type: 'GET_CONTRACT_DETAIL_ERROR',
    payload: {
      error,
    },
  }
}
