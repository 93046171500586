import {
  approvePropertyDocumentStart,
  approvePropertyDocumentSuccess,
  approvePropertyDocumentError,
} from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { getPropertyDocuments } from '../../../tango-react-base/reduxActions'

const approvePropertyDocument = (id: number, unitId: number) => {
  return (dispatch: any) => {
    dispatch(approvePropertyDocumentStart())
    putData(`unit_documents/${id}/approve`)
      .then((result) => {
        dispatch(getPropertyDocuments(unitId))
        dispatch(approvePropertyDocumentSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(approvePropertyDocumentError(e))
      })
  }
}
export default approvePropertyDocument
