/* istanbul ignore file */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  approveDocument,
  rejectDocument,
  renterDocuments,
  renterDocumentsCount,
  updateDocument,
  updateProfile,
} from '../../redux/actions';
import { approveRenter, expireRenter, rejectRenter, rejectRenterAmount } from '../../redux/actions/renterValidationActions';
import { CircularLoading, DocumentValidation, IncomeDataForm, Input } from '../../tango-react-base/components';
import CustomToolTip from '../../tango-react-base/components/customTooltip';
import DialogModal from '../../tango-react-base/components/dialogModal';
import { RELATIONSHIP_OPTIONS } from '../../tango-react-base/helpers/selectOptions/options';
import { getBankResource, getCountries, getLocationUser, getRentersPending } from '../../tango-react-base/reduxActions';
import { alertInfoIcon, closeTooltip } from '../../tango-react-base/resources';
import AuthorizedAmount from './AuthorizedAmount';


const moment = require('moment');
const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

/**
 * Here it is defined the type of the UserDetails Component
 * @typedef UserDetailsPropsTypes
 * @type {(function|object|string)}
 * @property {object} bankResource - is an object.
 * @property {object} contries - is an object.
 * @property {object} dataContract - is an object.
 * @property {string} fetchBankResource - is a string.
 * @property {string} fetchingConsignee - is a string.
 * @property {string} fetchingCountries - is a string.
 * @property {string} fetchingRenterDocumentsCount - is a string.
 * @property {string} fetchingState - is a string.
 * @property {object} ipLocationData - is an object.
 * @property {function} onApproveDocument - is a function.
 * @property {function} onApproveRenter - is a function.
 * @property {function} onGetBankResource - is a function.
 * @property {function} onGetCountries - is a function.
 * @property {function} onGetIpLocation - is a function.
 * @property {function} onGetRenterPending - is a function.
 * @property {function} onRejectDocument - is a function.
 * @property {function} onRejectRenter - is a function.
 * @property {function} onRejectRenterAmount - is a function.
 * @property {function} onRenterDocumentsCount - is a function.
 * @property {function} onUpdateConsignee - is a function.
 * @property {function} onUpdateDocument - is a function.
 * @property {object} renter - is an object.
 * @property {object} renterDocumentsCount - is an object.
 */

interface UserDetailsProps {
  bankResource: any;
  contries: any;
  dataContract: any;
  fetchBankResource: string;
  fetchingConsignee: string;
  fetchingCountries: string;
  fetchingRenterDocumentsCount: any;
  fetchingRenterDocuments: any;
  fetchingState: string;
  ipLocationData: any;
  onApproveDocument: any;
  onApproveRenter: any;
  onGetBankResource: () => void;
  onGetCountries: () => void;
  onGetIpLocation: any;
  onGetRenterPending: () => void;
  onRejectDocument: any;
  onRejectRenter: any;
  onExpireRenter: any;
  onRejectRenterAmount: any;
  onRenterDocumentsCount: any;
  onRenterDocuments: any;
  onUpdateConsignee: any;
  onUpdateDocument: any;
  renter: any;
  renterDocumentsCount: any;
  renterDocuments: any;
}

/**
 * UserDetails is a functional component
 * @function
 * @param {UserDetailsPropsTypes} bankResource - is an object with banking options available in Tango.
 * @param {UserDetailsPropsTypes} contries - is an object with options of the countries available in Tango.
 * @param {UserDetailsPropsTypes} dataContract - is an object with the data for the contract.
 * @param {UserDetailsPropsTypes} fetchBankResource - fetching status of whether available banks.
 * @param {UserDetailsPropsTypes} fetchingConsignee - fetching status of whether Co-signer's data.
 * @param {UserDetailsPropsTypes} fetchingCountries - fetching status of whether of the available countries.
 * @param {UserDetailsPropsTypes} fetchingRenterDocumentsCount - fetching status of whether of the available Renter documents count.
 * @param {UserDetailsPropsTypes} fetchingState - fetching status of whether validation Renter.
 * @param {UserDetailsPropsTypes} ipLocationData - is a object with nationality types.
 * @param {UserDetailsPropsTypes} onApproveDocument - this function approves a document uploaded by the Renter or Admin.
 * @param {UserDetailsPropsTypes} onApproveRenter - this function makes a Renter available so that he can lease a property.
 * @param {UserDetailsPropsTypes} onGetBankResource - this function gets the bank options available in Tango.
 * @param {UserDetailsPropsTypes} onGetCountries - this function gets the options of the countries available in Tango.
 * @param {UserDetailsPropsTypes} onGetIpLocation - this function gets the nationality types.
 * @param {UserDetailsPropsTypes} onGetRenterPending - this function gets the Renter in pending state.
 * @param {UserDetailsPropsTypes} onRejectDocument - this function rejects a document uploaded by the Renter or Admin.
 * @param {UserDetailsPropsTypes} onRejectRenter - this function rejects a Renter.
 * @param {UserDetailsPropsTypes} onExpireRenter - this function expires a Renter.
 * @param {UserDetailsPropsTypes} onRejectRenterAmount - this function rejects a Renter for a minimum amount.
 * @param {UserDetailsPropsTypes} onRenterDocumentsCount -this function gets the Renter and Co-signer's documents count.
 * @param {UserDetailsPropsTypes} onUpdateConsignee - this function updates data from a Co-signer's.
 * @param {UserDetailsPropsTypes} onUpdateDocument - this function the Admin uploads new Renter document.
 * @param {UserDetailsPropsTypes} renter - is an object with Renter information.
 * @param {UserDetailsPropsTypes} renterDocumentsCount - is an object with un total of all types of documents belonging to the Renter and Co-signer's.
 * @returns {(ReactComponent)} Returns a react component with Renter's validation form in Admin.
 */

const UserDetails = ({
  bankResource,
  contries,
  dataContract,
  fetchBankResource,
  fetchingConsignee,
  fetchingCountries,
  fetchingRenterDocumentsCount,
  fetchingRenterDocuments,
  fetchingState,
  ipLocationData,
  onApproveDocument,
  onApproveRenter,
  onGetBankResource,
  onGetCountries,
  onGetIpLocation,
  onGetRenterPending,
  onRejectDocument,
  onRejectRenter,
  onExpireRenter,
  onRejectRenterAmount,
  onRenterDocumentsCount,
  onRenterDocuments,
  onUpdateConsignee,
  onUpdateDocument,
  renter,
  renterDocumentsCount,
  renterDocuments,
}: UserDetailsProps) => {
  const history = useHistory();
  useEffect(() => {
    // TODO: update the way in which we get the currency in the future
    //onGetIpLocation()
    onGetCountries();
    onGetBankResource();
  }, [onGetCountries, onGetIpLocation, onGetBankResource]);

  const {
    renter_by_unit_registered,
    price_post_by_unit_registered,
    identity_document,
    renter_document_count,
    renter_profiles,
    renter_validations,
    renter_state,
  } = renter.attributes;

  const [renterDocumentsCountData, setRenterDocumentsCountData] = useState(renterDocumentsCount);
  const [renterDocumentsData, setRenterDocumentsData] = useState(renterDocuments);
  const [showUploadDocumentsIndex, setShowUploadDocumentsIndex] = useState([
    {
      truora: '',
      dicom: '',
      proof_of_job_seniority: '',
      salary_settlement: '',
      afp_quotation_dependent: '',
      provitional_monthly_payment: '',
      afp_quotation_independent: '',
      form22: '',
      ci_both_sides: '',
    },
    {
      truora: '',
      dicom: '',
      proof_of_job_seniority: '',
      salary_settlement: '',
      afp_quotation_dependent: '',
      provitional_monthly_payment: '',
      afp_quotation_independent: '',
      form22: '',
      ci_both_sides: '',
    },
    {
      truora: '',
      dicom: '',
      proof_of_job_seniority: '',
      salary_settlement: '',
      afp_quotation_dependent: '',
      provitional_monthly_payment: '',
      afp_quotation_independent: '',
      form22: '',
      ci_both_sides: '',
    },
  ]);

  useEffect(() => {
    onRenterDocumentsCount(renter_document_count);
  }, []);

  useEffect(() => {
    let renterDocumentsAux: Array<any> = [];
    renter_profiles.map((renter: any) => {
      renterDocumentsAux = [...renterDocumentsAux, renter.renter_documents];
    });
    onRenterDocuments(renterDocumentsAux);
  }, []);

  useEffect(() => {
    if (fetchingRenterDocumentsCount === 'LOADED') {
      setRenterDocumentsCountData(renterDocumentsCount);
    }
  }, [renterDocumentsCount]);

  useEffect(() => {
    if (fetchingRenterDocuments === 'LOADED') {
      setRenterDocumentsData(renterDocuments);
    }
  }, [renterDocuments]);

  let disabledApproveRenter = false;
  const [expanded, setExpanded] = useState('panel0');
  const [hiddenIconButtonTop, setHiddenIconButtonTop] = useState(true);
  const [openFirst, setOpenFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openThird, setOpenThird] = useState(false);
  const [comment, setComment] = useState(null);
  const [unauthorizedAmount, setUnauthorizedAmount] = useState(false);

  const handleChangeExpansion = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : '');
  };

  const onSubmit = (id: number) => (values: any) => {
    if (values.acceptedAddress) {
      onUpdateConsignee(values, id);
    } else if (!values.acceptedAddress) {
      toast(intl.get('INVALID_ADDRESS_GENERIC'));
    }
  };

  const sortDocuments = (renterDocuments: any) => {
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < renterDocuments?.length - 1; i++) {
        let dateOne = moment(renterDocuments[i].rejected_at).format(`DD-MM-YYYY HH:mm:ss`);
        let dateTwo = moment(renterDocuments[i + 1].rejected_at).format(`DD-MM-YYYY HH:mm:ss`);
        if (dateOne > dateTwo) {
          let temp = renterDocuments[i];
          renterDocuments[i] = renterDocuments[i + 1];
          renterDocuments[i + 1] = temp;
          swapped = true;
        }
      }
    } while (swapped);
    renterDocuments = pendingSortDocuments(renterDocuments);
    return renterDocuments;
  };

  const pendingSortDocuments = (renterDocuments: any) => {
    let restDocuments: Array<any> = [];
    let pendingDocuments = renterDocuments?.filter((document: any) => {
      if (document.aasm_state === 'pending') {
        return document;
      } else {
        restDocuments = [...restDocuments, document];
      }
    });
    let documentsOrdered = pendingDocuments?.concat(restDocuments);
    return documentsOrdered;
  };

  const renderDocuments = (renter_profile_index: number) => {
    if (renter_profile_index && renterDocumentsData[renter_profile_index]?.length === 0) {
      return <h3>{intl.get('NO_DOCUMENT')}</h3>;
    } else {
      let documentsOrdered = sortDocuments(renterDocumentsData[renter_profile_index]);
      const dicomOrTruora = ['dicom', 'truora'];
      const filteredDocuments = documentsOrdered?.filter((doc: any) => !dicomOrTruora.includes(doc.document_type));
      return filteredDocuments?.map((document: any, index: number) => {
        let new_index = `doc-${document.id}-${document.document_type}-${renter_profile_index}`;
        return (
          <DocumentValidation
            document={document}
            onAprove={onApproveDocument}
            onReject={onRejectDocument}
            onUpdate={onUpdateDocument}
            index={new_index}
            key={index}
            disabled={renter_validations[0].aasm_state !== 'pending' || disabledApproveRenter}
            renterDocumentCount={renterDocumentsCountData[renter_profile_index]}
            renterProfileIndex={renter_profile_index}
            showUploadDocumentsIndex={showUploadDocumentsIndex[renter_profile_index]}
          />
        );
      });
    }
  };
  let filterDicom: Array<Object>;
  let filterTruora: Array<Object>;

  const renters_container = (renter_profiles: any) => {
    if (!renter_profiles.length) return <h3>{intl.get('USER_NOT_CREATED_PROFILE')}</h3>;
    let renters_profiles = renter_profiles.map((renter: any, index: number) => {
      let renter_relationship_other = RELATIONSHIP_OPTIONS.filter((v: any) => v.value === renter.renter_relationship);
      let current_score = renter.current_score === null ? 0 : renter.current_score;
      let average_score_last_12_months =
        renter.average_score_last_12_months === null ? 0 : renter.average_score_last_12_months;
      let last_payment_due = renter.last_payment_due === null ? null : moment(renter.last_payment_due);

      filterDicom = renter.renter_documents.filter((doc: { document_type: string }) => doc.document_type === 'dicom');
      filterTruora = renter.renter_documents.filter((doc: { document_type: string }) => doc.document_type === 'truora');
      return (
        <ExpansionPanel
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChangeExpansion(`panel${index}`)}
        >
          <ExpansionPanelSummary
            expandIcon={<i className='fa fa-arrow-circle-down' />}
            aria-controls={`panel${index}a-content`}
            id={`panelpanel${index}a-header`}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <h4>
              {renter.profile_type === 'signer'
                ? intl.get('RENTER_INFORMATION')
                : `${intl.get('COSIGNER_INFORMATION')} ${index}`}
            </h4>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='renter-validation container-home container-home-100 p-0 m-0'>
              <IncomeDataForm
                bankResource={bankResource}
                renterId={renter.id}
                filterDicom={filterDicom}
                filterTruora={filterTruora}
                renterState={renter_state}
                onSubmit={onSubmit(renter.id)}
                last_payment_amount={renter.last_payment_amount_amount}
                average_income_last_3_months_amount={renter.average_income_last_3_months.cents}
                average_income_last_3_months_currency={
                  renter.average_income_last_3_months.currency_iso
                    ? renter.average_income_last_3_months.currency_iso
                    : ipLocationData.currency
                }
                unpaid_debts_total_amount_amount={renter.unpaid_debts_total_amount.cents}
                unpaid_debts_total_amount_currency={
                  renter.unpaid_debts_total_amount.currency_iso
                    ? renter.unpaid_debts_total_amount.currency_iso
                    : ipLocationData.currency
                }
                tax_assessment_amount={renter.tax_assessment.cents}
                tax_assessment_currency={
                  renter.tax_assessment.currency_iso ? renter.tax_assessment.currency_iso : ipLocationData.currency
                }
                last_payment_amount_amount={renter.last_payment_amount.cents}
                last_payment_amount_currency={
                  renter.last_payment_amount.currency_iso
                    ? renter.last_payment_amount.currency_iso
                    : ipLocationData.currency
                }
                original_renter_relationship={
                  renter_relationship_other.length !== 0 ? renter.renter_relationship : 'OTHER'
                }
                renter_relationship_other={renter.renter_relationship}
                socioeconomic_level={renter.renter_relationship}
                last_payment_due={last_payment_due}
                current_score={current_score}
                average_score_last_12_months={average_score_last_12_months}
                {...renter}
                dataContract={dataContract}
                {...contries}
                keyNumber={index}
                disabled={false}
                identity_document={identity_document}
                renterProfileType={renter_profiles[index].profile_type}
              >
                <div className='mb-5'>
                  <TableContainer className='table'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '30%' }} className='table-header table-border-left'>
                            {intl.get('DOCUMENTS')}
                          </TableCell>
                          <TableCell style={{ width: '30%' }} className='table-header'>
                            {intl.get('ACTIONS')}
                          </TableCell>
                          <TableCell style={{ width: '10%' }} className='table-header'>
                            {intl.get('DATE')}/{intl.get('TIME')}
                          </TableCell>
                          <TableCell style={{ width: '30%' }} className='table-header table-border-right'>
                            {intl.get('STATUS')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {renderDocuments(index)}
                    </Table>
                  </TableContainer>
                </div>
                {fetchingConsignee === 'LOADING' && <div>{<CircularLoading />}</div>}
                {(fetchingConsignee === 'LOADED' || fetchingConsignee === 'ERROR') && (
                  <button className='button-primary col-6 mb-2' type='submit'>
                    {intl.get('SAVE')}
                  </button>
                )}
              </IncomeDataForm>

            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
    return renters_profiles;
  };

  // When called, this function closes the modals
  const handleClose = () => {
    setOpenFirst(false);
    setOpenSecond(false);
    setOpenThird(false);
  };

  const handleRenterApproval = () => {
    onApproveRenter(renter_validations[0].id);
  };

  useEffect(() => {
    if (fetchingState === 'SUCCESS_STATE_ERROR') {
      history.push('/renters');
    }
  }, [fetchingState]);

  window.onscroll = () => {
    let last_known_scroll_position = window.scrollY;
    setHiddenIconButtonTop(last_known_scroll_position < 150);
  };

  const rejectRenterAmmount = (userId: number, comment: any) => {
    onRejectRenterAmount(userId, comment);
    onGetRenterPending();
  };
  const rejectRenter = (userId: number) => {
    onRejectRenter(userId);
    onGetRenterPending();
  };

  const expireRenter = (renterValidationId: number) => {
    onExpireRenter(renterValidationId)
    onGetRenterPending();
  };

  return (
    <>
      <div className='renter-validation pt-1 container-home mt-5'>
        {fetchingCountries === 'FETCHED_COUNTRIES' &&
          fetchBankResource === 'FETCHED' &&
          renters_container(renter_profiles)}
      </div>
      {renter_validations.length > 0 ? (
        <>
          {fetchingCountries === 'FETCHED_COUNTRIES' && fetchBankResource === 'FETCHED' ? (
            <div className='renter-validation container-home'>
              <AuthorizedAmount
                price={renter_by_unit_registered === 'yes' && price_post_by_unit_registered}
                onSubmit={onApproveRenter(renter_validations[0].id)}
                authorized_amount_cents={renter_validations[0].authorized_amount.cents}
                authorized_amount_currency={renter_validations[0].authorized_amount.currency_iso}
                disabled={renter_validations[0].aasm_state !== 'pending' || disabledApproveRenter}
                {...renter_validations[0]}
                setUnauthorizedAmount={setUnauthorizedAmount}
              >
                <DialogModal title={intl.get('CONFIRMATION')} open={openFirst} handleClose={handleClose}>
                  <Typography gutterBottom>
                    {intl.getHTML('ABOUT_TO_VETO')}
                    <br />
                    <button
                      type='button'
                      className='button-primary mt-4 px-4'
                      onClick={() => rejectRenter(renter_validations[0].id)}
                      disabled={renter_validations[0].aasm_state !== 'pending'}
                    >
                      {intl.get('VETO')}
                    </button>
                  </Typography>
                </DialogModal>
                <DialogModal title={intl.get('REJECT_RENTER')} open={openSecond} handleClose={handleClose}>
                  <Typography gutterBottom>
                    {intl.getHTML('REJECT_RENTER_REASON')}
                    <br />

                    <div className='row'>
                      <div className='col-12'>
                        <Input
                          id='comment'
                          type='text'
                          multiline={true}
                          value={comment}
                          className='mt-4'
                          classNameInput='col-md-12 plm-0'
                          justifyCenter='align-items-center'
                          rows={3}
                          onChange={(e: any) => {
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className='row text-center'>
                      <div className='col-sm-6 text-right'>
                        <button
                          type='button'
                          className='button-tertiary mt-4 px-5'
                          onClick={() => {
                            setOpenSecond(false);
                          }}
                        >
                          {intl.get('CANCEL')}
                        </button>
                      </div>
                      <div className='col-sm-6 text-left'>
                        <button
                          type='button'
                          className='button-primary mt-4 px-5'
                          onClick={() => {
                            rejectRenterAmmount(renter_profiles[0].id, comment);
                            history.replace('/renters');
                          }}
                          disabled={!comment}
                        >
                          {intl.get('ACCEPT')}
                        </button>
                      </div>
                    </div>
                  </Typography>
                </DialogModal>
                <DialogModal title={intl.get('CONFIRMATION')} open={openThird} handleClose={handleClose}>
                  <Typography gutterBottom>
                    {intl.getHTML('ABOUT_TO_EXPIRE')}
                    <br />
                    <button
                      type='button'
                      className='button-primary mt-4 px-4'
                      onClick={() => {
                        expireRenter(renter_validations[0].id)
                        history.replace('/renters');
                      }}
                      disabled={renter_validations[0].aasm_state !== 'verified'}
                    >
                      {intl.get('EXPIRE')}
                    </button>
                  </Typography>
                </DialogModal>
                <div className='row d-flex justify-content-center bottom-buttons-rows'>
                  <div className='col text-align-center plm-0'>
                    <button
                      type='button' // this avoids triggering submit, do not remove
                      className='button-tertiary col-10'
                      onClick={() => setOpenSecond(true)}
                      disabled={renter_validations[0].aasm_state !== 'pending'}
                    >
                      {intl.get('REJECT')}
                    </button>
                  </div>
                  <div className='col text-align-center plm-0'>
                    <button
                      type='submit'
                      className='button-primary col-10'
                      onClick={handleRenterApproval}
                      disabled={
                        renter_validations[0].aasm_state !== 'pending' ||
                        disabledApproveRenter ||
                        fetchingState === 'WAIT_STATE_ERROR' ||
                        unauthorizedAmount
                      }
                    >
                      {intl.get('ENABLE_RENTER')}
                    </button>
                  </div>
                </div>
                <div className='row d-flex justify-content-center mt-3 bottom-buttons-rows'>
                  <div className='col text-align-center plm-0'>
                    <button
                      type='button' // this avoids triggering submit, do not remove
                      className='button-tertiary col-10'
                      onClick={() => setOpenFirst(true)}
                      disabled={renter_validations[0].aasm_state !== 'pending'}
                    >
                      {intl.get('VETO')}
                    </button>
                  </div>
                  <div className='col text-align-center plm-0'>
                    <button
                      type='button' // this avoids triggering submit, do not remove
                      className='button-sixth col-10'
                      onClick={() => setOpenThird(true)}
                      disabled={renter_validations[0].aasm_state !== 'verified'}
                    >
                      {intl.get('EXPIRE')}
                    </button>
                    <CustomToolTip
                      icon={alertInfoIcon}
                      iconClose={closeTooltip}
                      classImage='m-1 icon-alert-info-tooltip'
                      bodyText={intl.getHTML('EXPIRE_METHOD_INFO')}
                      popOver
                    />
                  </div>
                </div>
              </AuthorizedAmount>
            </div>
          ) : (
            <CircularLoading />
          )}
        </>
      ) : (
        <h3>{intl.get('NOT_VALIDATED')}</h3>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  renter: state.userValidation.user,
  fetchingState: state.publish.fetchingState,
  fetchingConsignee: state.consignee.fetchingConsignee,
  renterDocumentsCount: state.publish.renterDocumentsCount,
  renterDocuments: state.publish.renterDocuments,
  fetchingRenterDocumentsCount: state.publish.fetchingRenterDocumentsCount,
  fetchingRenterDocuments: state.publish.fetchingRenterDocuments,
  ipLocationData: state.ipLocation.ipLocationData,
  fetchLocationData: state.ipLocation.fetchingLocation,
  contries: state?.contries?.countries,
  fetchingCountries: state?.contries?.fetchingCountries,
  bankResource: state.bankResource.banks,
  fetchBankResource: state.bankResource.status,
  dataContract: state.userValidation.user.attributes.data_for_contract,
});

const mapDispatchToProps = (dispatch: any, getState: any) => {
  return {
    onApproveDocument: (id_doc: number, index: string, renterProfileIndex: any, documentType: any) => {
      return dispatch(approveDocument(id_doc, index, 'renter_documents', renterProfileIndex, documentType));
    },
    onRejectDocument: (id_doc: number, index: string, renterProfileIndex: any, documentType: any) => {
      return dispatch(rejectDocument(id_doc, index, 'renter_documents', renterProfileIndex, documentType));
    },
    onUpdateDocument: (id: number, index: string, data: any, renterProfileIndex: any, documentType: any) => {
      return dispatch(updateDocument(id, index, data, renterProfileIndex, documentType));
    },
    onApproveRenter: (id_renter: number) => (value: any) => {
      return dispatch(approveRenter(id_renter, value));
    },
    onRejectRenter: (id_renter: number) => {
      return dispatch(rejectRenter(id_renter));
    },
    onExpireRenter: (id_renter_validation: number) => {
      return dispatch(expireRenter(id_renter_validation))
    },
    onRejectRenterAmount: (id_renter: number, comment: string) => {
      return dispatch(rejectRenterAmount(id_renter, comment));
    },
    onUpdateConsignee: (data: any, id_renter: string) => {
      return dispatch(updateProfile(data, id_renter));
    },
    onGetIpLocation: () => {
      return dispatch(getLocationUser());
    },
    onGetCountries: () => {
      return dispatch(getCountries());
    },
    onGetBankResource: () => {
      return dispatch(getBankResource());
    },
    onGetRenterPending: () => {
      dispatch(getRentersPending());
    },
    onRenterDocumentsCount: (data: any) => {
      return dispatch(renterDocumentsCount(data));
    },
    onRenterDocuments: (data: any) => {
      return dispatch(renterDocuments(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
