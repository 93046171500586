import React from 'react'
import { AccordionItem } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { formatDates } from '../../tango-react-base/helpers/date'
import { useCurrencyConvertor } from '../../tango-react-base/hooks'
import { formatNumber } from '../../tango-react-base/helpers/numberFormat'
/** Here the types of the props for the interface ResponseHistoryProps' are defined
 * @typedef ResponseHistoryTypes
 * @type {(object})}
 * @property {object} owner - is a object.
 * @property {object} renter - is a object.
 * @property {object} response - is a object.
 * @property {object} ownerNames - is a object.
 * @property {object} renterNames - is a object.
 * @property {object} history - is a object
 * */

interface ResponseHistoryProps {
  owner: {
    inflation: string
    price: number
    response: boolean
    currency: string
    date: Date
  }
  renter: {
    inflation: string
    price: number
    response: boolean
    currency: string
    date: Date
  }
  ownerNames: { full_name: string }
  renterNames: { full_name: string }

  response: { renter: boolean; owner: boolean }
  history: Array<any>
}

const ResponseHistory = ({
  owner,
  renter,
  response,
  ownerNames,
  renterNames,
  history,
}: ResponseHistoryProps) => {
  const [, , , currentUF] = useCurrencyConvertor({
    currencyIsoValue: '',
    value: 0,
  })

  return (
    <div>
      <AccordionItem
        className='font-weight-bold'
        title={intl.get('PROPOSALS').toUpperCase()}
      >
        {response && (
          <form>
            <p>
              {!response.owner &&
                intl.get('WAIT_FOR_OTHER_PART', {
                  other_part: intl.get('OWNER'),
                })}
            </p>
            <p>
              {!response.renter &&
                intl.get('WAIT_FOR_OTHER_PART', {
                  other_part: intl.get('RENTER'),
                })}
            </p>
            <p>
              {owner?.response &&
                owner.currency === 'CLP' &&
                intl.getHTML('OWNER_PROPOSE', {
                  price: owner.price,
                  inflation: owner.inflation,
                })}

              {owner?.response &&
                owner.currency === 'CLF' &&
                intl.getHTML('OWNER_PROPOSE_UF', {
                  owner: ownerNames.full_name,
                  price: owner.price,
                  reference: `$ ${formatNumber(
                    owner.price * Number(currentUF),
                  )}`,
                  date: formatDates({ date: owner.date }),
                  hour: formatDates({ date: owner.date, formatTime: 'short' }),
                })}
            </p>
            <p>
              {renter?.response &&
                renter.currency === 'CLP' &&
                intl.getHTML('RENTER_PROPOSE', {
                  price: renter.price,
                  inflation: renter.inflation,
                })}
              {renter?.response &&
                renter.currency === 'CLF' &&
                intl.getHTML('RENTER_PROPOSE_UF', {
                  renter: renterNames.full_name,
                  price: renter.price,
                  reference: `$ ${formatNumber(
                    renter.price * Number(currentUF),
                  )}`,
                  date: formatDates({ date: renter.date }),
                  hour: formatDates({ date: renter.date, formatTime: 'short' }),
                })}
            </p>
          </form>
        )}
      </AccordionItem>

      <AccordionItem
        className='font-weight-bold'
        title={intl.get('MEDIATION_HISTORY').toUpperCase()}
      >
        {history && (
          <ul id='mediation-history-ul'>
            {history.map((item: any) => {
              return (
                <li>
                  {item.currency === 'CLP' &&
                    intl.getHTML('ADMIN_PROPOSE', {
                      admin: item.admin,
                      price: item.price,
                      date: formatDates({ date: item.date }),
                      hour: formatDates({
                        date: item.date,
                        formatTime: 'short',
                      }),
                    })}

                  {item.currency === 'CLF' &&
                    intl.getHTML('ADMIN_PROPOSE_UF', {
                      admin: item.admin,
                      price: item.price,
                      reference: `$ ${formatNumber(
                        parseInt(`${parseFloat(item.price.split(' ')[1]) * Number(currentUF)}`),
                      )}`,
                      date: formatDates({ date: item.date }),
                      hour: formatDates({
                        date: item.date,
                        formatTime: 'short',
                      }),
                    })}
                </li>
              )
            })}
          </ul>
        )}
      </AccordionItem>
    </div>
  )
}

export default ResponseHistory
