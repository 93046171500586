import React, { useEffect } from 'react'
import { withFormik, FormikProps, Form } from 'formik'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import ValidationFormInterface from '../../tango-react-base/model_interfaces/ValidationFormInterface'
import {
  BasicData,
  CommonSpaces,
  Equipment,
  Terms,
  UnitDetails,
} from '../../tango-react-base/components/postForm/sections'
import { AccordionItem, PostProperty } from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { MyFormProps } from '../../tango-react-base/model_interfaces/UnitVersionValidationClasses'
import { getCountriesCodes, getCLF } from '../../tango-react-base/reduxActions'
import { validateEstimatePriceAmount } from '../../tango-react-base/helpers/validateEstimatePriceAmount'

const InnerForm: React.FunctionComponent<
  MyFormProps & FormikProps<ValidationFormInterface>
> = (props) => {
  const {
    children,
    clf,
    disabled,
    onGetCLF,
    onGetCountriesCodes,
    setFullFieldsBasic,
    setFullFieldsCommonSpaces,
    setFullFieldsEquipment,
    setFullFieldsTerms,
    setFullFieldsUnitDetail,
    status,
    toRepublish,
    unitVersion,
    ...sectionProps
  } = props
  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]
  const isNewUnit = currentLocation === 'new-unit'

  useEffect(() => {
    onGetCountriesCodes()
  }, [])

  useEffect(() => {
    if (status === 'NO_FETCH') {
      onGetCLF()
    }
  }, [status])

  return (
    <div>
      <Form id='form' className='phone-form'>
        <AccordionItem expanded={true} title={intl.get('TERMS')}>
          <Terms
            isNewUnit={isNewUnit}
            {...sectionProps}
            disabled={!!disabled}
            toRepublish={true}
            setFullFieldsTerms={setFullFieldsTerms}
            clf={clf}
          />
        </AccordionItem>
        <div>{children.props.children[0]}</div>
        <div>{children.props.children[1]}</div>
        <AccordionItem title={intl.get('PROPERTY_DATA')}>
          <PostProperty
            {...sectionProps}
            toRepublish={true}
            disabled={disabled}
          />
        </AccordionItem>
        <AccordionItem title={intl.get('BASIC_DATA_A')}>
          <BasicData
            {...sectionProps}
            disabled={!!disabled}
            isNewUnit={isNewUnit}
            toRepublish={true}
            setFullFieldsBasic={setFullFieldsBasic}
          />
        </AccordionItem>
        <AccordionItem title={intl.get('UNIT_DETAIL')}>
          <UnitDetails
            {...sectionProps}
            disabled={!!disabled}
            toRepublish={true}
            setFullFieldsUnitDetail={setFullFieldsUnitDetail}
          />
        </AccordionItem>
        <AccordionItem title={intl.get('EQUIPMENT')}>
          <Equipment
            {...sectionProps}
            disabled={!!disabled}
            toRepublish={true}
            setFullFieldsEquipment={setFullFieldsEquipment}
          />
        </AccordionItem>
        <AccordionItem title={intl.get('OTHERS')}>
          <CommonSpaces
            {...sectionProps}
            disabled={!!disabled}
            toRepublish={true}
            setFullFieldsCommonSpaces={setFullFieldsCommonSpaces}
          />
        </AccordionItem>
        <div>{children.props.children[2]}</div>
        <div>{children.props.children[3]}</div>
      </Form>
    </div>
  )
}

const RepublishForm = withFormik<MyFormProps, ValidationFormInterface>({
  mapPropsToValues: (props: any) => {
    const recentRemodel =
      props.unitVersion !== undefined &&
      props.unitVersion.attributes.recent_remodel
    const recentRemodelComment =
      recentRemodel &&
        props.unitVersion.attributes.recent_remodel_comment !== null
        ? props.unitVersion.attributes.recent_remodel_comment
        : ''

    if (props.unitVersion !== undefined) {
      const {
        attributes: {
          current_price,
          unitId,
          common_expenses_amount,
          street_address,
          full_address,
          ...unitProps
        },
      } = props.unitVersion

      unitProps.recent_remodel_comment = recentRemodelComment

      const initialProps = {
        ...unitProps,
        full_address: full_address ? full_address : street_address,
        common_expenses_amount: common_expenses_amount,
        current_price: current_price,
      }

      return { ...initialProps }
    }
  },
  handleSubmit: (values: ValidationFormInterface, { props }) => {
    if (
      values.janitor_phone_number &&
      values.janitor_phone_number.toString().indexOf(values.country_code) ===
      -1 &&
      values.country_code
    ) {
      values.janitor_phone_number = `${values.country_code}${values.janitor_phone_number}`
    }
    if (values.parking_spaces === 0) {
      values.parking_space_number = ''
    }
    if (values.estimate_price_amount && values.estimate_price_currency) {
      const estimatePriceAmount = values.estimate_price_amount
      const estimatePriceCurrency = values.estimate_price_currency
      if(validateEstimatePriceAmount(props.clf, estimatePriceAmount, estimatePriceCurrency)) props.onSubmit(values.id, values)
    }
  },
})(InnerForm)
const mapStateToProps = (state: any) => {
  const { status, clf } = state.CLFResource
  return {
    countriesCodes: state.countriesCodes.codes.data,
    fetchingCodes: state.countriesCodes.fetchingCodes,
    status,
    clf
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetCountriesCodes: () => {
      return dispatch(getCountriesCodes())
    },
    onGetCLF: () => {
      return dispatch(getCLF())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepublishForm)
