import {
  createAdvertStart,
  createAdvertSuccess,
  createAdvertError,
} from './types'
import intl from 'react-intl-universal'
import { postData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const createAdvert = (data: Object) => {
  return (dispatch: any, getState: any) => {
    dispatch(createAdvertStart())
    postData('adverts', data)
      .then((result) => {
        toast(intl.get('CREATED_ADVERT'))
        return dispatch(createAdvertSuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(createAdvertError(e))
      })
  }
}
export default createAdvert
