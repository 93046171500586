const initialState = {
  
  fetchingSendPreApproved: 'NO_FETCH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'SEND_PRE_APPROVED_START':
      return {
        ...state,
        fetchingSendPreApproved: 'FETCHING',
      }
    case 'SEND_PRE_APPROVED_SUCCESS':
      return {
        ...state,
        fetchingSendPreApproved: 'FETCHED',

      }
    case 'SEND_PRE_APPROVED_ERROR':
      return {
        ...state,
        fetchingSendPreApproved: 'FETCH_ERROR',
      }
    default:
      return state
  }
}
