import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PopUpInputReject from '../../components/PopUpInputReject'
import intl from 'react-intl-universal'
import { Renter } from '../../tango'
import { titlesHeader, titlesHeaderPreApproved } from './createTable'
import { clearApproveRenter } from '../../redux/actions/renterValidationActions/approveRenter/types';
import {
  ButtonRedirect,
  HandleFetch,
  TableTango,
} from '../../tango-react-base/components'
import {
  getRentersPending,
  getRentersValidate,
  setTemplateChild,
  getRenterPreApproved,
} from '../../tango-react-base/reduxActions'

type RenterIndexProps = {
  fetchingRentersPending: string
  fetchingRentersValidate: string
  fetchingDataPreApproved: string
  fetchingSendPreApproved: string
  rentersPending: Array<Renter>
  rentersValidate: Array<Renter>
  rentersPreApproved: Array<Renter>
  onGetRenterPending: () => void
  onGetRenterValidate: () => void
  onSetTemplateChild: (child: Object) => void
  onClearApproveRenter: () => void
  onGetRenterPreApproved: () => void
}



/**
 * Here the type of each Table is defined, this prop is similar to 'TableTangoProps' but 'TableTangoTypes' is for the documentation
 *  @typedef  RenterIndexTypes
 *  @type {(function | string | array)}
 *  @property {string} fetchingRentersPending - is an string.
 *  @property {string} fetchingRentersValidate - is an string.
 * @property {string} fetchingDataPreApproved - is an string.
 *  @property {array} rentersPending, - is an Array.
 *  @property {array} rentersValidate, - is an Array.
 *  @property {array} rentersPreApproved, - is an Array.
 *  @property {function} onGetRenterPending - is an function.
 *  @property {function} onGetRenterValidate - is an function.
 *  @property {function} onSetTemplateChild - is an function.
 *  @property {function} onClearApproveRenter - is an function.
 * @property {function} onGetRenterPreApproved - is an function.
 */

/**
 * TableTango is a functional component with props.
 * The main function of this component is to render the tables in general.
 *  @function
 *  @param {RenterIndexTypes}  fetchingRentersPending - It is an string 
that validates the promise
 *  @param {RenterIndexTypes} fetchingRentersValidate - It is an string 
that validates the promise
  * @property {string} fetchingDataPreApproved, - is an string.
 *  @param {RenterIndexTypes} rentersPending - It is an array with pending validation renters
 *  @param {RenterIndexTypes} rentersValidate - It is an array with validated renters
 * @param {RenterIndexTypes} renterPreApproved - It is an array with pre approved renters
 *  @param {RenterIndexTypes} onGetRenterPending - function that makes the call RenterPending
 *  @param {RenterIndexTypes} onGetRenterValidate - function that makes the call RenterValidate
 *  @param {RenterIndexTypes} onSetTemplateChild - function onSetTemplateChild
 *  @param {RenterIndexTypes} onClearApproveRenter - function clear approve renter
 * @param {RenterIndexTypes} onGetRenterPreApproved - function get renter pre approved
 *  @returns {(ReactComponent)} Returns a TableMui
 */
const RenterIndex = ({
  rentersPending,
  rentersValidate,
  rentersPreApproved,
  fetchingRentersPending,
  fetchingDataPreApproved,
  fetchingSendPreApproved,
  fetchingRentersValidate,
  onSetTemplateChild,
  onGetRenterPending,
  onGetRenterValidate,
  onClearApproveRenter,
  onGetRenterPreApproved,
}: RenterIndexProps) => {
  const history = useHistory()
  const [statePreApproved, setStatePreApproved] = useState({
    name: '',
    userId: '',
  })

  const showPopup =
    statePreApproved.name === 'accept' || statePreApproved.name === 'reject'
      ? true
      : false

  useEffect(() => {
    if (fetchingSendPreApproved === 'FETCHED') {
      onGetRenterPreApproved()
    }
  }, [fetchingSendPreApproved])

  useEffect(() => {
    if (fetchingSendPreApproved === 'FETCHED') {
      onGetRenterPreApproved()
    }
  }, [fetchingSendPreApproved])

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('RENTERS')}</h1>
        </>,
      )
      onGetRenterPending()
      onGetRenterValidate()
      onClearApproveRenter()
      onGetRenterPreApproved()
    }
  }, [history, onGetRenterPending, onGetRenterValidate, onSetTemplateChild])

  const RenderContent = () => {
    const tableRowPending = rentersPending?.map(
      ({ id, attributes: { names, surnames, phone_number, email } = {} }) => [
        `${names?.split(' ')[0]} ${surnames}`,
        <a href={`tel:${phone_number}`}>{phone_number}</a>,
        email,
        <ButtonRedirect route={`/renters/${id}`} label={'DETAILS'} />,
      ],
    )
    const tableRowValidates = rentersValidate?.map(
      ({ id, attributes: { names, surnames, phone_number, email } = {} }) => [
        `${names?.split(' ')[0]} ${surnames}`,
        <a href={`tel:${phone_number}`}>{phone_number}</a>,
        email,
        <ButtonRedirect route={`/renters/${id}`} label={'DETAILS'} />,
      ],
    )
    const tablePreApproved = rentersPreApproved?.map(
      ({ id, attributes: { renter_data } = {} }) => [
        `${renter_data?.names}`,
        renter_data?.rut,
        renter_data?.email,
        <a href={`tel:${renter_data?.phone_number}`}>
          {renter_data?.phone_number}
        </a>,
        <div className='d-flex justify-content-between'>
          <button
            className='button-secondary col-6 mx-1'
            onClick={() => setStatePreApproved({ name: 'accept', userId: id })}
          >
            {intl.get('APPROVE')}{' '}
          </button>
          <button
            className='button-fourth col-6 mr-2'
            onClick={() => setStatePreApproved({ name: 'reject', userId: id })}
          >
            {' '}
            {intl.get('REJECT')}
          </button>
        </div>,
      ],
    )

    return (
      <>
        {!!rentersPreApproved.length && (
          <>
            <h2 className='text text-color-secondary my-4'>
              {intl.get('RENTER_WAIT_PRE_APPROVED')}
            </h2>
            <div className='row'>
              <TableTango
                verticalCentered
                listTitle={titlesHeaderPreApproved}
                listBody={tablePreApproved}
                elevation={0}
                paginator={false}
              />
            </div>
          </>
        )}
        {!!rentersPending.length && (
          <>
            <h2 className='text text-color-secondary my-4'>
              {intl.get('PENDING_RENTERS')}
            </h2>
            <div className='row '>
              <TableTango
                verticalCentered
                listTitle={titlesHeader}
                listBody={tableRowPending}
                elevation={0}
                paginator={false}
              />
            </div>
          </>
        )}
        {!!rentersValidate.length && (
          <>
            <h2 className='text text-color-secondary my-4'>
              {intl.get('QUALIFIED_RENTERS')}
            </h2>
            <div className='row'>
              <TableTango
                verticalCentered
                listTitle={titlesHeader}
                listBody={tableRowValidates}
                elevation={0}
                paginator={false}
              />
            </div>
          </>
        )}
      </>
    )
  }

  const noRenters =
    !rentersPending.length &&
    !rentersValidate.length &&
    !rentersPreApproved.length
  const fetchingRenters = [
    fetchingRentersPending,
    fetchingRentersValidate,
    fetchingDataPreApproved,
  ]
  return (
    <div className='container-home mt-5 px-2'>
      <div className='template-line' />
      <h2 className='new-subtitle'>{intl.get('ALL_RENTERS')}</h2>
      <PopUpInputReject
        disabled={false}
        showPopUp={showPopup}
        statePreApproved={statePreApproved.name}
        userId={statePreApproved.userId}
        comment=''
        handleClosePopUp={() => setStatePreApproved({ name: '', userId: '' })}
      />
      <HandleFetch fetching={fetchingRenters}>
        <RenderContent />
      </HandleFetch>
      {fetchingRenters.includes('LOADED') &&
        noRenters &&
        intl.get('NO_RENTERS')}
    </div>
  )
}
/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is a Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */
const mapStateToProps = (state: any) => ({
  ...state.renters,
  ...state.renterPreApproved,
  ...state.sendRenterEvaluation,
})

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetRenterPending: () => {
      dispatch(getRentersPending())
    },
    onGetRenterValidate: () => {
      dispatch(getRentersValidate())
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onClearApproveRenter: () => {
      dispatch(clearApproveRenter())
    },
    onGetRenterPreApproved: () => {
      dispatch(getRenterPreApproved())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenterIndex)
