import React, { useEffect, useState } from 'react'
import { ButtonsHeader } from '../../tango-react-base/components'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { redirect_tabs } from './buttonOptions'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import getPaidOrDueRenter from '../../tango-react-base/reduxActions/getPaidOrDueRenter'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import CircularLoading from '../../tango-react-base/components/circularLoading'

type RenterProps = {
  onSetTemplateChild: (child: Object) => void
  onGetPaidOrDueRenter: (state: string) => void
  posts: any
  fetchingData: string
  statusNotification: string
}

const RentersPayment = ({
  onSetTemplateChild,
  onGetPaidOrDueRenter,
  posts,
  fetchingData,
  statusNotification,
}: RenterProps) => {
  const [buttonSelected, setButtonSelected] = useState(true)
  const [showTable, setShowTable] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PAYMENTS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'renters'}
          />
        </>,
      )
    }
  }, [onSetTemplateChild])

  useEffect(() => {
    setShowTable(statusNotification === 'FETCHED')
  }, [statusNotification === 'FETCHED'])

  useEffect(() => {
    onGetPaidOrDueRenter(buttonSelected ? 'paid' : 'due')
  }, [buttonSelected])

  const renderDue = () => {
    return posts.length > 0 ? (
      posts &&
        posts.map((data: any, i: number) => {
          const attributes = data.attributes
          const postId = attributes.post.id
          const renterId = attributes.renter.id
          return (
            <TableRow key={i}>
              <TableCell className='table-content'>
                {attributes.days_due}
              </TableCell>
              <TableCell className='table-content'>
                {moment(attributes.due_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className='table-content'>
                <span
                  className='select-span'
                  onClick={() => history.push(`../renters/${renterId}`)}
                >
                  {attributes.renter.name}
                </span>
              </TableCell>
              <TableCell className='table-content'>
                {attributes.owner.name}
              </TableCell>
              <TableCell className='table-content'>
                <span
                  className='select-span'
                  onClick={() => history.push(`../posts/${postId}`)}
                >
                  {attributes.post.unit_title}
                </span>
              </TableCell>
            </TableRow>
          )
        })
    ) : (
      <TableRow>
        <TableCell colSpan={4} className='table-content text-center'>
          {intl.get('NO_DUE_RENTER')}
        </TableCell>
      </TableRow>
    )
  }

  const renderPaid = () => {
    return posts.length > 0 ? (
      posts &&
        posts.map((data: any, i: number) => {
          const attributes = data.attributes
          const postId = attributes.post.id
          const renterId = attributes.renter.id
          return (
            <TableRow key={i}>
              <TableCell className='table-content'>
                <span
                  className='select-span'
                  onClick={() => history.push(`../renters/${renterId}`)}
                >
                  {attributes.renter.name}
                </span>
              </TableCell>
              <TableCell className='table-content'>
                {moment(attributes.due_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell className='table-content'>
                {attributes.owner.name}
              </TableCell>
              <TableCell className='table-content'>
                <span
                  className='select-span'
                  onClick={() => history.push(`../posts/${postId}`)}
                >
                  {attributes.post.unit_title}
                </span>
              </TableCell>
            </TableRow>
          )
        })
    ) : (
      <TableRow>
        <TableCell colSpan={4} className='table-content text-center'>
          {intl.get('NO_PAID_RENTER')}
        </TableCell>
      </TableRow>
    )
  }

  const validationTable = (type: string) =>
    type === 'paid' ? (
      <>
        <TableCell className='table-header table-border-left'>
          {intl.get('RENTER')}
        </TableCell>
        <TableCell className='table-header'>
          {intl.get('PAYMENET_DUE')}
        </TableCell>
        <TableCell className='table-header'>{intl.get('OWNER')}</TableCell>
        <TableCell
          className={`table-header ${buttonSelected && 'table-border-right'}`}
        >
          {intl.get('PROPERTY')}
        </TableCell>
      </>
    ) : (
      <>
        <TableCell className='table-header table-border-left'>
          {intl.get('DAYS_LATE')}
        </TableCell>
        <TableCell className='table-header'>
          {intl.get('PAYMENET_DUE')}
        </TableCell>
        <TableCell className='table-header'>{intl.get('RENTER')}</TableCell>
        <TableCell className='table-header'>{intl.get('OWNER')}</TableCell>
        <TableCell className='table-header table-border-right'>
          {intl.get('PROPERTY')}
        </TableCell>
      </>
    )

  const renderRenterInformation = (type: string) => {
    return (
      <>
        <TableBody>{type === 'paid' ? renderPaid() : renderDue()}</TableBody>
      </>
    )
  }

  const RenderTable = ({ ...props }) => {
    const { type } = props
    return (
      <div className='mt-5'>
        <TableContainer className='table'>
          <Table>
            <TableHead>
              <TableRow>{validationTable(type)}</TableRow>
            </TableHead>
            {renderRenterInformation(type)}
          </Table>
        </TableContainer>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className='row my-5'>
          <button
            disabled={fetchingData === 'LOADED' ? false : true}
            className={`w-10 ${
              buttonSelected
                ? 'button-admin-tab '
                : 'button-admin-tab-disabled button-primary-disabled'
            } mr-4 big-button`}
            onClick={() => setButtonSelected(true)}
          >
            {intl.get('RENTER_PAID')}
          </button>

          <button
            disabled={fetchingData === 'LOADED' ? false : true}
            className={`w-10 ${
              !buttonSelected
                ? 'button-admin-tab w-10'
                : 'button-admin-tab-disabled button-primary-disabled'
            }`}
            onClick={() => setButtonSelected(false)}
          >
            {intl.get('RENTER_DUE')}
          </button>
        </div>

        <div className='template-line-title' />

        <h2 className='text my-2'>
          {buttonSelected
            ? intl.get('RENTER_PAID_TITLE')
            : intl.get('RENTER_DUE_TITLE')}
        </h2>
        {fetchingData === 'LOADED' && showTable ? (
          <RenderTable type={buttonSelected ? 'paid' : 'due'} />
        ) : (
          <CircularLoading />
        )}
      </>
    )
  }

  return <div className='container-home'>{renderContent()}</div>
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetPaidOrDueRenter: (state: string) => {
      dispatch(getPaidOrDueRenter(state))
    },
  }
}
const mapStateToProps = (state: any) => {
  const { posts, fetchingData } = state.paidOrDueReducer
  const { fetch: statusNotification } = state.notification
  return {
    posts,
    fetchingData,
    statusNotification,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RentersPayment)
