import { getData } from '../../helpers/apiConnections';
import { getPendingVouchersStart, getPendingVouchersSuccess, getPendingVouchersError } from './types';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getPendingVouchers = (document_state: string, doc_type: string) => {
    return (dispatch: any) => {
        dispatch(getPendingVouchersStart());
        getData(`lease_documents/voucher_index?document_state=${document_state}&doc_type=${doc_type}`)
            .then((result) => {
                dispatch(getPendingVouchersSuccess(result.data, doc_type));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPendingVouchersError(e));
            });
    };
};
export default getPendingVouchers;
