import { getAdvertStart, getAdvertSuccess, getAdvertError } from './types'
import { getData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const getAdvert = (advertId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(getAdvertStart())
    getData(`adverts/${advertId}`)
      .then((result) => {
        return dispatch(getAdvertSuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getAdvertError(e))
      })
  }
}
export default getAdvert
