import React, { useEffect, useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { eyeIcon } from '../resources';
import { Tooltip } from '@material-ui/core';
import getDocumentFile from '../reduxActions/getDocumentFile';

interface DocumentsTableProps {
    title: string;
    listHeader: Array<any>;
    listBody?: Array<any> | undefined;
    actionColumn?: boolean;
    viewColumn?: boolean;
    documentUrlAttr: string;
    stateToActionDisplay?: string;
    buttonsTitles: Array<any>;
    columnData?: string;
    onGetDocumentFile?: any;
}
const DocumentsTable = ({
    title,
    listHeader,
    listBody,
    actionColumn = true,
    viewColumn = true,
    documentUrlAttr,
    buttonsTitles,
    stateToActionDisplay,
    columnData = 'attributes',
    onGetDocumentFile,
}: DocumentsTableProps) => {
    const useStyles = makeStyles({
        container: {
            maxHeight: 550,
            minHeight: 200,
        },
        cell: {
            padding: '4px',
            width: '40px',
        },
    });

    const classes = useStyles();
    const [indexButtons, setIndexButtons] = useState<Array<number>>([]);
    const hiddenAttributes = ['uploader_kind', 'contract_id'];

    const getDocument = (item: any) => {
        if (item.attributes.doc_type === 'lease_contract') {
            onGetDocumentFile(
                item.id,
                `${process.env.REACT_APP_API_URL_BASE}/document/lease_contracts/${item.attributes.file_url
                    .split('/')
                    .pop()}`,
            );
        } else if (item.attributes.doc_type === 'admin_contract') {
            onGetDocumentFile(
                item.id,
                `${process.env.REACT_APP_API_URL_BASE}/document/admin_contracts/${item.attributes.file_url
                    .split('/')
                    .pop()}`,
            );
        } else {
            window.open(item.attributes.file_url, '_blank');
        }
    };

    return (
        <>
            <div className='text-left my-2'>
                <span className='span-text'> {intl.get(title)}</span>
            </div>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {listHeader.map((title: any, index: number) => (
                                <Fragment key={index}>
                                    <TableCell
                                        className={`table-header table-header-padding ${
                                            index === 0
                                                ? 'table-border-top-left'
                                                : !actionColumn && index === listHeader.length - 1
                                                ? 'table-border-top-right'
                                                : ''
                                        }`}
                                    >
                                        {title.name ? intl.get(title.name) : ''}
                                    </TableCell>

                                    {viewColumn && index === 2 && (
                                        <TableCell
                                            className={'table-header table-header-padding'}
                                            key={index}
                                        ></TableCell>
                                    )}
                                </Fragment>
                            ))}

                            {actionColumn && (
                                <>
                                    {buttonsTitles.map((value: any, indexButton: number) => (
                                        <TableCell
                                            className={`table-header table-header-padding  ${
                                                indexButton === buttonsTitles.length - 1 ? 'table-border-top-right' : ''
                                            }`}
                                            key={indexButton}
                                        ></TableCell>
                                    ))}
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listBody &&
                            (!listBody.length ? (
                                <TableRow>
                                    <TableCell className='table-content text-center' colSpan={8}>
                                        {intl.get('NO_DATA')}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                listBody?.map((document: any, index: number) => (
                                    <TableRow key={index}>
                                        {Object.entries(document?.[columnData]).map(
                                            ([key, value]: any, docIndex: number) => {
                                                if (!hiddenAttributes.includes(key))
                                                    return (
                                                        <Fragment key={docIndex}>
                                                            {key !== documentUrlAttr && (
                                                                <TableCell
                                                                    className={`${
                                                                        !value ? 'text-center' : ''
                                                                    } table-content`}
                                                                >
                                                                    {value && typeof value === 'string' ? (
                                                                        <>
                                                                            <label>
                                                                                {intl.get(value.toUpperCase()) || value}
                                                                            </label>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <label>-</label>
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            {viewColumn && docIndex === 2 && (
                                                                <TableCell className={'table-content'}>
                                                                    {document?.[columnData][documentUrlAttr] && (
                                                                        <span
                                                                            className='contract-details-modal-link'
                                                                            onClick={() => {
                                                                                getDocument(document);
                                                                            }}
                                                                        >
                                                                            <div className='tooltip-delete-logo'>
                                                                                <Tooltip
                                                                                    title={intl.get('SEE_DOCUMENT')}
                                                                                >
                                                                                    <img
                                                                                        src={eyeIcon}
                                                                                        alt='eyeIcon'
                                                                                        className='upload-logo'
                                                                                        onClick={() => {
                                                                                            setIndexButtons([
                                                                                                ...indexButtons,
                                                                                                index,
                                                                                            ]);
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </span>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                        </Fragment>
                                                    );
                                            },
                                        )}
                                        {actionColumn &&
                                            indexButtons.includes(index) &&
                                            document?.[columnData]['aasm_state'] === stateToActionDisplay && (
                                                <>
                                                    {buttonsTitles.map((value: any, indexButton: number) => (
                                                        <TableCell
                                                            className={`${classes.cell} table-content`}
                                                            key={indexButton}
                                                        >
                                                            <Tooltip title={intl.get(value.titleTooltip)}>
                                                                <button
                                                                    type='button'
                                                                    className='button-primary table-button'
                                                                    onClick={() => {
                                                                        const doc = document?.[columnData];
                                                                        value.action(
                                                                            document.id,
                                                                            doc['doc_type'],
                                                                            doc['uploader_kind'],
                                                                            doc['contract_id'],
                                                                        );
                                                                    }}
                                                                >
                                                                    {intl.get(value.titleButton)}
                                                                </button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    ))}
                                                </>
                                            )}
                                    </TableRow>
                                ))
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetDocumentFile: (docId: number, route: string) => {
            dispatch(getDocumentFile(docId, route));
        },
    };
};

export default connect(null, mapDispatchToProps)(DocumentsTable);
