import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { ButtonLoading, Input, SelectInputPrice } from '../../tango-react-base/components';
import { getPercentageDifference } from '../../tango-react-base/helpers/getPercentageIncrease';
import { formatNumber } from '../../tango-react-base/helpers/numberFormat';
import { priceAllowedByCountry } from '../../tango-react-base/helpers/priceValidation';
import { CURRENCY_OPTIONS } from '../../tango-react-base/helpers/selectOptions/options';
import { useCurrencyConvertor } from '../../tango-react-base/hooks';
import { newPriceIcon, noLeaseRenewalIcon } from '../../tango-react-base/resources';

interface RenewalDataFormProps {
  onSubmit: any;
  currentPrice: number;
  mediationStatus: string;
  response: { renter: boolean; owner: boolean };
  renewalPeriodDataStatus: string;
  proposePriceStatus: string;
  reproposePriceStatus: string;
  originalCurrentPrice: string;
}

interface RenewalDataForm {
  reasonNoRenewal: string;
  reasonNewPrice: string;
  proposed_price: number;
  option: any;
  currencyIsoValue: string;
  proposedPrice: number;
}

const RenewalData: React.FunctionComponent<RenewalDataFormProps & FormikProps<RenewalDataForm>> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  children,
  currentPrice,
  mediationStatus,
  response,
  renewalPeriodDataStatus,
  proposePriceStatus,
  reproposePriceStatus,
  originalCurrentPrice,
}) => {
  const [disableButton, setDisableButton] = useState(true);
  const [, priceConversion, , currentUF] = useCurrencyConvertor({
    value: priceAllowedByCountry()!,
    minimunPrice: 0,
  });

  const conversionPorcentage =
    originalCurrentPrice === values.currencyIsoValue
      ? getPercentageDifference(currentPrice, values.proposed_price)
      : originalCurrentPrice === 'CLP' && values.currencyIsoValue === 'CLF'
      ? getPercentageDifference(currentPrice, values.proposed_price * Number(currentUF))
      : getPercentageDifference(currentPrice, values.proposed_price / Number(currentUF));

  const conversionChange =
    originalCurrentPrice === values.currencyIsoValue
      ? currentPrice > values.proposed_price
        ? intl.get('PERCENTAGE_DECREASE')
        : intl.get('PERCENTAGE_INCREASE')
      : originalCurrentPrice === 'CLP' && values.currencyIsoValue === 'CLF'
      ? currentPrice > values.proposed_price * Number(currentUF)
        ? intl.get('PERCENTAGE_DECREASE')
        : intl.get('PERCENTAGE_INCREASE')
      : currentPrice > values.proposed_price / Number(currentUF)
      ? intl.get('PERCENTAGE_DECREASE')
      : intl.get('PERCENTAGE_INCREASE');

  useEffect(() => {
    if (values.option === 'newPrice') {
      if (!values.currencyIsoValue || values.currencyIsoValue === 'CLP') {
        setDisableButton(!values.proposed_price || values.proposed_price < priceAllowedByCountry()!);
      } else {
        setDisableButton(
          !values.proposed_price ||
            values.proposed_price < parseFloat(priceConversion.replace('UF', '').replace(',', '.')),
        );
      }
    }

    if (values.option === 'reject') setDisableButton(!values.reasonNoRenewal);
  }, [values, response]);

  const handleChangeLeaseNewPrice = () => {
    values.reasonNoRenewal = '';
  };
  const handleChangeNoLeaseRenewal = () => {
    values.proposed_price = 0;
    values.reasonNewPrice = '';
  };
  const isFetchingRenewalPeriodStatus =
    renewalPeriodDataStatus === 'FETCHING' ||
    renewalPeriodDataStatus === 'ERROR_FETCHING' ||
    proposePriceStatus === 'ERROR_SENDING_PROPOSE_PRICE' ||
    reproposePriceStatus === 'ERROR_SENDING_REPROPOSE_PRICE' ||
    proposePriceStatus === 'SENDING_PROPOSE_PRICE' ||
    reproposePriceStatus === 'SENDING_REPROPOSE_PRICE';

  return (
    <Form>
      <div className='row mt-5 d-flex justify-content-between'>
        <div className='col-md-6 my-1'>
          <label className={'lease-renewal-radio'}>
            <div className='lease-renewal-input-container'>
              <input
                type='radio'
                name='option'
                className='mr-2'
                id='leaseNewPrice'
                value='newPrice'
                onChange={handleChange}
                onClick={handleChangeLeaseNewPrice}
              />
              <span className='check-lease-renewal'></span>
              <img src={newPriceIcon} className='mr-2' alt='leaseRenewalIcon' height='50' />
            </div>
            <h2 className={`lease-renewal-text py-2 lease-renewal-text-left`}>{intl.get('PROPOSE_NEW_PRICE_ADMIN')}</h2>
          </label>
        </div>
        <div className='col-md-6 my-1'>
          <label className={'lease-renewal-radio'}>
            <div className='lease-renewal-input-container'>
              <input
                type='radio'
                name='option'
                id='noLeaseRenewal'
                className='mr-2'
                value='reject'
                onChange={handleChange}
                onClick={handleChangeNoLeaseRenewal}
              />
              <span className='check-lease-renewal'></span>
              <img src={noLeaseRenewalIcon} className='mr-2' alt='noLeaseRenewalIcon' height='50' />
            </div>
            <h2 className={`lease-renewal-text py-2 lease-renewal-text-left`}>
              {intl.get('NO_RENEW_LEASE_AGREEMENT_ADMIN')}
            </h2>
          </label>
        </div>
      </div>
      <div className='row col-12 my-1'>
        <div className='col-md-6 p-0'>
          {values.option === 'newPrice' && (
            <>
              <div>
                <div className='row'>
                  <p className='span-text-no-uppercase col'>{intl.get('NEW_PROPOSED_PRICE')}</p>
                </div>
                <SelectInputPrice
                  applyGrayStyle
                  classNameLabel={'col-12 p-0'}
                  classNameSelectInput={'col-12 col-md-8 p-0 mt-0'}
                  currencyIsoValue={values.currencyIsoValue}
                  currencyOptions={CURRENCY_OPTIONS}
                  id='proposed_price'
                  idCurrency='currencyIsoValue'
                  messageAdditionalValidation='MINIMUM_PRICE'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.proposed_price}
                  value={values.proposed_price}
                  classNameCurrency='col-3 col-lg-2'
                />
                {values.proposed_price && values.proposed_price !== currentPrice ? (
                  <p className='text-left'>
                    <small>
                      {conversionChange}
                      <span className='font-weight-bold'>{formatNumber(parseFloat(conversionPorcentage))}%</span>
                    </small>
                  </p>
                ) : null}
              </div>
              <Input
                id='reasonNewPrice'
                label='NEW_PROPOSED_PRICE_REASON'
                type='text'
                value={values.reasonNewPrice}
                placeholder={intl.get('REASON_PROPOSAL')}
                requiredMessage={intl.get('REQUIRED_FIELD')}
                multiline={true}
                rows={5}
                classNameInput='col-12 p-0'
                classNameLabel='col-12 mt-1 p-0'
                onChange={handleChange}
                error={errors.reasonNewPrice}
                onBlur={handleBlur}
                touched={touched.reasonNewPrice}
                noTextTransformation
              />
            </>
          )}
        </div>

        <div className='col-md-6 p-0'>
          {values.option === 'reject' && (
            <Input
              id='reasonNoRenewal'
              label='NO_RENEWAL_REASON'
              type='text'
              value={values.reasonNoRenewal}
              placeholder={intl.get('INDICATE_YOUR_REASONS')}
              requiredMessage={intl.get('REQUIRED_FIELD')}
              multiline={true}
              rows={5}
              classNameInput='col-12'
              classNameLabel='col-12 mt-1'
              onChange={handleChange}
              error={errors.reasonNoRenewal}
              noTextTransformation
            />
          )}
        </div>
      </div>

      <div className='div-container-input-class-12 input-container-class'>
        <div className='text-center col-12'>
          <button className='button-primary col-md-4 my-4' disabled={disableButton} type='submit'>
            {isFetchingRenewalPeriodStatus ? (
              <ButtonLoading />
            ) : mediationStatus === 'mediating' ? (
              intl.get('SEND_NEW_PROPOSAL')
            ) : (
              intl.get('SEND_PROPOSAL')
            )}
          </button>
        </div>
      </div>
    </Form>
  );
};

const renewalDataForms = withFormik<RenewalDataFormProps, RenewalDataForm>({
  mapPropsToValues: (props: any) => {
    const initialValues = {
      reasonNoRenewal: '',
      reasonNewPrice: '',
      currencyIsoValue: props.originalCurrentPrice,
    };
    return { ...initialValues, ...props };
  },

  handleSubmit(values: RenewalDataForm, { props, setSubmitting, setErrors }) {
    if (!values.currencyIsoValue) values.currencyIsoValue = 'CLP';
    values.proposedPrice = values.proposed_price;
    props.onSubmit(values);
  },
})(RenewalData);
export default renewalDataForms;
