// TODO Cambiar a dir 'actionCreators'!

export const publishPostStart = () => {
  return {
    type: 'PUBLISH_POST_STARTED',
  }
}

export const publishPostSuccess = (post: any) => {
  return {
    type: 'PUBLISH_POST_SUCCESS',
    payload: { post },
  }
}
export const publishPostError = (error: any) => {
  return {
    type: 'PUBLISH_POST_ERROR',
    payload: {
      error,
    },
  }
}
