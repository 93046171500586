const initialState = {
  requisition: {},
  fetchingRequisition: 'EMPTY',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_REQUISITION_START':
      return {
        ...state,
        fetchingRequisition: 'LOADING',
      }
    case 'GET_REQUISITION_SUCCESS':
      return {
        ...state,
        fetchingRequisition: 'LOADED',
        requisition: action.payload,
      }
    case 'GET_REQUISITION_ERROR':
      return {
        ...state,
        fetchingRequisition: 'ERROR',
      }
    default:
      return state
  }
}
