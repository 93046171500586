export const getAdminContractHistoryStart = () => {
    return {
        type: 'GET_ADMIN_CONTRACT_HISTORY_STARTED',
    };
};

export const getAdminContractHistorySuccess = (result: any) => {
    return {
        type: 'GET_ADMIN_CONTRACT_HISTORY_SUCCESS',
        payload: { result },
    };
};
export const getAdminContractHistoryError = (error: any) => {
    return {
        type: 'GET_ADMIN_CONTRACT_HISTORY_ERROR',
        payload: {
            error,
        },
    };
};
