import React from 'react'
import { AccordionItem, InputDataShow } from '../../tango-react-base/components'
import intl from 'react-intl-universal'

/** Here the types of the props for the interface 'DataProps' are defined
 * @typedef DataModalTypes
 * @type {(object})}
 * @property {object} owner - is a object.
 * @property {object} renter - is a object.
 */

interface DataModalProps {
  owner: { phone_number: number; full_name: string; email: string }
  renter: { phone_number: number; full_name: string; email: string }
}

const DataModal = ({ renter, owner }: DataModalProps) => {
  const ownerData = [
    { id: 'full_name', label: 'OWNER_NAME', value: owner?.full_name },
    {
      id: 'owner_contact',
      label: 'OWNER_CONTACT',
      value: owner?.phone_number,
    },
    {
      id: 'owner_email',
      label: 'OWNER_EMAIL',
      value: owner?.email,
    },
  ]
  const renterData = [
    { id: 'full_name', label: 'RENTER_NAME', value: renter?.full_name },
    {
      id: 'renter_contact',
      label: 'RENTER_CONTACT',
      value: renter?.phone_number,
    },
    {
      id: 'renter_email',
      label: 'RENTER_EMAIL_2',
      value: renter?.email,
    },
  ]

  return (
    <div>
      <AccordionItem title={intl.getHTML('CONTACT_INFORMATION')}>
        <form>
          {ownerData.map((item: any) => (
            <InputDataShow
              id={item.id}
              label={item.label}
              value={item.value}
              disabled={true}
              className='col-12 p-0'
              classNameInput='col-md-6 col-12 p-0'
              classNameLabel='col-md-4 col-12 p-0 text-color-secondary'
            ></InputDataShow>
          ))}
          {renterData.map((item: any) => (
            <InputDataShow
              id={item.id}
              label={item.label}
              value={item.value}
              disabled={true}
              className='col-12 p-0'
              classNameInput='col-md-6 col-12 p-0'
              classNameLabel='col-md-4 col-12 p-0 text-color-secondary'
            ></InputDataShow>
          ))}
        </form>
      </AccordionItem>
    </div>
  )
}

export default DataModal
