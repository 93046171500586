import { getDocumentStart, getDocumentSuccess, getDocumentError } from './types'
import { getDocument } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getDocumentFile = (id: any, route: string) => {
  return (dispatch: any) => {
    dispatch(getDocumentStart())
    getDocument(route)
      .then(async (result) => {
        const base64Data = result.data.file
        const base64Response = await fetch(`data:application/pdf;base64,${base64Data}`);
        const blob = await base64Response.blob();
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        return dispatch(getDocumentSuccess(result.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getDocumentError(e))
      })
  }
}

export default getDocumentFile