// TODO Cambiar a dir 'actionCreators'!

export const rejectRenterStart = () => {
  return {
    type: 'REJECT_RENTER_STARTED',
  }
}

export const rejectRenterSuccess = (renter_validation: any) => {
  return {
    type: 'REJECT_RENTER_SUCCESS',
    payload: { renter_validation },
  }
}
export const rejectRenterError = (error: any) => {
  return {
    type: 'REJECT_RENTER_ERROR',
    payload: {
      error,
    },
  }
}
