import AboutUs from './tango-react-base/components/aboutUs';
import TermAndCondition from './tango-react-base/components/termsAndCondition';
import Adverts from './views/adverts';
import BlankView from './views/blankView';
import CalendarLite from './views/calendar';
import Campaigns from './views/campaigns';
import ClosureLease from './views/closureLease';
import Contracts from './views/Contracts';
import Documents from './views/Documents';
import Mediation from './views/mediation';
import OwnerShow from './views/Owners';
import RentersPayment from './views/payments/renters';
import SalesNotes from './views/payments/salesNotes';
import PendingVouchers from './views/pendingVouchers';
import PostsIndex from './views/postsIndex';
import PostPublished from './views/postsIndex/postPublished';
import PostRegistered from './views/postsIndex/postRegistered';
import PostRented from './views/postsIndex/postRented';
import PostToRepublish from './views/postsIndex/postsToRepublish';
import Promotions from './views/promotions';
import PublishedPost from './views/publishedPost';
import publishPost from './views/publishPost';
import PublishPostPayments from './views/PublishPostPayments';
import RenterIndex from './views/renters';
import RenterShow from './views/renters/renterDetail';
import RepublishPost from './views/republishPost';
import Requisitions, { BuildingView } from './views/requisitions';
import RequisitionsDetails from './views/requisitions/details';
import UserSearch from './views/userSearch';
import UserDetails from './views/userSearch/userDetails';
import Visits from './views/visits';

const routes: any[] = [
    {
        path: '/requisitions/:id',
        component: process.env.REACT_APP_REQUISITION_STATE == 'off' ? BuildingView : RequisitionsDetails,
    },
    {
        path: '/requisitions',
        component: process.env.REACT_APP_REQUISITION_STATE == 'off' ? BuildingView : Requisitions,
    },
    {
        path: '/published_post',
        component: PublishedPost,
    },
    {
        path: '/creation_advert',
        component: Adverts,
    },
    {
        path: '/new_advert/:gallery_id',
        component: Adverts,
    },
    {
        path: '/gallery/:gallery_id/advert/:id',
        component: Adverts,
    },
    {
        path: '/users/details/:id',
        component: UserDetails,
    },
    // TODO: remove this route -> change route for /posts/:id/documents
    {
        path: '/documents/:id',
        component: Documents,
    },
    {
        path: '/users',
        component: UserSearch,
    },
    {
        path: '/registered/pending',
        component: SalesNotes,
    },
    {
        path: '/registered/rented',
        component: PostRegistered,
    },
    {
        path: '/registered',
        component: PostRegistered,
    },
    {
        path: '/payments/sales-notes',
        component: SalesNotes,
    },
    {
        path: '/payments/renters',
        component: RentersPayment,
    },
    {
        path: '/payments',
        component: RentersPayment,
    },
    {
        path: '/lease_period/:id/closure_lease',
        component: process.env.REACT_APP_REQUISITION_STATE == 'off' ? BuildingView : ClosureLease,
    },
    {
        path: '/visits',
        component: Visits,
    },
    {
        path: '/published',
        component: PostPublished,
    },
    {
        path: '/posts/to-republish',
        component: PostToRepublish,
    },
    {
        path: '/rented',
        component: PostRented,
    },
    {
        path: '/login/:email',
        component: BlankView,
    },
    {
        path: '/login',
        component: BlankView,
    },
    {
        path: '/sign_up/:email',
        component: BlankView,
    },
    {
        path: '/sign_up',
        component: BlankView,
    },
    {
        path: '/forget_password/:email/token/:token',
        component: BlankView,
    },
    {
        path: '/forget_password/:email',
        component: BlankView,
    },
    {
        path: '/forget_password',
        component: BlankView,
    },
    {
        path: '/posts/:id/documents',
        component: Documents,
    },
    {
        path: '/posts/:id/payments',
        component: PublishPostPayments,
    },
    {
        path: '/posts/:id/promotions',
        component: Campaigns,
    },
    {
        path: '/posts/:id/promotion',
        component: Promotions,
    },
    {
        path: '/posts/:id',
        component: publishPost,
    },
    {
        path: '/owners/:id',
        component: OwnerShow,
    },
    {
        path: '/campaigns',
        component: Campaigns,
    },
    {
        path: '/renters/:id',
        component: RenterShow,
    },
    {
        path: '/calendar/:id',
        component: CalendarLite,
    },
    {
        path: '/contracts/:contract_id/renewal-period/:id/mediation',
        component: Mediation,
    },
    {
        path: '/pending-vouchers',
        component: PendingVouchers,
    },
    {
        path: '/renters',
        component: RenterIndex,
    },
    {
        path: '/posts',
        component: PostsIndex,
    },
    {
        path: '/republish/:id',
        component: RepublishPost,
    },
    {
        path: '/contracts',
        component: Contracts,
    },

    {
        path: '/terms-and-conditions',
        component: TermAndCondition,
    },
    {
        path: '/about-us',
        component: AboutUs,
    },
    {
        path: '/',
        component: PostsIndex,
    },
    {
        path: 'home',
        component: PostsIndex,
    },
];

export default routes;
