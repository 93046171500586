import {
    postClosureLeaseConfirmationStart,
    postClosureLeaseConfirmationSuccess,
    postClosureLeaseConfirmationError,
} from './types';
import { postData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const postClosureLeaseConfirmation = (leasePeriodId: number, leaseClosingAt: Date, requesterComment: string) => {
    const params = {
        requisitions: {
            lease_period_id: leasePeriodId,
            lease_closing_at: leaseClosingAt,
            requester_comment: requesterComment,
        },
    };
    return (dispatch: any, getState: any) => {
        dispatch(postClosureLeaseConfirmationStart());
        postData('requisitions', params)
            .then((result) => {
                return dispatch(postClosureLeaseConfirmationSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(postClosureLeaseConfirmationError(e));
            });
    };
};
export default postClosureLeaseConfirmation;
