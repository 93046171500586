import React, { useState, useEffect } from 'react'
import { SectionProps } from './SectionInterfaces'
import { RadioButtonTango, SwitchString, Input, CheckBoxTango, SelectInputPrice, InputFee } from '../..'
import {
  RESIDENT_TYPE_OPTIONS,
  BOOLEAN_OPTIONS,
  BASIC_SERVICES_INCLUDES_OPTIONS,
  COMMON_EXPENSES_OPTIONS,
  CURRENCY_OPTIONS
} from '../../../helpers/selectOptions/options'
import DatePicker from '../../datePicker'
import { verifierOrAdminPermissions } from './SectionHelpers'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import {
  isRoleOwner,
  isRolePhotographer,
  isRoleAdmin
} from '../../../helpers/roleComparison/index'
import { formatDates } from '../../../helpers/date'
import { validateEstimatePriceAmount } from '../../../helpers/validateEstimatePriceAmount'

const Terms = ({
  canUpdateRenter = true,
  clf,
  disabled,
  errors,
  handleBlur,
  handleChange,
  isNewUnit,
  isRegistered = false,
  isVerifier,
  setFullFieldsTerms,
  toRepublish = false,
  touched,
  values
}: SectionProps) => {
  const history = useHistory()
  const currentLocation = history.location.pathname.split('/')[1]
  const newRentedView = currentLocation === 'new-rented-unit'
  const priceValidation =
    values.estimate_price_amount < 200000
      ? intl.get('MINIMUM_PRICE')
      : !values.estimate_price_amount
        ? intl.get('REQUIRED_FIELD')
        : ''
  // Controls when owner confirms that he notified the renter
  const [checked, setChecked] = useState(false)

  /** formatRut : This function formats a given argument into a rut formatted string
   * @function
   * @returns {(String)} Returns a string
   */
  const formatRut = (rut: any) => {
    return rut
      .replace(/[.-]/g, '')
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
  }
  /** getYesterday: This function returns a yesterday date
   * @function
   * @returns {(Date)} Returns a date
   */
  const getYesterday = () => {
    const today = new Date()
    return new Date(today.setDate(today.getDate() - 1))
  }

  const fields: any =
    isRolePhotographer || toRepublish
      ? [
        values.resident_type || undefined,
        values.estimate_price_amount ||
        undefined ||
        values.includes_common_expenses ||
        undefined,
        values.available_now === 'no' ? values.available_at || undefined : '',
        values.description || undefined,
        values.max_capacity || undefined,
      ]
      : []

  useEffect(() => {
    const estimatePriceAmount = values.estimate_price_amount
    const estimatePriceCurrency = values.estimate_price_currency
    setFullFieldsTerms && setFullFieldsTerms(!fields.includes(undefined) && validateEstimatePriceAmount(clf, estimatePriceAmount, estimatePriceCurrency))
  }, [fields])

  useEffect(() => {
    if (values.available_now === 'yes') {
      values.available_at = null
    }
  }, [values.available_now])

  const appOwner = isRoleOwner && isNewUnit
  const cantEditFee = isRoleAdmin && values.can_change_fee
  const cantEditGuarantee = isRoleAdmin && values.can_change_fee && !isRegistered
  return (
    <>
      {newRentedView || isRegistered ? (
        <>
          <div className='template-line mt-4' />
          <h2 className='new-subtitle'>{intl.get('CONTRACT_CONDITIONS')}</h2>
          <SwitchString
            disabled={disabled}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            label='RENTED_UNIT'
            name='leased'
            options={BOOLEAN_OPTIONS}
            value={values.leased}
          />
          <DatePicker
            disabled={disabled}
            label='CONTRACT_DATE'
            name='lease_start'
            value={values.lease_start}
            maxDate={getYesterday()}
          />
          <SelectInputPrice
            currencyIsoValue={values.estimate_price_currency}
            currencyOptions={CURRENCY_OPTIONS}
            disabled={disabled}
            id='estimate_price_amount'
            idCurrency='estimate_price_currency'
            info='SELECT_CLF_INFO'
            isVerifier={isVerifier}
            label='RENT'
            messageAdditionalValidation='MINIMUM_PRICE'
            onBlur={handleBlur}
            onChange={handleChange}
            required={isNewUnit}
            touched={touched.estimate_price_amount}
            value={values.estimate_price_amount}
          />
          {!isRoleOwner && (
          <>
            <Input
              disabled={!cantEditGuarantee}
              error={errors.amount_guarantees}
              id='amount_guarantees'
              label='GUARANTEE'
              info='GUARANTEE_INFO'
              onBlur={handleBlur}
              onChange={handleChange}
              requiredMessage={intl.get('REQUIRED_FIELD')}
              type='number'
              min='1'
              max='10000'
              justifyCenter='align-items-center'
              value={values.amount_guarantees}
              unit={intl.get('PER_MONTH')}
              touched={values.amount_guarantees}
              step={'.01'}
            />
            <InputFee
              id='owner_brokerage_fee'
              label='OWNER_BROKERAGE_FEE'
              info='BROKERAGE_INFO'
              value={values.owner_brokerage_fee}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!cantEditFee}
            />
            <InputFee
              id='renter_brokerage_fee'
              label='RENTER_BROKERAGE_FEE'
              info='BROKERAGE_INFO'
              value={values.renter_brokerage_fee}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!cantEditFee}
            />
            <InputFee
              id='administration_fee_percentage'
              label='ADMINISTRATION_FEE'
              info='ADMIN_FEE_INFO'
              value={values.administration_fee_percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!cantEditFee}
            />
          </>
          )}
          {!appOwner && (
            <>
              <RadioButtonTango
                error={errors.includes_common_expenses}
                required={newRentedView}
                disabled={disabled}
                id='includes_common_expenses'
                label='INCLUDES_COMMON_EXPENSES'
                onChange={handleChange}
                options={COMMON_EXPENSES_OPTIONS}
                valueChecked={values.includes_common_expenses}
              />
              {values.includes_common_expenses === 'separated' ? (
                <>
                  <Input
                    required={newRentedView}
                    disabled={disabled}
                    placeholder='$'
                    error={errors.common_expenses_amount}
                    id='common_expenses_amount'
                    max='20000000'
                    min='0'
                    label='NO_LABEL'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    requiredMessage={intl.get('REQUIRED_FIELD')}
                    type='price'
                    value={values.common_expenses_amount}
                    unit='PESOS'
                  />
                  <CheckBoxTango
                    disabled={disabled}
                    label='INCLUDES_BASIC_SERVICES'
                    name='basic_services_included'
                    options={BASIC_SERVICES_INCLUDES_OPTIONS}
                    info={intl.get('INFO_BASIC_SERVICES')}
                  />
                </>
              ) : null}
              {values.includes_common_expenses === 'yes' ? (
                <CheckBoxTango
                  disabled={disabled}
                  label='INCLUDES_BASIC_SERVICES'
                  name='basic_services_included'
                  options={BASIC_SERVICES_INCLUDES_OPTIONS}
                  info={intl.get('INFO_BASIC_SERVICES')}
                />
              ) : null}
            </>
          )}

          <Input
            required={newRentedView}
            disabled={!canUpdateRenter}
            error={errors.full_name}
            id='full_name'
            label='RENTER_FULLNAME'
            onBlur={handleBlur}
            onChange={handleChange}
            requiredMessage={intl.get('REQUIRED_FIELD')}
            messageAdditionalValidation={
              !values.full_name ? intl.get('REQUIRED_FIELD') : ''
            }
            touched={touched.full_name}
            type='text'
            value={values.full_name}
          />
          <Input
            id='rut'
            label='RENTER_RUT'
            type='text'
            maxLength={12}
            required={newRentedView}
            error={errors.rut}
            onBlur={handleBlur}
            touched={touched.rut}
            disabled={!canUpdateRenter}
            value={values.rut ? formatRut(values.rut) : values.rut}
            onChange={handleChange}
            classNameInput='col-lg-3 plm-0 mb-3'
            requiredMessage={intl.get('REQUIRED_FIELD')}
          />
          <Input
            required={newRentedView}
            disabled={!canUpdateRenter}
            error={errors.email}
            id='email'
            label='RENTER_EMAIL'
            onBlur={handleBlur}
            onChange={handleChange}
            requiredMessage={intl.get('REQUIRED_FIELD')}
            touched={touched.email}
            type='email'
            value={values.email}
            info='RENTER_EMAIL_INFO'
          />
          <div className='text-left'>
            <div className='ml-4'>
              <input
                type='checkbox'
                required={newRentedView}
                className='form-check-input'
                id='alert_to_renter'
                checked={
                  newRentedView
                    ? (values.alert_to_renter = checked)
                    : values.alert_to_renter
                }
                onChange={() => setChecked(!checked)}
              />
              <label
                className='text-color-secondary text-uppercase'
                htmlFor='alert_to_renter'
              >
                {intl.get('ALERT_TO_RENTER')}
              </label>
            </div>
            <p className='text-color-tertiary'>
              {intl.get('ALERT_TO_RENTER_INFO')}
            </p>
          </div>
        </>
      ) : (
        <div>
          {!appOwner && (
            <>
              {!toRepublish && (
                <h2 className='new-subtitle'>{intl.get('TERMS')}</h2>
              )}
              <RadioButtonTango
                error={errors.resident_type}
                required={false}
                disabled={disabled}
                id='resident_type'
                label='UNIT_USED_NOW'
                onChange={handleChange}
                options={RESIDENT_TYPE_OPTIONS}
                valueChecked={values.resident_type}
                info='CURRENTLY_WHO_LIVES'
                classInfo='d-none d-md-block'
                isVerifier={isVerifier}
                isRequiredVerifier={!values.resident_type}
              />
            </>
          )}

          {!appOwner && (
            <>
              {values.last_renewal_date ? (
                <>
                  <Input
                    disabled={true}
                    placeholder='$'
                    id='price_amount'
                    label='INITIAL_LEASE_PRICE'
                    max='5000000'
                    min='200000'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.price_amount}
                    type='price'
                    value={values.price_amount}
                    unit='PESOS'
                  />
                  <Input
                    isVerifier={isVerifier}
                    required={false}
                    disabled={disabled}
                    placeholder='$'
                    id='current'
                    label='CURRENT_LEASE_PRICE'
                    max='5000000'
                    min='200000'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.current_price}
                    messageAdditionalValidation={priceValidation}
                    type='price'
                    value={values.current_price}
                    unit='PESOS'
                  />

                  <Input
                    disabled={true}
                    id='last_renewal_date'
                    label='LEASE_RENEWAL_PERIOD_DATE'
                    value={formatDates({ date: values.last_renewal_date })}
                  />
                </>
              ) : (
                <>
                  <SelectInputPrice
                    currencyIsoValue={values.estimate_price_currency}
                    currencyOptions={CURRENCY_OPTIONS}
                    disabled={disabled}
                    id='estimate_price_amount'
                    idCurrency='estimate_price_currency'
                    info='SELECT_CLF_INFO'
                    isVerifier={isVerifier}
                    label='CURRENT_LEASE_PRICE'
                    messageAdditionalValidation='MINIMUM_PRICE'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={false}
                    touched={touched.estimate_price_amount}
                    value={values.estimate_price_amount}
                  />
              </>
              )}
                <Input
                  disabled={!cantEditGuarantee}
                  error={errors.amount_guarantees}
                  id='amount_guarantees'
                  label='GUARANTEE'
                  info='GUARANTEE_INFO'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  requiredMessage={intl.get('REQUIRED_FIELD')}
                  type='number'
                  min='1'
                  max='10000'
                  justifyCenter='align-items-center'
                  value={values.amount_guarantees}
                  unit={intl.get('PER_MONTH')}
                  touched={values.amount_guarantees}
                  step={'.01'}
                />

                <InputFee
                    id='owner_brokerage_fee'
                    label='OWNER_BROKERAGE_FEE'
                    info='BROKERAGE_INFO'
                    value={values.owner_brokerage_fee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={!cantEditFee}
                  />
                  <InputFee
                    id='renter_brokerage_fee'
                    label='RENTER_BROKERAGE_FEE'
                    info='BROKERAGE_INFO'
                    value={values.renter_brokerage_fee}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={!cantEditFee}
                  />
                  <InputFee
                    id='administration_fee_percentage'
                    label='ADMINISTRATION_FEE'
                    info='ADMIN_FEE_INFO'
                    value={values.administration_fee_percentage}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={!cantEditFee}
                  />

              <RadioButtonTango
                error={errors.includes_common_expenses}
                required={false}
                disabled={disabled}
                id='includes_common_expenses'
                label='INCLUDES_COMMON_EXPENSES'
                onChange={handleChange}
                options={COMMON_EXPENSES_OPTIONS}
                valueChecked={values.includes_common_expenses}
                isVerifier={isVerifier}
                isRequiredVerifier={!values.includes_common_expenses}
              />
              {values.includes_common_expenses === 'separated' ? (
                <>
                  <Input
                    required={false}
                    disabled={disabled}
                    placeholder='$'
                    error={errors.common_expenses_amount}
                    id='common_expenses_amount'
                    max='20000000'
                    min='0'
                    label='NO_LABEL'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    messageAdditionalValidation={
                      !values.common_expenses_amount
                        ? intl.get('REQUIRED_FIELD')
                        : ''
                    }
                    type='price'
                    value={values.common_expenses_amount}
                    unit='PESOS'
                  />
                  <CheckBoxTango
                    disabled={disabled}
                    label='INCLUDES_BASIC_SERVICES'
                    name='basic_services_included'
                    options={BASIC_SERVICES_INCLUDES_OPTIONS}
                    info={intl.get('INFO_BASIC_SERVICES')}
                  />
                </>
              ) : null}
              {values.includes_common_expenses === 'yes' ? (
                <CheckBoxTango
                  disabled={disabled}
                  label='INCLUDES_BASIC_SERVICES'
                  name='basic_services_included'
                  options={BASIC_SERVICES_INCLUDES_OPTIONS}
                  info={intl.get('INFO_BASIC_SERVICES')}
                />
              ) : null}
              <SwitchString
                disabled={disabled}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                label='ACCEPT_ANIMALS'
                name='animals'
                options={BOOLEAN_OPTIONS}
                value={values.animals}
              />
              <SwitchString
                disabled={disabled}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                label='AVAILABLE_NOW'
                name='available_now'
                options={BOOLEAN_OPTIONS}
                value={values.available_now}
              />
              {values.available_now === 'no' && (
                <DatePicker
                  disabled={disabled}
                  label='AVALIABLE_FROM'
                  minDate={new Date()}
                  name='available_at'
                  value={values.available_at}
                  isVerifier={isVerifier}
                />
              )}
            </>
          )}

          {verifierOrAdminPermissions() && (
            <>
              <Input
                isVerifier={isVerifier}
                disabled={disabled}
                error={errors.max_capacity}
                id='max_capacity'
                label='MAX_CAPACITY'
                max='20000000'
                min='0'
                onBlur={handleBlur}
                onChange={handleChange}
                requiredMessage={intl.get('REQUIRED_FIELD')}
                type='number'
                value={values.max_capacity}
                unit={intl.get('PERSONS')}
                isRequiredVerifier={!values.max_capacity}
              />
              <Input
                isVerifier={isVerifier}
                disabled={disabled}
                error={errors.description}
                id='description'
                label='DESCRIPTION_PROPERTY'
                onBlur={handleBlur}
                onChange={handleChange}
                requiredMessage={intl.get('REQUIRED_FIELD')}
                touched={touched.description}
                type='text'
                value={values.description}
                isRequiredVerifier={!values.description}
              />

              <SwitchString
                disabled={disabled}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                label='YOU_WANT_LOCKBOX'
                name='lockbox'
                options={BOOLEAN_OPTIONS}
                value={values.lockbox}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
export default Terms
