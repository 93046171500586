import { getUserStart, getUserSuccess, getUserError } from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUser = (id: number) => {
  return (dispatch: any) => {
    dispatch(getUserStart())
    getData(`users/${id}`)
      .then((result) => {
        return dispatch(getUserSuccess(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getUserError(e))
      })
  }
}

export default getUser
