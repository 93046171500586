export const approvePostRegisteredStart = () => {
  return {
    type: 'APPROVE_POST_REGISTERED_STARTED'
  }
}

export const approvePostRegisteredSuc = (postRegister: any) => {
  return {
    type: 'APPROVE_POST_REGISTERED_SUCCESS',
    payload: { postRegister },
  }
}

export const approvePostRegisteredError = (error: any) => {
  return {
    type: 'APPROVE_POST_REGISTERED_ERROR',
    payload: { error },
  }
}
