import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestUserList } from '../../redux/actions';
import { ButtonRedirect, CircularLoading, TableTango } from '../../tango-react-base/components';
import { setTemplateChild } from '../../tango-react-base/reduxActions';
import { deactiveInsertedProperties, insertedProperties } from '../../tango-react-base/resources';
import { userSearchTable } from './createTable';

interface UserSearchViewProps {
  onSetTemplateChild: (child: Object) => void;
  fetch: string;
  users: Array<any>;
  onRequestUserList: () => void;
}

const UserSearchView = ({ onSetTemplateChild, fetch, users, onRequestUserList }: UserSearchViewProps) => {
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('USERS')}</h1>
          <p className='px-0 d-none d-md-block'>
            <br />
          </p>
        </>,
      );
    }
  }, [history, onSetTemplateChild]);

  useEffect(() => {
    setTimeout(() => {
      setDisable(false);
    }, 10000);
  }, [disable]);

  const BodyTable = () => {
    return (
      <>
        {!users.length ? (
          <TableRow>
            <TableCell colSpan={6} className='table-content text-center'>
              {intl.get('SEARCH_NOT_FOUND')}
            </TableCell>
          </TableRow>
        ) : (
          users.map((data: any) => {
            const { attributes, id } = data;
            const {
              full_name,
              email,
              rut,
              renter_profile_present,
              unit_count,
              roles,
              renter_profile_state,
            } = attributes;

            const rolesMap = roles.map((rol: string) => `${intl.get(rol.toLocaleUpperCase())}`).join(', ');
            return (
              <TableRow key={id}>
                <TableCell className='table-content'>{full_name}</TableCell>
                <TableCell className='table-content'>{email}</TableCell>
                <TableCell className='table-content'>{rut}</TableCell>
                <TableCell className='table-content'>{!!roles.length ? rolesMap : intl.get('NOT_HAVE')}</TableCell>
                <TableCell className='table-content'>{renter_profile_state}</TableCell>
                <TableCell className='table-content'>
                  <div className='container-img'>
                    <img
                      src={!unit_count ? deactiveInsertedProperties : insertedProperties}
                      alt='properties'
                      width='40'
                    />
                    <div className='centered'>{unit_count}</div>
                  </div>
                </TableCell>
                <TableCell className='table-content'>
                  <ButtonRedirect route={`/users/details/${id}`} label={'details'} />
                </TableCell>
              </TableRow>
            );
          })
        )}
      </>
    );
  };

  const RenderTable = () => {
    return (
      <div className='mt-5'>
        <TableTango listTitle={userSearchTable.titlesHeader}>
          <BodyTable />
        </TableTango>
      </div>
    );
  };

  return (
    <>
      <div className='container-home pd-1 mt-5'>
        <div className='template-line-title' />
        <h2 className='text my-2'>{intl.get('SEARCH_RESULT')}</h2>
        {fetch === 'LOADING' && (
          <p>
            <CircularLoading />
          </p>
        )}
        {fetch === 'LOADED' && <RenderTable />}
        {fetch === 'ERROR' && <p>{intl.get('ERROR')}</p>}
        <div className='row d-flex justify-content-end mt-3'>
          <button
            className='col-md-3 button-primary'
            onClick={() => {
              onRequestUserList();
              setDisable(true);
            }}
            disabled={disable}
          >
            {intl.get('SEND_USERLIST')}
          </button>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onRequestUserList: () => {
      dispatch(requestUserList());
    },
  };
};
const mapStateToProps = (state: any) => {
  const { users, fetch } = state.usersSearch;
  return {
    users,
    fetch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchView);
