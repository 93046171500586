/**
 * This function returns a string with a percentage increase or decrease
 * @function
 * @returns {(string)} percentage increase or decrease
 */
export const getPercentageDifference = (initialValue: number, newValue: number) => {
    const increasePercentage = ((newValue - initialValue) / initialValue) * 100;
    const decreasePercentage = ((initialValue - newValue) / initialValue) * 100;
    return `${initialValue < newValue ? increasePercentage.toFixed(2) : decreasePercentage.toFixed(2)}%`;
};
