export const getOwnerPaymentsStart = () => {
    return {
        type: 'GET_OWNER_PAYMENTS_STARTED',
    };
};

export const getOwnerPaymentsSuc = (data: any) => {
    return {
        type: 'GET_OWNER_PAYMENTS_SUCCESS',
        payload: data,
    };
};

export const getOwnerPaymentsError = (error: any) => {
    return {
        type: 'GET_OWNER_PAYMENTS_ERROR',
        payload: {
            error,
        },
    };
};
