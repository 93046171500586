import { PublishState } from '../../../tango';

const initialState: PublishState = {
  changingPostState: 'NO_PUBLISH',
  documentsOnError: [],
  documentsOnQuery: [],
  documentsProcessed: [],
  fetchingPost: 'NO_POSTS',
  fetchingRenterDocuments: 'NO_LOADED',
  fetchingRenterDocumentsCount: 'NO_LOADED',
  fetchingState: 'NO_STATE',
  fetchingUnit: 'NO_CHANGE',
  post: {},
  renter_validation: {},
  renterDocuments: [],
  renterDocumentsCount: [],
  response: {},
  unit: {},
};

export default (state = initialState, action: any) => {
  let newData = {};
  let newDocument;
  let newProfileDocuments;

  const createNewRenterDocumentCount = (dataToModified: any) => {
    let newData = {};
    let approved =
      state.renterDocumentsCount[dataToModified.renterProfileIndex][dataToModified.documentType]['approved'] || 0;
    let pending =
      state.renterDocumentsCount[dataToModified.renterProfileIndex][dataToModified.documentType]['pending'] || 0;
    let rejected =
      state.renterDocumentsCount[dataToModified.renterProfileIndex][dataToModified.documentType]['rejected'] || 0;

    switch (dataToModified.action) {
      // REJECT
      case 1:
        pending -= 1;
        rejected += 1;

        if (approved) {
          let addApproved = { approved: approved };
          Object.assign(newData, addApproved);
        }
        if (pending) {
          let addPending = { pending: pending };
          Object.assign(newData, addPending);
        }
        if (rejected) {
          let addRejected = { rejected: rejected };
          Object.assign(newData, addRejected);
        }
        return newData;
      // UPDATE
      case 2:
        pending += 1;

        if (approved) {
          let addApproved = { approved: approved };
          Object.assign(newData, addApproved);
        }
        if (pending) {
          let addPending = { pending: pending };
          Object.assign(newData, addPending);
        }
        if (rejected) {
          let addRejected = { rejected: rejected };
          Object.assign(newData, addRejected);
        }
        return newData;
      // APPROVED
      default:
        approved += 1;
        pending -= 1;

        if (approved) {
          let addApproved = { approved: approved };
          Object.assign(newData, addApproved);
        }
        if (pending) {
          let addPending = { pending: pending };
          Object.assign(newData, addPending);
        }
        if (rejected) {
          let addRejected = { rejected: rejected };
          Object.assign(newData, addRejected);
        }
        return newData;
    }
  };

  switch (action.type) {
    case 'GET_POST_STARTED':
      return {
        ...state,
        fetchingPost: 'LOADING',
        changingPostState: 'NO_PUBLISH',
        fetchingState: 'NO_STATE',
        fetchingUnit: 'NO_CHANGE',
        error: '',
      };
    case 'GET_POST_SUCCESS':
      return {
        ...state,
        post: action.payload.data,
        unit: action.payload.data.attributes.unit,
        fetchingPost: 'LOADED',
      };
    case 'GET_POST_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingPost: 'ERROR',
      };
    case 'PUBLISH_POST_STARTED':
      return {
        ...state,
        changingPostState: 'PUBLISHING',
      };
    case 'PUBLISH_POST_SUCCESS':
      return {
        ...state,
        changingPostState: 'PUBLISHED',
      };
    case 'PUBLISH_POST_ERROR':
      return {
        ...state,
        changingPostState: 'PUBLISH_ERROR',
        error: action.payload.error,
      };
    case 'REJECT_POST_STARTED':
      return {
        ...state,
        changingPostState: 'REJECTING',
      };

    case 'REJECT_POST_SUCCESS':
      return {
        ...state,
        response: action.payload,
        changingPostState: 'REJECTED',
      };
    case 'REJECT_POST_ERROR':
      return {
        ...state,
        changingPostState: 'REJECT_ERROR',
      };
    case 'RESET_DOCUMENT':
      return {
        ...state,
        documentsProcessed: [],
      };

    case 'UPDATE_DOCUMENT_STARTED':
    case 'REJECT_DOCUMENT_STARTED':
    case 'APPROVE_DOCUMENT_STARTED':
      return {
        ...state,
        documentsOnQuery: [...state.documentsOnQuery, action.payload.index],
        fetchingRenterDocuments: 'NO_LOADED',
      };

    case 'REJECT_DOCUMENT_SUCCESS':
    case 'APPROVE_DOCUMENT_SUCCESS':
      // FOR AN RENTER DOCUMENTS
      if (typeof action.payload.renterProfileIndex === 'number') {
        newDocument = action.payload.document.data.attributes;
        newDocument.id = Number(action.payload.document.data.id);
        let renterProfileDocuments = state.renterDocuments[action.payload.renterProfileIndex];
        let newDocumentsRenter = renterProfileDocuments.filter((document: any) => document.id !== action.payload.id);
        newProfileDocuments = [...newDocumentsRenter, newDocument];
        state.renterDocuments[action.payload.renterProfileIndex] = newProfileDocuments;
        return {
          ...state,
          documentsOnQuery: state.documentsOnQuery.filter((docIndex: any) => docIndex !== action.payload.index),
          fetchingRenterDocuments: 'LOADED',
        };
      } else {
        // FOR AN UNIT DOCUMENTS
        let newDocumentsProcessed = state.documentsProcessed.filter(
          (document: any) => document.index !== action.payload.index,
        );

        return {
          ...state,
          documentsOnQuery: state.documentsOnQuery.filter((docIndex: any) => docIndex !== action.payload.index),
          documentsProcessed: [
            ...newDocumentsProcessed,
            {
              index: action.payload.index,
              state: action.payload.document.data.attributes.aasm_state,
              document: action.payload.document,
            },
          ],
        };
      }

    case 'UPDATE_DOCUMENT_SUCCESS':
      newDocument = action.payload.document.data.attributes;
      newDocument.id = Number(action.payload.document.data.id);
      newProfileDocuments = [...state.renterDocuments[action.payload.renterProfileIndex], newDocument];
      state.renterDocuments[action.payload.renterProfileIndex] = newProfileDocuments;
      return {
        ...state,
        documentsOnQuery: state.documentsOnQuery.filter((docIndex: any) => docIndex !== action.payload.index),
        fetchingRenterDocuments: 'LOADED',
      };

    case 'UPDATE_DOCUMENT_ERROR':
    case 'REJECT_DOCUMENT_ERROR':
    case 'APPROVE_DOCUMENT_ERROR':
      return {
        ...state,
        documentsOnQuery: state.documentsOnQuery.filter((docIndex: any) => docIndex !== action.payload.index),
        documentsOnError: [
          ...state.documentsOnError,
          {
            index: action.payload.index,
            error: action.payload.error,
          },
        ],
      };

    case 'RENTER_DOCUMENTS_COUNT_SUC':
      return {
        ...state,
        renterDocumentsCount: action.payload,
        fetchingRenterDocumentsCount: 'LOADED',
      };

    case 'RENTER_DOCUMENTS_COUNT_START':
      return {
        ...state,
        fetchingRenterDocumentsCount: 'NO_LOADED',
      };

    case 'RENTER_DOCUMENTS_COUNT_MODIFIED_REJECTED':
    case 'RENTER_DOCUMENTS_COUNT_MODIFIED_PENDING':
    case 'RENTER_DOCUMENTS_COUNT_MODIFIED_APPROVED':
      newData = createNewRenterDocumentCount(action.payload);
      state.renterDocumentsCount[action.payload.renterProfileIndex][action.payload.documentType] = newData;
      return {
        ...state,
        fetchingRenterDocumentsCount: 'LOADED',
      };

    case 'RENTER_DOCUMENTS_START':
      return {
        ...state,
        fetchingRenterDocuments: 'NO_LOADED',
      };

    case 'RENTER_DOCUMENTS_SUC':
      return {
        ...state,
        renterDocuments: action.payload,
        fetchingRenterDocuments: 'LOADED',
      };

    case 'REJECT_RENTER_STARTED':
    case 'APPROVE_RENTER_STARTED':
      return {
        ...state,
        fetchingState: 'WAIT_STATE_ERROR',
      };
    case 'CLEAR_APPROVE_RENTER':
      return {
        ...state,
        ...initialState,
      };

    case 'REJECT_RENTER_SUCCESS':
    case 'APPROVE_RENTER_SUCCESS':
      return {
        ...state,
        renter_validation: action.payload.renter_validation.data,
        fetchingState: 'SUCCESS_STATE_ERROR',
      };

    case 'REJECT_RENTER_ERROR':
    case 'APPROVE_RENTER_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingState: 'CHANGE_STATE_ERROR',
      };
    case 'CHANGE_UNIT_STARTED':
      return { ...state, fetchingUnit: 'NO_CHANGE' };
    case 'CHANGE_UNIT_SUCCESS':
      let new_unit: any = state.unit;
      new_unit.property_comments = action.payload.property_comments;
      return {
        ...state,
        unit: new_unit,
        fetchingUnit: 'CHANGE_SUCC',
      };

    default:
      return state;
  }
};
