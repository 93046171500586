import {
  getContractHistoryStart,
  getContractHistorySuccess,
  getContractHistoryError,
} from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getContractHistory = (id: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(getContractHistoryStart())
    getData(`contracts/${id}/history`)
      .then((result) => {
        return dispatch(getContractHistorySuccess(result))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getContractHistoryError(e))
      })
  }
}
export default getContractHistory
