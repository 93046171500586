import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import intl from 'react-intl-universal';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { TableBody } from '@material-ui/core';
import { formatCurrencyNumber } from '../../helpers/numberFormat';

/**
 * @typedef NumberedTableProps
 * @property {string} className - Clase aplicada a la div parent de la talba
 * @property {Array<any>} headers - Headers de la tabla, debe tener el formato [{name: '', class: ''},...] corresponde a el nombre y a la clase de cada header
 * @property {Array<any>} rows - Contenido de tabla, debe tener el formato [{label: '', value: }] corresponde al label y al valor
 * @property {boolean} disabled - Indica si los inputs de la tabla son editables o no
 * @property {boolean} noGutters - Indica si la tabla debe tener o no gutters
 * @property {boolean} form - Indica si la tabla esta en un form o no, es decir si los valores serán inputs o no
 * @property {number} total - Valor del total en la tabla, sumas de los campos.
 * @property {boolean} showTotal - Indica si se debe mostrar el total en la tabla.
 * @property {boolean} calculateTotal - Indica si la tabla debe calcular el total cuando hay cambios.
 * @property {(index: number, value: number) => void} onChange - Funcion que se ejecuta al cambiar un valor de un input en la tabla.
 * @property {(id: string, value: number | string) => any} inputValidation - Funcion que realiza validaciones sobre los inputs, debe devolver un objeto de la forma { error: false, helperText: '' }.
 */
interface NumberedTableProps {
    className?: string;
    headers?: Array<any>;
    rows: Array<any>;
    disabled?: boolean;
    form?: boolean;
    noGutters?: boolean;
    withoutBottomBorder?: boolean;
    total?: number;
    showTotal?: boolean;
    calculateTotal?: boolean;
    onChange?: (index: number, value: number) => void;
    onChageTotal?: (value: number) => void;
    inputValidation?: (id: string, value: number | string) => any;
}

/**
 * @function NumberedTable
 * @param {NumberedTableProps} props
 * @returns {JSX.Element}
 * @description Renderiza una tabla numerica, que puede calcular o no la suma total dependiendo de los props que se le pase
 */
const NumberedTable = ({
    className = '',
    headers = [],
    rows,
    disabled,
    withoutBottomBorder,
    noGutters = false,
    form,
    total,
    showTotal = false,
    calculateTotal = false,
    onChange = (index, value) => {},
    onChageTotal,
    inputValidation,
}: NumberedTableProps) => {
    const [rowValues, setRowValues] = useState(rows.map((row) => row.value));
    const [totalValue, setTotalValue] = useState(
        !!total ? total : calculateTotal && rows.length > 0 ? rows.reduce((acc, next) => acc + next.value, 0) : 0,
    );

    useEffect(() => {
        if (!calculateTotal || rowValues.length === 0) return;

        setTotalValue(rowValues.reduce((acc, next) => acc + next));
    }, [rowValues]);

    useEffect(() => {
        setRowValues(rows.map((row) => row.value));
    }, [rows]);

    const withValueLimit = ({ floatValue }: any) => floatValue <= 100000000;

    const handleOnChange = (index: number, value: number) => {
        const newRowValues = [...rowValues];
        newRowValues[index] = value;
        setRowValues(newRowValues);
        onChange(index, value);
        onChageTotal && onChageTotal(totalValue ? totalValue : 0);
    };

    return (
        <div
            className={`numbered-table ${className} ${showTotal ? 'with-total' : 'without-total'} ${
                headers.length === 0 && 'headerless'
            }
      ${withoutBottomBorder && 'without-border-bottom'}`}
        >
            <fieldset disabled={disabled}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((title, index: number) => (
                                <TableCell
                                    key={index}
                                    className={`text-dark font-weight-bold ${title.class} ${noGutters && 'px-0'}`}
                                >
                                    {intl.get(title.name)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, index: number) => {
                            row.validation = !!inputValidation
                                ? inputValidation(row.labelId, row.value)
                                : { error: false, helperText: '' };
                            return (
                                <TableRow key={index}>
                                    <TableCell className={`label-row ${row.labelClass} ${noGutters && 'px-0'}`}>
                                        <div>{row.label}</div>
                                    </TableCell>
                                    <TableCell className={`value-row ${row.valueClass} ${noGutters && 'px-0'}`}>
                                        {form ? (
                                            <NumberFormat
                                                variant='outlined'
                                                helperText={!disabled && row.validation.helperText}
                                                id={row.labelId}
                                                error={!disabled && row.validation.error}
                                                className='w-100 number-input-admin-payments'
                                                disabled={disabled}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                decimalScale={row.decimalScale}
                                                allowNegative={false}
                                                customInput={TextField}
                                                value={row.value ? row.value : '0'}
                                                prefix={'$'}
                                                isAllowed={withValueLimit}
                                                onValueChange={(values) => handleOnChange(index, +values.value)}
                                            />
                                        ) : (
                                            <div className='text-right'>{formatCurrencyNumber(row.value)}</div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {showTotal && (
                            <TableRow className={'table-total'}>
                                <TableCell className={`font-weight-bold text-dark ${noGutters && 'px-0'}`}>
                                    {intl.get('TOTAL')}
                                </TableCell>
                                <TableCell className={`font-weight-bold text-dark text-right ${noGutters && 'px-0'}`}>
                                    <div className='text-right'>{formatCurrencyNumber(totalValue)}</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </fieldset>
        </div>
    );
};

export default NumberedTable;
