export const getChargeLinesStart = () => {
    return {
        type: 'GET_CHARGE_LINES_STARTED',
    };
};

export const getChargeLinesSuc = (data: any) => {
    return {
        type: 'GET_CHARGE_LINES_SUCCESS',
        payload: data,
    };
};

export const getChargeLinesError = (error: any) => {
    return {
        type: 'GET_CHARGE_LINES_ERROR',
        payload: {
            error,
        },
    };
};
