import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import intl from 'react-intl-universal'

/**
 * @typedef CardPriceTableProps
 * @property {string} title - Titulo de la tarjeta
 * @property {string} subtitle - Subtitulo de la tarjeta
 * @property {any} children - Contenido de la tarjeta (tabla)
 * @property {boolean} disabled - Indica si el boton EDIT de la tarjeta esta deshabilitado
 * @property {boolean} isEditing - Indica si la tarjeta esta en modo edicion
 * @property {boolean} canEdit - Indica debe aparecer el boton de edicion
 * @property {boolean} disabledSave - Indica si el boton SAVE de la tarjeta esta deshabilitado
 * @property {function} handleSave - Funcion que se ejecuta al presionar el boton SAVE de la tarjeta
 * @property {(value: boolean) => void} onChangeEditing - Funcion que se ejecuta al cambiar el estado de la tarjeta en modo edicion.
 */
interface CardPriceTableProps {
  title: string
  subtitle: any
  children?: any
  disabled?: boolean
  disabledSave?: boolean
  isEditing?: boolean
  canEdit?: boolean
  handleSave?: () => void
  handleCancel?: () => void
  onChangeEditing?: (value: boolean) => void
}

/**
 * @function CardPriceTable
 * @param {CardPriceTableProps} props
 * @returns {JSX.Element}
 * @description Renderiza una tarjeta con un titulo y un subtitulo y un contenido. La tarjeta puede estar en modo edicion o no además que presenta un header en color magenta Tango.
 */

const CardPriceTable = ({
  title,
  subtitle,
  children,
  disabled,
  disabledSave = true,
  isEditing,
  canEdit,
  onChangeEditing = (condition: any) => {},
  handleSave,
  handleCancel,
}: CardPriceTableProps) => {
  return (
    <Card className='card-price-table'>
      <CardHeader
        className='card-header'
        title={title}
        subheader={subtitle}
      ></CardHeader>
      <CardContent className='card-content px-0'>{children}</CardContent>
      { canEdit && 
        <CardActions className='card-actions pb-4'>
          {isEditing ? (
            <>
              <button className='button-white text-dark' onClick={handleCancel}>
                {intl.get('CANCEL')}
              </button>
              <button
                className='button-primary'
                disabled={disabledSave}
                onClick={handleSave}
              >
                {intl.get('SAVE')}
              </button>
            </>
          ) :  (
            <button
              onClick={() => onChangeEditing(true)}
              disabled={disabled}
              className='button-primary'
            >
              {intl.get('EDIT')}
            </button>
          )}
        </CardActions>
      }
    </Card>
  )
}

export default CardPriceTable
