import React from 'react';
import intl from 'react-intl-universal';
import { PopupWithIcon } from '../../tango-react-base/components';
import { useCurrencyFormater } from '../../tango-react-base/hooks';
import { NoRenewalIcon } from '../../tango-react-base/resources';

/** Here the types of the props for the interface 'ConfirmationModaProps' are defined
 * @typedef ConfirmationModaTypes
 * @type {(function|boolean|string|number)}
 * @property {boolean} openConfirmationModal - is a boolean.
 * @property {function} handleCloseConfirmation - is a function.
 * @property {function} handleConfirm - is a function.
 * @property {string} optionSeleted - is a string.
 * @property {string} proposedPrice - is a string.
 */

interface ConfirmationModalProps {
  handleCloseConfirmation: () => void;
  openConfirmationModal: boolean;
  handleConfirm: () => void;
  optionSeleted: string;
  proposedPrice: string;
  currencyIsoValue: string;
}

/**
 * ConfirmationModal is a functional component that renders a confirmation modal
 *@function
 *@param {ConfirmationModalTypes} proposedPrice - the price proposed, it will be showed
 *@param {ConfirmationModalTypes} handleConfirm - to submit the form data
 *@param {ConfirmationModalTypes} optionSeleted - to know what is the text to be showed, depending on de option before seleted
 *@param {ConfirmationModalTypes} openConfirmationModal - state that validates the rendering of the modal
 *@param {ConfirmationModalTypes} handleCloseConfirmation - function that closes the modal
 *@returns {(React.FunctionComponent)} Returns a react component with a functional component
 */

const ConfirmationModal = ({
  handleCloseConfirmation,
  openConfirmationModal,
  handleConfirm,
  optionSeleted,
  proposedPrice,
  currencyIsoValue,
}: ConfirmationModalProps) => {
  const [formatCurrency] = useCurrencyFormater({
    currency: currencyIsoValue,
    proposedPrice: proposedPrice,
  });

  return (
    <>
      <PopupWithIcon
        title={optionSeleted === 'reject' ? 'NO_RENEWAL' : 'PROPOSE_NEW_VALUE'}
        open={openConfirmationModal}
        handleClose={handleCloseConfirmation}
        icon={optionSeleted === 'reject' ? NoRenewalIcon : null}
        lineUp
      >
        <>
          {optionSeleted === 'newPrice' && (
            <>
              <p>{intl.get('NEW_LEASE_PROPOSAL')}</p>
              <p className='text-secondary-big'>{formatCurrency}</p>
            </>
          )}
          <p className='font-weight-bold mt-4'>
            {optionSeleted === 'reject'
              ? intl.get('ARE_YOU_SURE_TO_REJECT_ADMIN')
              : intl.get('ARE_YOU_SURE_TO_PROPOSE_ADMIN')}
          </p>
          <div className='mt-4'>
            <button className='button-tertiary col-md-6 mr-3 mb-2' onClick={handleCloseConfirmation}>
              {intl.get('CANCEL')}
            </button>
            <button className='button-primary col-md-5' onClick={() => handleConfirm()}>
              {intl.get('SEND')}
            </button>
          </div>
        </>
      </PopupWithIcon>
    </>
  );
};
export default ConfirmationModal;
