const initialState: any = {
  fetch: 'NO_FETCH',
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'REJECT_ANNEX_STARTED':
    case 'APPROVE_ANNEX_STARTED':
      return {
        fetch: 'FETCHING',
      };
    case 'REJECT_ANNEX_SUCCESS':
    case 'APPROVE_ANNEX_SUCCESS':
      return {
        fetch: 'FETCHED',
      };
    case 'REJECT_ANNEX_ERROR':
    case 'APPROVE_ANNEX_ERROR':
      return {
        error: action.payload.error,
        fetch: 'ERROR',
      };
    default:
      return state;
  }
};
