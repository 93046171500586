export const getPaidOrDueStart = () => {
    return {
        type: 'GET_PAID_DUE_STARTED',
    };
};

export const getPaidOrDueSuccess = (result: any) => {
    return {
        type: 'GET_PAID_DUE_SUCCESS',
        payload: { result },
    };
};
export const getPaidOrDuenError = (error: any) => {
    return {
        type: 'GET_PAID_DUE_ERROR',
        payload: {
            error,
        },
    };
};
