import React, { useEffect } from 'react'
import { ButtonsHeader } from '../../tango-react-base/components'
import { setTemplateChild } from '../../tango-react-base/reduxActions'
import { redirect_tabs } from './buttonOptions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import intl from 'react-intl-universal'
import { dowloadLogo } from '../../tango-react-base/resources/index'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import CircularLoading from '../../tango-react-base/components/circularLoading'
import getSalesNotes from '../../redux/actions/getSalesNotes'
import { useHistory } from 'react-router-dom'

interface SalesNotesProps {
  onSetTemplateChild: (child: Object) => void
  onGetSalesNotes: () => void
  notes: any
  fetchingNotes: string
}
const moment = require('moment')

const SalesNotes = ({ onSetTemplateChild, onGetSalesNotes, notes, fetchingNotes }: SalesNotesProps) => {
  const history = useHistory()
  useEffect(() => {
    if (!sessionStorage.getItem('authorization'))
      return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PAYMENTS')}</h1>
          <p className="px-0 d-none d-md-block">
            <br />
          </p>
          <ButtonsHeader
            imAdmin={true}
            buttonShowsArray={redirect_tabs}
            buttonSelected={'sales notes'}
          />
        </>,
      )
      onGetSalesNotes()
    }
  }, [onSetTemplateChild, onGetSalesNotes])

  const tableData = () => {
    return notes.length > 0 ? (
      notes && (
        notes.map((note: any) => {
          const attributes = note.attributes
          const { user, payment_date, note_url_pdf, post } = attributes
          const postId = post.id
          return (
            <TableRow key={note.id}>
              <TableCell className="table-content">{user.name}</TableCell>
              <TableCell className="table-content">
                <span
                  className="select-span"
                  onClick={() => history.replace(`../posts/${postId}`)}
                >
                  {post.unit_address}
                </span></TableCell>
              <TableCell className="table-content">
                {payment_date ? moment(payment_date).format('DD/MM/YYYY') : intl.get('NO_PAYMENTS')}
              </TableCell>
              <TableCell className="table-content text-center">
                <a href={note_url_pdf} target='_blank' rel="noopener noreferrer" download={note_url_pdf}>
                  <div className="tooltip-delete-logo">
                    <Tooltip title={intl.get('DOWNLOAD_DOCUMENT')}>
                      <img
                        src={dowloadLogo}
                        alt="dowloadLogo"
                        className="upload-logo"
                      />
                    </Tooltip>
                  </div>
                </a>
              </TableCell>
            </TableRow>
          )
        }))
    ) : (<TableRow>
      <TableCell colSpan={4} className="table-content text-center">
        {intl.get('NO_NOTES')}
      </TableCell>
    </TableRow>)
  }
  return (
    fetchingNotes === 'FETCHED_NOTES' ? (
      <div className="container-home">
        <div className="template-line mt-5" />
        <h2 className="text-left">{intl.get('SALES_NOTES')}</h2>
        <TableContainer className="table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-header table-border-left">
                  {intl.get('USER')}
                </TableCell>
                <TableCell className="table-header">{intl.get('PROPERTY')}</TableCell>
                <TableCell className="table-header">{intl.get('PAYMENT_DATE')}</TableCell>
                <TableCell className="table-header text-center table-border-right">
                  {intl.get('SALES_NOTE')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData()}
            </TableBody>
          </Table>
        </TableContainer>
      </div >) : (<CircularLoading />)
  )
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetSalesNotes: () => {
      dispatch(getSalesNotes())
    },
  }
}
const mapStateToProps = (state: any) => {
  const { notes, fetchingNotes } = state.salesNotes
  return {
    notes,
    fetchingNotes,
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(SalesNotes)
