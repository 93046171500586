const initialState = {
  status: 'EMPTY',
  data: {},
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'CONFIRM_EARLY_REQUISITION_STARTED':
      return { ...state, status: 'LOADING' }
    case 'CONFIRM_EARLY_REQUISITION_SUCCESS':
      return { ...state, status: 'LOADED', data: action.payload }
    case 'CONFIRM_EARLY_REQUISITION_ERROR':
      return { ...state, status: 'ERROR' }
    default:
      return state
  }
}

export default reducer
