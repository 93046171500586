export const postClosureLeaseConfirmationStart = () => {
    return {
        type: 'CLOSURE_LEASE_CONFIRMATION_STARTED',
    };
};

export const postClosureLeaseConfirmationSuccess = (result: any) => {
    return {
        type: 'CLOSURE_LEASE_CONFIRMATION_SUCCESS',
        payload: { result },
    };
};
export const postClosureLeaseConfirmationError = (error: any) => {
    return {
        type: 'CLOSURE_LEASE_CONFIRMATION_ERROR',
        payload: {
            error,
        },
    };
};
