const initialState: any = {
  fetchAssingPromotion: 'NO_FETCH'
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'ASSIGN_PROMOTION_STARTED':
      return {
        ...state,
        fetchAssingPromotion: 'FETCHING',
      }
    case 'ASSIGN_PROMOTION_SUCCESS':
      return {
        ...state,
        fetchAssingPromotion: 'FETCHED',
      }
    case 'ASSIGN_PROMOTION_ERROR':
      return {
        ...state,
        fetchAssingPromotion: 'ERROR',
      }
    default:
      return state
  }
}
