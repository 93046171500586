import { getAdminContractDetailStart, getAdminContractDetailSuccess, getAdminContractDetailError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
const getAdminContractDetail = (id: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getAdminContractDetailStart());
        getData(`admin_contracts/${id}/documents_summary`)
            .then((result) => {
                return dispatch(getAdminContractDetailSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getAdminContractDetailError(e));
            });
    };
};
export default getAdminContractDetail;
