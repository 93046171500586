import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import getPromotions from '../../redux/actions/getPromotions';
import { PopupWithIcon, PromotionCard } from '../../tango-react-base/components';
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize';
import { assignPromotion, getPost, setTemplateChild } from '../../tango-react-base/reduxActions';

/**
 * Here the type of each promotion for the documentation
 * @typedef PromotionProps
 * @type {(function|string|any)}
 * @property {function} onGetPost - is a function that get the post.
 * @property {string} fetchingPosts - is a String.
 * @property {any} error - return errors.
 * @property {array} promotions - is an array with promotions.
 * @property {function} onGetPromotions -is
 * @property {string} fetchingPromotions -is a function that get the promotions
 */
interface PromotionProps {
  onGetPost: (postId: number) => void;
  post: any;
  fetchingPost: string;
  error: any;
  promotions: any;
  onGetPromotions: (registerType: string) => void;
  fetchingPromotions: string;
  fetchAssingPromotion: string;
  onAssignPromotion: (unit_version_id: number, promotion_id: number) => void;
  onSetTemplateChild: (child: Object) => void;
}

/**
 * Promotions component functions
 * @param PromotionProps
 * @returns jsx.function
 * @describe show two cards, promotions card, and my promotion if the
 *           post have a promotion assign
 */
const Promotions = ({
  onGetPost,
  fetchingPost,
  post,
  onGetPromotions,
  error,
  promotions,
  fetchingPromotions,
  onAssignPromotion,
  fetchAssingPromotion,
  onSetTemplateChild,
}: PromotionProps) => {
  const { id }: any = useParams();

  const [openPopup, setOpenPopup] = useState(false);

  const [selectedPromotionId, setSelectedPromotionId] = useState<number | string>(0);
  const unit_version_id = post?.attributes?.unit?.id;

  useEffect(() => {
    onGetPost(id);
    onSetTemplateChild(
      <>
        <h1>{intl.get('PROMOTIONS')}</h1>
      </>,
    );
  }, []);

  useEffect(() => {
    if (fetchingPost !== 'LOADED') return;
    if (promotions?.length) return;
    onGetPromotions(post.attributes.unit.register_type);
  }, [post]);

  useEffect(() => {
    if (fetchAssingPromotion === 'FETCHED') {
      window.location.reload();
    }
  }, [fetchAssingPromotion]);

  let selectedPromotions = [];

  let have_proposed_accepted = post?.attributes?.have_proposed_acepted;
  let myPromotion = post?.attributes?.promotion?.data; //promotions[0]
  const activePromotions = promotions.filter((promo: any) => myPromotion?.id !== promo.id);

  useEffect(() => {
    if (promotions && !selectedPromotionId) {
      setSelectedPromotionId(activePromotions[0]?.id);
    }
  }, [promotions, selectedPromotionId]);

  if (promotions && selectedPromotionId) {
    selectedPromotions = promotions.filter((promo: any) => promo.id === selectedPromotionId);
  }
  const selectedPromotion = selectedPromotions && selectedPromotions.length > 0 && selectedPromotions[0];

  const size = useWindowSize();

  return (
    <>
      <div className={`container-home mt-5 px-2 pb-5 ${size.width > 348 && 'px-5'}`}>
        <div className='template-line' />
        <h2 className='new-subtitle'>{intl.get('CURRENT_PROMOTIONS') || 'CURRENT PROMOTION'}</h2>
        {myPromotion ? (
          <PromotionCard promotion={myPromotion} />
        ) : (
          <p className='empty-list'>{intl.get('NO_CURRENT_PROMOTION') || intl.get('NO_ACTUAL_PROMOTION')}</p>
        )}

        {have_proposed_accepted && (
          <>
            {activePromotions && activePromotions.length > 0 && (
              <h2 className='new-subtitle pt-5'>{intl.get('ACTIVE_PROMOTIONS') || 'ACTIVE PROMOTIONS'}</h2>
            )}

            {fetchingPromotions !== 'LOADED' && <p className='empty-list'>{intl.get('NO_AVAILABLE_PROMOTIONS')}</p>}
            {fetchingPromotions === 'LOADED' && selectedPromotion && (
              <select
                className='select-promotions-style'
                value={selectedPromotionId}
                onChange={(e) => setSelectedPromotionId(e.target.value)}
              >
                {activePromotions.map((promo: any) => (
                  <option value={promo.id}>{promo.attributes.name}</option>
                ))}
              </select>
            )}
            {!!selectedPromotion && (
              <PromotionCard
                promotion={activePromotions[activePromotions.findIndex((el: any) => el.id === selectedPromotionId)]}
              />
            )}
            <div className='promotion-button-container pt-3'>
              {selectedPromotion && (
                <button
                  className='button-secondary'
                  onClick={() => {
                    setOpenPopup(true);
                  }}
                >
                  {intl.get('BUTTON_CHANGE_PROMOTION')}
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <PopupWithIcon
        open={openPopup}
        title='CHANGE_PROMOTION'
        lineUp
        classNameTitle='font-weight-bold'
        handleClose={() => {
          setOpenPopup(false);
        }}
        className='pb-7 promotions-popup'
      >
        <p>
          {' '}
          {intl.getHTML('CONFIRM_CHANGE_PROMOTION', { promotion_name: `"${selectedPromotion?.attributes?.name}"` })}
        </p>
        <div className='promotion-popup '>
          <div>
            <h3 className='font-weight-bold d-flex justify-content-start'>{selectedPromotion?.attributes?.name}</h3>
            <p className='promotion-description d-flex justify-content-start'>
              {selectedPromotion?.attributes?.description}
            </p>
          </div>
          <hr className='border-color black' />
          <div className='row'>
            <h3 className='col font-weight-bold d-flex justify-content-start p-0'>{intl.get('KIND')}</h3>
            <span className='col d-flex justify-content-end'>
              {selectedPromotion?.attributes?.kind ? intl.get(selectedPromotion?.attributes?.kind.toUpperCase()) : ''}
            </span>
          </div>
          <hr className='border-color black' />
          <div className='row'>
            <h3 className='col font-weight-bold d-flex justify-content-start p-0'>{intl.get('STATE')}</h3>
            <span className='col d-flex justify-content-end'>
              {selectedPromotion?.attributes?.state_to_show
                ? intl.get('PROMOTION_' + selectedPromotion?.attributes?.state_to_show.toUpperCase())
                : ''}
            </span>
          </div>
          <div className='row'>
            <span className='col-12 col-sm-6 title-red d-flex justify-content-start p-0'>
              {intl.get('PROMOTION_START')}{' '}
              <span className='ml-2 text-dark'>{selectedPromotion?.attributes?.starts_at}</span>
            </span>
            <span className='col-12 col-sm-6 title-red d-flex justify-content-start justify-content-sm-end p-0'>
              {intl.get('PROMOTION_END')}{' '}
              <span className='ml-2 text-dark'>{selectedPromotion?.attributes?.ends_at}</span>
            </span>
          </div>
          {(selectedPromotion?.attributes?.administration_fee_new_discount ||
            selectedPromotion?.attributes?.collection_fee_new_discount_owner ||
            selectedPromotion?.attributes?.collection_fee_new_discount_renter) && (
            <>
              <hr className='border-color black' />
              <div className='row'>
                <span className='col font-weight-bold d-flex justify-content-start p-0'>Plan</span>
                <span className='col font-weight-bold d-flex justify-content-end'>{intl.get('REGISTER_TYPE_NEW')}</span>
              </div>
              <div className='row'>
                {selectedPromotion?.attributes?.administration_fee_new_discount && (
                  <span className='col-9 col-md-6 title-red d-flex justify-content-start'>
                    {intl.get('ADMINISTRATION_FEE_NEW_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.administration_fee_new_discount && (
                  <span className='col-3 col-md-6 d-flex justify-content-end'>
                    {selectedPromotion?.attributes?.administration_fee_new_discount}%
                  </span>
                )}
              </div>
              <div className='row pt-3'>
                {selectedPromotion?.attributes?.collection_fee_new_discount_owner && (
                  <span className='col title-red d-flex justify-content-start'>
                    {intl.get('OWNER_BROKERAGE_FEE_NEW_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.collection_fee_new_discount_owner && (
                  <span className='col d-flex justify-content-end'>
                    {' '}
                    {selectedPromotion?.attributes?.collection_fee_brokerage_discount_owner}%
                  </span>
                )}
              </div>
              <div className='row pt-3'>
                {selectedPromotion?.attributes?.collection_fee_new_discount_renter && (
                  <span className='col title-red d-flex justify-content-start'>
                    {intl.get('RENTER_BROKERAGE_FEE_NEW_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.collection_fee_new_discount_renter && (
                  <span className='col d-flex justify-content-end'>
                    {' '}
                    {selectedPromotion?.attributes?.collection_fee_brokerage_discount_renter}%
                  </span>
                )}
              </div>
            </>
          )}

          {selectedPromotion?.attributes?.administration_fee_registered_discount && (
            <>
              <hr className='border-color black' />
              <div className='row'>
                <span className='col font-weight-bold d-flex justify-content-start p-0'>Plan</span>
                <span className='col font-weight-bold d-flex justify-content-end'>
                  {intl.get('REGISTER_TYPE_REGISTERED')}
                </span>
              </div>
              <div className='row pt-3'>
                {selectedPromotion?.attributes?.administration_fee_registered_discount && (
                  <span className='col title-red d-flex justify-content-start'>
                    {intl.get('ADMINISTRATION_FEE_REGISTERED_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.administration_fee_registered_discount && (
                  <span className='col d-flex justify-content-end'>
                    {' '}
                    {selectedPromotion?.attributes?.administration_fee_registered_discount}%
                  </span>
                )}
              </div>
            </>
          )}
          {(selectedPromotion?.attributes?.collection_fee_brokerage_discount_owner ||
            selectedPromotion?.attributes?.collection_fee_brokerage_discount_renter) && (
            <>
              <hr className='border-color black' />
              <div className='row'>
                <span className='col font-weight-bold d-flex justify-content-start p-0'>Plan</span>
                <span className='col font-weight-bold d-flex justify-content-end'>
                  {intl.get('REGISTER_TYPE_BROKERAGE')}
                </span>
              </div>
              <div className='row pt-3'>
                {selectedPromotion?.attributes?.collection_fee_brokerage_discount_owner && (
                  <span className='col title-red d-flex justify-content-start'>
                    {intl.get('OWNER_BROKERAGE_FEE_BROKERAGE_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.collection_fee_brokerage_discount_owner && (
                  <span className='col d-flex justify-content-end'>
                    {' '}
                    {selectedPromotion?.attributes?.collection_fee_brokerage_discount_owner}%
                  </span>
                )}
              </div>
              <div className='row pt-3'>
                {selectedPromotion?.attributes?.collection_fee_brokerage_discount_renter && (
                  <span className='col title-red d-flex justify-content-start'>
                    {intl.get('RENTER_BROKERAGE_FEE_BROKERAGE_DISCOUNT')}
                  </span>
                )}
                {selectedPromotion?.attributes?.collection_fee_brokerage_discount_renter && (
                  <span className='col d-flex justify-content-end'>
                    {' '}
                    {selectedPromotion?.attributes?.collection_fee_brokerage_discount_renter}%
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        <div className='promotion-buttons-container pt-3'>
          <button
            className='mx-1 button-five px-4'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            {intl.get('BUTTON_CANCEL_PROMOTION')}
          </button>
          <button
            className='mx-1 button-primary px-4'
            onClick={() => {
              onAssignPromotion(selectedPromotion.id, unit_version_id);
            }}
          >
            {intl.get('BUTTON_ASSIGN_PROMOTION')}
          </button>
        </div>
      </PopupWithIcon>
    </>
  );
};

/**
 *  Redux:GetPost, GetPromotion
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPost: (postId: number) => {
      dispatch(getPost(postId));
    },
    onGetPromotions: (registerType?: string) => {
      dispatch(getPromotions(registerType));
    },
    onAssignPromotion: (promotion_id: number, unit_version_id: number) => {
      dispatch(assignPromotion(unit_version_id, promotion_id));
    },
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
  };
};

const mapStateToProps = (state: any) => {
  const { post, fetchingPost, error } = state.publish;
  const { promotions, fetchingPromotions } = state.promotions;
  const { fetchAssingPromotion } = state.assignPromotion;
  return {
    post,
    fetchingPost,
    error,
    promotions,
    fetchingPromotions,
    fetchAssingPromotion,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
