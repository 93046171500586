const initialState = {
  renter: [],
  fetchingData: 'NO_RENTER',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_PAID_DUE_STARTED':
      return {
        ...state,
        fetchingData: 'LOADING',
      }
    case 'GET_PAID_DUE_SUCCESS':
      return {
        ...state,
        fetchingData: 'LOADED',
        posts: action.payload.result.data,
      }
    case 'GET_PAID_DUE_ERROR':
      return {
        ...state,
        fetchingData: 'ERROR',
      }
    default:
      return state
  }
}
