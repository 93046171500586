export const approveDocumentStart = (index: string) => {
  return {
    type: 'APPROVE_DOCUMENT_STARTED',
    payload: { index },
  }
}

export const approveDocumentSuc = (document: any, id: number, index: string, renterProfileIndex: number) => {
  return {
    type: 'APPROVE_DOCUMENT_SUCCESS',
    payload: {
      document,
      id,
      index,
      renterProfileIndex
    },
  }
}

export const approveDocumentError = (error: any, index: string) => {
  return {
    type: 'APPROVE_DOCUMENT_ERROR',
    payload: {
      error,
      index,
    },
  }
}