export const getRequisitionsStart = (type: string) => {
    return {
        type: 'GET_REQUISITIONS_STARTED',
        payload: { type },
    };
};

export const getRequisitionsSuccess = (result: any, type: string) => {
    return {
        type: 'GET_REQUISITIONS_SUCCESS',
        payload: { result, type },
    };
};
export const getRequisitionsError = (error: any, type: string) => {
    return {
        type: 'GET_REQUISITIONS_ERROR',
        payload: {
            error,
            type,
        },
    };
};
