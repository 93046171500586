import { getPromotionsStart, getPromotionsSuccess, getPromotionsError } from './types'
import { getData } from '../../../tango-react-base/helpers/apiConnections'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
import { toast } from 'react-toastify'

const getPromotions = (registerType?: string ) => {
  return (dispatch: any, getState: any) => {
    const params = { 'register_type': registerType }
    dispatch(getPromotionsStart())
    getData('promotions', params)
      .then((result) => dispatch(getPromotionsSuccess(result)))
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getPromotionsError(e))
      })
  }
}
export default getPromotions
