import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { approvePropertyDocument, disableDocument, enableDocument, rejectPropertyDocument } from '../../redux/actions';
import { PopupWithIcon, RejectInventoryModal } from '../../tango-react-base/components';
import Glossary from '../../tango-react-base/components/glossary';
import UploadDocumentForm from '../../tango-react-base/components/uploadDocuments';
import { getParameterByName } from '../../tango-react-base/helpers/getParamsUrl';
import STATE from '../../tango-react-base/helpers/glossaryItems/index';
import {
  approveAnnex,
  getDatasContracts,
  getLeaseDocuments,
  getPropertyDocuments,
  rejectAnnex,
  setTemplateChild,
  uploadLeaseDocument,
} from '../../tango-react-base/reduxActions';
import DocumentsTable from '../../tango-react-base/tables';

/**
 * Here it is defined the type, this prop is similar to 'DocumentsProps' but 'DocumentsPropsTypes' is for the documentation
 * @typedef DocumentsPropsTypes
 * @type {(function|string)}
 * @property {function} onSaveDocument - is a function.
 * @property {function} onSetTemplateChild - is a function.
 * @property {function} onGetDatasContracts - is a function.
 * @property {string} fetchDatasContracts - is an string.
 * @property {array} datasContracts - is an array.
 */

/**
 * Documents is a functional component
 *@function
 *@param {DocumentsPropsTypes}  onSaveDocument - upload or save the lease property document.
 *@param {DocumentsPropsTypes}  onSetTemplateChild - returns a template
 *@param {DocumentsPropsTypes}  onGetDatasContracts - calls the endpoint to obtain the information of the contracts
 *@param {DocumentsPropsTypes}  fetchDocument - returns status of the fetch
 *@param {DocumentsPropsTypes}  datasContracts - where the result of the call is stored

 * @returns {(ReactComponent)} Returns a react component with a functional component
 */

type DocumentsProps = {
  fetchDocument: string;
  onSetTemplateChild: (child: Object) => void;
  onSaveDocument: (id: number, values: Object) => void;
  onEnableDocument: (id: any, rentId: any, doc_type: any) => void;
  onDisableDocument: (id: number, rentId: any) => void;
  onGetLeaseDocuments: (id: any) => void;
  onGetDatasContracts: (id: any) => void;
  onGetPropertyDocuments: (id: number) => void;
  propertyDocuments: any;
  fetchPropertyDocuments: string;
  leaseDocuments: any;
  fetchLeaseDocuments: string;
  datasContracts: any;
  fetchDatasContracts: string;
  onRejectDocument: (id: number, unitId: number) => void;
  onApproveDocument: (id: number, unitId: number) => void;
  onRejectAnnex: (id: string, value: string) => void;
  onApproveAnnex: (id: string) => void;
  deliverVoucherFetch: string;
};

const Documents = ({
  onSetTemplateChild,
  onSaveDocument,
  fetchDocument,
  datasContracts,
  fetchDatasContracts,
  onEnableDocument,
  onDisableDocument,
  onGetLeaseDocuments,
  onGetDatasContracts,
  onGetPropertyDocuments,
  propertyDocuments,
  fetchPropertyDocuments,
  leaseDocuments,
  fetchLeaseDocuments,
  onRejectDocument,
  onApproveDocument,
  onApproveAnnex,
  onRejectAnnex,
  deliverVoucherFetch,
}: DocumentsProps) => {
  const history = useHistory();
  let location = useLocation();
  const { id }: any = useParams();
  const [positiveAction, setPositiveAction] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [negativeAction, setNegativeAction] = useState(false);
  let dataLeaseDocuments = leaseDocuments;
  let dataPropertyDocuments = propertyDocuments;
  const documentId = sessionStorage.getItem('documentId');
  const documentType = sessionStorage.getItem('documentType');
  const contractId = sessionStorage.getItem('contractId') || 0;
  const rentId = getParameterByName('rentId', location?.search);

  /**
  These are the titles for the buttons in lease documents table
  */
  const buttonsLeaseTitles = [
    {
      titleButton: 'ENABLE',
      titleTooltip: 'ENABLE_DOCUMENT',
      action: (documentId: number, documentType: string, uploaderKind: string, contractId: any) => {
        if (uploaderKind === 'tanguero' && documentType === 'delivery_voucher') {
          onApproveAnnex(contractId);
        } else {
          sessionStorage.setItem('documentId', `${documentId}`);
          sessionStorage.setItem('documentType', `${documentType}`);
          setPositiveAction(true);
        }
      },
    },
    {
      titleButton: 'DISABLE',
      titleTooltip: 'DISABLE_DOCUMENT',
      action: (documentId: number, documentType: string, uploaderKind: string, contractId: any) => {
        if (uploaderKind === 'tanguero' && documentType === 'delivery_voucher') {
          sessionStorage.setItem('contractId', `${contractId}`);
          setOpenRejectModal(true);
        } else {
          setNegativeAction(true);
          // TODO: disabling document was not part of the scope of sprint 1A
          // This feature should be developed soon
          //onDisableDocument(documentId, id)
        }
      },
    },
  ];
  /**
  These are the titles for the buttons in property documents table
  */
  const buttonsPropertyTitles = [
    {
      titleButton: 'APPROVE',
      titleTooltip: 'APPROVE_DOCUMENT',
      action: (documentId: number) => {
        onApproveDocument(documentId, id);
      },
    },
    {
      titleButton: 'REJECT',
      titleTooltip: 'REJECT_DOCUMENT',
      action: (documentId: number) => {
        onRejectDocument(documentId, id);
      },
    },
  ];
  /**
  These are the columns for the table lease header
  */
  const titlesHeaderLeaseDocuments = [
    { name: 'NAME' },
    { name: 'STATE' },
    { name: 'DOCUMENT_TYPE' },
    { name: 'CREATED_DATE' },
    { name: 'CREATED_BY' },
    { name: 'UPDATED_DATE' },
    { name: 'UPDATED_BY' },
    { name: '' },
  ];

  /**
  These are the columns for the table property header
  */
  const titlesHeaderPropertyDocuments = [
    { name: 'NAME' },
    { name: 'STATE' },
    { name: 'DOCUMENT_TYPE' },
    { name: 'UPDATED_DATE' },
    { name: 'UPDATED_BY' },
    { name: '' },
  ];
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login');
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('DOCUMENTS')}</h1>
        </>,
      );
    }
    onGetPropertyDocuments(id);
    if (rentId) {
      onGetLeaseDocuments(rentId);
      onGetDatasContracts(rentId);
    }
  }, [onSetTemplateChild, history, onGetPropertyDocuments, id, onGetLeaseDocuments, onGetDatasContracts, rentId, onSaveDocument]);
  
  useEffect(() => {
    if (rentId) {
      if (fetchDocument === 'FETCHED' || deliverVoucherFetch === 'FETCHED') {
        onGetLeaseDocuments(rentId);
        onGetDatasContracts(rentId);
      }
    }
  }, [fetchDocument, onGetLeaseDocuments, rentId, deliverVoucherFetch]);

  const saveDocument = (id: any) => (values: any) => {
    onSaveDocument(id, values);
  };
  /** handleClose: This function updates the openRejectModal state
   * @function
   */
  const handleClose = () => {
    setOpenRejectModal(!openRejectModal);
  };

  /**
   * rejectInventory: This function receives two arguments: id and values
   * Its main purpose is to send a PUT request with the contract id and rejection comments (values) so as to update the inventory status
   * When triggered it also closes RejectInventoryModal component and calls the onGetcontract action to update the state
   * @function
   */
  const rejectInventory = (id: any) => (values: any) => {
    onRejectAnnex(id, values);
    handleClose();
    values.comments = '';
    sessionStorage.removeItem('contractId');
  };

  /**
   * ConfirmationModal is a functional component which allows the admin to confirm the enabling process of a lease document
   *@function
   * @returns {(ReactComponent)} Returns a react component with a functional component
   */
  const ConfirmationModal = () => {
    return (
      <PopupWithIcon
        title=''
        open={positiveAction}
        handleClose={() => {
          setPositiveAction(false);
        }}
      >
        <div className='col-12'>
          <p className='text-left'>{intl.get('ENABLING_DOC_CONFIRMATION')}</p>
        </div>
        <button
          className='button-primary col-md-4'
          onClick={() => {
            onEnableDocument(documentId, rentId, documentType);
            sessionStorage.removeItem('documentId');
            sessionStorage.removeItem('documentType');
            setPositiveAction(false);
          }}
        >
          {intl.get('ENABLE')}
        </button>
        <div className='text-center col-12 mt-2'>
          <p
            className='underline-link'
            onClick={() => {
              setPositiveAction(false);
            }}
          >
            {intl.get('BACK')}
          </p>
        </div>
      </PopupWithIcon>
    );
  };
  return (
    <div className='contracts-container my-4'>
      {fetchDatasContracts === 'FETCHED' && (
        <UploadDocumentForm
          fetchDocument={fetchDocument}
          onSubmit={saveDocument(rentId)}
          datasContracts={datasContracts}
        />
      )}
      <>
        {fetchLeaseDocuments === 'FETCHED' ? (
          <div className='my-4'>
            <DocumentsTable
              title='LEASE_DOCUMENTS_TITLE'
              listHeader={titlesHeaderLeaseDocuments}
              listBody={dataLeaseDocuments}
              documentUrlAttr='file_url'
              stateToActionDisplay='pending'
              buttonsTitles={buttonsLeaseTitles}
            />
          </div>
        ) : null}
      </>
      <div className='my-4'>
        <DocumentsTable
          title='PROPERTY_DOCUMENTS_TITLE'
          listHeader={titlesHeaderPropertyDocuments}
          listBody={dataPropertyDocuments}
          documentUrlAttr='file_url'
          stateToActionDisplay='pending'
          buttonsTitles={buttonsPropertyTitles}
        />
      </div>
      <ConfirmationModal />
      <RejectInventoryModal
        openRejectModel={openRejectModal}
        handleClose={handleClose}
        onSubmit={rejectInventory(contractId)}
      />
      <Glossary items={STATE['DOCUMENT_STATE']}></Glossary>
    </div>
  );
};

/**
 *  * It's used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short
 * @typedef  MapToProps
 * @type {(function|object)}
 * @property {object} state - is an Object.
 * @property {function} dispatch - is a Function. */

/**
 *@function
 *@param {MapToProps}  state - here the redux data is obtained.
 */

const mapStateToProps = (state: any) => {
  const { fetchDocument } = state.uploadLeaseDocument;
  const { propertyDocuments, fetchPropertyDocuments } = state.propertyDocuments;
  const { leaseDocuments, fetchLeaseDocuments } = state.leaseDocuments;
  const { datasContracts, fetchDatasContracts } = state.datasContracts;
  const { fetch: deliverVoucherFetch } = state.annexReducer;

  return {
    fetchDocument,
    propertyDocuments,
    fetchPropertyDocuments,
    leaseDocuments,
    fetchLeaseDocuments,
    deliverVoucherFetch,
    datasContracts,
    fetchDatasContracts,
  };
};

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an action to change the state.
 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child));
    },
    onSaveDocument: (id: number, values: any) => {
      dispatch(uploadLeaseDocument(id, values));
    },
    onGetPropertyDocuments: (id: number) => {
      dispatch(getPropertyDocuments(id));
    },
    onGetLeaseDocuments: (id: any) => {
      dispatch(getLeaseDocuments(id));
    },
    onGetDatasContracts: (id: any) => {
      dispatch(getDatasContracts(id));
    },
    onEnableDocument: (id: number, rentId: any, doc_type: any) => {
      dispatch(enableDocument(id, rentId, doc_type));
    },
    onDisableDocument: (id: number, rentId: any) => {
      dispatch(disableDocument(id, rentId));
    },
    onApproveDocument: (id: number, unitId: number) => {
      dispatch(approvePropertyDocument(id, unitId));
    },
    onRejectDocument: (id: number, unitId: number) => {
      dispatch(rejectPropertyDocument(id, unitId));
    },
    onApproveAnnex: (id: string) => {
      return dispatch(approveAnnex(id));
    },
    onRejectAnnex: (id: string, value: string) => {
      return dispatch(rejectAnnex(id, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
