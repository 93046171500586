import { getPaymentsProvidersStart, getPaymentsProvidersSuccess, getPaymentsProvidersError } from './types';
import { getDataGeneral } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getPaymentsProviders = (all_payments?: boolean) => {
    return (dispatch: any) => {
        dispatch(getPaymentsProvidersStart());
        getDataGeneral(`payment_method/payments/providers_with_methods${all_payments ? '' : '?first_payment=true'}`)
            .then((result) => {
                dispatch(getPaymentsProvidersSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPaymentsProvidersError(e));
            });
    };
};
export default getPaymentsProviders;
