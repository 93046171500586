const initialState: any = {
  status: '',
  closureLeaseData: {},
}
export default (state = initialState, action: any) => {

  switch (action.type) {
    case 'GET_CLOSURE_LEASE_DATA_STARTED':
      return {
        ...state,
        status: 'STARTED',
      }
    case 'GET_CLOSURE_LEASE_DATA_SUCCESS':      
      return {
        ...state,
        status: 'SUCCESS',
        closureLeaseData: action.payload.result.data.attributes,
      }
    case 'GET_CLOSURE_LEASE_DATA_ERROR':
      return {
        ...state,
        error: action.payload.error,
        status: 'ERROR',
      }
    default:
      return state
  }
}
