import React, { useState } from 'react';
import intl from 'react-intl-universal';
import UnpublishPostsModal from './unpublishPostModal';
interface PostCardAdminProps {
  short_address: string;
  unit_type: string;
  children: React.ReactNode;
  aasm_state?: string;
  commune: string;
  postId: number | 0;
  docState?: string;
}
/**
 * Here it is defined the type of the props, this prop is similar to 'ComponentProps' but 'PostCardAdminPropsTypes' is for the documentation
 * @typedef PostCardAdminPropsTypes
 * @type {(function)}
 * @property {string} short_address - is a string.
 * @property {string} unit_type - is a string.
 * @property {string} postId - is a number.
 * @property {string} aasm_state - is a string.
 * @property {string} commune - is a string.
 * @property {string} docState - is a string.
 * @property {React.ReactNode} children - is a children.
 * @return {component} Card admin component
 */
const PostCardAdmin = ({
  short_address: shortAddress,
  unit_type = 'apartment',
  children,
  aasm_state,
  commune,
  postId,
  docState = 'pending',
}: PostCardAdminProps) => {
  const [showModal, setShowModal] = useState(false);

  /** UnPublishButton: This function renders a button element that connects to the unpublishing endpoint
   * @function
   * @returns {(ReactComponent)} Returns a tsx element
   */
  const UnPublishLink = () => {
    return (
      <a
        className='underline-link text-color-dark-gray col-12 mt-2'
        onClick={() => {
          setShowModal(true);
        }}
      >
        {intl.get('UNPUBLISH')}
      </a>
    );
  };
  return (
    <div className='admin-card-container'>
      <div className='card-admin'>
        <span className='font-weight-bold'>{shortAddress}</span>
        <p className='font-weight-bold'>{commune}</p>
        <p className='text-color-tertiary'>{unit_type && intl.get(unit_type.toUpperCase())}</p>
        <div className='row'>
          {children}
          {/* Only published posts can be unpublished */}
          {aasm_state === 'published' && <UnPublishLink />}
        </div>
      </div>
      <UnpublishPostsModal
        open={showModal}
        postId={postId}
        address={shortAddress}
        setShowModal={setShowModal}
        docState={docState}
      />
    </div>
  );
};

export default PostCardAdmin;
