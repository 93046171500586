import { sendRenterEvaluationStart, sendRenterEvaluationSuccess, sendRenterEvaluationError } from './types';
import { toast } from 'react-toastify';
import { putData } from '../../helpers/apiConnections';
import { handleError } from '../../helpers/handleErrors';
const sendRenterEvaluation = (id: string, state: string, comments?: string) => {
    return (dispatch: any) => {
        dispatch(sendRenterEvaluationStart());
        putData(`credit_evaluation/${id}/${state}`, {
            params: state === 'accept' ? {} : { comment: comments },
        })
            .then((result) => {
                dispatch(sendRenterEvaluationSuccess(result.data));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(sendRenterEvaluationError(e));
            });
    };
};
export default sendRenterEvaluation;
