import { getData } from '../../../tango-react-base/helpers/apiConnections'
import {
  getUsersStart,
  getUsersError,
  getUsersSuc,
  getUserRestart,
} from './types'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'
const getUsersSearch = (params: any, state?: any) => {
  if (state === 'users') {
    return (dispatch: any) => {
      dispatch(getUserRestart())
    }
  }
  const { user_search } = params
  return (dispatch: any) => {
    dispatch(getUsersStart())
    getData('users', {
      text_to_search: user_search,
    })
      .then((result) => {
        dispatch(getUsersSuc(result.data.data))
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(getUsersError(e))
      })
  }
}
export default getUsersSearch
