const initialState: any = {
  ownerPayments: [],
  fetchOwnerPayments: 'NO_INIT',
}

export default (state = initialState, action: any) => {
  
  switch (action.type) {
    case 'GET_OWNER_PAYMENTS_STARTED':
      return {
        ...state,
        fetchOwnerPayments: 'FETCHING',
      }
    case 'GET_OWNER_PAYMENTS_SUCCESS':
      return {
        ...state,
        ownerPayments: [{kind: 'administration_fee', value: action.payload.data.attributes.administration_fee_amount},
                        {kind: 'collection_fee', value: action.payload.data.attributes.brokerage_fee_amount}],
        fetchOwnerPayments: 'FETCHED',
      }
    case 'GET_OWNER_PAYMENTS_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchOwnerPayments: 'ERROR',
      }
  
    default:
      return state
  }
}
