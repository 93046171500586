export const  unpublishPostStart = () => {
    return {
      type: 'UNPUBLISH_POST_STARTED',
    }
  }
  
  export const  unpublishPostSuccess = (post: any) => {
    return {
      type: 'UNPUBLISH_POST_SUCCESS',
      payload: { post },
    }
  }
  export const  unpublishPostError = (error: any) => {
    return {
      type: 'UNPUBLISH_POST_ERROR',
      payload: {
        error,
      },
    }
  }