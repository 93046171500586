const initialState: any = {
  user: { id: '21' },
  fetchForgetStatus: 'NO_FETCH',
  fetchSessionStatus: 'NO_FETCH',
  fetchStatus: 'NO_FETCH',
  updateStatus: 'NO_UPDATE',
  fetchUpdatePasswordStatus: 'NO_UPDATE',
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_USER_DATA_STARTED':
      return {
        ...state,
        fetchStatus: 'FETCHING',
      };
    case 'GET_USER_DATA_SUCCESS':
      return {
        ...state,
        user: action.payload.user.data,
        fetchStatus: 'FETCHED',
      };
    case 'LOGOUT_USER_ERROR':
    case 'GET_USER_DATA_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchStatus: 'ERROR_FETCHING',
      };
    case 'UPDATE_USER_INFORMATION_STARTED':
      return {
        ...state,
        updateStatus: 'UPLOADING',
      };
    case 'UPDATE_USER_INFORMATION_SUCCESS':
      return {
        ...state,
        user: action.payload.user.data,
        updateStatus: 'UPLOADED',
      };
    case 'UPDATE_USER_INFORMATION_ERROR':
      return {
        ...state,
        updateStatus: 'ERROR_UPLOADING',
      };
    case 'CREATE_PROFILE_SUCCESS':
      let user = state.user;
      let userAttributes = state.user.attributes;
      let dataConsignee = action.payload.profile.data.attributes;
      dataConsignee.id = action.payload.profile.data.id;
      dataConsignee.type = action.payload.profile.data.type;
      userAttributes.renter_profiles = userAttributes.renter_profiles.concat(dataConsignee);
      user.attributes = userAttributes;
      return {
        ...state,
        user: user,
        updateStatus: 'UPLOADED',
      };
    case 'SET_USER_DATA_STARTED':
      return {
        ...state,
        fetchSessionStatus: 'FETCHING',
      };
    case 'SET_USER_DATA_SUCCESS':
      return {
        ...state,
        user: action.payload.session.data,
        fetchSessionStatus: 'FETCHING',
      };
    case 'SET_USER_DATA_SUCCESS_STORAGE':
      return {
        ...state,
        fetchSessionStatus: 'FETCHED',
      };
    case 'SET_USER_DATA_ERROR':
      return {
        ...state,
        fetchSessionStatus: 'ERROR_FETCHING',
      };
    case 'SET_FORGET_PASSWORD_STARTED':
      return {
        ...state,
        fetchUpdatePasswordStatus: 'NO_UPDATE',
        fetchForgetStatus: 'FETCHING',
      };
    case 'SET_FORGET_PASSWORD_SUCCESS':
      return {
        ...state,
        fetchForgetStatus: 'FETCHED',
      };
    case 'SET_FORGET_PASSWORD_ERROR':
      return {
        ...state,
        fetchForgetStatus: 'ERROR_FETCHING',
      };
    case 'SET_SEND_TOKEN_STARTED':
      return {
        ...state,
        fetchForgetStatus: 'FETCHING_TOKEN',
      };
    case 'SET_SEND_TOKEN_SUCCESS':
      return {
        ...state,
        fetchForgetStatus: 'FETCHED_TOKEN',
      };
    case 'SET_SEND_TOKEN_ERROR':
      return {
        ...state,
        fetchForgetStatus: 'ERROR_FETCHING_TOKEN',
      };
    case 'UPDATE_PASSWORD_STARTED':
      return {
        ...state,
        fetchUpdatePasswordStatus: 'UPLOADING',
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        fetchForgetStatus: 'NO_FETCH',
        fetchUpdatePasswordStatus: 'UPLOADED',
      };
    case 'UPDATE_PASSWORD_ERROR':
      return {
        ...state,
        fetchUpdatePasswordStatus: 'ERROR_UPLOADING',
      };
    /* Reset */
    case 'LOGOUT_USER_SUCCESS':
      return {
        state: { ...initialState },
      };
    default:
      return state;
  }
};
