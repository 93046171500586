export const getPostsRentedStart = () => {
  return {
    type: 'GET_POST_RENTED_STARTED',
  }
}

export const getPostsRentedSuccess = (result: any) => {
  return {
    type: 'GET_POST_RENTED_SUCCESS',
    payload: { result },
  }
}
export const getPostsRentedError = (error: any) => {
  return {
    type: 'GET_POST_RENTED_ERROR',
    payload: {
      error,
    },
  }
}
