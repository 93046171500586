import {
  approvePostRegisteredStart,
  approvePostRegisteredSuc,
  approvePostRegisteredError,
} from './types'
import { putData } from '../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import { handleError } from '../../../tango-react-base/helpers/handleErrors'

const approvePostRegistered = (id: number, history?: any) => {
  return (dispatch: any) => {
    dispatch(approvePostRegisteredStart())
    putData(`posts/${id}/approve`)
      .then((result) => {
        dispatch(approvePostRegisteredSuc(result.data))
        history && history.push('/published_post', {redirect: '/registered'})
      })
      .catch((e) => {
        toast(handleError(e))
        return dispatch(approvePostRegisteredError(e))
      })
  }
}
export default approvePostRegistered
