export const getRequisitionStart = () => {
    return {
        type: 'GET_REQUISITION_START',
    };
};

export const getRequisitionSuccess = (result: any) => {
    return {
        type: 'GET_REQUISITION_SUCCESS',
        payload: result,
    };
};

export const getRequisitionError = (error: any) => {
    return {
        type: 'GET_REQUISITION_ERROR',
        payload: error,
    };
};
