const initialState = {
  response: {},
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_CHARGE_LINES_STARTED':
      return {
        ...state,
        fetchingUpdateChargeLines: 'LOADING',
      }
    case 'UPDATE_CHARGE_LINES_SUCCESS':
      return {
        ...state,
        response: action.payload.data,
        fetchingUpdateChargeLines: 'LOADED',
      }
    case 'UPDATE_CHARGE_LINES_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingUpdateChargeLines: 'ERROR',
      }
    
    default:
      return state
  }
}
