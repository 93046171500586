import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import {
  setTemplateChild,
  getPendingVouchers,
} from '../../tango-react-base/reduxActions'
import { CircularLoading, TableTango } from '../../tango-react-base/components'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Tooltip } from '@material-ui/core'
import { eyeIcon } from '../../tango-react-base/resources'
import { enableDocument } from '../../redux/actions'
import { useWindowSize } from '../../tango-react-base/helpers/hookWindowSize'

/**
 * Here the type of each Table is defined, this prop is similar to 'PendingVouchersProps' but 'RPendingVouchersTypes' is for the documentation
 *  @typedef  PendingVouchersTypes
 *  @type {(function|Array|boolean|string)}
 *  @property {function} onSetTemplateChild - is a function.
 *  @property {function} onGetRestorationPendingVouchers - is a function.
 *  @property {function} onGetDeliveryPendingVouchers - is a function.
 *  @property {function} onValidatePendingVoucher - is a function.
 *  @property {boolean} voucherValidated - is a boolean.
 *  @property {string} fetchingData - is an string.
 *  @property {string} validationStatus - is an string.
 *  @property {Array} restorationPendingVouchers - is an Array.
 *  @property {Array} deliveryPendingVouchers - is an Array.*
 */
interface PendingVouchersProps {
  onSetTemplateChild: (child: Object) => void
  onGetRestorationPendingVouchers: (
    document_state: string,
    doc_type: string,
  ) => void
  onGetDeliveryPendingVouchers: (
    document_state: string,
    doc_type: string,
  ) => void
  onValidatePendingVoucher: (document_id: number, doc_type: string) => void
  deliveryPendingVouchers: Array<any>
  voucherValidated: boolean
  validationStatus: string
  fetchingData: string
  restorationPendingVouchers: Array<any>
}

/**
 * PendingVouchers is a functional component with props.
 * The main function of this component is to render the tables in general.
 *  @function
 *  @param {PendingVouchersTypes} onSetTemplateChild - function onSetTemplateChild
 *  @param {PendingVouchersTypes} onGetDeliveryPendingVouchers - is a function gets the delivery pending vouchers.
 *  @param {PendingVouchersTypes} onGetRestorationPendingVouchers - is a function gets the restoration pending vouchers.
 *  @param {PendingVouchersTypes} onValidatePendingVoucher - handle the validation of the voucher
 *  @param {PendingVouchersTypes} deliveryPendingVouchers - returns the delivery pending vouchers
 *  @param {PendingVouchersTypes} restorationPendingVouchers - returns the restoration pending vouchers
 *  @param {PendingVouchersTypes} voucherValidated - returns voucher status
 *  @param {PendingVouchersTypes} fetchingData - returns fetching data status
 *  @param {PendingVouchersTypes} validationStatus - return the validation status
 *  @returns {(ReactComponent)} Returns a PendingVouchers
 *
 */
const PendingVouchers = ({
  onSetTemplateChild,
  onGetRestorationPendingVouchers,
  onGetDeliveryPendingVouchers,
  deliveryPendingVouchers,
  onValidatePendingVoucher,
  voucherValidated,
  validationStatus,
  fetchingData,
  restorationPendingVouchers,
}: PendingVouchersProps) => {
  const history = useHistory()

  useEffect(() => {
    onGetRestorationPendingVouchers('pending', 'restoration_voucher')
    onGetDeliveryPendingVouchers('pending', 'delivery_voucher')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherValidated])

  const [currentDocumentId, setCurrentDocumentId] = useState(0)
  const [validationDeliveryVoucher, setValidationDeliveryVoucher] = useState<
    number[]
  >([])
  const [
    validationRestorationVoucher,
    setValidationRestorationVoucher,
  ] = useState<number[]>([])

  const enableValidationVouchers = (id: number, voucher_type: string) => {
    return voucher_type === 'delivery'
      ? validationDeliveryVoucher.includes(id)
      : validationRestorationVoucher.includes(id)
  }

  // this is the tableTango header
  const titlesHeader = [
    { name: 'ADDRESS', sizes: '20%', class: 'table-border-left' },
    { name: 'RENTER', sizes: '15%' },
    { name: 'PHONE', sizes: '15%' },
    { name: 'VISIT_STATE', sizes: '15%' },
    { name: 'SEE_DOCUMENT', sizes: '15%' },
    { name: 'ACTIONS', sizes: '19%' },
    { name: ' ', sizes: '1%', class: 'table-border-right' },
  ]

  const size = useWindowSize()

  const handleValidate = (documentId: number, doc_type: string) => {
    setCurrentDocumentId(documentId)
    onValidatePendingVoucher(documentId, doc_type)
  }

  const loadingStatus = (id: number) => {
    return validationStatus === 'LOADING' && currentDocumentId === id
  }

  const DeliveryVouchersTable = () => {
    return (
      <>
        {!deliveryPendingVouchers.length ? (
          <TableRow>
            <TableCell colSpan={6} className='table-content text-center'>
              {intl.get('NO_DATA')}
            </TableCell>
          </TableRow>
        ) : (
          deliveryPendingVouchers.map((data: any) => {
            const { attributes, id } = data
            const {
              renter_names,
              unit_address,
              renter_phone,
              url_voucher,
              post_id,
              visit_state,
            } = attributes
            return (
              <TableRow key={id}>
                <TableCell className='table-content'>{unit_address}</TableCell>
                <TableCell className='table-content'>{renter_names}</TableCell>
                <TableCell className='table-content'>{renter_phone}</TableCell>
                <TableCell className='table-content'>
                  {intl.get(visit_state.toUpperCase())}
                </TableCell>
                <TableCell className='table-content'>
                  <div className='tooltip-delete-logo d-flex justify-content-center'>
                    <Tooltip title={intl.get('SEE_PROPERTY_INVENTORY')}>
                      <a
                        target='_blank'
                        className='d-flex align-items/center'
                        rel='noopener noreferrer'
                        href={url_voucher}
                      >
                        <img
                          src={eyeIcon}
                          alt='eye-icon'
                          width='45'
                          onClick={() =>
                            setValidationDeliveryVoucher(
                              (validationDeliveryVoucher) =>
                                validationDeliveryVoucher.concat(id),
                            )
                          }
                        />
                      </a>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell className='table-content text-center'>
                  <button
                    className='button-fourth'
                    onClick={() => history.push(`/posts/${post_id}`)}
                  >
                    {intl.get('SHOW_DETAILS')}
                  </button>
                </TableCell>
                <TableCell className='table-content text-center'>
                  <Tooltip
                    title={
                      !validationDeliveryVoucher
                        ? intl.get('MUST_SEE_DOCUMENT_BEFORE_VALIDATE')
                        : intl.get('PUBLISH_PROPERTY_ADMIN')
                    }
                  >
                    <button
                      className={`button-primary col-12`}
                      onClick={() => handleValidate(id, 'delivery_voucher')}
                      disabled={
                        loadingStatus(id) ||
                        !enableValidationVouchers(id, 'delivery')
                      }
                    >
                      {loadingStatus(id) ? (
                        <CircularLoading />
                      ) : (
                        intl.get('PUBLISH_PROPERTY_ADMIN')
                      )}
                    </button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })
        )}
      </>
    )
  }
  const RestorationVouchersTable = () => {
    return (
      <>
        {!restorationPendingVouchers.length ? (
          <TableRow>
            <TableCell colSpan={6} className='table-content text-center'>
              {intl.get('NO_DATA')}
            </TableCell>
          </TableRow>
        ) : (
          restorationPendingVouchers.map((data: any) => {
            const { attributes, id } = data
            const {
              renter_names,
              unit_address,
              renter_phone,
              url_voucher,
              post_id,
              visit_state,
            } = attributes
            return (
              <TableRow key={id}>
                <TableCell className='table-content'>{unit_address}</TableCell>
                <TableCell className='table-content'>{renter_names}</TableCell>
                <TableCell className='table-content'>{renter_phone}</TableCell>
                <TableCell className='table-content'>
                  {intl.get(visit_state.toUpperCase())}
                </TableCell>
                <TableCell className='table-content'>
                  <div className='tooltip-delete-logo d-flex justify-content-center'>
                    <Tooltip title={intl.get('SEE_RESTORATION_VOUCHER')}>
                      <a
                        target='_blank'
                        className='d-flex align-items/center'
                        rel='noopener noreferrer'
                        href={url_voucher}
                      >
                        <img
                          src={eyeIcon}
                          alt='eye-icon'
                          width='45'
                          onClick={() =>
                            setValidationRestorationVoucher(
                              (validationRestorationVoucher) =>
                                validationRestorationVoucher.concat(id),
                            )
                          }
                        />
                      </a>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell className='table-content row'>
                  <button
                    className='button-show-detail col-md-12'
                    onClick={() => history.push(`/posts/${post_id}`)}
                  >
                    {intl.get('SHOW_DETAILS')}
                  </button>
                  {
                    <Tooltip
                      title={
                        !validationRestorationVoucher
                          ? intl.get('MUST_SEE_DOCUMENT_BEFORE_VALIDATE')
                          : intl.get('PUBLISH_PROPERTY_ADMIN')
                      }
                    >
                      <button
                        className={`${
                          !validationRestorationVoucher
                            ? 'button-validate'
                            : 'button-vouchers'
                        } col-md-12 ${size.width >= 768 ? 'ml-1' : 'mt-1'}`}
                        onClick={() =>
                          handleValidate(id, 'restoration_voucher')
                        }
                        disabled={
                          loadingStatus(id) ||
                          !enableValidationVouchers(id, 'restoration')
                        }
                      >
                        {loadingStatus(id) ? (
                          <CircularLoading />
                        ) : (
                          intl.get('PUBLISH_PROPERTY_ADMIN')
                        )}
                      </button>
                    </Tooltip>
                  }
                </TableCell>
                <TableCell className='table-content text-center' />
              </TableRow>
            )
          })
        )}
      </>
    )
  }
  useEffect(() => {
    if (!sessionStorage.getItem('authorization')) return history.push('/login')
    else {
      onSetTemplateChild(
        <>
          <h1>{intl.get('PENDING_VOUCHERS')}</h1>
          <p className='px-0 d-none d-md-block' />
        </>,
      )
    }
  }, [history, onSetTemplateChild])

  return (
    <>
      <div className='container-home mt-5 pd-1'>
        <h2 className='text my-2'>{intl.get('PENDING_DELIVERY_VOUCHERS')}</h2>
        {fetchingData === 'LOADING' && !deliveryPendingVouchers.length ? (
          <CircularLoading />
        ) : (
          <>
            <TableTango listTitle={titlesHeader} paginator={false}>
              <DeliveryVouchersTable />
            </TableTango>
          </>
        )}
      </div>
      <div className='container-home mt-5 pd-1'>
        <h2 className='text my-2'>
          {intl.get('PENDING_RESTORATION_VOUCHERS')}
        </h2>
        {fetchingData === 'LOADING' && !restorationPendingVouchers.length ? (
          <CircularLoading />
        ) : (
          <>
            <TableTango listTitle={titlesHeader} paginator={false}>
              <RestorationVouchersTable />
            </TableTango>
          </>
        )}
      </div>
    </>
  )
}

/**
 *@function
 *@param {MapToProps}  dispatch - Dispatch an a action that usually changes a reducer state.
 */

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: Object) => {
      dispatch(setTemplateChild(child))
    },
    onGetRestorationPendingVouchers: (
      document_state: string,
      doc_type: string,
    ) => {
      dispatch(getPendingVouchers(document_state, doc_type))
    },
    onGetDeliveryPendingVouchers: (
      document_state: string,
      doc_type: string,
    ) => {
      dispatch(getPendingVouchers(document_state, doc_type))
    },
    onValidatePendingVoucher: (document_id: number, doc_type: string) => {
      dispatch(enableDocument(document_id, null, doc_type))
    },
  }
}
/**
 *@function
 *@param {MapToProps}  state - By state the redux data can be accesssed.
 */
const mapStateToProps = (state: any) => {
  return {
    restorationPendingVouchers: state.pendingVouchers.dataRestorationVoucher,
    deliveryPendingVouchers: state.pendingVouchers.dataDeliveryVoucher,
    voucherValidated: state.pendingVouchers.voucher_validated,
    validationStatus: state.pendingVouchers.validation_status,
    fetchingData: state.pendingVouchers.fetchingData,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PendingVouchers)
