import { getLeaseDocumentsStart, getLeaseDocumentsSuccess, getLeaseDocumentsError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getLeaseDocuments = (activeRentId: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(getLeaseDocumentsStart());
        getData(`lease_documents?rent_id=${activeRentId}`)
            .then((result) => {
                return dispatch(getLeaseDocumentsSuccess(result));
            })
            .catch((e) => {
                const errors = { ...e };
                const { response } = errors;
                if (response) {
                    const { status } = response;
                    status !== 404 && toast(handleError(e));
                    return dispatch(getLeaseDocumentsError(e));
                }
            });
    };
};
export default getLeaseDocuments;
