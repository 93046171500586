export const requestUserListStart = () => {
    return {
      type: 'GET_USER_LIST_STARTED',
    }
  }
  
  export const requestUserListSuccess = (result: any) => {
    return {
      type: 'GET_USER_LIST_SUCCESS',
      payload: { result },
    }
  }
  export const requestUserListError = (error: any) => {
    return {
      type: 'GET_USER_LIST_ERROR',
      payload: {
        error,
      },
    }
  }
  