import React, { useEffect } from 'react'
import { Field } from 'formik'
import { SectionProps } from './SectionInterfaces'
import { SwitchString, RadioButtonTango, Input, MapAutoComplete, SelectInputPrice } from '../..'
import intl from 'react-intl-universal'
import {
  isRoleOwner,
  isRolePhotographer,
} from '../../../helpers/roleComparison/index'
import {
  BEDROOMS_COUNT_OPTIONS,
  SUITE_BEDROOM_COUNT_OPTIONS,
  BATHROOM_COUNT_OPTIONS,
  COMUNITY_TYPE_OPTIONS,
  BOOLEAN_OPTIONS,
  OWNER_TYPE_OPTIONS,
  UNIT_TYPE_OPTIONS,
  REGISTER_TYPE_OPTIONS,
  CURRENCY_OPTIONS
} from '../../../../tango-react-base/helpers/selectOptions/options'

const BasicData = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  disabled,
  setIamOwner,
  isNewUnit,
  setFullFieldsBasic,
  toVerify = false,
  setValidAddresRented,
  isVerifier,
  toRepublish = false,
  disableRegisterType,
}: SectionProps) => {
  const { aasm_state } = values
  useEffect(() => {
    setIamOwner && setIamOwner(values.owner_type === 'yes')
  }, [setIamOwner])

  const appOwner = isRoleOwner && isNewUnit

  const priceValidation =
    values.estimate_price_amount < 200000
      ? intl.get('MINIMUM_PRICE')
      : !values.estimate_price_amount
      ? intl.get('REQUIRED_FIELD')
      : ''

  const fields: any =
    isRolePhotographer || toRepublish
      ? [
          values.owner_type || undefined,
          values.register_type || undefined,
          values.unit_type || undefined,
          values.unit_type === 'apartment' ||
          (values.unit_type === 'house' &&
            values.community_type === 'condominium')
          ? values.street_address_extra || undefined
          : '',

        values.unit_type === 'house'
          ? values.community_type || undefined
          : '',
        values.full_address || undefined,
        values.inner_area || undefined,
        values.unit_type === 'apartment'
          ? values.terrace_area < 0 || values.terrace_area === null ? undefined : values.terrace_area
          : '',
        values.unit_type === 'house' ? values.terrain_area || undefined : '',
        values.total_area || undefined,
        values.suite_bedroom_count < 0 || values.suite_bedroom_count === null
          ? undefined
          : values.suite_bedroom_count,
        values.bathroom_count || undefined
      ]
      : []

  useEffect(() => {
    setFullFieldsBasic && setFullFieldsBasic(!fields.includes(undefined))
  }, [fields])
  return (
    <>
      {!toRepublish && (
        <>
          <h2 className='new-subtitle'>{intl.get('BASIC_DATA_A')}</h2>
        </>
      )}
      {!appOwner && (
        <RadioButtonTango
          error={errors.owner_type}
          disabled={disabled}
          id='owner_type'
          label='ARE_YOU_OWNER'
          onChange={handleChange}
          options={OWNER_TYPE_OPTIONS}
          valueChecked={values.owner_type}
          isVerifier={isVerifier}
          isRequiredVerifier={!values.owner_type}
        />
      )}

      {!isRoleOwner && (
        <RadioButtonTango
          error={errors.register_type}
          disabled={disableRegisterType}
          id='register_type'
          label='REGISTER_TYPE'
          onChange={handleChange}
          options={REGISTER_TYPE_OPTIONS}
          valueChecked={values.register_type}
          isVerifier={isVerifier}
          isRequiredVerifier={!values.register_type}
        />
      )}

      <RadioButtonTango
        error={errors.unit_type}
        disabled={toRepublish || disabled}
        id='unit_type'
        label='UNIT_TYPE'
        onChange={handleChange}
        options={UNIT_TYPE_OPTIONS}
        valueChecked={values.unit_type}
        isVerifier={isVerifier}
        isRequiredVerifier={!values.unit_type}
      />
      {!appOwner && values.unit_type === 'house' && (
        <RadioButtonTango
          error={errors.community_type}
          disabled={disabled}
          id='community_type'
          label='COMUNITY_TYPE'
          onChange={handleChange}
          options={COMUNITY_TYPE_OPTIONS}
          valueChecked={values.community_type}
          info='PROPERTY_AND_HOUSE_DESCRIPTION'
          isVerifier={isVerifier}
          isRequiredVerifier={!values.community_type}
        />
      )}
      <MapAutoComplete
        required={true}
        values={values.full_address}
        id={'full_address'}
        label={'ADDRESS'}
        touched={touched.full_address}
        type='text'
        disabled={toRepublish || disabled}
        error={errors.full_address}
        latitude={values.latitude}
        longitude={values.longitude}
        info='ADDRESS_INSTRUCTIONS'
        typeMap='marker'
        allValues={values}
        stateValidAddress={true}
        stateUnit={aasm_state}
        setValidAddresRented={setValidAddresRented}
      />
      {values.unit_type === 'house' &&
        values.community_type === 'independent' ? null : (
        <Input
          disabled={toRepublish || disabled}
          error={errors.street_address_extra}
          id='street_address_extra'
          label='UNIT_DEPT_HOUSE'
          onBlur={handleBlur}
          onChange={handleChange}
          requiredMessage={intl.get('REQUIRED_FIELD')}
          touched={touched.street_address_extra}
          type='text'
          value={values.street_address_extra}
        />
      )}
      {isNewUnit &&
        <SelectInputPrice
          currencyIsoValue={values.estimate_price_currency}
          currencyOptions={CURRENCY_OPTIONS}
          disabled={disabled}
          id='estimate_price_amount'
          idCurrency='estimate_price_currency'
          info='SELECT_CLF_INFO'
          isVerifier={isVerifier}
          label='CURRENT_PRICE'
          messageAdditionalValidation='MINIMUM_PRICE'
          onBlur={handleBlur}
          onChange={handleChange}
          required={isNewUnit}
          touched={touched.estimate_price_amount}
          value={values.estimate_price_amount}
        />
      }
      {!appOwner && (
        <>
          {values.unit_type === 'house' && (
            <Input
              disabled={disabled}
              id='terrain_area'
              label='LABEL_TERRAIN_AREA'
              min='0'
              max='900'
              onBlur={handleBlur}
              onChange={handleChange}
              touched={touched.terrain_area}
              type='number'
              value={values.terrain_area}
              unit='m'
              isVerifier={isVerifier}
              messageAdditionalValidation={
                values.terrain_area ? intl.get(`${errors.terrain_area}`) : ''
              }
              isRequiredVerifier={
                values.terrain_area === null ||
                values.terrain_area === undefined
              }
            />
          )}
          <Input
            disabled={disabled}
            id='inner_area'
            label='LABEL_INNER_AREA'
            min='0'
            max='900'
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.inner_area}
            type='number'
            value={values.inner_area}
            unit='m'
            isVerifier={isVerifier}
            messageAdditionalValidation={
              values.inner_area ? intl.get(`${errors.inner_area}`) : ''
            }
            isRequiredVerifier={
              values.inner_area === null || values.inner_area === undefined
            }
          />
        </>
      )}

      {!appOwner && (
        <>
          {values.unit_type === 'apartment' && (
            <Input
              isVerifier={isVerifier}
              disabled={disabled}
              id='terrace_area'
              label='LABEL_TERRACE_AREA'
              min='0'
              max='900'
              onBlur={handleBlur}
              onChange={handleChange}
              requiredMessage={intl.get('REQUIRED_FIELD')}
              touched={touched.terrace_area}
              type='number'
              value={values.terrace_area}
              unit='m'
              messageAdditionalValidation={
                values.terrace_area ? intl.get(`${errors.terrace_area}`) : ''
              }
              isRequiredVerifier={
                values.terrace_area === null ||
                values.terrace_area === undefined
              }
            />
          )}

          <Field>
            {({ field, form }: any) => {
              const apartment_area =
                (values.terrace_area > 0 ? values.terrace_area : 0) +
                (values.inner_area > 0 ? values.inner_area : 0)
              const house_area = values.inner_area > 0 ? values.inner_area : 0
              const total_area =
                values.unit_type === 'apartment' ? apartment_area : house_area

              return (
                <div className='row input-container d-flex align-items-start'>
                  <div className='col-md-4 col-12 pl-0'>
                    <span className={`span-text`}>
                      {intl.getHTML('LABEL_TOTAL_AREA')}
                    </span>
                  </div>
                  <div className='col-6 col-lg-3 plm-0'>
                    <input
                      className='total-area'
                      disabled={disabled}
                      id='total_area'
                      type='number'
                      onChange={() => { }}
                      value={(values.total_area = total_area)}
                    />
                  </div>
                  <span className='pl-1 span-text super-index mlp-sm-1'>
                    m<sup>2</sup>
                  </span>
                </div>
              )
            }}
          </Field>
        </>
      )}
      {!appOwner && (
        <>
          <RadioButtonTango
            error={errors.bedroom_count}
            disabled={disabled}
            id='bedroom_count'
            label='BEDROOMS_NUMBER'
            onChange={handleChange}
            options={BEDROOMS_COUNT_OPTIONS}
            valueChecked={Number(values.bedroom_count)}
            info='INFO_BEDROOM'
            isVerifier={isVerifier}
            isRequiredVerifier={values.bedroom_count === null}
          />
          <>
            {values.bedroom_count < values.suite_bedroom_count ? (
              <RadioButtonTango
                error={errors.suite_bedroom_count}
                disabled={disabled}
                id='suite_bedroom_count'
                label='SUITE_BEDROOMS_NUMBER'
                onChange={handleChange}
                options={SUITE_BEDROOM_COUNT_OPTIONS}
                valueChecked={Number(values.bedroom_count - 1)}
                message={intl.get('MORE_BEDROOMS_IN_SUITE')}
                isVerifier={isVerifier}
                isRequiredVerifier={
                  values.suite_bedroom_count < 0 ||
                  values.suite_bedroom_count === null ||
                  values.suite_bedroom_count === undefined
                }
              />
            ) : (
              <RadioButtonTango
                error={errors.suite_bedroom_count}
                disabled={disabled}
                id='suite_bedroom_count'
                label='SUITE_BEDROOMS_NUMBER'
                onChange={handleChange}
                options={SUITE_BEDROOM_COUNT_OPTIONS}
                valueChecked={Number(values.suite_bedroom_count)}
                isVerifier={isVerifier}
                isRequiredVerifier={
                  values.suite_bedroom_count < 0 ||
                  values.suite_bedroom_count === null ||
                  values.suite_bedroom_count === undefined
                }
              />
            )}
            <RadioButtonTango
              error={errors.bathroom_count}
              disabled={disabled}
              id='bathroom_count'
              label={'NUMBER_BATHROOMS'}
              onChange={handleChange}
              options={BATHROOM_COUNT_OPTIONS}
              valueChecked={Number(values.bathroom_count)}
              info='INFO_BATHROOM'
              isVerifier={isVerifier}
              isRequiredVerifier={!values.bathroom_count}
            />
            <SwitchString
              disabled={disabled}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              label='SERVICES'
              name='services'
              options={BOOLEAN_OPTIONS}
              value={values.services}
              info='INFO_SERVICES'
            />
          </>
        </>
      )}
    </>
  )
}

export default BasicData
