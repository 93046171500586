import React, { useState, useEffect } from 'react'
import { FormikProps, withFormik, Form } from 'formik'
import {
  Input,
  Select,
  MapAutoCompleteProfile,
} from '../../tango-react-base/components'
import intl from 'react-intl-universal'
import { MARITAL_STATUS_OPTIONS } from '../../tango-react-base/helpers/selectOptions/options'
import { toast } from 'react-toastify'

/**
 * Here it is defined the type of the props for the interface "SaveUserDataForm"
 * @typedef SaveUserDataFormTypes
 * @type {(object|boolean|array)}
 * @property {object} data - is an function.
 * @property {string} ownerId - is a string.
 * @property {function} onSubmit- is a function.
 */
interface SaveUserDataFormProps {
  data: any
  ownerId?: string
  onSubmit: (id: any, values: object) => void
}
/**
 * Here it is defined the type of the props for the interface "SaveUserDataForm"
 * @typedef SaveUserDataFormTypes
 * @type {(string)}
 * @property {string} country_of_residence - is an string
 * @property {string} marital_status - is an string
 * @property {string} full_address - is an string
 * @property {string} occupation - is an string
 * @property {string} bank_name - is an string
 * @property {string} bank_account_number - is an string
 * @property {string} bank_account_type - is an string
 * @property {string} birth_date - is an string
 * @property {string} street_address_extra - is an string
 */
interface SaveUserDataForm {
  names: string
  first_surname: string
  second_surname: string
  rut: string
  country_of_residence: string
  marital_status: string
  full_address: string
  occupation: string
  bank_name: string
  bank_account_number: string
  bank_account_type: string
  birth_date: string
  street_address_extra: string
  data_for_contract: {
    birth_date: string
    full_name: string
    nationality: string
    names: string
    surnames: string
    rut: string
  }
}

/**
 * InnerForm(SaveUserDataForm) is a functional component
 *@function
 *@param {SaveUserDataForm} names - returns an names
 *@param {SaveUserDataForm} first_surname - returns an first_surname
 *@param {SaveUserDataForm} second_surname - returns an second_surname
 *@param {SaveUserDataForm} rut: string - returns an rut
 *@param {SaveUserDataForm} country_of_residence - returns an country_of_residence
 *@param {SaveUserDataForm} marital_status - returns an marital_status
 *@param {SaveUserDataForm} full_address - returns an full_address
 *@param {SaveUserDataForm} occupation - returns an occupation
 *@param {SaveUserDataForm} bank_name - returns an bank_name
 *@param {SaveUserDataForm} bank_account_number - returns an bank_account_number
 *@param {SaveUserDataForm} bank_account_type - returns an bank_account_type
 *@param {SaveUserDataForm} birth_date - returns an birth_date
 *@param {SaveUserDataForm} street_address_extra - returns an street_address_extra
 * @returns {(React.FunctionComponent)} Returns a react component with a functional component
 */
const InnerForm: React.FunctionComponent<
  SaveUserDataFormProps & FormikProps<SaveUserDataForm>
> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  data, // the countries from the api
  ownerId,
  onSubmit,
}) => {
  const [disable, setDisable] = useState(true)
  const [invalidAddress, setInvalidAddress] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18)

  useEffect(() => {
    setDisableBtn(!values.full_address.includes(',') || invalidAddress)
  }, [values, invalidAddress])

  const handleSubmit = () => {
    if (!disableBtn) {
      setDisable(true)
      onSubmit(ownerId, values)
    } else {
      toast(intl.get('INVALID_ADDRESS_GENERIC'))
    }
  }

  return (
    <Form className='pd-1 mb-5'>
      <Input
        id='names'
        label='NAMES'
        type='text'
        value={values.data_for_contract?.names}
        error={errors.data_for_contract?.names}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.data_for_contract?.names}
        disabled
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
      />
      <Input
        id='surnames'
        label='SURNAMES'
        type='text'
        value={values.data_for_contract?.surnames}
        error={errors.data_for_contract?.surnames}
        disabled
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
      />
      <Input
        id='rut'
        label='RUT'
        type='text'
        value={values.data_for_contract?.rut}
        error={errors.data_for_contract?.rut}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.data_for_contract?.rut}
        disabled
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
      />
      <Input
        id='birth_date'
        label='BIRTHDATE'
        type='text'
        value={values.data_for_contract?.birth_date}
        error={errors.data_for_contract?.birth_date}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.data_for_contract?.birth_date}
        disabled
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
      />
      <Select
        options={MARITAL_STATUS_OPTIONS}
        id='marital_status'
        onChange={handleChange}
        label={'MARITAL_STATUS'.toUpperCase()}
        value={values.marital_status}
        disabled={disable}
        isDefaultValue
        classNameInput='col-md-6 plm-0'
        classNameSelect={
          disable ? 'col-12 select-style disabled' : 'col-12 select-style'
        }
      />
      <Input
        id='nationality'
        label='NATIONALITY'
        type='text'
        value={values.data_for_contract?.nationality}
        error={errors.data_for_contract?.nationality}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.data_for_contract?.nationality}
        disabled
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
      />
      <Select
        options={data}
        id='country_of_residence'
        onChange={handleChange}
        label={'COUNTRY_OF_RESIDENCE'.toUpperCase()}
        value={values.country_of_residence}
        disabled={disable}
        classNameInput='col-md-6 plm-0'
        classNameSelect={
          disable ? 'col-12 select-style disabled' : 'col-12 select-style'
        }
        resource={true}
      />
      <MapAutoCompleteProfile
        values={values.full_address}
        id='full_address'
        label='EXACT_ADDRESS'
        touched={touched.full_address}
        type='text'
        required={true}
        disabled={disable}
        error={errors.full_address}
        allValues={values}
        className='my-4'
        classNameInput='col-md-6 plm-0'
        countrySelected={values.country_of_residence}
        validAddress={true}
        address={values.full_address}
        setInvalidAddress={setInvalidAddress}
        info={'SELECT_ADDRESS'}
      />
      <Input
        id='street_address_extra'
        label='STREET_ADDRESS_EXTRA'
        type='text'
        required={false}
        value={values.street_address_extra}
        error={errors.street_address_extra}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.street_address_extra}
        disabled={disable}
        className='mt-4 mb-0'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
        info='EXTRA_ADDRESS_INFO'
      />
      <Input
        id='occupation'
        label='OCUPATION'
        type='text'
        value={values.occupation}
        error={errors.occupation}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.occupation}
        disabled={disable}
        className='my-4'
        classNameInput='col-md-6 plm-0'
        justifyCenter='align-items-center'
        required={true}
      />
      {disable ? (
        <button
          className='button-primary col-md-3'
          type='button'
          onClick={() => setDisable(false)}
        >
          {intl.get('EDIT')}
        </button>
      ) : (
        <>
          <button
            className='button-tertiary col-md-3 m-1'
            type='button'
            onClick={() => {
              setDisable(true)
            }}
          >
            {intl.get('CANCEL')}
          </button>
          <button
            className='button-primary col-md-3 m-1'
            type='submit'
            onClick={() => handleSubmit()}
          >
            {intl.get('SAVE')}
          </button>
        </>
      )}
    </Form>
  )
}

const SaveUserDataForms = withFormik<SaveUserDataFormProps, SaveUserDataForm>({
  mapPropsToValues: (props: any) => {
    return { ...props }
  },

  handleSubmit(
    values: SaveUserDataForm,
    { props, setSubmitting, setErrors },
  ) {},
})(InnerForm)

export default SaveUserDataForms
