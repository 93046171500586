import { AdvertState } from '../../../tango'

const initialState: AdvertState = {
  advert: [],
  fetchingStatusAdvert: 'NO_LOADED',
  fetchingStatusCreateAdvert: 'NO_CREATED',
  fetchingStatusDeleteAdvert: 'NO_DELETED',
  fetchingStatusEditAdvert: 'NO_EDITED',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    // GET ADVERT
    case 'GET_ADVERT_START':
      return {
        ...state,
        fetchingStatusAdvert: 'LOADING',
      }
    case 'GET_ADVERT_SUCCESS':
      return {
        ...state,
        advert: action.payload.result.data.data,
        fetchingStatusAdvert: 'LOADED',
      }
    case 'GET_ADVERT_ERROR':
      return {
        ...state,
        fetchingStatusAdvert: 'ERROR',
      }
    // CREATE ADVERT
    case 'CREATE_ADVERT_START':
      return {
        ...state,
        fetchingStatusCreateAdvert: 'CREATING',
      }
    case 'CREATE_ADVERT_SUCCESS':
      return {
        ...state,
        advert: action.payload.result.data.data,
        fetchingStatusCreateAdvert: 'CREATED',
      }
    case 'CREATE_ADVERT_ERROR':
      return {
        ...state,
        fetchingStatusCreateAdvert: 'ERROR',
      }
    // DELETE ADVERT
    case 'DELETE_ADVERT_START':
      return {
        ...state,
        fetchingStatusDeleteAdvert: 'DELETING',
      }
    case 'DELETE_ADVERT_SUCCESS':
      return {
        ...state,
        fetchingStatusDeleteAdvert: 'DELETED',
      }
    case 'DELETE_ADVERT_ERROR':
      return {
        ...state,
        fetchingStatusDeleteAdvert: 'ERROR',
      }
    // EDIT ADVERT
    case 'EDIT_ADVERT_START':
      return {
        ...state,
        fetchingStatusEditAdvert: 'EDITING',
      }
    case 'EDIT_ADVERT_SUCCESS':
      return {
        ...state,
        fetchingStatusEditAdvert: 'EDITED',
      }
    case 'EDIT_ADVERT_ERROR':
      return {
        ...state,
        fetchingStatusEditAdvert: 'ERROR',
      }
    // RESET ADVERT
    case 'RESET_DATA':
      return {
        advert: [],
        fetchingStatusAdvert: 'NO_LOADED',
        fetchingStatusCreateAdvert: 'NO_CREATED',
        fetchingStatusDeleteAdvert: 'NO_DELETED',
        fetchingStatusEditAdvert: 'NO_EDITED',
      }
    default:
      return state
  }
}
