export const approvePropertyDocumentStart = () => {
  return {
    type: 'APPROVE_PROPERTY_DOCUMENT_STARTED',
  }
}

export const approvePropertyDocumentSuccess = (document: any) => {
  return {
    type: 'APPROVE_PROPERTY_DOCUMENT_SUCCESS',
    payload: { document },
  }
}

export const approvePropertyDocumentError = (error: any) => {
  return {
    type: 'APPROVE_PROPERTY_DOCUMENT_ERROR',
    payload: { error },
  }
}
