const initialState: any = {
  documentsUploaded: [],
  fetchDocument: 'NO_FECTH',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'SAVE_DOCUMENT_STARTED':
      return {
        ...state,
        documentsUploaded: [],
        fetchDocument: 'FETCHING',
      }
    case 'SAVE_DOCUMENT_SUCCESS':
      return {
        ...state,
        documentsUploaded: action.payload.file.data,
        fetchDocument: 'FETCHED',
      }
    case 'SAVE_DOCUMENT_ERROR':
      const { error } = action.payload
      return {
        ...state,
        error,
        fetchDocument: 'ERROR',
      }

    default:
      return state
  }
}
