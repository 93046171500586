import { getPropertyDocumentsStart, getPropertyDocumentsSuccess, getPropertyDocumentsError } from './types';
import { getData } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const getPropertyDocuments = (postId: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(getPropertyDocumentsStart());
        getData(`unit_documents?post_id=${postId}`)
            .then((result) => {
                return dispatch(getPropertyDocumentsSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getPropertyDocumentsError(e));
            });
    };
};
export default getPropertyDocuments;
