import {
  rejectRenterStart,
  rejectRenterSuccess,
  rejectRenterError,
} from './types'
import { putData } from '../../../../tango-react-base/helpers/apiConnections'
import { toast } from 'react-toastify'
import intl from 'react-intl-universal'
import { handleError } from '../../../../tango-react-base/helpers/handleErrors'
const rejectRenter = (user_id: number) => {
  return (dispatch: any) => {
    dispatch(rejectRenterStart())
    putData(`renter_validations/${user_id}/reject`)
      .then((result: any) => {
        toast(intl.get('RENTER_REJECTED'))
        dispatch(rejectRenterSuccess(result.data))
      })
      .catch((e: any) => {
        toast(handleError(e))
        dispatch(rejectRenterError(e))
      })
  }
}
export default rejectRenter
