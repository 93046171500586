import { RentersState } from '../../../tango'

const initialState: RentersState = {
  rentersPending: [],
  rentersNotReady: [],
  rentersValidate: [],
  fetchingRentersPending: 'NO_RENTERS_PENDING',
  fetchingRentersNotReady: 'NO_RENTERS_NOT_READY',
  fetchingRentersValidate: 'NO_RENTERS_VALIDATE',
  error: 'NOT_ERROR',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'GET_RENTERS_PENDING_STARTED':
      return {
        ...state,
        fetchingRentersPending: 'LOADING',
      }
    case 'GET_RENTERS_PENDING_SUCCESS':
      return {
        ...state,
        rentersPending: action.payload.result.data,
        fetchingRentersPending: 'LOADED',
      }
    case 'GET_RENTERS_PENDING_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingRentersPending: 'ERROR',
      }
    // Not ready
    case 'GET_RENTERS_NOT_READY_STARTED':
      return {
        ...state,
        fetchingRentersNotReady: 'LOADING',
      }
    case 'GET_RENTERS_NOT_READY_SUCCESS':
      return {
        ...state,
        rentersNotReady: action.payload.result.data,
        fetchingRentersNotReady: 'LOADED',
      }
    case 'GET_RENTERS_NOT_READY_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingRentersNotReady: 'ERROR',
      }
    // validate
    case 'GET_RENTERS_VALIDATE_STARTED':
      return {
        ...state,
        fetchingRentersValidate: 'LOADING',
      }
    case 'GET_RENTERS_VALIDATE_SUCCESS':
      return {
        ...state,
        rentersValidate: action.payload.result.data,
        fetchingRentersValidate: 'LOADED',
      }
    case 'GET_RENTERS_VALIDATE_ERROR':
      return {
        ...state,
        error: action.payload.error,
        fetchingRentersValidate: 'ERROR',
      }

    default:
      return state
  }
}
