import React, { useEffect } from 'react'
import {
  setTemplateChild,
  getRequisition,
  rejectEarlyRequisition,
  confirmEarlyRequisition,
  getPaymentsProviders,
  markAsPaidCharges,
  markAsPaidFineOwner,
} from '../../tango-react-base/reduxActions'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { detailsLabels, selectOptions, paymentLables } from './options'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import { useParams, useHistory } from 'react-router-dom'
import {
  CircularLoading,
  PopupWithIconButtons,
} from '../../tango-react-base/components'
import { formatCurrencyNumber } from '../../tango-react-base/helpers/numberFormat'
import { CardPriceTable } from '../../components'
import NumberedTable from '../../tango-react-base/components/numberedTable'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

/**
 * @typedef {object} FineTargetInputsProps
 * @property {string} type - The type of the requisition
 * @property {string} currency - The currency of the requisition
 * @property {number} fineRenter - The fine of the renter of the requisition
 * @property {number} fineOwner - The fine of the owner of the requisition
 * @property {function} handleCancel - Handle the cancel action
 * @property {function} handleSave - Handle the save action
 * @property {function} handleChange - Handle the change action
 */

interface FineTargetInputsProps {
  type: string
  currency: string
  fineRenter: number
  fineOwner: number
  handleCancel: () => void
  handleSave: () => void
  handleChange: (values: any, type: string) => void
}

/**
 * @function FineTargetInputs
 * @param {FineTargetInputsProps} props - The props of the component
 * @returns {(ReactComponent)} Returns a react component with a functional component
 * @description This component is used to render the fine target inputs
 */

const FineTargetInputs = ({
  type,
  currency,
  fineOwner,
  fineRenter,
  handleChange,
  handleCancel,
  handleSave,
}: FineTargetInputsProps) => {
  const prefix = currency === 'clf' ? 'UF ' : '$ '
  const decimal = currency === 'clf' ? 2 : 0
  const [change, setChange] = React.useState(false)
  const label = () => {
    switch (type) {
      case 'Owner':
        return !change ? '' : intl.get('OWNER')
      case 'Renter':
        return !change ? '' : intl.get('RENTER')
      default:
        return ''
    }
  }
  const validateInputs = (value: any) => {
    if (value === undefined) {
      return intl.get('IS_REQUIRED')
    }
    return null
  }

  const onHandleCancel = () => {
    setChange(false)
    handleCancel()
  }
  const onHandleSave = () => {
    setChange(false)
    handleSave()
  }
  const inputs = () => {
    return (
      <>
        {type === 'Owner' || type === 'Renter' ? (
          <NumberFormat
            variant='outlined'
            error={
              validateInputs(type === 'Owner' ? fineOwner : fineRenter) !== null
            }
            helperText={validateInputs(
              type === 'Owner' ? fineOwner : fineRenter,
            )}
            label={label()}
            className='w-100 text-left disable-inputs'
            disabled={!change}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={decimal}
            allowNegative={false}
            customInput={TextField}
            value={type === 'Owner' ? fineOwner : fineRenter}
            prefix={prefix}
            onValueChange={(values) => handleChange(values, type.toLowerCase())}
          />
        ) : (
          <>
            {type === 'both' ? (
              <>
                <NumberFormat
                  variant='outlined'
                  error={validateInputs(fineOwner) !== null}
                  helperText={validateInputs(fineOwner)}
                  label={!change ? '' : intl.get('OWNER')}
                  className='w-100 text-left mb-4 disable-inputs'
                  disabled={!change}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={decimal}
                  allowNegative={false}
                  customInput={TextField}
                  value={fineOwner}
                  prefix={prefix}
                  onValueChange={(values) => handleChange(values, 'owner')}
                />
                <NumberFormat
                  variant='outlined'
                  error={validateInputs(fineRenter) !== null}
                  helperText={validateInputs(fineRenter)}
                  label={!change ? '' : intl.get('RENTER')}
                  className='w-100 text-left disable-inputs'
                  disabled={!change}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={decimal}
                  allowNegative={false}
                  customInput={TextField}
                  value={fineRenter}
                  prefix={prefix}
                  onValueChange={(values) => handleChange(values, 'renter')}
                />
              </>
            ) : null}
          </>
        )}
      </>
    )
  }
  const buttons = () => {
    return (
      <div className='d-flex flex-nowrap justify-content-end pt-4'>
        {change ? (
          <>
            <button
              className='button-white col-6 col-md-5 button-outlined text-dark my-2 mx-2'
              onClick={onHandleCancel}
            >
              {intl.get('CANCEL')}
            </button>
            <button
              className='button-primary col-6 col-md-5 button-outlined my-2'
              disabled={
                type !== 'both'
                  ? validateInputs(
                      type === 'Owner' ? fineOwner : fineRenter,
                    ) !== null
                  : validateInputs(fineOwner) !== null ||
                    validateInputs(fineRenter) !== null
              }
              onClick={onHandleSave}
            >
              {intl.get('SAVE')}
            </button>
          </>
        ) : (
          <button
            onClick={() => setChange(true)}
            className='button-primary col-6 col-md-5 button-outlined my-2'
          >
            {intl.get('EDIT')}
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      {inputs()}
      {type !== 'none' ? buttons() : null}
    </>
  )
}

/**
 * @typedef {object} RequisitionsDetailsProps
 * @property {function} onSetTemplateChild - Set the child of the template (navbar)
 * @property {function} onGetRequisition - Get the requisition
 * @property {function} onRejectEarlyRequisition -Reject the requisition
 * @property {function} onConfirmEarlyRequisition - Confirm the requisition
 * @property {function} onGetPaymentsProviders - Get the payments providers
 * @property {Object} paymentsProviders - The payments providers
 * @property {object} requisition - The requisition
 * @property {string} requisition.id - The id of the requisition
 * @property {string} requisition.type - The type of the requisition
 * @property {object} requisition.attributes - The attributes of the requisition
 * @property {string} fetchingRequisition - The status of the requisition
 * @property {string} fetchingMarkAsPaid - The status of the mark as paid
 * @property {string} fetchingPaymentsProviders - The status of the payments providers
 * @property {string} fetchingRejectEarlyRequisition - the Status of the reject
 * @property {string} fetchingconfirmEarlyRequisition - The status of the confirm
 */
interface RequisitionsDetailsProps {
  onSetTemplateChild: (child: any) => void
  onGetRequisition: (id: string) => void
  onRejectEarlyRequisition: (requisitionID: number, comment: string) => void
  onConfirmEarlyRequisition: (
    requisitionID: number,
    comment: string,
    fine_values: { owner: number; renter: number },
    fine_target: string,
  ) => void
  onGetPaymentsProviders: (all_payments: boolean) => void
  onMarkAsPaidFineOwner: (id: number, provider: string, method: string) => void
  onMarkAsPaidCharges: (
    chargeLinesIds: Array<number>,
    unitVersionId: number,
    rentID: number,
    provider_name: string,
    provider_method: string,
  ) => void
  requisition: { id: string; type: string; attributes: any }
  fetchingRequisition: string
  fetchingRejectEarlyRequisition: string
  fetchingconfirmEarlyRequisition: string
  fetchPaymentsProviders: string
  fetchingMarkAsPaid: string
  fethingMarkAsPaidOwnerFine: string
  paymentsProviders: any
}

/**
 * DetailsView component
 * @property {DetailsViewProps} props - Component props
 * @returns {(ReactComponent)} Returns a react component with a functional component
 * @description  This component is used to render the details view of the requisition
 */

const RequisitionsDetails = ({
  onSetTemplateChild,
  onGetRequisition,
  requisition,
  fetchingRequisition,
  onRejectEarlyRequisition,
  onConfirmEarlyRequisition,
  onGetPaymentsProviders,
  onMarkAsPaidCharges,
  onMarkAsPaidFineOwner,
  fetchingRejectEarlyRequisition,
  fetchingconfirmEarlyRequisition,
  fethingMarkAsPaidOwnerFine,
  fetchPaymentsProviders,
  fetchingMarkAsPaid,
  paymentsProviders,
}: RequisitionsDetailsProps) => {
  const { id }: any = useParams()
  const [fineTarget, setFineTarget] = React.useState('none')
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [fineValue, setFineValue] = React.useState({ owner: 0, renter: 0 })
  const [fineSave, setFineSave] = React.useState({ owner: 0, renter: 0 })
  const [openDialog, setOpenDialog] = React.useState('none')
  const [providerPayment, setProviderPayment] = React.useState<any>('')
  const [providerPaymentList, setProviderPaymentList] = React.useState<any>('')
  const [paymentMethod, setPaymentMethod] = React.useState<any>('')
  const [openDialogMarkAsPaid, setOpenDialogMarkAsPaid] = React.useState('none')
  const [firstLoadMotive, setFirstLoadMotive] = React.useState(true)
  const [adminMotive, setAdminMotive] = React.useState('')
  const history = useHistory()

  useEffect(() => {
    onSetTemplateChild(
      <>
        <h1>{intl.get('ANTICIPATED_CONTRACT_TERM')}</h1>
      </>,
    )
    onGetRequisition(id)
    onGetPaymentsProviders(true)
  }, [])
  useEffect(() => {
    if (fetchingRequisition === 'LOADED') {
      setFineTarget(
        requisition.attributes.requester_kind === 'Admin'
          ? 'both'
          : requisition.attributes.requester_kind,
      )
      setFineValue({ ...fineValue, ...requisition.attributes.fine_amount })
      setFineSave({ ...fineSave, ...requisition.attributes.fine_amount })
    }
  }, [fetchingRequisition, requisition])

  useEffect(() => {
    if (
      fetchingRejectEarlyRequisition === 'LOADED' ||
      fetchingconfirmEarlyRequisition === 'LOADED'
    ) {
      history.push('/requisitions')
    }
  }, [fetchingRejectEarlyRequisition, fetchingconfirmEarlyRequisition])

  useEffect(() => {
    if (fetchingMarkAsPaid === 'SUCCESS') {
      onGetRequisition(id)
      setOpenDialogMarkAsPaid('none')
      setProviderPayment('')
      setPaymentMethod('')
    }
  }, [fetchingMarkAsPaid])

  useEffect(() => {
    if (fethingMarkAsPaidOwnerFine === 'SUCCESS') {
      onGetRequisition(id)
      setOpenDialogMarkAsPaid('none')
      setProviderPayment('')
      setPaymentMethod('')
    }
  }, [fethingMarkAsPaidOwnerFine])

  useEffect(() => {
    if (firstLoad) {
      if (fineTarget === 'both') {
        setFineValue({
          ...fineValue,
          owner: fineValue.owner / 2,
          renter: fineValue.renter / 2,
        })
      } else {
        setFineValue({ ...fineValue, ...fineSave })
      }
    }
  }, [fineTarget])

  useEffect(() => {
    if (fetchPaymentsProviders === 'FETCHED') {
      setProviderPaymentList(
        paymentsProviders
          ? Object.entries(paymentsProviders).map((el: any) => {
              return {
                provider: el[0],
                methods: el[1].payment_methods,
              }
            })
          : [],
      )
    }
  }, [fetchPaymentsProviders])

  const dialogs = () => {
    const closing_contract_day = requisition?.attributes?.lease_closing_at
    const registered_currency = requisition?.attributes?.registered_currency
    return (
      <>
        <PopupWithIconButtons
          classNameTitle='font-weight-bold'
          lineUp
          handleConfirm={() => {
            onRejectEarlyRequisition(id, adminMotive)
          }}
          textButtonWhite={intl.get('CANCEL')}
          classNameDiv='px-md-4 pb-md-5 pt-md-3'
          textButtonRed={intl.get('REJECT_REQUEST')}
          open={openDialog === 'reject'}
          handleClose={() => setOpenDialog('none')}
          title='CONFIRM_REJECT_REQUEST'
        >
          <h3>{intl.getHTML('ARE_YOU_SURE_ABOUT_REJECT')}</h3>
          <p className='mt-4'>{intl.get('YOUR_DECISION_WILL_BE_NOTIFIED')}</p>
          <div className='font-weight-bold'>{intl.get('UNREVERSE_ACTION')}</div>
        </PopupWithIconButtons>

        <PopupWithIconButtons
          classNameTitle='font-weight-bold mb-0'
          lineUp
          handleConfirm={() => {
            onConfirmEarlyRequisition(id, adminMotive, fineValue, fineTarget)
          }}
          textButtonWhite={intl.get('CANCEL')}
          classNameDiv='px-md-4 pb-md-3 pt-md-3'
          textButtonRed={intl.get('FINISH_CONTRACT')}
          open={openDialog === 'accept'}
          handleClose={() => setOpenDialog('none')}
          title='CONFIRM_FINISH_CONTRACT'
        >
          <div className='confirm-finishi-contrct-popup-container'>
            <h3 className='font-weight-bold'>
              {intl.getHTML('ARE_YOU_SURE_ABOUT_FINISH_CONTRACT', {
                day: closing_contract_day,
              })}
            </h3>
            {fineTarget !== 'none' && (
              <>
                <div className='mx-1 my-2 my-md-3'>
                  {intl.get('THE_FOLLOWING_FINE_WILL_APPLY')}
                </div>

                {(fineTarget === 'Owner' || fineTarget === 'both') && (
                  <div className='d-flex justify-content-between flex-wrap px-3'>
                    <div className='font-weight-bold'>{intl.get('OWNER')}</div>
                    <div>
                      {registered_currency.toUpperCase() === 'CLP'
                        ? formatCurrencyNumber(fineValue.owner)
                        : `UF ${fineValue.owner}`}
                    </div>
                  </div>
                )}
                {(fineTarget === 'Renter' || fineTarget === 'both') && (
                  <div className='d-flex justify-content-between flex-wrap px-3'>
                    <div className='font-weight-bold'>{intl.get('RENTER')}</div>
                    <div>
                      {registered_currency.toUpperCase() === 'CLP'
                        ? formatCurrencyNumber(fineValue.renter)
                        : `UF ${fineValue.renter}`}
                    </div>
                  </div>
                )}
              </>
            )}

            <div className='font-weight-bold mt-md-4 pt-3 maximun-font-size'>
              {intl.get('UNREVERSE_ACTION')}
            </div>
          </div>
        </PopupWithIconButtons>
      </>
    )
  }

  const titleTraduction = (state: string) => {
    if (state === 'pending') return 'REQUEST_ANTICIPATED_CONTRACT_TERM'
    if (state === 'accepted') return 'STATE_OF_REQUISITION'
    if (state === 'rejected') return 'STATE_OF_REQUISITION'
    if (state === 'finished') return 'STATE_OF_REQUISITION'
    else return 'DEFAULT_TRADUCTION'
  }

  const handleChange = (values: any, type: string) => {
    if (type !== 'owner' && type !== 'renter') return
    setFineValue({ ...fineValue, [type]: values.floatValue })
  }
  const handleCancel = () => {
    if (firstLoad && fineTarget === 'both') {
      setFineValue({
        ...fineValue,
        owner: fineSave.owner / 2,
        renter: fineSave.renter / 2,
      })
    } else {
      setFineValue({ ...fineValue, ...fineSave })
    }
  }
  const handleSave = () => {
    setFirstLoad(false)
    setFineSave({ ...fineSave, ...fineValue })
  }
  const handleChangeMotive = (e: any) => {
    setFirstLoadMotive(false)
    setAdminMotive(e.target.value)
  }
  const isValidMotive = () => {
    return adminMotive.length > 0 && adminMotive.length <= 200
  }
  const isValidConfirm = () => {
    return (
      fineValue.owner !== undefined &&
      fineValue.renter !== undefined &&
      isValidMotive()
    )
  }
  const isValidReject = () => {
    return isValidMotive()
  }

  const renderView = () => {
    const {
      unit_id,
      rent_id,
      fine_id,
      customers,
      full_address_with_street_address_extra,
      lease_amount,
      requester_comment,
      lease_closing_at,
      lease_period_end_date,
      lease_period_start_date,
      plan_name,
      registered_currency,
      requester_kind,
      sign_date,
      state,
      closing_comment,
      charge_lines,
      fine_for_owner,
      can_mark_as_paid,
      last_charge_lines_paid,
      owner_payments
    } = requisition?.attributes

    const { owner, renter } = customers
    const actual_charge_lines = state.toUpperCase() === 'ACCEPTED' ? charge_lines : last_charge_lines_paid?.charge_lines
    const actual_fine_for_owner = state.toUpperCase() === 'ACCEPTED' ? fine_for_owner : owner_payments?.amount
    const rowClass = 'py-2 text-dark'
    const ChargeLinesRows: Array<any> = actual_charge_lines?.map((charge_line: any) => {
      return {
        label: charge_line.description,
        value: parseInt(
          charge_line.amounts.clp.replaceAll('.', '').match(/\d+/),
        ),
        labelClass: rowClass,
        valueClass: rowClass,
      }
    })
    const pendingFineOwner = [
      {
        label:  intl.get(state.toUpperCase() === 'ACCEPTED' ? 'PENDING_FINE' : 'FINE'),
        
        value: actual_fine_for_owner,
        labelClass: rowClass,
        valueClass: rowClass,
      },
    ]

    const lease_value =
      registered_currency.toUpperCase() === 'CLP' ? (
        <span>{lease_amount.clp}</span>
      ) : (
        <span>
          {lease_amount.clf} /{' '}
          <span className='text-color-gray'>{lease_amount.clp}</span>
        </span>
      )

    const chargelinesIds = actual_charge_lines?.map((chargeLine: any) =>
      parseInt(chargeLine.id),
    )
    const AcceptedOrFinishedView = (state: any) => {
      const requisitionStateFinished = state.state.toUpperCase() === 'FINISHED'
      
      return (
        <div className='col-12 col-lg-6 p-0 justify-content-end'>
          {(actual_charge_lines?.length > 0 || actual_fine_for_owner) && (
            <div className='container-requisitions'>
              {actual_charge_lines?.length > 0 && (
                <>
                  <CardPriceTable
                    title={intl.get('RENTER')}
                    subtitle={requisitionStateFinished ? intl.get('PAYMENT_TITLE_2') : intl.get('PENDING_CHARGES')}
                  >
                    <NumberedTable
                      headers={[
                        { name: 'TYPE', class: '' },
                        { name: 'CHARGED_AMOUNT', class: 'text-right' },
                      ]}
                      rows={ChargeLinesRows}
                      className='mb-2'
                      showTotal
                      calculateTotal
                      withoutBottomBorder={!requisitionStateFinished || !last_charge_lines_paid.provider}
                    />
                      {(requisitionStateFinished && !!last_charge_lines_paid.provider) && 
                        <div className='text-left mt-3 pl-3'>
                          {paymentLables(
                            last_charge_lines_paid.paid_at,
                            last_charge_lines_paid.provider,
                            last_charge_lines_paid.payment_method
                          ).map((label, index) => {
                            return (
                              <div key={index}>
                                <b>{intl.get(label.label)}:</b> {label.value}
                              </div>
                            )
                          })}
                        </div>
                      }
                  </CardPriceTable>
                  <div className='buttons-container-options max-width-card-price pb-5'>
                    {can_mark_as_paid && (
                      <button onClick={() => setOpenDialogMarkAsPaid('renter')}>
                        {intl.get('MARK_AS_PAID')}
                      </button>
                    )}
                  </div>
                </>
              )}
              { actual_fine_for_owner !==0 && actual_fine_for_owner && (
                <>
                  <CardPriceTable
                    title={intl.get('OWNER')}
                    subtitle={intl.get('ASSOCIATED_DISCOUNTS')}
                  >
                    <NumberedTable
                      headers={[
                        { name: 'TYPE', class: '' },
                        { name: 'CHARGED_AMOUNT', class: 'text-right' },
                      ]}
                      rows={pendingFineOwner}
                      className='mb-2'
                      showTotal
                      calculateTotal
                      withoutBottomBorder={!requisitionStateFinished || !owner_payments.provider}
                    />
                      {(requisitionStateFinished && !!owner_payments.provider) && 
                        <div className='text-left mt-3 pl-3'>
                          {paymentLables(
                            owner_payments.paid_at,
                            owner_payments.provider,
                            owner_payments.payment_method
                          ).map((label, index) => {
                            return (
                              <div key={index}>
                                <b>{intl.get(label.label)}:</b> {label.value}
                              </div>
                            )
                          })}
                        </div>
                      }
                  </CardPriceTable>
                  <div className='buttons-container-options max-width-card-price'>
                    {can_mark_as_paid && (
                      <button onClick={() => setOpenDialogMarkAsPaid('owner')}>
                        {intl.get('MARK_AS_PAID')}
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )
    }
    return (
      <div className='row d-flex pb-4'>
        <div className='col-12 px-3 col-lg-6 p-0 text-left'>
          <h2 className='pb-3'>
            <b>
              {intl.getHTML(titleTraduction(state), {
                state: intl.get(state.toUpperCase()),
              })}
            </b>
          </h2>
          <div className='pb-5'>
            {intl.getHTML('REQUEST_ANTICIPATED_CONTRACT_TERM_TEXT', {
              requester: intl.get(requester_kind.toUpperCase()),
              full_address: full_address_with_street_address_extra,
              contract_end_date: lease_closing_at,
            })}
          </div>
          <div className='text-left font-weight-bold pb-5'>
            {intl.get('REASONS_OF_CONTRACT_TERMINATION')}
            <div className='font-weight-normal'>{requester_comment}</div>
          </div>
          <div className='mb-5'>
            <div className='link-general-app'>
              <b>{intl.get('OWNER')}</b> :{' '}
              <a
                onClick={() => {
                  history.push('/owners/' + owner?.profile_id)
                }}
              >
                {owner?.full_name}
              </a>
            </div>
            <div className='link-general-app'>
              <b>{intl.get('RENTER')}</b> :{' '}
              <a
                onClick={() => {
                  history.push('/renters/' + renter?.id)
                }}
              >
                {renter?.full_name}
              </a>
            </div>
          </div>
          <div className='pb-4'>
            <h3>
              <b>{intl.get('CURRENT_LEASE_DATA')}</b>
            </h3>
            {detailsLabels(
              plan_name,
              lease_value,
              sign_date,
              lease_period_start_date,
              lease_period_end_date,
            ).map((label, index) => {
              return (
                <div key={index}>
                  <b>{intl.get(label.label)}</b> : {label.value}
                </div>
              )
            })}
          </div>
          {!!closing_comment && (
            <div className='pb-4'>
              <h3>
                <b>{intl.get('CLOSING_COMMENT')}</b>
              </h3>
              <div>
                <div className='font-weight-normal'>{closing_comment}</div>
              </div>
            </div>
          )}
        </div>
        {state === 'pending' && (
          <div className='col-12 col-lg-6 p-0 justify-content-end'>
            <div className='container-requisitions'>
              <div className='tango-card-requisitions mx-auto'>
                <h3 className='card-title'>{intl.get('FINE')}</h3>
                <div className='text-left'>{intl.getHTML('FINE_TEXT')}</div>
                <div className='template-line mb-3' />
                <div className='text-left font-weight-bold'>
                  {intl.get('FINE_SUBTITLE')}
                </div>
                <div className='card-actions'>
                  <Select
                    value={fineTarget}
                    id='fineTarget'
                    onChange={(e: any) => {
                      setFineTarget(e.target.value)
                    }}
                    className='w-100 text-left'
                    placeholder={intl.get('SELECT_OPTION')}
                    variant='outlined'
                  >
                    {selectOptions.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {intl.get(option.label)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
                <div className='text-left font-weight-bold pt-4'>
                  {fineTarget !== 'none' ? intl.get('FINE_VALUE') : ''}
                </div>
                <div className='card-actions pt-2'>
                  <FineTargetInputs
                    type={fineTarget}
                    currency={registered_currency.toLowerCase()}
                    fineRenter={fineValue.renter}
                    fineOwner={fineValue.owner}
                    handleChange={handleChange}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                  />
                  <div className='text-left font-weight-bold pt-3'>
                    {intl.getHTML('REASONS_OF_YOUR_DECISION')}
                  </div>
                  <TextField
                    multiline
                    variant='outlined'
                    className='w-100 text-left pt-2 rounded-input-5'
                    placeholder={intl.get('MAX_CHARACTERS')}
                    helperText={
                      firstLoadMotive
                        ? false
                        : isValidMotive()
                        ? ''
                        : intl.get('REQUIRED')
                    }
                    error={firstLoadMotive ? false : !isValidMotive()}
                    rows={6}
                    value={adminMotive}
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={handleChangeMotive}
                  ></TextField>
                </div>
              </div>
              <div className='buttons-container-options'>
                <button
                  onClick={() => setOpenDialog('reject')}
                  disabled={!isValidConfirm()}
                >
                  {intl.get('REJECT_REQUEST')}
                </button>
                <button
                  onClick={() => setOpenDialog('accept')}
                  disabled={!isValidReject()}
                >
                  {intl.get('FINISH_CONTRACT')}
                </button>
              </div>
            </div>
          </div>
        )}
        { ['accepted', 'finished'].includes(state) && <AcceptedOrFinishedView state={state}/>}
        <PopupWithIconButtons
          classNameTitle='font-weight-bold'
          lineUp
          loading={
            fetchingMarkAsPaid === 'FETCHING' ||
            fethingMarkAsPaidOwnerFine === 'FETCHING'
          }
          handleConfirm={() => {
            openDialogMarkAsPaid === 'renter'
              ? onMarkAsPaidCharges(
                  chargelinesIds,
                  unit_id,
                  rent_id,
                  providerPayment.provider,
                  paymentMethod.value,
                )
              : onMarkAsPaidFineOwner(
                  fine_id,
                  providerPayment.provider,
                  paymentMethod.value,
                )
          }}
          textButtonWhite={intl.get('CANCEL')}
          classNameDiv='mx-5 custom-accept-requisition'
          textButtonRed={intl.get('MARK_AS_PAID')}
          open={openDialogMarkAsPaid !== 'none'}
          disabled={
            openDialogMarkAsPaid === 'renter' ? paymentMethod === '' : false
          }
          handleClose={() => {
            setOpenDialogMarkAsPaid('none')
            setProviderPayment('')
            setPaymentMethod('')
          }}
          title={
            openDialogMarkAsPaid === 'renter'
              ? 'ENTER_RENTER_PAYMENTS'
              : 'ENTER_OWNER_PAYMENTS'
          }
        >
          <div className='custom-padding-inline'>
            <NumberedTable
              headers={[
                { name: 'TYPE', class: 'py-0 py-md-3' },
                { name: 'CHARGED_AMOUNT', class: 'text-right py-0 py-md-3' },
              ]}
              rows={
                openDialogMarkAsPaid === 'renter'
                  ? ChargeLinesRows
                  : [
                      {
                        label: intl.get('FINE'),
                        value: fine_for_owner,
                        labelClass: rowClass,
                        valueClass: rowClass,
                      },
                    ]
              }
              className='mb-2'
              showTotal
              noGutters
              calculateTotal
              withoutBottomBorder
            />

            <div className='payments-containers'>
              <p>{intl.get('PROVIDER')}</p>
              <Select
                displayEmpty
                id='provider'
                variant='outlined'
                className='mb-2'
                value={providerPayment}
                IconComponent={ExpandMoreIcon}
                onChange={(event: any) => {
                  setProviderPayment(event.target.value)
                  setPaymentMethod('')
                }}
              >
                <MenuItem disabled value=''>
                  {intl.get('SELECT_PAYMENT_PROVIDER')}
                </MenuItem>
                {!!providerPaymentList &&
                  providerPaymentList.map((provider: any, i: number) => (
                    <MenuItem key={i} value={provider}>
                      {provider.provider}
                    </MenuItem>
                  ))}
              </Select>

              <p>{intl.get('PAYMENT_KIND')}</p>
              <Select
                displayEmpty
                id='payment_method'
                variant='outlined'
                value={paymentMethod}
                disabled={providerPayment === ''}
                IconComponent={ExpandMoreIcon}
                className='mb-2'
                onChange={(event: any) => setPaymentMethod(event.target.value)}
              >
                <MenuItem disabled value=''>
                  {intl.get('SELECT_PAYMENT_KIND')}
                </MenuItem>
                {providerPayment !== '' &&
                  providerPayment.methods.map((provider: any, i: number) => (
                    <MenuItem key={i} value={provider}>
                      {provider.text}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>

          <div className='font-weight-bold'>{intl.get('UNREVERSE_ACTION')}</div>
        </PopupWithIconButtons>
      </div>
    )
  }

  const renderLoading = () => {
    if (fetchingRequisition === 'LOADING') {
      return <CircularLoading />
    } else if (fetchingRequisition === 'LOADED') {
      return (
        <>
          {dialogs()}
          {renderView()}
        </>
      )
    } else if (fetchingRequisition === 'ERROR') {
      return intl.get('REQUESTED_NOT_FOUND')
    }
  }

  return <div className='container-home mt-3'>{renderLoading()}</div>
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSetTemplateChild: (child: any) => dispatch(setTemplateChild(child)),
    onGetRequisition: (id: string) => dispatch(getRequisition(id)),
    onGetPaymentsProviders: (all_payments: boolean) => {
      dispatch(getPaymentsProviders(all_payments))
    },

    onRejectEarlyRequisition: (requisitionID: number, comment: string) =>
      dispatch(rejectEarlyRequisition(requisitionID, comment)),

    onConfirmEarlyRequisition: (
      requisitionID: number,
      comment: string,
      fine_values: { owner: number; renter: number },
      fine_target: string,
    ) =>
      dispatch(
        confirmEarlyRequisition(
          requisitionID,
          comment,
          fine_values,
          fine_target,
        ),
      ),
    onMarkAsPaidCharges: (
      chargeLinesIds: Array<number>,
      unitVersionId: number,
      rentID: number,
      provider_name: string,
      provider_method: string,
    ) =>
      dispatch(
        markAsPaidCharges(
          chargeLinesIds,
          unitVersionId,
          rentID,
          provider_name,
          provider_method,
        ),
      ),
    onMarkAsPaidFineOwner: (id: number, provider: string, method: string) =>
      dispatch(markAsPaidFineOwner(id, provider, method)),
  }
}

const mapStateToProps = (state: any) => {
  const { requisition, fetchingRequisition } = state.requisition
  const { paymentsProviders, fetchPaymentsProviders } = state.paymentsProviders
  const { fetchingMarkAsPaid, fethingMarkAsPaidOwnerFine } = state.markAsPaid
  return {
    requisition,
    fetchingRequisition,
    fetchingRejectEarlyRequisition: state.rejectEarlyRequisition.status,
    fetchingconfirmEarlyRequisition: state.confirmEarlyRequisition.status,
    paymentsProviders,
    fetchPaymentsProviders,
    fetchingMarkAsPaid,
    fethingMarkAsPaidOwnerFine,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequisitionsDetails)
