export const getUsersStart = () => {
  return {
    type: 'GET_USERS_STARTED',
  }
}
export const getUsersSuc = (users: any) => {
  return {
    type: 'GET_USERS_SUCCESS',
    payload: users,
  }
}
export const getUserRestart = () => {
  return {
    type: 'GET_USERS_RESET',
  }
}
export const getUsersError = (error: any) => {
  return {
    type: 'GET_USERS_ERROR',
    payload: {
      error,
    },
  }
}
