export const getPaymentsProvidersStart = () => {
    return {
        type: 'GET_PAYMENTS_PROVIDERS_STARTED',
    };
};

export const getPaymentsProvidersSuccess = (data: any) => {
    return {
        type: 'GET_PAYMENTS_PROVIDERS_SUCCESS',
        payload: { data },
    };
};

export const getPaymentsProvidersError = (error: any) => {
    return {
        type: 'GET_PAYMENTS_PROVIDERS_ERROR',
        payload: {
            error,
        },
    };
};
