export const getOwnerStart = () => {
  return {
    type: 'GET_OWNER_PROFILE_STARTED',
  }
}

export const getOwnerSuccess = (owner: any) => {
  return {
    type: 'GET_OWNER_PROFILE_SUCCESS',
    payload: owner,
  }
}
export const getOwnerError = (error: any) => {
  return {
    type: 'GET_OWNER_PROFILE_ERROR',
    payload: {
      error,
    },
  }
}