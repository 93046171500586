import { updateChargeLinesStart, updateChargeLinesSuccess, updateChargeLinesError } from './types';
import intl from 'react-intl-universal';
import { putDataGeneral } from '../../helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../helpers/handleErrors';
const updateChargeLines = (chargelines: any) => {
    return (dispatch: any) => {
        dispatch(updateChargeLinesStart());
        putDataGeneral('admin/charge_lines/update', chargelines)
            .then((result) => {
                dispatch(updateChargeLinesSuccess(result.data));
                toast(intl.get('DATA_SAVED'));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(updateChargeLinesError(e));
            });
    };
};
export default updateChargeLines;
