import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getAdminContract,
  getContract,
} from '../../tango-react-base/reduxActions'
import intl from 'react-intl-universal'
import { Tooltip } from '@material-ui/core'
import { dowloadLogo } from '../../tango-react-base/resources'

type ContractViewProps = {
  idContract: string
  idAdminContract: string
  contract: any
  adminContract: any
  fetchContract: string
  fetchAdminContract: string
  onGetContract: (id: string) => void
  onGetAdminContract: (id: string) => void
}

const ContractView = ({
  idContract,
  idAdminContract,
  contract,
  adminContract,
  fetchContract,
  fetchAdminContract,
  onGetContract,
  onGetAdminContract,
}: ContractViewProps) => {
  useEffect(() => {
    onGetContract(idContract)
    onGetAdminContract(idAdminContract)
  }, [onGetContract, onGetAdminContract, idContract, idAdminContract])

  return (
    <>
      <div className="row py-3">
        {fetchAdminContract === 'FETCHED' && (
          <div className="col-12 pl-0">
            <div className="row">
              <span className="col-6 col-md-4 span-text">
                {intl.get('ADMINISTRATION_CONTRACT')}
              </span>
              <a
                href={adminContract.attributes.url_contract}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <div className="tooltip-delete-logo">
                  <Tooltip title={intl.get('DOWNLOAD_CONTRACT')}>
                    <img
                      src={dowloadLogo}
                      alt="dowloadLogo"
                      className="upload-logo"
                    />
                  </Tooltip>
                </div>
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="row py-3">
        {fetchContract === 'FETCHED' && (
          <div className="col-12 pl-0">
            <div className="row">
              <span className="col-md-4 col-6 span-text">
                {intl.get('LEASE_CONTRACT')}
              </span>
              <a
                href={contract.attributes.url_contract}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <div className="tooltip-delete-logo">
                  <Tooltip title={intl.get('DOWNLOAD_CONTRACT')}>
                    <img
                      src={dowloadLogo}
                      alt="dowloadLogo"
                      className="upload-logo"
                    />
                  </Tooltip>
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { contract, fetchContract } = state.contract
  const { adminContract, fetchAdminContract } = state.adminContract
  return {
    contract,
    fetchContract,
    adminContract,
    fetchAdminContract,
  }
}

const mapDispatchToProps = (dispatch: any, getState: any) => {
  return {
    onGetContract: (id: string) => {
      return dispatch(getContract(id))
    },
    onGetAdminContract: (id: string) => {
      return dispatch(getAdminContract(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractView)
