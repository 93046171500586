import { getAdminContractsStart, getAdminContractsSuccess, getAdminContractsError } from './types';
import { getData } from '../../../tango-react-base/helpers/apiConnections';
import { toast } from 'react-toastify';
import { handleError } from '../../../tango-react-base/helpers/handleErrors';
const getAdminContracts = () => {
    return (dispatch: any, getState: any) => {
        dispatch(getAdminContractsStart());
        getData('admin_contracts')
            .then((result) => {
                return dispatch(getAdminContractsSuccess(result));
            })
            .catch((e) => {
                toast(handleError(e));
                return dispatch(getAdminContractsError(e));
            });
    };
};
export default getAdminContracts;
