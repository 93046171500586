export const sendRenterEvaluationStart = () => {
    return {
        type: 'SEND_PRE_APPROVED_START',
    };
};

export const sendRenterEvaluationSuccess = (payload: any) => {
    return {
        type: 'SEND_PRE_APPROVED_SUCCESS',
        payload: { payload },
    };
};

export const sendRenterEvaluationError = (error: any) => {
    return {
        type: 'SEND_PRE_APPROVED_ERROR',
        payload: {
            error,
        },
    };
};
